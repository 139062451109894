import axios from '@axios'

export default {
  state: {
    ways: [],
  },

  mutations: {
    setWays(state, value) {
      state.ways = value
    },
  },

  actions: {
    fetchWays({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/ways-to-manage').then(response => {
          commit('setWays', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },

  getters: {
    waysList(state) {
      return state.ways
    },
  },
}

// export const myAction = ( state ) => {

// }
export const setJobListData = (state, list) => {
  state.jobList = list
}
export const setJobArrayData = (state, list) => {
  state.jobListArray = list
}
export const addNewJob = (state, job) => {
  state.jobList.data.unshift(job)
}
export const updateJob = (state, job) => {
  const index = state.jobList.data.findIndex(element => element.id === job.id)
  state.jobList.data[index] = job
}
export const deleteJob = (state, job) => {
  const index = state.jobList.data.findIndex(element => element.id === job.id)
  state.jobList.data.splice(index, 1)
}

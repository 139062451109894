import apiInstance from './index'
import queryParams from './queryParams'

const baseURLRelative = '/tags'

export function getTags() {
  // const query = queryParams.serializeParams(tempQuery.paramsObj, tempQuery.filtersObj)
  return apiInstance({
    url: `${baseURLRelative}`,
    method: 'GET',
  })
}
export function showTag(hash) {
  return apiInstance({
    url: `${baseURLRelative}/${hash}`,
    method: 'GET',
  })
}
export function storeTag(data) {
  return apiInstance({
    url: baseURLRelative,
    method: 'POST',
    data,
  })
}
export function updateTag(data) {
  return apiInstance({
    url: `${baseURLRelative}/${data.hash}`,
    method: 'PUT',
    data,
  })
}
export function destroyTag(hash) {
  return apiInstance({
    url: `${baseURLRelative}/${hash}`,
    method: 'DELETE',
  })
}

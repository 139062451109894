import mock from '@/@fake-db/mock'

export const data = [
  { id: 0, name: 'Poderes Especiales' },
  { id: 1, name: 'Actos de Administración' },
  { id: 2, name: 'Actos de Dominio' },
  { id: 3, name: 'Pleitos y Cobranzas' },
]

mock.onGet('/clubs/powers-granted').reply(() => [200, data])

export default () => ({
  teamList: [],
  jerseyNumbers: [],
  team: {
    name: null,
    number_participants: 50,
    number_participants_min: 1,
    number_participants_max: 51,
    excluded_numbers: null,
    club_hash: null,
    club: {
      country_hash: null,
      state_hash: null,
    },
    status: true,
  },
})

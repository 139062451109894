import axios from '@axios'

export default {
  state: {
    power: {},
    powerX: {},
    powers: [],
  },

  mutations: {
    setPowersInfo(state, value) {
      state.powers = value
    },

    setPowerInfo(state, value) {
      state.power = value
    },

    setPowerInfoX(state, value) {
      state.powerX = value
    },
  },

  actions: {
    fetchPowersByClubId({ commit }, powerFilters) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux/${powerFilters.clubId}/powers`, { params: { filters: powerFilters } }).then(response => {
          commit('setPowersInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    savePower({ commit }, power) {
      return new Promise((resolve, reject) => {
        axios.post(`/clubs-aux-save/${power.club_id}/powers`, power).then(response => {
          commit('setPowersInfo', response.data.powerData)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    showPowerByClubId({ commit }, powerObj) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux-show/${powerObj.clubId}/powers/${powerObj.powerId}`).then(response => {
          commit('setPowerInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    showPowerByClubIdX({ commit }, powerObj) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux-show/${powerObj.clubId}/powers/${powerObj.powerId}`).then(response => {
          commit('setPowerInfoX', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    fetchPowerInfo({ commit }, powerObj) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux-edit/${powerObj.clubId}/powers/${powerObj.powerId}/edit`).then(response => {
          commit('setPowerInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    updatePower({ commit }, powerP) {
      return new Promise((resolve, reject) => {
        axios.post(`/clubs-aux-update/${powerP.club_id}/powers`, powerP).then(response => {
          commit('setPowersInfo', response.data.powerData)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },

  getters: {
    powersList(state) {
      return state.powers
    },

    powerInfo(state) {
      return state.power
    },

    powerInfoX(state) {
      return state.powerX
    },
  },
}

import mock from '@/@fake-db/mock'

const data = [{
  id: 1,
  key: 'PUBLISH',
  name: 'Publicar',
}, {
  id: 0,
  key: 'UNPUBLISHED',
  name: 'Sin publicar',
}]

mock.onGet('/new-status').reply(() => [200, data])

export default data

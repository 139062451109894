export default {
  state: {
    colorRGB: '',
    colorRGBSidebar: '',
  },

  mutations: {
    setColorSidebar(state, value) {
      state.colorRGBSidebar = `${value}`
    },

    setColorRGB(state, value) {
      state.colorRGB = `${value}`
    },
  },

  actions: {
    setColorValueSidebar({ commit }, color) {
      commit('setColorSidebar', color)
    },

    setColorValue({ commit }, color) {
      commit('setColorRGB', color)
    },
  },

  getters: {
    colorRGBSidebarX(state) {
      return state.colorRGBSidebar
    },

    colorRGBX(state) {
      return state.colorRGB
    },
    colorPrimaryBtn(state) {
      const colorBtn = {
        'background-color': `${state.colorRGB} !important`,
        'border-color': `${state.colorRGB} !important`,
        'box-shadow': `${state.colorRGB} 0px 8px 25px -8px !important`,
      }
      return colorBtn
    },
  },
}

import axios from '@axios'

export default {
  state: {
    dataOfRepresentative: {},
    dataOfRepresentativeX: {},
    dataRepresentatives: [],
    dataAssignRepresentative: [],
    allDataAssignRepresentatives: [],
    dataAssignRepresentativeInstrument: [],
    dataAssignRepresentativePartnerRepresentative: [],
    dataAssignRepresentativePowerGranted: [],
  },

  mutations: {
    setAllAssignationOfRepresentatives(state, value) {
      state.allDataAssignRepresentatives = value
    },

    setAssignationOfRepresentatives(state, value) {
      state.dataAssignRepresentative = value
    },

    setAssignOfRepresentativeInstrument(state, value) {
      state.dataAssignRepresentativeInstrument = value
    },

    setAssignOfRepresentativePowerGranted(state, value) {
      state.dataAssignRepresentativePowerGranted = value
    },

    setRepresentatives(state, value) {
      state.dataRepresentatives = value
    },

    setAssignOfRepresentativePartnerRepresentative(state, value) {
      state.dataAssignRepresentativePartnerRepresentative = value
    },

    setAssignationOfRepresentative(state, value) {
      state.dataOfRepresentative = value
    },
  },

  actions: {
    fetchAssignOfRepresentativesByClubId({ commit }, representativeFilters) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux/${representativeFilters.clubId}/assign-representatives`, { params: { filters: representativeFilters } }).then(response => {
          commit('setAllAssignationOfRepresentatives', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    saveAssignationOfRepresentatives({ commit }, representative) {
      return new Promise((resolve, reject) => {
        axios.post(`/clubs-aux-save/${representative.club_id}/assign-representatives`, representative).then(response => {
          commit('setAssignationOfRepresentatives', response.data.inserts.data)
          commit('setAssignOfRepresentativeInstrument', response.data.inserts.dataARI)
          commit('setAssignOfRepresentativePartnerRepresentative', response.data.inserts.dataARPR)
          commit('setRepresentatives', response.data.inserts.dataRepresentatives)
          commit('setAssignOfRepresentativePowerGranted', response.data.inserts.dataARPG)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    showAssignRepresentativeByClubId({ commit }, representatives) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux-show/${representatives.clubId}/assign-representatives/${representatives.representativeId}`).then(response => {
          commit('setAssignationOfRepresentative', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },

  getters: {
    dataOfRepresentativesList(state) {
      return state.allDataAssignRepresentatives
    },

    assignRepresentativeInfo(state) {
      return state.dataOfRepresentative
    },
  },
}

import mock from '@/@fake-db/mock'

export const data = [
  {
    id: 1,
    assign_representative_id: 1,
    power_granted_id: 1,
    special_powers_granted: null,
  },
]

import axios from '@axios'

export default {
  state: {
    societies: [],
    societyDetail: {},
    society: {
      id: null,
      club_id: null,
      incorporation_date: '',
      instrument_number_id: null,
      society_object: '',
      duration: null,
      apply_foreign_users: false,
      way_to_manage_id: null,
      other_option: '',
      management_structure: '',
      observations: '',
      commissioner: '',
      partners: [],
      created_at: '',
      status: 1,
    },
  },

  mutations: {
    setSocietiesByClubId(state, value) {
      state.societies = value
    },

    setSocietyInfo(state, value) {
      state.society = value
    },

    saveNewSociety(state, value) {
      state.societies = value
    },

    updateSocietyX(state, value) {
      state.societies = value
    },

    deleteSociety(state, value) {
      state.societies.splice(value, 1)
    },

    setDetailSocietyInfo(state, value) {
      state.societyDetail = value
    },
  },

  actions: {
    fetchSocietiesByClubId({ commit }, societyFilters) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux/${societyFilters.clubId}/societies`, { params: { filters: societyFilters } }).then(response => {
          commit('setSocietiesByClubId', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    fetchSocietyInfo({ commit }, societyObj) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux-edit/${societyObj.clubId}/societies/${societyObj.societyId}/edit`).then(response => {
          commit('setSocietyInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    saveSociety({ commit }, society) {
      return new Promise((resolve, reject) => {
        axios.post(`/clubs-aux-save/${society.club_id}/societies`, society).then(response => {
          commit('saveNewSociety', response.data.societyData)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    updateSociety({ commit }, societyU) {
      return new Promise((resolve, reject) => {
        axios.post(`/clubs-aux-update/${societyU.club_id}/societies`, societyU).then(response => {
          commit('updateSocietyX', response.data.societyData)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    showSocietyByClubId({ commit }, societyObjX) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux-show/${societyObjX.clubId}/societies/${societyObjX.societyId}`).then(response => {
          commit('setSocietyInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    deleteSocietyByClubId({ commit }, societyObjW) {
      return new Promise((resolve, reject) => {
        axios.delete(`/clubs-aux-delete/${societyObjW.clubId}/societies/${societyObjW.societyId}`).then(response => {
          commit('deleteSociety', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    showDetailOfSocietyByClubId({ commit }, societyObjX) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux-show/${societyObjX.clubId}/societies/${societyObjX.societyId}`).then(response => {
          commit('setDetailSocietyInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    fetchCurrentSocietyByClubId({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux/${id}/society`).then(response => {
          commit('setSocietyInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },

  getters: {
    societiesList(state) {
      return state.societies
    },

    societyInfo(state) {
      return state.society
    },

    societyDetailInfo(state) {
      return state.societyDetail
    },
  },
}

import axios from '@axios';

export default {
    state: {
        countries: [],
    },
    mutations: {
        setNacionalities(state, value) {
            state.countries = value
        },
    },
    actions: {
        getNacionalities({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('/nacionalities').then(({ data }) => {
                    commit('setNacionalities', data)
                    resolve(data)
                }).catch(error => reject(error))
            })
        },
    },
}
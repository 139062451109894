// import axios from '@axios'
import {
  getTags,
  storeTag,
  updateTag,
  destroyTag,
} from '@/services/tagService'

export default {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {
    list(state) {
      return state.list
    },
  },
  mutations: {
    setList(state, value) {
      state.list = value
    },
  },
  actions: {
    fetchTags({ commit }) {
      return new Promise((resolve, reject) => {
        getTags().then(response => {
          commit('setList', response.data.data)
          resolve(response)
        })
          .catch(error => reject(error))
      })
    },
    addTag(ctx, tagData) {
      return new Promise((resolve, reject) => {
        storeTag(tagData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTag(ctx, { tag }) {
      return new Promise((resolve, reject) => {
        updateTag(tag).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeTag(ctx, id) {
      return new Promise((resolve, reject) => {
        destroyTag(id).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

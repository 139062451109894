import {
  getTeams, storeTeam, updateStatus, updateTeam, destroyTeam, numberOfTeamJersey,
} from '@/services/teamService'

export const fetchData = ({ commit }, tempQuery) => new Promise((resolve, reject) => {
  getTeams(tempQuery)
    .then(response => {
      commit('setTeamListData', response.data)
      resolve(response)
    }).catch(error => {
      reject(error)
    })
})
export const createNewTeam = ({ commit }, newTeam) => new Promise((resolve, reject) => {
  storeTeam(newTeam).then(response => {
    commit('addNewTeam', response.data.data)
    resolve(response)
  }).catch(error => {
    reject(error)
  })
})
export const update = ({ commit }, team) => new Promise((resolve, reject) => {
  updateTeam(team).then(response => {
    commit('updateTeam', response.data.data)
    resolve(response)
  }).catch(error => {
    reject(error)
  })
})
export const destroy = ({ commit }, hash) => new Promise((resolve, reject) => {
  destroyTeam(hash).then(response => {
    commit('deleteTeam', response.data.data)
    resolve(response)
  }).catch(error => {
    reject(error)
  })
})
export const changeStatusTeam = ({ commit }, teamHash) => new Promise((resolve, reject) => {
  updateStatus(teamHash).then(response => {
    commit('updateTeam', response.data.data)
    resolve(response)
  }).catch(error => {
    reject(error)
  })
})

export const jerseyNumbersByteamId = ({ commit }, hash) => new Promise((resolve, reject) => {
  numberOfTeamJersey(hash).then(response => {
    commit('setJerseyNumbers', response.data)
    resolve(response)
  }).catch(error => {
    reject(error)
  })
})

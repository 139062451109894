// import axios from '@axios'
import {
  categories,
  genders,
  typeJurisdictions,
  typeContracts,
  typeOperations,
  typePaymentperiods,
  playerStatuses,
  staffJobPositions,
  membersTypes,
  educationLevels,
  nationalities,
} from '@/services/catalogsService'

export default {
  namespaced: true,
  state: {
    categories: [],
    genders: [],
    typeJurisdictions: [],
    typeContracts: [],
    typeOperations: [],
    typePaymentperiods: [],
    playerStatuses: [],
    staffJobPositions: [],
    membersTypes: [],
    educationLevels: [],
    nationalities: [],
  },
  getters: {
    categories(state) {
      const list = state.categories.map(c => ({ id: c.hash, name: c.labelables[0].label }))
      return list
    },
    genders(state) {
      const list = state.genders.map(c => ({ id: c.hash, name: c.labelables[0].label }))
      return list
    },
    // gender(state){
    //   const gender = state.genders.find(e => e.)
    //   return gender
    // }
    typeJurisdictions(state) {
      const list = state.typeJurisdictions.map(c => ({ id: c.hash, key: c.key, name: c.labelables[0].label }))
      return list
    },
    typeContracts(state) {
      const list = state.typeContracts.map(c => ({ id: c.hash, key: c.key, name: c.labelables[0].label }))
      return list
    },
    typeOperations(state) {
      const list = state.typeOperations.map(c => ({ id: c.hash, key: c.key, name: c.labelables[0].label }))
      return list
    },
    typePaymentperiods(state) {
      const list = state.typePaymentperiods.map(c => ({ id: c.hash, key: c.key, name: c.labelables[0].label }))
      return list
    },
    playerStatuses(state) {
      const list = state.playerStatuses.map(c => ({ id: c.hash, key: c.key, name: c.labelables[0].label }))
      return list
    },
    staffJobPositions(state) {
      const list = state.staffJobPositions.map(c => ({ id: c.hash, name: c.labelables[0].label }))
      return list
    },
    membersTypes(state) {
      const list = state.membersTypes.map(c => ({
        id: c.hash, key: c.key, gender_hash: c.gender_hash, name: c.labelables[0].label,
      }))
      return list
    },
    educationLevels(state) {
      const list = state.educationLevels.map(c => ({ id: c.hash, name: c.labelables[0].label }))
      return list
    },
    nationalities(state) {
      const list = state.nationalities.map(c => ({
        id: c.hash, key: c.key, country_id: c.country_id, name: c.labelables[0].label,
      }))
      return list
    },
  },
  mutations: {
    setCategories(state, value) {
      state.categories = value
    },
    setGenders(state, value) {
      state.genders = value
    },
    setTypeJurisdictions(state, value) {
      state.typeJurisdictions = value
    },
    setTypeContracts(state, value) {
      state.typeContracts = value
    },
    setTypeOperations(state, value) {
      state.typeOperations = value
    },
    setTypePaymentperiods(state, value) {
      state.typePaymentperiods = value
    },
    setPlayerStatuses(state, value) {
      state.playerStatuses = value
    },
    setStaffJobPositions(state, value) {
      state.staffJobPositions = value
    },
    setMembersTypes(state, value) {
      state.membersTypes = value
    },
    setEducationLevels(state, value) {
      state.educationLevels = value
    },
    setNationalities(state, value) {
      state.nationalities = value
    },
  },
  actions: {
    fetchCategories({ commit }, tempQuery) {
      return new Promise((resolve, reject) => {
        categories(tempQuery).then(response => {
          commit('setCategories', response.data.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    fetchGenders({ commit }, tempQuery) {
      return new Promise((resolve, reject) => {
        genders(tempQuery).then(response => {
          commit('setGenders', response.data.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    fetchTypeJustifications({ commit }) {
      return new Promise((resolve, reject) => {
        typeJurisdictions().then(response => {
          commit('setTypeJurisdictions', response.data.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    fetchTypeContracts({ commit }) {
      return new Promise((resolve, reject) => {
        typeContracts().then(response => {
          commit('setTypeContracts', response.data.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    fetchTypeOperations({ commit }) {
      return new Promise((resolve, reject) => {
        typeOperations().then(response => {
          commit('setTypeOperations', response.data.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    fetchTypePaymentperiods({ commit }) {
      return new Promise((resolve, reject) => {
        typePaymentperiods().then(response => {
          commit('setTypePaymentperiods', response.data.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    fetchPlayerStatuses({ commit }) {
      return new Promise((resolve, reject) => {
        playerStatuses().then(response => {
          commit('setPlayerStatuses', response.data.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    fetchStaffJobPositions({ commit }, tempQuery) {
      return new Promise((resolve, reject) => {
        staffJobPositions(tempQuery).then(response => {
          commit('setStaffJobPositions', response.data.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    fetchMembersTypes({ commit }) {
      return new Promise((resolve, reject) => {
        membersTypes().then(response => {
          commit('setMembersTypes', response.data.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    fetchEducationLevels({ commit }) {
      return new Promise((resolve, reject) => {
        educationLevels().then(response => {
          commit('setEducationLevels', response.data.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    fetchNationalities({ commit }) {
      return new Promise((resolve, reject) => {
        nationalities().then(response => {
          commit('setNationalities', response.data.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },
}

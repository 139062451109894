import mock from '@/@fake-db/mock'

export const data = [
  { 
    id: 1,
    name: 'Amateur femenil',
    type: 'female',
  },
  { 
    id: 2,
    name: 'Profesional femenil',
    type: 'female',
  },
  { 
    id: 3,
    name: 'Amateur varonil',
    type: 'male',
  },
  { 
    id: 4,
    name: 'Profesional varonil',
    type: 'male',
  },
]

mock.onGet('/categories').reply(() => [200, data])

mock.onGet('/category/filter').reply(({ params }) => {
  const { type } = params

  const categories = data.filter(item => item.type === type)

  return [200, categories]
})

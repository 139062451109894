import apiInstance from './index'
import queryParams from './queryParams'

export function getTeams(tempQuery) {
  // const query = queryParams.serializeParams(tempQuery.paramsObj, tempQuery.filtersObj)
  let query = ''
  if (tempQuery) {
    query = queryParams.serializeParams(tempQuery.paramsObj, tempQuery.filtersObj)
  }
  return apiInstance({
    url: `/teams${query}`,
    method: 'GET',
    // params: query,
  })
}

export function storeTeam(data) {
  return apiInstance({
    url: '/teams',
    method: 'POST',
    data,
  })
}
export function updateTeam(data) {
  return apiInstance({
    url: `/teams/${data.hash}`,
    method: 'PUT',
    data,
  })
}
export function destroyTeam(hash) {
  return apiInstance({
    url: `/teams/${hash}`,
    method: 'DELETE',
  })
}
export function updateStatus(hash) {
  return apiInstance({
    url: `/teams/status/${hash}`,
    method: 'PUT',
  })
}
export function numberOfTeamJersey(tempQuery) {
  const query = queryParams.serializeParams(tempQuery)
  return apiInstance({
    url: `/teams/jersey/number${query}`,
    method: 'GET',
  })
}

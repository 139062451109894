import axios from '@axios'

export default {
  state: {
    artisticWorks: [],
    artisticWork: {},
  },

  mutations: {
    setLiteraryOrArtisticWorks(state, value) {
      state.artisticWorks.push(value)
    },
    setArtisticWorks(state, value) {
      state.artisticWorks = value
    },
  },

  actions: {
    fetchLiteraryOrArtisticWorkByClubId({ commit }, powerFilters) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs/${powerFilters.clubId}/literary-or-artistic-works`, { params: { filters: powerFilters } }).then(response => {
          commit('setLiteraryOrArtisticWorkInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    saveLiteraryOrArtisticWorks({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.post('/clubs/literary-or-artistic-work', params).then(response => {
          commit('setLiteraryOrArtisticWorks', response.data.artisticWork)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },

  getters: {
    artisticWorksList(state) {
      return state.artisticWorks
    },
  },
}

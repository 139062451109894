const data = [
  {
    id: 1,
    user_id: 1,
    role_id: 2,
  },
  {
    id: 2,
    user_id: 2,
    role_id: 1,
  },
  {
    id: 3,
    user_id: 3,
    role_id: 3,
  },
  {
    id: 4,
    user_id: 4,
    role_id: 4,
  },
  {
    id: 5,
    user_id: 5,
    role_id: 1,
  },
  {
    id: 6,
    user_id: 4,
    role_id: 7,
  },
  // {
  //   id: 7,
  //   user_id: 2,
  //   role_id: 7,
  // },
]

export default data

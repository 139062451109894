import mock from '@/@fake-db/mock'

export const data = [
  {
    id: 0,
    name: 'Activo',
    key: 'ACTIVE',
    value: 1,
  },
  {
    id: 1,
    name: 'Cedido a',
    key: 'ON_LOAN_TO',
    value: 0,
  },
  {
    id: 2,
    name: 'Cedido por',
    key: 'LOANED_BY',
    value: 0,
  },
]

mock.onGet('/status').reply(() => [200, data])

/* eslint-disable no-param-reassign */
import mock from '@/@fake-db/mock'
import checkPermission from '@/auth/permissions'
import { paginateArray } from '@/@fake-db/utils'
import { data as dataClubes } from '@/@fake-db/data/clubs/club-setting'
import { data as dataCountries } from '@/@fake-db/data/catalogs/countries'
import { data as dataCities } from '@/@fake-db/data/catalogs/cities'
import { data as dataNationatilies } from '@/@fake-db/data/catalogs/nacionalities'
import { data as dataCategories } from '@/@fake-db/data/catalogs/categories'
import { data as dataStatus } from '@/@fake-db/data/catalogs/status'
import { data as dataJobStaff } from '@/@fake-db/data/catalogs/jobs-staff'
import { data as dataGenders } from '@/@fake-db/data/catalogs/genders'
import { data as dataStudies } from '@/@fake-db/data/catalogs/study-levels'
import { data as dataMemberType } from '@/@fake-db/data/catalogs/member-type'
import dataTeams from '@/@fake-db/data/team/team'
import dataTodo from '@/@fake-db/data/todo'
import dataUserClub from '@/@fake-db/data/clubs/club-user'
import store from '@/store'
import ostrail from '../clubs/ostrail'
// import contrato from './contrato'

const avatar1 = require('@/assets/images/members/cris.jpg')
const avatar2 = require('@/assets/images/members/Ale.jpg')
const avatar3 = require('@/assets/images/members/messi.jpg')
const avatar4 = require('@/assets/images/members/kim.jpg')
const avatar5 = require('@/assets/images/members/javi.webp')
const avatar6 = require('@/assets/images/members/eva.webp')
const avatar7 = require('@/assets/images/members/pique.webp')
const avatar8 = require('@/assets/images/members/auba.webp')
const avatar9 = require('@/assets/images/members/adama.webp')
const avatar10 = require('@/assets/images/members/xavi.webp')

export const data = [
  {
    id: 1,

    member_type_id: 0,
    team_id: 14,
    // Integrante
    avatar: avatar1,
    category_id: 3,
    validityInitDate: '2017-03-29',
    validityEndDate: '2023-03-29',
    path_file_contract: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_terms: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    registration_in_federation: '123456',
    date_of_admission: '2023-03-01',
    number: '10',
    status: 1,
    id_fifa: 123456,

    // General
    fullName: 'Cristiano Ronaldo',
    name: 'Cristiano',
    first_name: 'Ronaldo',
    last_name: '',
    birthday: '1996-05-10 21:37:14',
    country_birth_id: 110,
    state_birth_id: 1,
    last_degree_studies_id: 2,
    address: {
      address: 'Calle 16 de septiembre, Col. Allende',
      country_id: 110,
      state_id: 1,
      zip_code: 190,
    },
    email: 'cristian@correo.com',
    cell_phone: '5547113677',

    // identificación
    curp: '54D5S6F4SD6F',
    path_file_curp: '',
    id_type: null,
    id_number: 'S5F4S6F5',
    path_file_id: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_birth_certificate: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_another_document: '',

    nationalities: [{
      id: 1,
      nationality_id: 110,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 0,
    }, {
      id: 2,
      nationality_id: 109,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 1,
    }],
    // Visa
    visas: [{
      id: 1,
      expedition_country: 110,
      path_file_visa: null,
      visa_number: '2DS1F3SF',
      type_of_visa: 'Visa de residencia temporal',
      visa_expiration: '2023-03-18 21:37:14',
    }],
    sport_club_id: 2,

    gender_id: null,
    job_position_id: null,
    another_job: null,

    // TODO: conditions
    conditions: {
      id: 1,
      member_id: null,
      type_contractual_conditions: 'TRANSFER', // FREE_CONTRACT or TRANSFER
      descrition_contractual_conditions: 'Conditions id 1 crsi',
      // Transfer
      transfer: {
        operations: 'DOMESTIC',
        tms_number: null, // only international -> operations
        type_operation_id: 3,
        type_operation: {
          id: 3,
          key: 'TEMPORARY',
          name: 'Temporal sin costo',
          status: 1,
        }, // ! No add database
        init_date_operation: '2020-03-15 21:37:14',
        end_date_operation: '2023-03-15 21:37:14',
        ceding_club: 'Barcelona',
        percentage_transfer_economic_rights: null,
        transfer_conditions: null, // Solo si no es el 100 de transferencia
        contract_agreement: 'YES', // yes no

        // yes
        assign_representative_id: null,
        signature_date: null,
        init_date: null,
        end_date: null,
        against_provision: {
          has_fixed_quantity: false,
          fixed_quantity: null, // Amount
          has_variable_quantity: false,
          variable_quantity: null,
        },
        method_payment: null,
        payment_period: {
          id: 4,
          name: 'Otro',
          key: 'OTHER',
          status: 1,
        }, // ! No add database
        payment_period_id: null,
        payment_period_other: 'Cada 10 días',
        penalty_amount: null,

        // Notification Address
        address: {
          address: null,
          zip_code: '07199',
          country_id: null,
          state_id: null,
        },
        email: 'user@gmail.com',
        jurisdiction: null, // ! No add database
        jurisdiction_id: null,
        jurisdiction_other: null,
        law_applicable_contract: null,

        // Option Agente
        player_agent: 'YES',

        // Yes
        agent: {
          id: 1,
          name: 'Primer Agente',
          license_number: null,
          role: null,
          payment_amount: null,
          method_payment: null,
          payment_period: null, // ! No add database
          payment_period_id: null,
          payment_period_other: null,
          init_effective_date: null,
          end_effective_date: null,
          document_path: null,
          assign_representative_id: null,
        },
      },

      // General
      type_contract: null, // ! No add database
      type_contract_id: 5,
      type_contract_other: null,
      assign_representative_id: null,
      signature_date: null,
      init_date: null,
      end_date: null,
      against_provision: {
        has_fixed_quantity: false,
        fixed_quantity: null, // Amount
        has_variable_quantity: false,
        variable_quantity: null,
      },
      increments: [{
        id: 1,
        value: null,
      }],
      increase_conditions: null,

      method_payment: null,
      payment_period: null, // ! No add database
      payment_period_id: null,
      payment_period_other: null,
      bonds: null,
      benefits: [{
        id: 1,
        value: null,
      }],
      compensation_amount: null,
      compensation_details: null,

      penalty_amount: null,
      penalty_details: null,

      rescission_amount: null,
      rescission_details: null,

      // Notificartion Address
      address: {
        address: null,
        zip_code: null,
        country_id: null,
        state_id: null,
      },
      email: null,
      jurisdiction: null, // ! No add database
      jurisdiction_id: null,
      jurisdiction_other: null,
      law_applicable_contract: null,

      // Agents general
      player_agent: 'YES',

      // Yes
      agent: {
        id: 2,
        name: 'Segundo Agente',
        license_number: '132SDSSAD321',
        role: 'Adinistrador',
        payment_amount: null,
        method_payment: null,
        payment_period: null, // ! No add database
        payment_period_id: null,
        payment_period_other: null,
        init_effective_date: null,
        end_effective_date: null,
        document_path: null,
        assign_representative_id: null,
      },
    },
    isCompleteData: true,
  },
  {
    id: 2,

    team_id: 7,
    member_type_id: 2,
    // Integrante
    avatar: avatar2,
    category_id: 2,
    validityInitDate: '2020-03-29',
    validityEndDate: '2025-03-29',
    path_file_contract: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_terms: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    registration_in_federation: '123456',
    date_of_admission: '2023-03-15 21:37:14',
    number: '11',
    status: 1,
    id_fifa: 123456,

    // General
    fullName: 'Alexandra Popp',
    name: 'Alexandra',
    first_name: 'Popp',
    last_name: '',
    birthday: '1990-08-10 21:37:14',
    country_birth_id: 110,
    state_birth_id: 2,
    last_degree_studies_id: 2,
    address: {
      address: 'Calle 16 de septiembre, Col. Allende',
      country_id: 110,
      state_id: 1,
      zip_code: 190,
    },
    email: 'popp@correo.com',
    cell_phone: '5547113677',

    // identificación
    curp: '54D5S6F4SD6F',
    path_file_curp: '',
    id_type: null,
    id_number: 'S5F4S6F5',
    path_file_id: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_birth_certificate: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_another_document: '',

    // Nationality
    nationalities: [{
      id: 1,
      nationality_id: 110,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 0,
    }, {
      id: 2,
      nationality_id: 109,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 1,
    }],
    // Visa
    visas: [{
      id: 1,
      expedition_country: 110,
      path_file_visa: null,
      visa_number: '2DS1F3SF',
      type_of_visa: 'Visa de residencial permamente',
      visa_expiration: '2023-03-18 21:37:14',
    }],

    gender_id: null,
    sport_club_id: 2,
    job_position_id: null,
    another_job: null,
    conditions: {
      id: 1,
      member_id: null,
      type_contractual_conditions: 'TRANSFER', // FREE_CONTRACT or TRANSFER
      descrition_contractual_conditions: 'Conditions id 1 crsi',
      // Transfer
      transfer: {
        operations: 'DOMESTIC',
        tms_number: null, // only international -> operations
        type_operation_id: 3,
        type_operation: {
          id: 3,
          key: 'TEMPORARY',
          name: 'Temporal sin costo',
          status: 1,
        }, // ! No add database
        init_date_operation: '2020-03-15 21:37:14',
        end_date_operation: '2023-03-15 21:37:14',
        ceding_club: null,
        percentage_transfer_economic_rights: null,
        transfer_conditions: null, // Solo si no es el 100 de transferencia
        contract_agreement: 'YES', // yes no

        // yes
        assign_representative_id: null,
        signature_date: null,
        init_date: null,
        end_date: null,
        against_provision: {
          has_fixed_quantity: false,
          fixed_quantity: null, // Amount
          has_variable_quantity: false,
          variable_quantity: null,
        },
        method_payment: null,
        payment_period: {
          id: 4,
          name: 'Otro',
          key: 'OTHER',
          status: 1,
        }, // ! No add database
        payment_period_id: null,
        payment_period_other: 'Cada 10 días',
        penalty_amount: null,

        // Notification Address
        address: {
          address: null,
          zip_code: '07199',
          country_id: null,
          state_id: null,
        },
        email: 'user@gmail.com',
        jurisdiction: null, // ! No add database
        jurisdiction_id: null,
        jurisdiction_other: null,
        law_applicable_contract: null,

        // Option Agente
        player_agent: 'YES',

        // Yes
        agent: {
          id: 1,
          name: 'Primer Agente',
          license_number: null,
          role: null,
          payment_amount: null,
          method_payment: null,
          payment_period: null, // ! No add database
          payment_period_id: null,
          payment_period_other: null,
          init_effective_date: null,
          end_effective_date: null,
          document_path: null,
          assign_representative_id: null,
        },
      },

      // General
      type_contract: null, // ! No add database
      type_contract_id: 5,
      type_contract_other: null,
      assign_representative_id: 2,
      signature_date: null,
      init_date: null,
      end_date: null,
      against_provision: {
        has_fixed_quantity: false,
        fixed_quantity: null, // Amount
        has_variable_quantity: false,
        variable_quantity: null,
      },
      increments: [{
        id: 1,
        value: null,
      }],
      increase_conditions: null,

      method_payment: null,
      payment_period: null, // ! No add database
      payment_period_id: null,
      payment_period_other: null,
      bonds: null,
      benefits: [{
        id: 1,
        value: null,
      }],
      compensation_amount: null,
      compensation_details: null,

      penalty_amount: null,
      penalty_details: null,

      rescission_amount: null,
      rescission_details: null,

      // Notificartion Address
      address: {
        address: null,
        zip_code: null,
        country_id: null,
        state_id: null,
      },
      email: null,
      jurisdiction: null, // ! No add database
      jurisdiction_id: null,
      jurisdiction_other: null,
      law_applicable_contract: null,

      // Agents general
      player_agent: 'YES',

      // Yes
      agent: {
        id: 2,
        name: 'Segundo Agente',
        license_number: '132SDSSAD321',
        role: 'Adinistrador',
        payment_amount: null,
        method_payment: null,
        payment_period: null, // ! No add database
        payment_period_id: null,
        payment_period_other: null,
        init_effective_date: null,
        end_effective_date: null,
        document_path: null,
        assign_representative_id: null,
      },
    },
    isCompleteData: true,
  },
  {
    id: 3,

    team_id: 6,
    member_type_id: 0,
    // Integrante
    avatar: avatar3,
    category_id: 4,
    validityInitDate: '2020-03-29',
    validityEndDate: '2021-03-29',
    path_file_contract: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_terms: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    registration_in_federation: '123456',
    date_of_admission: '2023-02-10 21:37:14',
    number: '30',
    status: 1,
    id_fifa: 123456,

    // General
    fullName: 'Lionel Messi',
    name: 'Lionel',
    first_name: 'Messi',
    last_name: '',
    birthday: '1989-08-15 21:37:14',
    country_birth_id: 110,
    state_birth_id: 3,
    last_degree_studies_id: 2,
    address: {
      address: 'hanck',
      country_id: 110,
      state_id: 2,
      zip_code: 190,
    },
    email: 'lionel@correo.com',
    cell_phone: '5547113677',

    // identificación
    curp: '54D5S6F4SD6F',
    path_file_curp: '',
    id_type: null,
    id_number: 'S5F4S6F5',
    path_file_id: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_birth_certificate: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_another_document: '',

    // Nationality
    nationalities: [{
      id: 1,
      nationality_id: 110,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 0,
    }, {
      id: 2,
      nationality_id: 109,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 1,
    }],
    // Visa
    visas: [{
      id: 1,
      expedition_country: 110,
      path_file_visa: null,
      visa_number: '2DS1F3SF',
      type_of_visa: 'Visa de residencial permamente',
      visa_expiration: '2023-03-18 21:37:14',
    }],

    gender_id: null,
    sport_club_id: 1,
    job_position_id: null,
    another_job: null,
    isCompleteData: false,
  },

  {
    id: 4,
    member_type_id: 2,
    team_id: 21,

    // Integrante
    avatar: avatar4,
    category_id: 2,
    validityInitDate: '2018-03-29',
    validityEndDate: '2024-03-29',
    path_file_contract: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_terms: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    registration_in_federation: '123456',
    date_of_admission: '2023-03-15 21:37:14',
    number: '12',
    status: 1,
    id_fifa: 123456,

    // General
    fullName: 'kim Little',
    name: 'Kim',
    first_name: 'Little',
    last_name: '',
    birthday: '1990-08-10 21:37:14',
    country_birth_id: 110,
    state_birth_id: 4,
    last_degree_studies_id: 3,
    address: {
      address: 'Calle 16 de septiembre, Col. Allende',
      country_id: 110,
      state_id: 1,
      zip_code: 190,
    },
    email: 'kim@correo.com',
    cell_phone: '5547113677',

    // identificación
    curp: '54D5S6F4SD6F',
    path_file_curp: '',
    id_type: null,
    id_number: 'S5F4S6F5',
    path_file_id: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_birth_certificate: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_another_document: '',

    // Nationality
    nationalities: [{
      id: 1,
      nationality_id: 110,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 0,
    }, {
      id: 2,
      nationality_id: 109,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 1,
    }],
    // Visa
    visas: [{
      id: 1,
      expedition_country: 110,
      path_file_visa: null,
      visa_number: '2DS1F3SF',
      type_of_visa: 'Visa de residencial permamente',
      visa_expiration: '2023-03-18 21:37:14',
    }],

    gender_id: null,
    sport_club_id: 3,
    job_position_id: null,
    another_job: null,

    // isCompleteRegister
    isCompleteData: false,
  },

  // Cuerpo tecnico
  {
    id: 5,

    member_type_id: 1,
    team_id: 14,
    // Integrante
    avatar: avatar5,
    category_id: 4,
    validityInitDate: '2020-03-29',
    validityEndDate: '2025-03-29',
    path_file_contract: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_terms: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    registration_in_federation: '123456',
    date_of_admission: '2023-02-10 21:37:14',
    number: null,
    status: null,

    // General
    fullName: 'Javier Aguirre',
    name: 'Javier',
    first_name: 'Aguirre',
    last_name: '',
    birthday: '1989-08-15 21:37:14',
    country_birth_id: 110,
    state_birth_id: 5,
    last_degree_studies_id: 2,
    address: {
      address: 'Calle 16 de septiembre, Col. Allende',
      country_id: 110,
      state_id: 2,
      zip_code: 190,
    },
    email: 'javier@correo.com',
    cell_phone: '5547113677',

    // identificación
    curp: '54D5S6F4SD6F',
    path_file_curp: '',
    id_type: null,
    id_number: 'S5F4S6F5',
    path_file_id: null,
    path_file_birth_certificate: null,
    path_file_another_document: null,

    // Nationality
    nationalities: [{
      id: 1,
      nationality_id: 110,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 0,
    }, {
      id: 2,
      nationality_id: 109,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 1,
    }],
    // Visa
    visas: [{
      id: 1,
      expedition_country: 110,
      path_file_visa: null,
      visa_number: '2DS1F3SF',
      type_of_visa: 'Visa de residencial permamente',
      visa_expiration: '2023-03-18 21:37:14',
    }],

    gender_id: 1,
    sport_club_id: 2,
    job_position_id: 2,
    another_job: null,
    isCompleteData: true,
  },

  {
    id: 6,

    member_type_id: 1,
    team_id: 16,
    // Integrante
    avatar: avatar6,
    category_id: null,
    validityInitDate: '2018-03-29',
    validityEndDate: '2025-03-29',
    path_file_contract: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_terms: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    registration_in_federation: '123456',
    date_of_admission: '2023-02-10 21:37:14',
    number: null,
    status: null,

    // General
    fullName: 'Eva Espejo',
    name: 'Eva',
    first_name: 'Espejo',
    last_name: '',
    birthday: '1989-08-15 21:37:14',
    country_birth_id: 110,
    state_birth_id: 6,
    last_degree_studies_id: 2,
    address: {
      address: 'hanck',
      country_id: 110,
      state_id: 2,
      zip_code: 190,
    },
    email: 'ava@correo.com',
    cell_phone: '5547113677',

    // identificación
    curp: '54D5S6F4SD6F',
    path_file_curp: '',
    id_type: null,
    id_number: 'S5F4S6F5',
    path_file_id: null,
    path_file_birth_certificate: null,
    path_file_another_document: null,

    // Nationality
    nationalities: [{
      id: 1,
      nationality_id: 110,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 0,
    }, {
      id: 2,
      nationality_id: 109,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 1,
    }],
    // Visa
    visas: [{
      id: 1,
      expedition_country: 110,
      path_file_visa: null,
      visa_number: '2DS1F3SF',
      type_of_visa: 'Visa de residencial permamente',
      visa_expiration: '2023-03-18 21:37:14',
    }],

    gender_id: 0,
    sport_club_id: 3,
    job_position_id: 1,
    another_job: null,
    isCompleteData: true,
  },

  // Jugadores FCB
  {
    id: 7,

    member_type_id: 0,
    team_id: 6,
    // Integrante
    avatar: avatar7,
    category_id: 4,
    validityInitDate: '2019-03-29',
    validityEndDate: '2024-03-29',
    path_file_contract: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_terms: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    registration_in_federation: '123456',
    date_of_admission: '2023-03-15 21:37:14',
    number: '3',
    status: 1,
    id_fifa: 123456,

    // General
    fullName: 'Gerard Piqué',
    name: 'Gerard',
    first_name: 'Piqué',
    last_name: '',
    birthday: '1990-08-10 21:37:14',
    country_birth_id: 110,
    state_birth_id: 8,
    last_degree_studies_id: 3,
    address: {
      address: 'Calle 16 de septiembre, Col. Allende',
      country_id: 110,
      state_id: 1,
      zip_code: 190,
    },
    email: 'gerard@correo.com',
    cell_phone: '5547113677',

    // identificación
    curp: '54D5S6F4SD6F',
    path_file_curp: '',
    id_type: null,
    id_number: 'S5F4S6F5',
    path_file_id: null,
    path_file_birth_certificate: null,
    path_file_another_document: null,

    // Nationality
    nationalities: [{
      id: 1,
      nationality_id: 110,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 0,
    }, {
      id: 2,
      nationality_id: 109,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 1,
    }],
    // Visa
    visas: [{
      id: 1,
      expedition_country: 110,
      path_file_visa: '',
      visa_number: '2DS1F3SF',
      type_of_visa: 'Visa de residencial permamente',
      visa_expiration: '2023-03-18 21:37:14',
    }],

    gender_id: null,
    sport_club_id: 1,
    job_position_id: null,
    another_job: null,
    isCompleteData: true,
  },

  // dos
  {
    id: 8,

    member_type_id: 0,
    team_id: 7,
    // Integrante
    avatar: avatar8,
    category_id: 4,
    validityInitDate: '2016-03-29',
    validityEndDate: '2021-03-29',
    path_file_contract: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_terms: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    registration_in_federation: '123456',
    date_of_admission: '2023-03-15 21:37:14',
    number: '25',
    status: 1,
    id_fifa: 123456,

    // General
    fullName: 'Pierre-Emerick Aubameyang',
    name: 'Pierre-Emerick',
    first_name: 'Aubameyang',
    last_name: '',
    birthday: '1990-08-10 21:37:14',
    country_birth_id: 110,
    state_birth_id: 1,
    last_degree_studies_id: 3,
    address: {
      address: 'Calle 16 de septiembre, Col. Allende',
      country_id: 110,
      state_id: 1,
      zip_code: 190,
    },
    email: 'aubameyang@correo.com',
    cell_phone: '5547113677',

    // identificación
    curp: '54D5S6F4SD6F',
    path_file_curp: '',
    id_type: null,
    id_number: 'S5F4S6F5',
    path_file_id: null,
    path_file_birth_certificate: null,
    path_file_another_document: null,

    // Nationality
    nationalities: [{
      id: 1,
      nationality_id: 110,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 1,
    }, {
      id: 2,
      nationality_id: 109,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 0,
    }],
    // Visa
    visas: [{
      id: 1,
      expedition_country: 110,
      path_file_visa: null,
      visa_number: '2DS1F3SF',
      type_of_visa: 'Visa de residencial permamente',
      visa_expiration: '2023-03-18 21:37:14',
    }],

    gender_id: null,
    sport_club_id: 1,
    job_position_id: null,
    another_job: null,
    isCompleteData: true,
  },

  // Tres
  {
    id: 9,

    member_type_id: 0,
    team_id: 6,
    // Integrante
    avatar: avatar9,
    category_id: 4,
    validityInitDate: '2020-03-29',
    validityEndDate: '2028-03-29',
    path_file_contract: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_terms: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    registration_in_federation: '123456',
    date_of_admission: '2023-03-15 21:37:14',
    number: '11',
    status: 1,

    // General
    fullName: 'Adama Traoré',
    name: 'Adama',
    first_name: 'Traoré',
    last_name: 'Diarra',
    birthday: '1990-08-10 21:37:14',
    country_birth_id: 110,
    state_birth_id: 2,
    last_degree_studies_id: 3,
    address: {
      address: 'Calle 16 de septiembre, Col. Allende',
      country_id: 110,
      state_id: 1,
      zip_code: 190,
    },
    email: 'adama@correo.com',
    cell_phone: '5547113677',

    // identificación
    curp: '54D5S6F4SD6F',
    path_file_curp: '',
    id_type: null,
    id_number: 'S5F4S6F5',
    path_file_id: null,
    path_file_birth_certificate: null,
    path_file_another_document: null,

    // Nationality
    nationalities: [{
      id: 1,
      nationality_id: 110,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 1,
    }, {
      id: 2,
      nationality_id: 109,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 0,
    }],
    // Visa
    visas: [{
      id: 1,
      expedition_country: 110,
      path_file_visa: null,
      visa_number: '2DS1F3SF',
      type_of_visa: 'Visa de residencial permamente',
      visa_expiration: '2023-03-18 21:37:14',
    }],

    gender_id: null,
    sport_club_id: 1,
    job_position_id: null,
    another_job: null,
    isCompleteData: true,
  },

  // Cuerpo tecnico
  {
    id: 10,

    member_type_id: 1,
    team_id: 7,
    // Integrante
    avatar: avatar10,
    category_id: null,
    validityInitDate: '2018-03-29',
    validityEndDate: '2021-03-29',
    path_file_contract: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    path_file_terms: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
    registration_in_federation: '123456',
    date_of_admission: '2023-02-10 21:37:14',
    number: null,
    status: null,

    // General
    fullName: 'Xavi Hernández',
    name: 'Xavi',
    first_name: 'Hernández',
    last_name: '',
    birthday: '1989-08-15 21:37:14',
    country_birth_id: 110,
    state_birth_id: 3,
    last_degree_studies_id: 2,
    address: {
      address: 'Calle 16 de septiembre, Col. Allende',
      country_id: 110,
      state_id: 2,
      zip_code: 190,
    },
    email: 'xavi@correo.com',
    cell_phone: '5547113677',

    // identificación
    curp: '54D5S6F4SD6F',
    path_file_curp: '',
    id_type: null,
    id_number: 'S5F4S6F5',
    path_file_id: null,
    path_file_birth_certificate: null,
    path_file_another_document: null,

    // Nationality
    nationalities: [{
      id: 1,
      nationality_id: 110,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 0,
    }, {
      id: 2,
      nationality_id: 109,
      with_passport: 1,
      path_file_passport: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
      passport_number: '5S4DF65SF4S',
      passport_expiration: '2023-03-15 21:37:14',
      is_main: 1,
    }],
    // Visa
    visas: [{
      id: 1,
      expedition_country: 110,
      path_file_visa: null,
      visa_number: '2DS1F3SF',
      type_of_visa: 'Visa de residencial permamente',
      visa_expiration: '2023-03-18 21:37:14',
    }],

    gender_id: 1,
    sport_club_id: 1,
    job_position_id: 1,
    another_job: null,
    isCompleteData: true,
  },
]

mock.onGet('/members').reply(({ params }) => {
  // let dataList = paginateArray(data, params.filters.perPage, params.filters.page)
  let dataList = data

  dataList.forEach(member => {
    if (member.sport_club_id != null) {
      const clubData = dataClubes.filter(sportClub => sportClub.id === member.sport_club_id)
      member.sport_club = clubData.length > 0 ? clubData[0] : null
    }
    if (member.sport_club_id == null) {
      member.sport_club = ostrail
    }
    if (member.sport_club != null) {
      const indexCountry = dataCountries.findIndex(element => element.id === member.sport_club.country_id)
      const indexCity = dataCities.findIndex(element => element.id === member.sport_club.city_id)
      member.sport_club.country = dataCountries[indexCountry]
      member.sport_club.city = dataCities[indexCity]
    }

    // Category
    if (member.category_id != null) {
      const category = dataCategories.filter(item => item.id === member.category_id)
      member.category = category.length > 0 ? category[0] : null
    }

    // Team
    if (member.team_id != null) {
      const team = dataTeams.filter(item => item.id === member.team_id)
      member.team = team.length > 0 ? team[0] : null
    }

    // Status
    if (member.status != null) {
      const status = dataStatus.filter(item => item.id === Number(member.status))
      member.statusName = status.length > 0 ? status[0].name : null
    }
    // JobPosition
    if (member.job_position_id != null) {
      const jobStaff = dataJobStaff.filter(itemx => (itemx.id == member.job_position_id))
      member.job_position = jobStaff.length > 0 ? jobStaff[0].name : null
    }

    // fullName
    if (member.name != null) {
      member.fullName = `${member.name} ${member.first_name}`
    }

    // Member type
    if (member.member_type_id != null) {
      const dataFilter = dataMemberType.filter(e => e.id === member.member_type_id)
      member.member_type = dataFilter.length > 0 ? dataFilter[0] : null
    }
  })

  // Filter country
  if (params.filters.idCountry != null) {
    const dataFilter = dataList.filter(item => item.sport_club_id !== null)
    dataList = dataFilter.filter(item => item.sport_club.country_id === params.filters.idCountry)
  }

  // Filter state
  if (params.filters.idState != null) {
    const dataFilter = dataList.filter(item => item.sport_club_id !== null)
    dataList = dataFilter.filter(item => item.sport_club.city_id === params.filters.idState)
  }

  const userData = JSON.parse(localStorage.getItem('userData'))
  if (!checkPermission(['ALLOW_ALL'])) {
    const filterClub = dataUserClub.filter(item => item.user_id === Number(userData.id))
    dataList = dataList.filter(item => filterClub.find(e => e.sport_club_id == item.sport_club_id))
  }

  // Filter club
  if (params.filters.idClub != null) {
    const dataFilter = dataList.filter(item => item.sport_club_id !== null)
    dataList = dataFilter.filter(item => item.sport_club_id === params.filters.idClub)
  }

  if (params.filters.idCategory != null) {
    dataList = dataList.filter(item => item.category_id === params.filters.idCategory)
  }
  // if (params.filters.isCompleteData != null) {
  // }

  // Filter search
  const filter = dataList
  if (params.filters.search != null) {
    dataList = filter.filter(item => item.fullName.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }
  if (params.filters.search != null && dataList.length === 0) {
    const filterCategory = filter.filter(item => item.category_id != null)
    dataList = filterCategory.filter(item => item.category.name.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }
  if (params.filters.search != null && dataList.length === 0) {
    const filterNumber = filter.filter(item => item.number != null)
    dataList = filterNumber.filter(item => item.number.includes(params.filters.search))
  }
  if (params.filters.search != null && dataList.length === 0) {
    const filterClub = filter.filter(item => item.sport_club_id != null)
    dataList = filterClub.filter(item => item.sport_club.club_name.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }
  if (params.filters.search != null && dataList.length === 0) {
    const filterTeam = filter.filter(item => item.team_id != null)
    dataList = filterTeam.filter(item => item.team.name.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }
  if (params.filters.search != null && dataList.length === 0) {
    const filterStatus = filter.filter(item => item.status != null)
    dataList = filterStatus.filter(item => item.statusName.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }
  if (params.filters.search != null && dataList.length === 0) {
    const filterBirthday = filter.filter(item => item.birthday != null)
    dataList = filterBirthday.filter(item => item.birthday.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }

  // Filter JobPosition
  if (params.filters.idJobStaff != null) {
    dataList = dataList.filter(itemx => itemx.job_position_id === params.filters.idJobStaff)
  }

  // Filter status
  // if (params.filters.status == null) {
  //   dataList = dataList.filter(item => item.status !== 2)
  // }
  if (params.filters.status == 0) {
    dataList = dataList.filter(item => item.status === 0)
  }
  if (params.filters.status == 1) {
    dataList = dataList.filter(item => item.status === 1)
  }
  if (params.filters.status == 2) {
    dataList = dataList.filter(item => item.status === 2)
  }

  // async function getTypeMember() {
  //   return store.getters.
  // }

  // const result = getTypeMember()

  if (params.filters.idMemberType != null) {
    dataList = dataList.filter(item => item.member_type.query === params.filters.idMemberType)
    // const dataFilter = dataMemberType.filter(e => e.query === params.filters.idMemberType)
    // if (dataFilter.length === 2) {
    //   dataList = dataList.filter(item => item.member_type_id === dataFilter[0].id)
    //   dataList = dataList.filter(item => item.member_type_id === dataFilter[1].id)
    // } else {
    //   dataList = dataList.filter(item => item.member_type_id === dataFilter[0].id)
    // }
  }
  dataList = dataList.filter(item => item.isCompleteData === params.filters.isCompleteData)

  dataList = paginateArray(dataList, params.filters.perPage, params.filters.page)
  const totalItems = dataList.length

  return [200, {
    data: dataList,
    totalRecors: totalItems,
    to: params.filters.perPage * (params.filters.page - 1) + totalItems,
    from: params.filters.perPage * (params.filters.page - 1) + (totalItems ? 1 : 0),
  }]
})

// ------------------------------------------------
// POST: Add new member
// ------------------------------------------------

mock.onPost('/members').reply(config => {
  // Get event from post data
  const member = JSON.parse(config.data)

  const userData = JSON.parse(localStorage.getItem('userData'))

  member.id = Date.now()
  member.create_at = new Date()
  dataTodo.unshift({
    id: Date.now(),
    title: `Registrar ${member.name}`,
    dueDate: new Date(),
    description:
      '<p>Registro incompleto</p>',
    assignee: {
      fullName: 'Ana peniche',
      avatar: '',
    },
    tags: ['medium'],
    isCompleted: false,
    isDeleted: false,
    isImportant: true,
    dependency_of: {
      id: member.id,
      entity: 'member',
    },
    user_id: userData.id,
  })
  // data.push(member)
  data.unshift(member)

  return [200, member]
})

// ------------------------------------------------
// POST: update member
// ------------------------------------------------
mock.onPut('/members').reply(config => {
  // Get event from post data
  const member = JSON.parse(config.data)
  // fullName
  if (member.name != null) {
    member.fullName = `${member.name} ${member.first_name}`
  }
  const userData = JSON.parse(localStorage.getItem('userData'))
  const todo = dataTodo.filter(item => item.dependency_of.id === member.id)
  if (todo.length === 0) {
    dataTodo.unshift({
      id: Date.now(),
      title: `Registrar integrante ${member.name}`,
      dueDate: new Date(),
      description:
        '<p>Registro incompleto</p>',
      assignee: {
        fullName: 'Ana peniche',
        avatar: '',
      },
      tags: ['medium'],
      isCompleted: false,
      isDeleted: false,
      isImportant: true,
      dependency_of: {
        id: member.id,
        entity: 'member',
      },
      user_id: userData.id,
    })
  }
  const index = data.findIndex(element => element.id === member.id)
  if (data[index] === undefined) {
    data.unshift(member)
  } else {
    data[index] = member
  }

  return [200, member]
})

// ------------------------------------------------
// POST: delete member
// ------------------------------------------------
mock.onPost('/members/delete').reply(config => {
  // Get event from post data
  const member = JSON.parse(config.data)
  const index = data.findIndex(element => element.id === member.id)
  data.splice(index, 1)

  return [200, member]
})

mock.onGet('/members/list').reply(() => {
  const members = data.filter(item => item.sport_club_id == null)
  return [200, members]
})

// Detalles
// Trae un integrante para mostrar detalles
mock.onGet(/\/members\/\d+/).reply(config => {
  const idMember = config.url.split('/')[2]

  const member = data.filter(item => item.id === Number(idMember))[0]

  if (member.category_id != null) {
    const category = dataCategories.filter(item => item.id === member.category_id)
    member.category = category.length > 0 ? category[0] : null
  }
  // Age
  const moonLanding = new Date(member.birthday)
  const today = new Date()
  const age = today.getFullYear() - moonLanding.getFullYear()

  member.age = age == null ? 1 : age

  // Pais de nacimiento
  if (member.country_birth_id != null) {
    const placeBirth = dataCountries.filter(itemx => (itemx.id == member.country_birth_id))
    member.country_birth = placeBirth.length > 0 ? placeBirth[0].name : null
  }

  // Estado de nacimiento
  if (member.country_birth_id != null) {
    const stateBirth = dataCities.filter(itemx => (itemx.id == member.state_birth_id))
    member.state_birth = stateBirth.length > 0 ? stateBirth[0].name : null
  }

  // JobPosition
  if (member.job_position_id != null) {
    const jobStaff = dataJobStaff.filter(itemx => (itemx.id == member.job_position_id))
    member.job_position = jobStaff.length > 0 ? jobStaff[0].name : null
  }

  // Team
  if (member.team_id != null) {
    const team = dataTeams.filter(item => (item.id === Number(member.team_id)))
    member.team = team.length > 0 ? team[0].name : null
  }

  // Type
  if (member.member_type_id != null) {
    const memberType = dataMemberType.filter(item => (item.id === Number(member.member_type_id)))
    member.member_type = memberType.length > 0 ? memberType[0] : null
  }

  // Address
  if (member.address != null) {
    const indexCountry = dataCountries.findIndex(element => element.id === member.address.country_id)
    const indexCity = dataCities.findIndex(element => element.id === member.address.state_id)
    member.address.country = dataCountries[indexCountry]
    member.address.city = dataCities[indexCity]
  }

  // Sport club
  if (member.sport_club_id != null) {
    const clubData = dataClubes.filter(sportClub => sportClub.id === member.sport_club_id)
    member.sport_club = clubData.length > 0 ? clubData[0] : null
  }

  // Sport club cities
  if (member.sport_club_id != null) {
    const indexCountry = dataCountries.findIndex(element => element.id === member.sport_club.country_id)
    const indexCity = dataCities.findIndex(element => element.id === member.sport_club.city_id)
    member.sport_club.country = dataCountries[indexCountry]
    member.sport_club.city = dataCities[indexCity]
  }

  // Sexo
  if (member.gender_id != null) {
    const gender = dataGenders.filter(itemx => (itemx.id === member.gender_id))
    member.gender = gender.length > 0 ? gender[0].name : null
  }

  // Last degree studies
  if (member.last_degree_studies_id != null) {
    const study = dataStudies.filter(itemx => (itemx.id === member.last_degree_studies_id))
    member.last_degree_studies = study.length > 0 ? study[0].name : null
  }

  member.nationalities.forEach(nationality => {
    if (nationality.nationality_id != null) {
      const filterData = dataNationatilies.filter(item => item.id === nationality.nationality_id)
      nationality.name = filterData.length > 0 ? filterData[0].name : null
    }
  })

  member.visas.forEach(visa => {
    if (visa.expedition_country != null) {
      const filterData = dataCountries.filter(item => item.id === visa.expedition_country)
      visa.country = filterData.length > 0 ? filterData[0].name : null
    }
  })
  return [200, member]
})

import mock from '@/@fake-db/mock'

import { paginateArray } from '@/@fake-db/utils'
import { data as dataCities } from '@/@fake-db/data/catalogs/cities'
import { data as dataClubSettings } from '@/@fake-db/data/clubs/club-setting'

export const data = []

//
mock.onGet(/\/clubs-aux\/\d+\/powers/).reply(config => {
  const clubIdX = config.url.split('/')[2]

  const { filters } = config.params

  const auxPowerData = []

  const currentPower = data.length > 0 ? data.filter(item => item.club_id == clubIdX && item.status == 1) : []
  const auxPowers = data.length > 0 ? data.filter(item => item.club_id == clubIdX && item.status == 0) : []

  const result = auxPowers.sort((a, b) => {
    if (a.created_at < b.created_at) {
      return 1
    }
    if (a.created_at > b.created_at) {
      return -1
    }
    // a must be equal to b
    return 0
  })

  const auxData = JSON.parse(JSON.stringify(paginateArray(result, filters.perPage, filters.page)))

  auxData.forEach(el => {
    const indexCity = dataCities.findIndex(element => element.id === el.city_of_notary_id)
    const clubX = dataClubSettings.filter(element => element.id === el.club_id)

    const objPower = {
      id: el.id,
      instrument_date: el.instrument_date,
      instrument_number: el.instrument_number,
      city_of_notary: dataCities[indexCity].name,
      notary_name: el.notary_name,
      notary_number: el.notary_number,
      observations: el.observations,
      file: el.file,
      denomination: clubX[0].denomination,
    }

    auxPowerData.push(objPower)
  })

  return [200, {
    current: currentPower.length ? currentPower[0] : null,
    powers: auxPowerData,
    totalRecors: auxPowers.length,
    to: filters.perPage * (filters.page - 1) + auxPowers.length,
    from: filters.perPage * (filters.page - 1) + (auxPowers.length ? 1 : 0),
  }]
})

//
mock.onPost(/\/clubs-aux-save\/\d+\/powers/).reply(config => {
  let auxId = 1
  const dataPowerRequest = JSON.parse(config.data)

  if (data.length > 0) {
    while (data.findIndex(el => el.id === auxId) !== -1) {
      auxId = Math.floor(Math.random() * 1000) + 5
    }

    const auxIndex = data.findIndex(el => el.club_id === dataPowerRequest.club_id && el.status == 1)

    if (auxIndex != -1) {
      data[auxIndex].status = 0
    }
  }

  dataPowerRequest.id = auxId
  dataPowerRequest.created_at = new Date()

  data.push(dataPowerRequest)

  return [200, { message: 'El registro se ha creado correctamente.', powerData: data }]
})

//
mock.onGet(/\/clubs-aux-show\/\d+\/powers\/\d+/).reply(config => {
  const clubIdB = config.url.split('/')[2]
  const powerId = config.url.split('/')[4]

  let dataByClubBAndPowerId = data.filter(item => (item.id == powerId && item.club_id == clubIdB))
  const city = dataCities.filter(element => element.id === dataByClubBAndPowerId[0].city_of_notary_id)

  dataByClubBAndPowerId[0].city = city[0]

  return [200, dataByClubBAndPowerId[0]]
})

//
mock.onGet(/\/clubs-aux-edit\/\d+\/powers\/\d+\/edit/).reply(config => {
  const clubIdB = config.url.split('/')[2]
  const powerId = config.url.split('/')[4]

  const dataByClubBAndPowerId = data.filter(item => (item.id == powerId && item.club_id == clubIdB))

  return [200, dataByClubBAndPowerId[0]]
})

//
mock.onPost(/\/clubs-aux-update\/\d+\/powers/).reply(config => {
  const dataPowerRequest = JSON.parse(config.data)

  const index = data.findIndex(item => (item.id == dataPowerRequest.id && item.club_id == dataPowerRequest.club_id))

  data[index] = dataPowerRequest

  return [200, { message: 'El registro se ha editado correctamente.', powerData: dataPowerRequest }]
})

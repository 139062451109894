import mock from '@/@fake-db/mock'

export const data = [
  {
    id: 1,
    assign_representative_id: 1,
    partner_id: 1,
    representative_id: null,
  },
]

import apiInstance from './index'
import queryParams from './queryParams'

export function getClubs(tempQuery) {
  const query = queryParams.serializeParams(tempQuery.paramsObj, tempQuery.filtersObj)
  return apiInstance({
    url: `/clubs${query}`,
    method: 'GET',
  })
}

export function storeClub(data) {
  return apiInstance({
    url: '/clubs',
    method: 'POST',
    data,
  })
}
export function statusClubs(data) {
  return apiInstance({
    url: '/clubs/status',
    method: 'POST',
    data,
  })
}

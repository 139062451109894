import axios from '@axios'

export default {
  state: {
    moduleList: [],
    role: null,
  },
  getters: {
    moduleList(state) {
      return state.moduleList
    },
    rolPermissions(state) {
      return state.role
    },
  },
  mutations: {
    setRolPermissions(state, data) {
      state.moduleList = data.modules
      state.role = data.role
    },
    updateRolPermissions(state, data) {
      const index = state.moduleList.findIndex(element => element.id === data.id)
      state.moduleList[index] = data
    },
  },
  actions: {
    getModulesData({ commit }, idRole) {
      return new Promise((resolve, reject) => {
        axios.get('/permissions', {
          params: {
            idRole,
          },
        }).then(({ data }) => {
          commit('setRolPermissions', data)
          resolve(true)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateModules({ commit }, module) {
      return new Promise((resolve, reject) => {
        axios.put('/role/permissions', module).then(response => {
          commit('updateRolPermissions', response.data)
          resolve(true)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}

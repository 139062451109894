import mock from '@/@fake-db/mock'
// import { paginateArray } from '@/@fake-db/utils'
// import { data as dataClubes } from '@/@fake-db/data/clubs/club-setting'
// import { data as dataCountries } from '@/@fake-db/data/catalogs/countries'
// import { data as dataCities } from '@/@fake-db/data/catalogs/cities'
// import i18n from '@/libs/i18n'
// import ostrail from '../clubs/ostrail'
/* eslint-disable global-require */
const data = [{
  id: 100,
  title: 'Jugadores',
  route: { name: 'member' },
  icon: 'icon-0-icons-dark-avatar',
  status: 1,
  location: 'es',
  user_id: 4,
  sport_club_id: 1,
  color: 'primary',
}, {
  id: 102,
  title: 'Roles',
  route: { name: 'roles' },
  icon: 'icon-0-icons-dark-folded',
  status: 1,
  location: 'es',
  user_id: 1,
  sport_club_id: null,
  color: 'warning',
  layout_id: '0',
}]
/* eslint-disable global-require */
mock.onGet('/access/direct/list').reply(() => {
  let dataList = data

  const userData = JSON.parse(localStorage.getItem('userData'))

  dataList = dataList.filter(item => item.user_id === Number(userData.id))

  return [200, dataList]
})

// ------------------------------------------------
// POST: Add new objNew
// ------------------------------------------------
mock.onPost('/access/direct').reply(config => {
  // Get objNew from post data
  const { directAccess } = JSON.parse(config.data)

  const userData = JSON.parse(localStorage.getItem('userData'))

  const { length } = data
  let lastIndex = 0
  if (length) {
    lastIndex = data[length - 1].id
  }
  directAccess.id = lastIndex + 1
  directAccess.user_id = userData.id

  data.push(directAccess)

  return [201, directAccess]
})

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete(/\/direct\/access\/\d+/).reply(config => {
  // Get new id from URL
  const id = config.url.substring(config.url.lastIndexOf('/') + 1)

  const newIndex = data.findIndex(e => e.id === Number(id))
  data.splice(newIndex, 1)
  return [200, id]
})

import apiInstance from './index'
import queryParams from './queryParams'

const baseURLRelative = '/notices'

export function getNotices(tempQuery) {
  const query = queryParams.serializeParams(tempQuery.paramsObj, tempQuery.filtersObj)
  return apiInstance({
    url: `${baseURLRelative}${query}`,
    method: 'GET',
  })
}
export function showNotice(hash) {
  return apiInstance({
    url: `${baseURLRelative}/${hash}`,
    method: 'GET',
  })
}
export function showPublicNotice(hash) {
  return apiInstance({
    url: `${baseURLRelative}/public/${hash}`,
    method: 'GET',
  })
}
export function storeNotice(data) {
  return apiInstance({
    url: baseURLRelative,
    method: 'POST',
    data,
  })
}
export function updateNotice(data) {
  return apiInstance({
    url: `${baseURLRelative}/${data.hash}`,
    method: 'PUT',
    data,
  })
}
export function destroyNotice(hash) {
  return apiInstance({
    url: `${baseURLRelative}/${hash}`,
    method: 'DELETE',
  })
}
export function updateStatusNotice(hash) {
  return apiInstance({
    url: `${baseURLRelative}/status/${hash}`,
    method: 'PUT',
  })
}

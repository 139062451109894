import {
  getDirectAccess,
  storeDirectAccess,
  destroyDirectAccess,
  updateWidgetConfiguration,
  getWidgetConfigurations,
} from '@/services/dashboardService'

export default {
  namespaced: true,
  state: {
    list: [],
    widgetConfigurationsList: [],
    directAccessConfigurations: [],
  },
  getters: {
    directAccessList(state) {
      return state.list
    },
    widgetConfigurationsList(state) {
      return state.widgetConfigurationsList
    },
  },
  mutations: {
    setlist(state, listData) {
      state.list = listData
    },
    setAccesDirect(state, accesData) {
      state.list.push(accesData)
    },
    deleteAccessDirect(state, id) {
      const index = state.list.findIndex(element => element.id === Number(id))
      state.list.splice(index, 1)
    },
    setwidgetConfigurationsList(state, listData) {
      state.widgetConfigurationsList = listData
    },
  },
  actions: {
    fetchDataDirectAccess({ commit }) {
      return new Promise((resolve, reject) => {
        getDirectAccess().then(response => {
          resolve(response)
          commit('setlist', response.data.data)
        })
          .catch(error => reject(error))
      })
    },
    addDirectAccess({ commit }, directAccessData) {
      return new Promise((resolve, reject) => {
        storeDirectAccess(directAccessData)
          .then(response => {
            resolve(response)
            commit('setAccesDirect', response.data)
          })
          .catch(error => reject(error))
      })
    },
    removeDirectAccess({ commit }, id) {
      return new Promise((resolve, reject) => {
        destroyDirectAccess(id)
          .then(response => {
            resolve(response)
            commit('deleteAccessDirect', response.data)
          })
          .catch(error => reject(error))
      })
    },
    makeUpdateWidgetConfiguration({ commit }, dataWidget) {
      return new Promise((resolve, reject) => {
        updateWidgetConfiguration(dataWidget)
          .then(response => {
            resolve(response)
            // commit('deleteAccessDirect', response.data)
          })
          .catch(error => reject(error))
      })
    },
    fetchDataWidgetConfigurations({ commit }) {
      return new Promise((resolve, reject) => {
        getWidgetConfigurations().then(response => {
          resolve(response)
          commit('setwidgetConfigurationsList', response.data.data)
        })
          .catch(error => reject(error))
      })
    },
  },
}

import mock from '@/@fake-db/mock'

export const data = [{
  id: 1,
  name: 'Federación',
  key: 'FEDERATION',
}, {
  id: 2,
  name: 'FIFA',
  key: 'FIFA',
}, {
  id: 3,
  name: 'TAS',
  key: 'TAS',
}, {
  id: 4,
  name: 'Otro',
  key: 'OTHER',
}]
mock.onGet('/jurisdiction').reply(() => [200, data])

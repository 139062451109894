import apiInstance from './index'
import queryParams from './queryParams'

const baseURLRelative = '/direct-access'
const baseURLWidget = '/widget-configuration'

export function getDirectAccess(tempQuery) {
  let query = ''
  if (tempQuery) {
    query = queryParams.serializeParams(tempQuery.paramsObj, tempQuery.filtersObj)
  }
  return apiInstance({
    url: `${baseURLRelative}${query}`,
    method: 'GET',
    // params: query,
  })
}

export function storeDirectAccess(data) {
  return apiInstance({
    url: baseURLRelative,
    method: 'POST',
    data,
  })
}
export function destroyDirectAccess(hash) {
  return apiInstance({
    url: `${baseURLRelative}/${hash}`,
    method: 'DELETE',
  })
}
export function updateWidgetConfiguration(data) {
  return apiInstance({
    url: `${baseURLWidget}/${data.hash}`,
    method: 'PUT',
    data,
  })
}
export function getWidgetConfigurations() {
  return apiInstance({
    url: `${baseURLWidget}`,
    method: 'get',
  })
}

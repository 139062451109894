/* eslint-disable no-unused-vars */
// import axios from '@axios'
import {
  storeMediaFile,
  destroyMediaFile,
  showMediaFile,
} from '@/services/mediaFileService'

export default {
  namespaced: true,
  state: {
    media_file: {},
  },
  // getters: {
  //   mediaFileData(state) {
  //     return state.media_file
  //   },
  // },
  mutations: {
    setMediaFile(state, list) {
      state.messagesList = list
    },
  },
  actions: {
    upload({ commit }, data) {
      return new Promise((resolve, reject) => {
        storeMediaFile(data)
          .then(response => {
            commit('setMediaFile', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    show({ commit }, hash) {
      return new Promise((resolve, reject) => {
        showMediaFile(hash)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    destroy({ commit }, hash) {
      return new Promise((resolve, reject) => {
        destroyMediaFile(hash)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}

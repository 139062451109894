import mock from '@/@fake-db/mock'

export const data = [
  {
    id: 1, name: 'Afghanistan', created_at: new Date(), status: true,
  },
  {
    id: 2, name: 'Albania', created_at: new Date(), status: true,
  },
  {
    id: 3, name: 'Algeria', created_at: new Date(), status: true,
  },
  {
    id: 4, name: 'Andorra', created_at: new Date(), status: true,
  },
  {
    id: 5, name: 'Angola', created_at: new Date(), status: true,
  },
  {
    id: 6, name: 'Antigua and Barbuda', created_at: new Date(), status: true,
  },
  {
    id: 7, name: 'Argentina', created_at: new Date(), status: true,
  },
  {
    id: 8, name: 'Armenia', created_at: new Date(), status: true,
  },
  {
    id: 9, name: 'Australia', created_at: new Date(), status: true,
  },
  {
    id: 10, name: 'Austria', created_at: new Date(), status: true,
  },
  {
    id: 11, name: 'Azerbaijan', created_at: new Date(), status: true,
  },
  {
    id: 12, name: 'Bahamas', created_at: new Date(), status: true,
  },
  {
    id: 13, name: 'Bahrain', created_at: new Date(), status: true,
  },
  {
    id: 14, name: 'Bangladesh', created_at: new Date(), status: true,
  },
  {
    id: 15, name: 'Barbados', created_at: new Date(), status: true,
  },
  {
    id: 16, name: 'Belarus', created_at: new Date(), status: true,
  },
  {
    id: 17, name: 'Belgium', created_at: new Date(), status: true,
  },
  {
    id: 18, name: 'Belize', created_at: new Date(), status: true,
  },
  {
    id: 19, name: 'Benin', created_at: new Date(), status: true,
  },
  {
    id: 20, name: 'Bhutan', created_at: new Date(), status: true,
  },
  {
    id: 21, name: 'Bolivia', created_at: new Date(), status: true,
  },
  {
    id: 22, name: 'Bosnia and Herzegovina', created_at: new Date(), status: true,
  },
  {
    id: 23, name: 'Botswana', created_at: new Date(), status: true,
  },
  {
    id: 24, name: 'Brazil', created_at: new Date(), status: true,
  },
  {
    id: 25, name: 'Brunei', created_at: new Date(), status: true,
  },
  {
    id: 26, name: 'Bulgaria', created_at: new Date(), status: true,
  },
  {
    id: 27, name: 'Burkina Faso', created_at: new Date(), status: true,
  },
  {
    id: 28, name: 'Burundi', created_at: new Date(), status: true,
  },
  {
    id: 29, name: "Côte d'Ivoire", created_at: new Date(), status: true,
  },
  {
    id: 30, name: 'Cabo Verde', created_at: new Date(), status: true,
  },
  {
    id: 31, name: 'Cambodia', created_at: new Date(), status: true,
  },
  {
    id: 32, name: 'Cameroon', created_at: new Date(), status: true,
  },
  {
    id: 33, name: 'Canada', created_at: new Date(), status: true,
  },
  {
    id: 34, name: 'Central African Republic', created_at: new Date(), status: true,
  },
  {
    id: 35, name: 'Chad', created_at: new Date(), status: true,
  },
  {
    id: 36, name: 'Chile', created_at: new Date(), status: true,
  },
  {
    id: 37, name: 'China', created_at: new Date(), status: true,
  },
  {
    id: 38, name: 'Colombia', created_at: new Date(), status: true,
  },
  {
    id: 39, name: 'Comoros', created_at: new Date(), status: true,
  },
  {
    id: 40, name: 'Congo', created_at: new Date(), status: true,
  },
  {
    id: 41, name: 'Costa Rica', created_at: new Date(), status: true,
  },
  {
    id: 42, name: 'Croatia', created_at: new Date(), status: true,
  },
  {
    id: 43, name: 'Cuba', created_at: new Date(), status: true,
  },
  {
    id: 44, name: 'Cyprus', created_at: new Date(), status: true,
  },
  {
    id: 45, name: 'Czechia', created_at: new Date(), status: true,
  },
  {
    id: 46, name: 'Denmark', created_at: new Date(), status: true,
  },
  {
    id: 47, name: 'Djibouti', created_at: new Date(), status: true,
  },
  {
    id: 48, name: 'Dominica', created_at: new Date(), status: true,
  },
  {
    id: 49, name: 'Dominican Republic', created_at: new Date(), status: true,
  },
  {
    id: 50, name: 'Ecuador', created_at: new Date(), status: true,
  },
  {
    id: 51, name: 'Egypt', created_at: new Date(), status: true,
  },
  {
    id: 52, name: 'El Salvador', created_at: new Date(), status: true,
  },
  {
    id: 53, name: 'Equatorial Guinea', created_at: new Date(), status: true,
  },
  {
    id: 54, name: 'Eritrea', created_at: new Date(), status: true,
  },
  {
    id: 55, name: 'Estonia', created_at: new Date(), status: true,
  },
  {
    id: 56, name: 'Eswatini', created_at: new Date(), status: true,
  },
  {
    id: 57, name: 'Ethiopia', created_at: new Date(), status: true,
  },
  {
    id: 58, name: 'Fiji', created_at: new Date(), status: true,
  },
  {
    id: 59, name: 'Finland', created_at: new Date(), status: true,
  },
  {
    id: 60, name: 'France', created_at: new Date(), status: true,
  },
  {
    id: 61, name: 'Gabon', created_at: new Date(), status: true,
  },
  {
    id: 62, name: 'Gambia', created_at: new Date(), status: true,
  },
  {
    id: 63, name: 'Georgia', created_at: new Date(), status: true,
  },
  {
    id: 64, name: 'Germany', created_at: new Date(), status: true,
  },
  {
    id: 65, name: 'Ghana', created_at: new Date(), status: true,
  },
  {
    id: 66, name: 'Greece', created_at: new Date(), status: true,
  },
  {
    id: 67, name: 'Grenada', created_at: new Date(), status: true,
  },
  {
    id: 68, name: 'Guatemala', created_at: new Date(), status: true,
  },
  {
    id: 69, name: 'Guinea', created_at: new Date(), status: true,
  },
  {
    id: 70, name: 'Guinea-Bissau', created_at: new Date(), status: true,
  },
  {
    id: 71, name: 'Guyana', created_at: new Date(), status: true,
  },
  {
    id: 72, name: 'Haiti', created_at: new Date(), status: true,
  },
  {
    id: 73, name: 'Holy See', created_at: new Date(), status: true,
  },
  {
    id: 74, name: 'Honduras', created_at: new Date(), status: true,
  },
  {
    id: 75, name: 'Hungary', created_at: new Date(), status: true,
  },
  {
    id: 76, name: 'Iceland', created_at: new Date(), status: true,
  },
  {
    id: 77, name: 'India', created_at: new Date(), status: true,
  },
  {
    id: 78, name: 'Indonesia', created_at: new Date(), status: true,
  },
  {
    id: 79, name: 'Iran', created_at: new Date(), status: true,
  },
  {
    id: 80, name: 'Iraq', created_at: new Date(), status: true,
  },
  {
    id: 81, name: 'Ireland', created_at: new Date(), status: true,
  },
  {
    id: 82, name: 'Israel', created_at: new Date(), status: true,
  },
  {
    id: 83, name: 'Italy', created_at: new Date(), status: true,
  },
  {
    id: 84, name: 'Jamaica', created_at: new Date(), status: true,
  },
  {
    id: 85, name: 'Japan', created_at: new Date(), status: true,
  },
  {
    id: 86, name: 'Jordan', created_at: new Date(), status: true,
  },
  {
    id: 87, name: 'Kazakhstan', created_at: new Date(), status: true,
  },
  {
    id: 88, name: 'Kenya', created_at: new Date(), status: true,
  },
  {
    id: 89, name: 'Kiribati', created_at: new Date(), status: true,
  },
  {
    id: 90, name: 'Kuwait', created_at: new Date(), status: true,
  },
  {
    id: 91, name: 'Kyrgyzstan', created_at: new Date(), status: true,
  },
  {
    id: 92, name: 'Laos', created_at: new Date(), status: true,
  },
  {
    id: 93, name: 'Latvia', created_at: new Date(), status: true,
  },
  {
    id: 94, name: 'Lebanon', created_at: new Date(), status: true,
  },
  {
    id: 95, name: 'Lesotho', created_at: new Date(), status: true,
  },
  {
    id: 96, name: 'Liberia', created_at: new Date(), status: true,
  },
  {
    id: 97, name: 'Libya', created_at: new Date(), status: true,
  },
  {
    id: 98, name: 'Liechtenstein', created_at: new Date(), status: true,
  },
  {
    id: 99, name: 'Lithuania', created_at: new Date(), status: true,
  },
  {
    id: 100, name: 'Luxembourg', created_at: new Date(), status: true,
  },
  {
    id: 101, name: 'Madagascar', created_at: new Date(), status: true,
  },
  {
    id: 102, name: 'Malawi', created_at: new Date(), status: true,
  },
  {
    id: 103, name: 'Malaysia', created_at: new Date(), status: true,
  },
  {
    id: 104, name: 'Maldives', created_at: new Date(), status: true,
  },
  {
    id: 105, name: 'Mali', created_at: new Date(), status: true,
  },
  {
    id: 106, name: 'Malta', created_at: new Date(), status: true,
  },
  {
    id: 107, name: 'Marshall Islands', created_at: new Date(), status: true,
  },
  {
    id: 108, name: 'Mauritania', created_at: new Date(), status: true,
  },
  {
    id: 109, name: 'Mauritius', created_at: new Date(), status: true,
  },
  {
    id: 110, name: 'Mexico', created_at: new Date(), status: true,
  },
  {
    id: 111, name: 'Micronesia', created_at: new Date(), status: true,
  },
  {
    id: 112, name: 'Moldova', created_at: new Date(), status: true,
  },
  {
    id: 113, name: 'Monaco', created_at: new Date(), status: true,
  },
  {
    id: 114, name: 'Mongolia', created_at: new Date(), status: true,
  },
  {
    id: 115, name: 'Montenegro', created_at: new Date(), status: true,
  },
  {
    id: 116, name: 'Morocco', created_at: new Date(), status: true,
  },
  {
    id: 117, name: 'Mozambique', created_at: new Date(), status: true,
  },
  {
    id: 118, name: 'Myanmar', created_at: new Date(), status: true,
  },
  {
    id: 119, name: 'Namibia', created_at: new Date(), status: true,
  },
  {
    id: 120, name: 'Nauru', created_at: new Date(), status: true,
  },
  {
    id: 121, name: 'Nepal', created_at: new Date(), status: true,
  },
  {
    id: 122, name: 'Netherlands', created_at: new Date(), status: true,
  },
  {
    id: 123, name: 'New Zealand', created_at: new Date(), status: true,
  },
  {
    id: 124, name: 'Nicaragua', created_at: new Date(), status: true,
  },
  {
    id: 125, name: 'Niger', created_at: new Date(), status: true,
  },
  {
    id: 126, name: 'Nigeria', created_at: new Date(), status: true,
  },
  {
    id: 127, name: 'North Korea', created_at: new Date(), status: true,
  },
  {
    id: 128, name: 'North Macedonia', created_at: new Date(), status: true,
  },
  {
    id: 129, name: 'Norway', created_at: new Date(), status: true,
  },
  {
    id: 130, name: 'Oman', created_at: new Date(), status: true,
  },
  {
    id: 131, name: 'Pakistan', created_at: new Date(), status: true,
  },
  {
    id: 132, name: 'Palau', created_at: new Date(), status: true,
  },
  {
    id: 133, name: 'Palestine State', created_at: new Date(), status: true,
  },
  {
    id: 134, name: 'Panama', created_at: new Date(), status: true,
  },
  {
    id: 135, name: 'Papua New Guinea', created_at: new Date(), status: true,
  },
  {
    id: 136, name: 'Paraguay', created_at: new Date(), status: true,
  },
  {
    id: 137, name: 'Peru', created_at: new Date(), status: true,
  },
  {
    id: 138, name: 'Philippines', created_at: new Date(), status: true,
  },
  {
    id: 139, name: 'Poland', created_at: new Date(), status: true,
  },
  {
    id: 140, name: 'Portugal', created_at: new Date(), status: true,
  },
  {
    id: 141, name: 'Qatar', created_at: new Date(), status: true,
  },
  {
    id: 142, name: 'Romania', created_at: new Date(), status: true,
  },
  {
    id: 143, name: 'Russia', created_at: new Date(), status: true,
  },
  {
    id: 144, name: 'Rwanda', created_at: new Date(), status: true,
  },
  {
    id: 145, name: 'Saint Kitts and Nevis', created_at: new Date(), status: true,
  },
  {
    id: 146, name: 'Saint Lucia', created_at: new Date(), status: true,
  },
  {
    id: 147, name: 'Saint Vincent & the Grenadines', created_at: new Date(), status: true,
  },
  {
    id: 148, name: 'Samoa', created_at: new Date(), status: true,
  },
  {
    id: 149, name: 'San Marino', created_at: new Date(), status: true,
  },
  {
    id: 150, name: 'Sao Tome and Principe', created_at: new Date(), status: true,
  },
  {
    id: 151, name: 'Saudi Arabia', created_at: new Date(), status: true,
  },
  {
    id: 152, name: 'Senegal', created_at: new Date(), status: true,
  },
  {
    id: 153, name: 'Serbia', created_at: new Date(), status: true,
  },
  {
    id: 154, name: 'Seychelles', created_at: new Date(), status: true,
  },
  {
    id: 155, name: 'Sierra Leone', created_at: new Date(), status: true,
  },
  {
    id: 156, name: 'Singapore', created_at: new Date(), status: true,
  },
  {
    id: 157, name: 'Slovakia', created_at: new Date(), status: true,
  },
  {
    id: 158, name: 'Slovenia', created_at: new Date(), status: true,
  },
  {
    id: 159, name: 'Solomon Islands', created_at: new Date(), status: true,
  },
  {
    id: 160, name: 'Somalia', created_at: new Date(), status: true,
  },
  {
    id: 161, name: 'South Africa', created_at: new Date(), status: true,
  },
  {
    id: 162, name: 'South Korea', created_at: new Date(), status: true,
  },
  {
    id: 163, name: 'South Sudan', created_at: new Date(), status: true,
  },
  {
    id: 164, name: 'Spain', created_at: new Date(), status: true,
  },
  {
    id: 165, name: 'Sri Lanka', created_at: new Date(), status: true,
  },
  {
    id: 166, name: 'Sudan', created_at: new Date(), status: true,
  },
  {
    id: 167, name: 'Suriname', created_at: new Date(), status: true,
  },
  {
    id: 168, name: 'Sweden', created_at: new Date(), status: true,
  },
  {
    id: 169, name: 'Switzerland', created_at: new Date(), status: true,
  },
  {
    id: 170, name: 'Syria', created_at: new Date(), status: true,
  },
  {
    id: 171, name: 'Tajikistan', created_at: new Date(), status: true,
  },
  {
    id: 172, name: 'Tanzania', created_at: new Date(), status: true,
  },
  {
    id: 173, name: 'Thailand', created_at: new Date(), status: true,
  },
  {
    id: 174, name: 'Timor-Leste', created_at: new Date(), status: true,
  },
  {
    id: 175, name: 'Togo', created_at: new Date(), status: true,
  },
  {
    id: 176, name: 'Tonga', created_at: new Date(), status: true,
  },
  {
    id: 177, name: 'Trinidad and Tobago', created_at: new Date(), status: true,
  },
  {
    id: 178, name: 'Tunisia', created_at: new Date(), status: true,
  },
  {
    id: 179, name: 'Turkey', created_at: new Date(), status: true,
  },
  {
    id: 180, name: 'Turkmenistan', created_at: new Date(), status: true,
  },
  {
    id: 181, name: 'Tuvalu', created_at: new Date(), status: true,
  },
  {
    id: 182, name: 'Uganda', created_at: new Date(), status: true,
  },
  {
    id: 183, name: 'Ukraine', created_at: new Date(), status: true,
  },
  {
    id: 184, name: 'United Arab Emirates', created_at: new Date(), status: true,
  },
  {
    id: 185, name: 'United Kingdom', created_at: new Date(), status: true,
  },
  {
    id: 186, name: 'USA', created_at: new Date(), status: true,
  },
  {
    id: 187, name: 'Uruguay', created_at: new Date(), status: true,
  },
  {
    id: 188, name: 'Uzbekistan', created_at: new Date(), status: true,
  },
  {
    id: 189, name: 'Vanuatu', created_at: new Date(), status: true,
  },
  {
    id: 190, name: 'Venezuela', created_at: new Date(), status: true,
  },
  {
    id: 191, name: 'Vietnam', created_at: new Date(), status: true,
  },
  {
    id: 192, name: 'Yemen', created_at: new Date(), status: true,
  },
  {
    id: 193, name: 'Zambia', created_at: new Date(), status: true,
  },
  {
    id: 194, name: 'Zimbabwe', created_at: new Date(), status: true,
  },
]

mock.onGet('/countries').reply(() => [200, data])

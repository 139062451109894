import apiInstance from './index'
import queryParams from './queryParams'

const baseURLRelative = '/tasks'

export function getTasks(tempQuery) {
  const query = queryParams.serializeParams(
    tempQuery.paramsObj,
    tempQuery.filtersObj,
  )
  return apiInstance({
    url: `${baseURLRelative}${query}`,
    method: 'GET',
  })
}
// dashboard
export function getTasksByDate() {
  return apiInstance({
    url: `${baseURLRelative}/filtered-by-date`,
    method: 'GET',
  })
}
export function showTask(hash) {
  return apiInstance({
    url: `${baseURLRelative}/${hash}`,
    method: 'GET',
  })
}
export function storeTask(data) {
  return apiInstance({
    url: baseURLRelative,
    method: 'POST',
    data,
  })
}
export function updateTask(data) {
  return apiInstance({
    url: `${baseURLRelative}/${data.hash}`,
    method: 'PUT',
    data,
  })
}
export function destroyTask(hash) {
  return apiInstance({
    url: `${baseURLRelative}/${hash}`,
    method: 'DELETE',
  })
}
export function updateStatusTask(hash) {
  return apiInstance({
    url: `${baseURLRelative}/status/${hash}`,
    method: 'PUT',
  })
}
export function orderTask(data) {
  return apiInstance({
    url: `${baseURLRelative}/order`,
    method: 'POST',
    data,
  })
}

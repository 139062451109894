import mock from '@/@fake-db/mock'

export const data = [
  {
    id: 1, name: 'Ciudad de México', country_id: 110, created_at: new Date(), status: true,
  },
  {
    id: 2, name: 'Guadalajara', country_id: 110, created_at: new Date(), status: true,
  },
  {
    id: 3, name: 'Monterrey', country_id: 110, created_at: new Date(), status: true,
  },
  {
    id: 4, name: 'Mérida', country_id: 110, created_at: new Date(), status: true,
  },
  {
    id: 5, name: 'Aguascalientes', country_id: 110, created_at: new Date(), status: true,
  },
  {
    id: 6, name: 'Morelia', country_id: 110, created_at: new Date(), status: true,
  },
  {
    id: 7, name: 'San Luis Postosi', country_id: 110, created_at: new Date(), status: true,
  },
  {
    id: 8, name: 'Guanajuato', country_id: 110, created_at: new Date(), status: true,
  },
  {
    id: 9, name: 'Mexicali', country_id: 110, created_at: new Date(), status: true,
  },
  {
    id: 10, name: 'Cuernavaca', country_id: 110, created_at: new Date(), status: true,
  },
]

mock.onGet('/cities').reply(() => [200, data])

mock.onGet(/\/countries\/\d+\/cities/).reply(config => {
  const countryId = config.url.split('/')[2]

  const cities = data.filter(item => item.country_id === Number(countryId))

  return [200, cities]
})

export default data

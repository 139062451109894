import state from './state'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

const memberStore = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
  // state() {
  //   return { ...state }
  // },
}

export default memberStore

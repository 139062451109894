// import { getDefaultState } from './state'

export const MUTATE_SET_RESET_STATE = (state, value = []) => {
  state.userList = value
  state.userList = {}
}
export const setUserListData = (state, list) => {
  state.userList = list
}
export const setUserListArray = (state, list) => {
  state.userListArray = list
}
export const addNewUser = (state, user) => {
  state.userList.data.unshift(user)
}
export const updateUser = (state, user) => {
  const index = state.userList.data.findIndex(element => element.id === user.id)
  state.userList.data[index] = user
}
export const deleteUser = (state, user) => {
  const index = state.userList.data.findIndex(element => element.id === user.id)
  state.userList.data.splice(index, 1)
}
export const setPermissions = (state, data) => {
  state.moduleList = data.modules
  state.role = data.role
}
export const updateModulePermissions = (state, value) => {
  const index = state.moduleList.findIndex(element => element.id === value.id)
  state.moduleList[index] = value
}
// test Revisar funcionalidad para actualizar permisos de un submodulo
// export const updatePermissions = (state, value) => {
//   const index = state.modulList.findIndex(element => element.id === value.id)
//   state.modulList[index] = value
// }

export const setUser = (state, userInfo) => {
  state.user = userInfo
}

export const setUserPassword = (state, pass) => {
  state.password = pass
}

export default [
  {
    path: '/account-settings/:id',
    name: 'account-settings',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    children: [
      {
        path: '/',
        redirect: { name: 'dashboard-main' },
      },
      {
        path: 'general-info',
        name: 'ac-general-info',
        component: () => import('@/views/pages/account-setting/AccountSettingGeneral.vue'),
        meta: {
          profile: true,
        },
      },
      {
        path: 'address-and-contact',
        name: 'ac-address-and-contact',
        component: () => import('@/views/pages/account-setting/AccountSettingInformation.vue'),
        meta: {
          profile: true,
        },
      },
      {
        path: 'change-password',
        name: 'ac-change-password',
        component: () => import('@/views/pages/account-setting/AccountSettingPassword.vue'),
        meta: {
          profile: true,
        },
      },
    ],
    props: route => ({ id: route.name }),
  },
  {
    path: '/account-settings/',
    redirect: { name: 'dashboard-main' },
  },
]

import axios from '@axios'

export default {
  state: {
    requestTypes: [],
  },

  mutations: {
    setRequestTypes(state, value) {
      state.requestTypes = value
    },
  },

  actions: {
    fetchRequestTypes({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/clubs/request-types').then(response => {
          commit('setRequestTypes', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },

  getters: {
    requestTypesList(state) {
      return state.requestTypes
    },
  },
}

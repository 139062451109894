import mock from '@/@fake-db/mock'

export const data = [{
  id: 1,
  name: 'Quincenal',
  key: 'BIWEEKLY',
  status: 1,
}, {
  id: 2,
  name: 'Mensual',
  key: 'MONTHLY',
  status: 1,
}, {
  id: 3,
  name: 'Anual',
  key: 'ANNUAL',
  status: 1,
}, {
  id: 4,
  name: 'Otro',
  key: 'OTHER',
  status: 1,
}]
mock.onGet('/payment-period').reply(() => [200, data])

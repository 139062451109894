import apiInstance from './index'
import queryParams from './queryParams'

export default {
  async getCountries() {
    return await apiInstance.get('countries')
  },

  async getStates(filtersObj) {
    const query = queryParams.serializeParams({}, filtersObj)
    return await apiInstance.get('states'+query)
  },
}
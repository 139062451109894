import mock from '@/@fake-db/mock'

export const data = [
  {
    id: 1,
    name: 'Alan Rojas',
    percentage: 10,
    society_id: 1,
    club_id: 1,
    created_at: new Date(),
    status: 1,
  },
  {
    id: 2,
    name: 'Roberto Gutierrez',
    percentage: 10,
    society_id: 1,
    club_id: 1,
    created_at: new Date(),
    status: 1,
  },
  {
    id: 3,
    name: 'Oscar Galindo',
    percentage: 10,
    society_id: 2,
    club_id: 2,
    created_at: new Date(),
    status: 1,
  },
  {
    id: 4,
    name: 'Eduardo Cano',
    percentage: 10,
    society_id: 2,
    club_id: 2,
    created_at: new Date(),
    status: 1,
  },
  {
    id: 5,
    name: 'Raul Ortega',
    percentage: 10,
    society_id: 3,
    club_id: 3,
    created_at: new Date(),
    status: 1,
  },
  {
    id: 6,
    name: 'Ivan Luna',
    percentage: 10,
    society_id: 3,
    club_id: 3,
    created_at: new Date(),
    status: 1,
  },
  {
    id: 7,
    name: 'Carlos Peña',
    percentage: 10,
    society_id: 4,
    club_id: 4,
    created_at: new Date(),
    status: 1,
  },
  {
    id: 8,
    name: 'Alex Dueñas',
    percentage: 10,
    society_id: 4,
    club_id: 4,
    created_at: new Date(),
    status: 1,
  },
]

mock.onGet(/\/clubs-aux-one\/\d+\/societies\/\d+\/partners/).reply(() => [200, data])

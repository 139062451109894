import mock from '@/@fake-db/mock'

export const data = [
  {
    id: 1, name: 'Administrador único', created_at: new Date(), status: true,
  },
  {
    id: 2, name: 'Consejos de Administración', created_at: new Date(), status: true,
  },
  {
    id: 3, name: 'Comité de vigilancia', created_at: new Date(), status: true,
  },
]

mock.onGet('/ways-to-manage').reply(() => [200, data])

// import mock from '@/@fake-db/mock'
// import { data as dataClubes } from '@/@fake-db/data/clubs/club-setting'
// import { data as dataUsers } from '@/@fake-db/jwt/index'

const data = [
  {
    id: 1,
    sport_club_id: 1,
    user_id: 2,
  },
  {
    id: 2,
    sport_club_id: 2,
    user_id: 3,
  },
  {
    id: 3,
    sport_club_id: 1,
    user_id: 4,
  },
  {
    id: 4,
    sport_club_id: 2,
    user_id: 4,
  },
  {
    id: 5,
    sport_club_id: 1,
    user_id: 5,
  },
]

export default data

import mock from '@/@fake-db/mock'
// import dataTeam from '@/@fake-db/data/team/team'
import { paginateArray } from '@/@fake-db/utils'

const data = [
  {
    id: 1,
    label: '1',
    value: 1,
    status: 1,
    team_id: 7,
  },
  {
    id: 2,
    label: '2',
    value: 2,
    status: 1,
    team_id: 7,
  },
  {
    id: 3,
    label: '3',
    value: 3,
    status: 2,
    team_id: 7,
  },
  {
    id: 4,
    label: '4',
    value: 4,
    status: 1,
    team_id: 7,
  },
  {
    id: 5,
    label: '5',
    value: 5,
    status: 1,
    team_id: 7,
  },
  {
    id: 6,
    label: '6',
    value: 6,
    status: 0,
    team_id: 7,
  },
  {
    id: 7,
    label: '7',
    value: 7,
    status: 0,
    team_id: 7,
  },
  {
    id: 8,
    label: '8',
    value: 8,
    status: 1,
    team_id: 7,
  },
  {
    id: 9,
    label: '9',
    value: 9,
    status: 1,
    team_id: 7,
  },
  {
    id: 10,
    label: '10',
    value: 10,
    status: 1,
    team_id: 7,
  },
  {
    id: 11,
    label: '11',
    value: 11,
    status: 1,
    team_id: 7,
  },
  {
    id: 12,
    label: '12',
    value: 12,
    status: 1,
    team_id: 7,
  },
  {
    id: 13,
    label: '13',
    value: 13,
    status: 1,
    team_id: 7,
  },
  {
    id: 14,
    label: '14',
    value: 14,
    status: 1,
    team_id: 7,
  },
  {
    id: 15,
    label: '15',
    value: 15,
    status: 0,
    team_id: 7,
  },
  {
    id: 16,
    label: '16',
    value: 16,
    status: 1,
    team_id: 7,
  },
  {
    id: 17,
    label: '17',
    value: 17,
    status: 0,
    team_id: 7,
  },
  {
    id: 18,
    label: '18',
    value: 18,
    status: 1,
    team_id: 7,
  },
  {
    id: 19,
    label: '19',
    value: 19,
    status: 0,
    team_id: 7,
  },
  {
    id: 20,
    label: '20',
    value: 20,
    status: 1,
    team_id: 7,
  },
  {
    id: 21,
    label: '21',
    value: 21,
    status: 1,
    team_id: 7,
  },
  {
    id: 22,
    label: '22',
    value: 22,
    status: 1,
    team_id: 7,
  },
  {
    id: 23,
    label: '23',
    value: 23,
    status: 1,
    team_id: 7,
  },
  {
    id: 24,
    label: '24',
    value: 24,
    status: 1,
    team_id: 7,
  },
  {
    id: 25,
    label: '25',
    value: 25,
    status: 1,
    team_id: 7,
  },
  {
    id: 26,
    label: '26',
    value: 26,
    status: 1,
    team_id: 7,
  },
  {
    id: 27,
    label: '27',
    value: 27,
    status: 1,
    team_id: 7,
  },
  {
    id: 28,
    label: '28',
    value: 28,
    status: 1,
    team_id: 7,
  },
  {
    id: 29,
    label: '29',
    value: 29,
    status: 1,
    team_id: 7,
  },
  {
    id: 30,
    label: '30',
    value: 30,
    status: 1,
    team_id: 7,
  },
  {
    id: 31,
    label: '31',
    value: 31,
    status: 1,
    team_id: 7,
  },
  {
    id: 32,
    label: '32',
    value: 32,
    status: 1,
    team_id: 7,
  },
  {
    id: 33,
    label: '33',
    value: 33,
    status: 1,
    team_id: 7,
  },
  {
    id: 34,
    label: '34',
    value: 34,
    status: 1,
    team_id: 7,
  },
  {
    id: 35,
    label: '35',
    value: 35,
    status: 1,
    team_id: 7,
  },
  {
    id: 36,
    label: '36',
    value: 36,
    status: 1,
    team_id: 7,
  },
  {
    id: 37,
    label: '37',
    value: 37,
    status: 1,
    team_id: 7,
  },
  {
    id: 38,
    label: '38',
    value: 38,
    status: 1,
    team_id: 7,
  },
  {
    id: 39,
    label: '39',
    value: 39,
    status: 1,
    team_id: 7,
  },
  {
    id: 40,
    label: '40',
    value: 40,
    status: 1,
    team_id: 7,
  },
  {
    id: 41,
    label: '41',
    value: 41,
    status: 1,
    team_id: 7,
  },
  {
    id: 42,
    label: '42',
    value: 42,
    status: 1,
    team_id: 7,
  },
  {
    id: 43,
    label: '43',
    value: 43,
    status: 1,
    team_id: 7,
  },
  {
    id: 44,
    label: '44',
    value: 44,
    status: 1,
    team_id: 7,
  },
  {
    id: 45,
    label: '45',
    value: 45,
    status: 1,
    team_id: 7,
  },
  {
    id: 46,
    label: '46',
    value: 46,
    status: 1,
    team_id: 7,
  },
  {
    id: 47,
    label: '47',
    value: 47,
    status: 1,
    team_id: 7,
  },
  {
    id: 48,
    label: '48',
    value: 48,
    status: 1,
    team_id: 7,
  },
  {
    id: 49,
    label: '49',
    value: 49,
    status: 1,
    team_id: 7,
  },
  {
    id: 50,
    label: '50',
    value: 50,
    status: 1,
    team_id: 7,
  },

]

mock.onGet('/jersey').reply(() => [200, data])

// Filtra por id
mock.onGet('/jersey').reply(({ params }) => {
  let dataList = data.filter(item => item.team_id === Number(params.filters.teamId))

  if (dataList.length === 0) {
    for (let index = 1; index <= 300; index += 1) {
      dataList.push({
        id: Date.now() + index,
        label: String(index),
        value: index,
        status: 1,
      })
    }
  }

  const totalItems = dataList.length

  dataList = paginateArray(dataList, params.filters.perPage, params.filters.page)

  return [200, {
    data: dataList,
    totalRecors: totalItems,
    to: params.filters.perPage * (params.filters.page - 1) + dataList.length,
    from: params.filters.perPage * (params.filters.page - 1) + (dataList.length ? 1 : 0),
  }]
})

export default data

import mock from '@/@fake-db/mock'
import { data as dataRoles } from '@/@fake-db/data/roles/roles'

export const modules = [
  {
    id: 1,
    name: 'ADMINISTRACIÓN',
    key: 'ADMINISTRATION',
    status: 0,
    submodules: [
      {
        id: 1,
        name: 'Roles',
        permissions: [
          {
            id: 1,
            key: 'VIEW_ROLES',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta de los roles',
            status: 0,
          },
          {
            id: 2,
            key: 'CREATE_ROLES',
            name: 'Registar',
            description: 'Descripcion de prueba, otorgar el permiso para el registro de los roles',
            status: 0,
          },
          {
            id: 3,
            key: 'EDIT_ROLES',
            name: 'Modificar',
            description: 'Descripcion de prueba, otorgar el permiso para modificar los roles',
            status: 0,
          },
          {
            id: 6,
            key: 'CHANGE_STATUS_ROLES',
            name: 'Desactivar',
            description: 'Descripcion de prueba, otorgar el permiso para desactivar los roles',
            status: 0,
          },
          {
            id: 7,
            key: 'DELETE_ROLES',
            name: 'Eliminar',
            description: 'Descripcion de prueba, otorgar el permiso para eliminar los roles',
            status: 0,
          },
        ],
      },
      {
        id: 2,
        name: 'Permisos',
        permissions: [
          {
            id: 1,
            key: 'VIEW_PERMISSIONS',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta de los permisos',
            status: 1,
          },
          {
            id: 2,
            key: 'EDIT_PERMISSIONS',
            name: 'Modificar',
            description: 'Descripcion de prueba, otorgar el permiso para modificar los permisos',
            status: 0,
          },
        ],
      },
      {
        id: 3,
        name: 'Usuarios',
        permissions: [
          {
            id: 1,
            key: 'VIEW_USERS',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta de usuarios',
            status: 0,
          },
          {
            id: 2,
            key: 'CREATE_USERS',
            name: 'Registar',
            description: 'Descripcion de prueba, otorgar el permiso para el registro de usuarios',
            status: 0,
          },
          {
            id: 3,
            key: 'EDIT_USERS',
            name: 'Modificar',
            description: 'Descripcion de prueba, otorgar el permiso para modificar usuarios',
            status: 0,
          },
          {
            id: 4,
            key: 'DELETE_USERS',
            name: 'Eliminar',
            description: 'Descripcion de prueba, otorgar el permiso para eliminiar usuarios',
            status: 0,
          },
        ],
      },
      {
        id: 4,
        name: 'Puestos de trabajo',
        permissions: [
          {
            id: 1,
            key: 'VIEW_JOBS',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta de puestos de trabajo',
            status: 1,
          },
          {
            id: 2,
            key: 'CREATE_JOBS',
            name: 'Registar',
            description: 'Descripcion de prueba, otorgar el permiso para el registro de puestos de trabajo',
            status: 1,
          },
          {
            id: 3,
            key: 'EDIT_JOBS',
            name: 'Modificar',
            description: 'Descripcion de prueba, otorgar el permiso para modificar puestos de trabajo',
            status: 1,
          },
          {
            id: 4,
            key: 'DELETE_JOBS',
            name: 'Eliminar',
            description: 'Descripcion de prueba, otorgar el permiso para eliminiar puestos de trabajo',
            status: 0,
          },
        ],
      },
      {
        id: 5,
        name: 'Equipos',
        permissions: [
          {
            id: 1,
            key: 'VIEW_TEAMS',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta de equipos',
            status: 1,
          },
          {
            id: 2,
            key: 'CREATE_TEAMS',
            name: 'Registar',
            description: 'Descripcion de prueba, otorgar el permiso para el registro de equipos',
            status: 1,
          },
          {
            id: 3,
            key: 'EDIT_TEAMS',
            name: 'Modificar',
            description: 'Descripcion de prueba, otorgar el permiso para modificar equipos',
            status: 1,
          },
          {
            id: 4,
            key: 'DELETE_TEAMS',
            name: 'Eliminar',
            description: 'Descripcion de prueba, otorgar el permiso para eliminiar equipos',
            status: 0,
          },
        ],
      },
      {
        id: 6,
        name: 'Noticias',
        permissions: [
          {
            id: 1,
            key: 'VIEW_NEWS',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta de noticias',
            status: 1,
          },
          {
            id: 2,
            key: 'CREATE_NEWS',
            name: 'Registar',
            description: 'Descripcion de prueba, otorgar el permiso para el registro de noticias',
            status: 1,
          },
          {
            id: 3,
            key: 'EDIT_NEWS',
            name: 'Modificar',
            description: 'Descripcion de prueba, otorgar el permiso para modificar noticias',
            status: 1,
          },
          {
            id: 4,
            key: 'DELETE_NEWS',
            name: 'Eliminar',
            description: 'Descripcion de prueba, otorgar el permiso para eliminiar noticias',
            status: 0,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'ACERVO DOCUMENTAL',
    key: 'DOCUMENTARY_HERITAGE',
    status: 1,
    submodules: [
      {
        id: 1,
        name: 'Club',
        permissions: [
          {
            id: 1,
            key: 'VIEW_CLUB',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta del corporativo',
            status: 1,
          },
          {
            id: 2,
            key: 'CREATE_CORPORATE',
            name: 'Registar',
            description: 'Descripcion de prueba, otorgar el permiso para el registro del corporativo',
            status: 1,
          },
        ],
      },
      {
        id: 2,
        name: 'Corporativo',
        permissions: [
          {
            id: 1,
            key: 'VIEW_CORPORATE',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta del corporativo',
            status: 1,
          },
          {
            id: 3,
            key: 'EDIT_CORPORATE',
            name: 'Modificar',
            description: 'Descripcion de prueba, otorgar el permiso para modificar el corporativo',
            status: 1,
          },
          {
            id: 6,
            key: 'CHANGE_STATUS_CORPORATE',
            name: 'Desactivar',
            description: 'Descripcion de prueba, otorgar el permiso para desactivar el corporativo',
            status: 1,
          },
          {
            id: 7,
            key: 'DONWLOAD_CORPORATE_PDF',
            name: 'Descargar PDF',
            description: 'Descripcion de prueba, otorgar el permiso para descargar la informacion del corporativo en pdf',
            status: 1,
          },
        ],
      },
      {
        id: 3,
        name: 'Sociedad',
        permissions: [
          {
            id: 1,
            key: 'VIEW_SOCIEDAD',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta de la sociedad',
            status: 1,
          },
          {
            id: 2,
            key: 'REGISTER_SOCIEDAD',
            name: 'Registar',
            description: 'Descripcion de prueba, otorgar el permiso para el registro de la sociedad',
            status: 1,
          },
          {
            id: 3,
            key: 'EDIT_SOCIEDAD',
            name: 'Modificar',
            description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la sociedad',
            status: 1,
          },
          {
            id: 4,
            key: 'DELETE_SOCIEDAD',
            name: 'Eliminar',
            description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la sociedad',
            status: 1,
          },
        ],
      },
      {
        id: 4,
        name: 'Asambleas',
        permissions: [
          {
            id: 1,
            key: 'VIEW_ASSEMBLY',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta de la sociedad',
            status: 1,
          },
          {
            id: 2,
            key: 'REGISTER_ASSEMBLY',
            name: 'Registar',
            description: 'Descripcion de prueba, otorgar el permiso para el registro de la sociedad',
            status: 1,
          },
          {
            id: 3,
            key: 'EDIT_ASSEMBLY',
            name: 'Modificar',
            description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la sociedad',
            status: 1,
          },
          {
            id: 4,
            key: 'DELETE_ASSEMBLY',
            name: 'Eliminar',
            description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la sociedad',
            status: 1,
          },
        ],
      },
      {
        id: 5,
        name: 'Poderes',
        permissions: [
          {
            id: 1,
            key: 'VIEW_CAPACITY',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta de los poderes',
            status: 1,
          },
          {
            id: 2,
            key: 'REGISTER_CAPACITY',
            name: 'Registar',
            description: 'Descripcion de prueba, otorgar el permiso para el registro de los poderes',
            status: 1,
          },
          {
            id: 3,
            key: 'EDIT_CAPACITY',
            name: 'Modificar',
            description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de los poderes',
            status: 1,
          },
          {
            id: 4,
            key: 'DELETE_CAPACITY',
            name: 'Eliminar',
            description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de los poderes',
            status: 1,
          },
        ],
      },
      {
        id: 6,
        name: 'Apoderados',
        permissions: [
          {
            id: 1,
            key: 'VIEW_PROXIES',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta de los apoderados',
            status: 1,
          },
          {
            id: 2,
            key: 'REGISTER_PROXIES',
            name: 'Registar',
            description: 'Descripcion de prueba, otorgar el permiso para el registro de los apoderados',
            status: 1,
          },
          {
            id: 3,
            key: 'EDIT_PROXIES',
            name: 'Modificar',
            description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de los apoderados',
            status: 1,
          },
          {
            id: 4,
            key: 'DELETE_PROXIES',
            name: 'Eliminar',
            description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de los apoderados',
            status: 1,
          },
        ],
      },
      {
        id: 7,
        name: 'Propiedad intelectual',
        permissions: [
          {
            id: 1,
            key: 'VIEW_INTELLECTUAL_PROPERTY',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
            status: 1,
          },
          {
            id: 2,
            key: 'REGISTER_INTELLECTUAL_PROPERTY',
            name: 'Registar',
            description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
            status: 1,
          },
          {
            id: 3,
            key: 'EDIT_INTELLECTUAL_PROPERTY',
            name: 'Modificar',
            description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
            status: 1,
          },
          {
            id: 4,
            key: 'DELETE_INTELLECTUAL_PROPERTY',
            name: 'Eliminar',
            description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
            status: 1,
          },
        ],
      },
      {
        id: 8,
        name: 'Integrantes',
        permissions: [
          {
            id: 1,
            key: 'VIEW_MEMBERS',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
            status: 1,
          },
          {
            id: 5,
            key: 'FILTER_MEMBERS_CLUB',
            name: 'Filtrar por club',
            description: 'Descripcion de prueba, otorga el permiso de filtrar a los integrantes po club',
            status: 1,
          },
        ],
      },
      {
        id: 9,
        name: 'Integrantes Jugadores',
        permissions: [
          {
            id: 1,
            key: 'VIEW_MEMBERS_PLAYERS',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
            status: 1,
          },
          {
            id: 2,
            key: 'REGISTER_MEMBERS_PLAYERS',
            name: 'Registar',
            description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
            status: 1,
          },
          {
            id: 3,
            key: 'EDIT_MEMBERS_PLAYERS',
            name: 'Modificar',
            description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
            status: 1,
          },
          {
            id: 4,
            key: 'DELETE_MEMBERS_PLAYERS',
            name: 'Eliminar',
            description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
            status: 1,
          },
        ],
      },
      {
        id: 10,
        name: 'Integrantes Cuerpo técnico',
        permissions: [
          {
            id: 1,
            key: 'VIEW_MEMBERS_COACHING_STAFF',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
            status: 1,
          },
          {
            id: 2,
            key: 'REGISTER_MEMBERS_COACHING_STAFF',
            name: 'Registar',
            description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
            status: 0,
          },
          {
            id: 3,
            key: 'EDIT_MEMBERS_COACHING_STAFF',
            name: 'Modificar',
            description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
            status: 0,
          },
          {
            id: 4,
            key: 'DELETE_MEMBERS_COACHING_STAFF',
            name: 'Eliminar',
            description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
            status: 1,
          },
        ],
      },
      {
        id: 11,
        name: 'Condiciones contractuales (Integrantes)',
        permissions: [
          {
            id: 1,
            key: 'VIEW_MEMBERS_CONTRACTUAL_CONDITIONS',
            name: 'Consultar',
            description: 'Descripcion de prueba, otorgar el permiso para la consulta de condiciones contractuales',
            status: 1,
          },
          {
            id: 2,
            key: 'REGISTER_MEMBERS_CONTRACTUAL_CONDITIONS',
            name: 'Registar',
            description: 'Descripcion de prueba, otorgar el permiso para el registro de condiciones contractuales',
            status: 0,
          },
          {
            id: 3,
            key: 'EDIT_MEMBERS_CONTRACTUAL_CONDITIONS',
            name: 'Modificar',
            description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de condiciones contractuales',
            status: 0,
          },
          // {
          //   id: 4,
          //   key: 'DELETE_MEMBERS_CONTRACTUAL_CONDITIONS',
          //   name: 'Eliminar',
          //   description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
          //   status: 1,
          // },
        ],
      },
    ],
  },
]

export const roleModuleData = [
  {
    id: 1,
    role_id: 1,
    modules: [
      {
        id: 1,
        name: 'ADMINISTRACIÓN',
        key: 'ADMINISTRATION',
        status: 1,
        submodules: [
          {
            id: 1,
            name: 'Roles',
            permissions: [
              {
                id: 1,
                key: 'VIEW_ROLES',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los roles',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_ROLES',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los roles',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_ROLES',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar los roles',
                status: 1,
              },
              {
                id: 6,
                key: 'CHANGE_STATUS_ROLES',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar los roles',
                status: 1,
              },
              {
                id: 7,
                key: 'DELETE_ROLES',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar los roles',
                status: 1,
              },
            ],
          },
          {
            id: 2,
            name: 'Permisos',
            permissions: [
              {
                id: 1,
                key: 'VIEW_PERMISSIONS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los permisos',
                status: 1,
              },
              {
                id: 2,
                key: 'EDIT_PERMISSIONS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar los permisos',
                status: 1,
              },
            ],
          },
          {
            id: 3,
            name: 'Usuarios',
            permissions: [
              {
                id: 1,
                key: 'VIEW_USERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de usuarios',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_USERS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de usuarios',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_USERS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar usuarios',
                status: 1,
              },
              {
                id: 4,
                key: 'CHANGE_STATUS_USERS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar usuarios',
                status: 1,
              },
              {
                id: 5,
                key: 'DELETE_USERS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar usuarios',
                status: 1,
              },
            ],
          },
          {
            id: 4,
            name: 'Puestos de trabajo',
            permissions: [
              {
                id: 1,
                key: 'VIEW_JOBS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de puestos de trabajo',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_JOBS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de puestos de trabajo',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_JOBS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar puestos de trabajo',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_JOBS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar puestos de trabajo',
                status: 1,
              },
              {
                id: 5,
                key: 'CHANGE_STATUS_JOBS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar puestos de trabajo',
                status: 1,
              },
            ],
          },
          {
            id: 5,
            name: 'Equipos',
            permissions: [
              {
                id: 1,
                key: 'VIEW_TEAMS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de equipos',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_TEAMS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de equipos',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_TEAMS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar equipos',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_TEAMS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar equipos',
                status: 1,
              },
              {
                id: 5,
                key: 'CHANGE_STATUS_TEAMS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar equipos',
                status: 1,
              },
            ],
          },
          {
            id: 6,
            name: 'Noticias',
            permissions: [
              {
                id: 1,
                key: 'VIEW_NEWS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de noticias',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_NEWS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de noticias',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_NEWS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar noticias',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_NEWS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar noticias',
                status: 1,
              },
            ],
          },
        ],
      },
      {
        id: 2,
        name: 'ACERVO DOCUMENTAL',
        key: 'DOCUMENTARY_HERITAGE',
        status: 1,
        submodules: [
          {
            id: 1,
            name: 'Club',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CLUB',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta del corporativo',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_CORPORATE',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro del corporativo',
                status: 1,
              },
            ],
          },
          {
            id: 2,
            name: 'Corporativo',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CORPORATE',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta del corporativo',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_CORPORATE',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el corporativo',
                status: 1,
              },
              {
                id: 6,
                key: 'CHANGE_STATUS_CORPORATE',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar el corporativo',
                status: 1,
              },
              {
                id: 7,
                key: 'DONWLOAD_CORPORATE_PDF',
                name: 'Descargar PDF',
                description: 'Descripcion de prueba, otorgar el permiso para descargar la informacion del corporativo en pdf',
                status: 1,
              },
            ],
          },
          {
            id: 3,
            name: 'Sociedad',
            permissions: [
              {
                id: 1,
                key: 'VIEW_SOCIEDAD',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la sociedad',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_SOCIEDAD',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la sociedad',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_SOCIEDAD',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la sociedad',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_SOCIEDAD',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la sociedad',
                status: 1,
              },
            ],
          },
          {
            id: 4,
            name: 'Asambleas',
            permissions: [
              {
                id: 1,
                key: 'VIEW_ASSEMBLY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la sociedad',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_ASSEMBLY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la sociedad',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_ASSEMBLY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la sociedad',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_ASSEMBLY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la sociedad',
                status: 1,
              },
            ],
          },
          {
            id: 5,
            name: 'Poderes',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CAPACITY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los poderes',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_CAPACITY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los poderes',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_CAPACITY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de los poderes',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_CAPACITY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de los poderes',
                status: 1,
              },
            ],
          },
          {
            id: 6,
            name: 'Apoderados',
            permissions: [
              {
                id: 1,
                key: 'VIEW_PROXIES',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los apoderados',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_PROXIES',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los apoderados',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_PROXIES',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de los apoderados',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_PROXIES',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de los apoderados',
                status: 1,
              },
            ],
          },
          {
            id: 7,
            name: 'Propiedad intelectual',
            permissions: [
              {
                id: 1,
                key: 'VIEW_INTELLECTUAL_PROPERTY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_INTELLECTUAL_PROPERTY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_INTELLECTUAL_PROPERTY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_INTELLECTUAL_PROPERTY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
          {
            id: 8,
            name: 'Integrantes',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 5,
                key: 'FILTER_MEMBERS_CLUB',
                name: 'Filtrar por club',
                description: 'Descripcion de prueba, otorga el permiso de filtrar a los integrantes po club',
                status: 1,
              },
            ],
          },
          {
            id: 9,
            name: 'Integrantes Jugadores',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS_PLAYERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_MEMBERS_PLAYERS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_MEMBERS_PLAYERS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_MEMBERS_PLAYERS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
          {
            id: 10,
            name: 'Integrantes Cuerpo técnico',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS_COACHING_STAFF',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_MEMBERS_COACHING_STAFF',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_MEMBERS_COACHING_STAFF',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_MEMBERS_COACHING_STAFF',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    role_id: 2,
    modules: [
      {
        id: 3,
        name: 'ADMINISTRACIÓN',
        key: 'ADMINISTRATION',
        status: 0,
        submodules: [
          {
            id: 1,
            name: 'Roles',
            permissions: [
              {
                id: 1,
                key: 'VIEW_ROLES',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los roles',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_ROLES',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los roles',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_ROLES',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar los roles',
                status: 1,
              },
              {
                id: 6,
                key: 'CHANGE_STATUS_ROLES',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar los roles',
                status: 1,
              },
              {
                id: 7,
                key: 'DELETE_ROLES',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar los roles',
                status: 1,
              },
            ],
          },
          {
            id: 2,
            name: 'Permisos',
            permissions: [
              {
                id: 1,
                key: 'VIEW_PERMISSIONS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los permisos',
                status: 1,
              },
              {
                id: 2,
                key: 'EDIT_PERMISSIONS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar los permisos',
                status: 0,
              },
            ],
          },
          {
            id: 3,
            name: 'Usuarios',
            permissions: [
              {
                id: 1,
                key: 'VIEW_USERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de usuarios',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_USERS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de usuarios',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_USERS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar usuarios',
                status: 1,
              },
              {
                id: 4,
                key: 'CHANGE_STATUS_USERS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar usuarios',
                status: 1,
              },
              {
                id: 5,
                key: 'DELETE_USERS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar usuarios',
                status: 0,
              },
            ],
          },
          {
            id: 4,
            name: 'Puestos de trabajo',
            permissions: [
              {
                id: 1,
                key: 'VIEW_JOBS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de puestos de trabajo',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_JOBS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de puestos de trabajo',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_JOBS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar puestos de trabajo',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_JOBS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar puestos de trabajo',
                status: 0,
              },
              {
                id: 5,
                key: 'CHANGE_STATUS_JOBS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar puestos de trabajo',
                status: 1,
              },
            ],
          },
          {
            id: 5,
            name: 'Equipos',
            permissions: [
              {
                id: 1,
                key: 'VIEW_TEAMS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de equipos',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_TEAMS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de equipos',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_TEAMS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar equipos',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_TEAMS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar equipos',
                status: 1,
              },
              {
                id: 5,
                key: 'CHANGE_STATUS_TEAMS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar equipos',
                status: 0,
              },
            ],
          },
          {
            id: 6,
            name: 'Noticias',
            permissions: [
              {
                id: 1,
                key: 'VIEW_NEWS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de noticias',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_NEWS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de noticias',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_NEWS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar noticias',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_NEWS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar noticias',
                status: 0,
              },
            ],
          },
        ],
      },
      {
        id: 4,
        name: 'ACERVO DOCUMENTAL',
        key: 'DOCUMENTARY_HERITAGE',
        status: 1,
        submodules: [
          {
            id: 1,
            name: 'Club',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CLUB',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta del corporativo',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_CORPORATE',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro del corporativo',
                status: 1,
              },
            ],
          },
          {
            id: 2,
            name: 'Corporativo',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CORPORATE',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta del corporativo',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_CORPORATE',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el corporativo',
                status: 1,
              },
              {
                id: 6,
                key: 'CHANGE_STATUS_CORPORATE',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar el corporativo',
                status: 1,
              },
              {
                id: 7,
                key: 'DONWLOAD_CORPORATE_PDF',
                name: 'Descargar PDF',
                description: 'Descripcion de prueba, otorgar el permiso para descargar la informacion del corporativo en pdf',
                status: 1,
              },
            ],
          },
          {
            id: 3,
            name: 'Sociedad',
            permissions: [
              {
                id: 1,
                key: 'VIEW_SOCIEDAD',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la sociedad',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_SOCIEDAD',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la sociedad',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_SOCIEDAD',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la sociedad',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_SOCIEDAD',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la sociedad',
                status: 1,
              },
            ],
          },
          {
            id: 4,
            name: 'Asambleas',
            permissions: [
              {
                id: 1,
                key: 'VIEW_ASSEMBLY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la sociedad',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_ASSEMBLY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la sociedad',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_ASSEMBLY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la sociedad',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_ASSEMBLY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la sociedad',
                status: 1,
              },
            ],
          },
          {
            id: 5,
            name: 'Poderes',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CAPACITY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los poderes',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_CAPACITY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los poderes',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_CAPACITY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de los poderes',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_CAPACITY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de los poderes',
                status: 1,
              },
            ],
          },
          {
            id: 6,
            name: 'Apoderados',
            permissions: [
              {
                id: 1,
                key: 'VIEW_PROXIES',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los apoderados',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_PROXIES',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los apoderados',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_PROXIES',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de los apoderados',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_PROXIES',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de los apoderados',
                status: 1,
              },
            ],
          },
          {
            id: 7,
            name: 'Propiedad intelectual',
            permissions: [
              {
                id: 1,
                key: 'VIEW_INTELLECTUAL_PROPERTY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_INTELLECTUAL_PROPERTY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_INTELLECTUAL_PROPERTY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_INTELLECTUAL_PROPERTY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
          {
            id: 8,
            name: 'Integrantes',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 5,
                key: 'FILTER_MEMBERS_CLUB',
                name: 'Filtrar por club',
                description: 'Descripcion de prueba, otorga el permiso de filtrar a los integrantes po club',
                status: 1,
              },
            ],
          },
          {
            id: 9,
            name: 'Integrantes Jugadores',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS_PLAYERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_MEMBERS_PLAYERS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_MEMBERS_PLAYERS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_MEMBERS_PLAYERS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
          {
            id: 10,
            name: 'Integrantes Cuerpo técnico',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS_COACHING_STAFF',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_MEMBERS_COACHING_STAFF',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_MEMBERS_COACHING_STAFF',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_MEMBERS_COACHING_STAFF',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    role_id: 3,
    modules: [
      {
        id: 5,
        name: 'ADMINISTRACIÓN',
        key: 'ADMINISTRATION',
        status: 1,
        submodules: [
          {
            id: 1,
            name: 'Roles',
            permissions: [
              {
                id: 1,
                key: 'VIEW_ROLES',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los roles',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_ROLES',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los roles',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_ROLES',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar los roles',
                status: 1,
              },
              {
                id: 6,
                key: 'CHANGE_STATUS_ROLES',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar los roles',
                status: 1,
              },
              {
                id: 7,
                key: 'DELETE_ROLES',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar los roles',
                status: 1,
              },
            ],
          },
          {
            id: 2,
            name: 'Permisos',
            permissions: [
              {
                id: 1,
                key: 'VIEW_PERMISSIONS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los permisos',
                status: 1,
              },
              {
                id: 2,
                key: 'EDIT_PERMISSIONS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar los permisos',
                status: 0,
              },
            ],
          },
          {
            id: 3,
            name: 'Usuarios',
            permissions: [
              {
                id: 1,
                key: 'VIEW_USERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de usuarios',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_USERS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de usuarios',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_USERS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar usuarios',
                status: 1,
              },
              {
                id: 4,
                key: 'CHANGE_STATUS_USERS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar usuarios',
                status: 1,
              },
              {
                id: 5,
                key: 'DELETE_USERS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar usuarios',
                status: 0,
              },
            ],
          },
          {
            id: 4,
            name: 'Puestos de trabajo',
            permissions: [
              {
                id: 1,
                key: 'VIEW_JOBS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de puestos de trabajo',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_JOBS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de puestos de trabajo',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_JOBS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar puestos de trabajo',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_JOBS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar puestos de trabajo',
                status: 0,
              },
              {
                id: 5,
                key: 'CHANGE_STATUS_JOBS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar puestos de trabajo',
                status: 1,
              },
            ],
          },
          {
            id: 5,
            name: 'Equipos',
            permissions: [
              {
                id: 1,
                key: 'VIEW_TEAMS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de equipos',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_TEAMS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de equipos',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_TEAMS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar equipos',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_TEAMS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar equipos',
                status: 1,
              },
              {
                id: 5,
                key: 'CHANGE_STATUS_TEAMS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar equipos',
                status: 0,
              },
            ],
          },
          {
            id: 6,
            name: 'Noticias',
            permissions: [
              {
                id: 1,
                key: 'VIEW_NEWS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de noticias',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_NEWS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de noticias',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_NEWS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar noticias',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_NEWS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar noticias',
                status: 0,
              },
            ],
          },
        ],
      },
      {
        id: 6,
        name: 'ACERVO DOCUMENTAL',
        key: 'DOCUMENTARY_HERITAGE',
        status: 1,
        submodules: [
          {
            id: 1,
            name: 'Club',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CLUB',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta del corporativo',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_CORPORATE',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro del corporativo',
                status: 1,
              },
            ],
          },
          {
            id: 2,
            name: 'Corporativo',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CORPORATE',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta del corporativo',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_CORPORATE',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el corporativo',
                status: 1,
              },
              {
                id: 6,
                key: 'CHANGE_STATUS_CORPORATE',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar el corporativo',
                status: 1,
              },
              {
                id: 7,
                key: 'DONWLOAD_CORPORATE_PDF',
                name: 'Descargar PDF',
                description: 'Descripcion de prueba, otorgar el permiso para descargar la informacion del corporativo en pdf',
                status: 1,
              },
            ],
          },
          {
            id: 3,
            name: 'Sociedad',
            permissions: [
              {
                id: 1,
                key: 'VIEW_SOCIEDAD',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la sociedad',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_SOCIEDAD',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la sociedad',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_SOCIEDAD',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la sociedad',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_SOCIEDAD',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la sociedad',
                status: 1,
              },
            ],
          },
          {
            id: 4,
            name: 'Asambleas',
            permissions: [
              {
                id: 1,
                key: 'VIEW_ASSEMBLY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la sociedad',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_ASSEMBLY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la sociedad',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_ASSEMBLY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la sociedad',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_ASSEMBLY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la sociedad',
                status: 1,
              },
            ],
          },
          {
            id: 5,
            name: 'Poderes',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CAPACITY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los poderes',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_CAPACITY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los poderes',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_CAPACITY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de los poderes',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_CAPACITY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de los poderes',
                status: 1,
              },
            ],
          },
          {
            id: 6,
            name: 'Apoderados',
            permissions: [
              {
                id: 1,
                key: 'VIEW_PROXIES',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los apoderados',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_PROXIES',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los apoderados',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_PROXIES',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de los apoderados',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_PROXIES',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de los apoderados',
                status: 1,
              },
            ],
          },
          {
            id: 7,
            name: 'Propiedad intelectual',
            permissions: [
              {
                id: 1,
                key: 'VIEW_INTELLECTUAL_PROPERTY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_INTELLECTUAL_PROPERTY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_INTELLECTUAL_PROPERTY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_INTELLECTUAL_PROPERTY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
          {
            id: 8,
            name: 'Integrantes',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 5,
                key: 'FILTER_MEMBERS_CLUB',
                name: 'Filtrar por club',
                description: 'Descripcion de prueba, otorga el permiso de filtrar a los integrantes po club',
                status: 1,
              },
            ],
          },
          {
            id: 9,
            name: 'Integrantes Jugadores',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS_PLAYERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_MEMBERS_PLAYERS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_MEMBERS_PLAYERS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_MEMBERS_PLAYERS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
          {
            id: 10,
            name: 'Integrantes Cuerpo técnico',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS_COACHING_STAFF',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_MEMBERS_COACHING_STAFF',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_MEMBERS_COACHING_STAFF',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_MEMBERS_COACHING_STAFF',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 4,
    role_id: 4,
    modules: [
      {
        id: 7,
        name: 'ADMINISTRACIÓN',
        key: 'ADMINISTRATION',
        status: 1,
        submodules: [
          {
            id: 1,
            name: 'Roles',
            permissions: [
              {
                id: 1,
                key: 'VIEW_ROLES',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los roles',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_ROLES',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los roles',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_ROLES',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar los roles',
                status: 1,
              },
              {
                id: 6,
                key: 'CHANGE_STATUS_ROLES',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar los roles',
                status: 1,
              },
              {
                id: 7,
                key: 'DELETE_ROLES',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar los roles',
                status: 1,
              },
            ],
          },
          {
            id: 2,
            name: 'Permisos',
            permissions: [
              {
                id: 1,
                key: 'VIEW_PERMISSIONS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los permisos',
                status: 1,
              },
              {
                id: 2,
                key: 'EDIT_PERMISSIONS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar los permisos',
                status: 0,
              },
            ],
          },
          {
            id: 3,
            name: 'Usuarios',
            permissions: [
              {
                id: 1,
                key: 'VIEW_USERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de usuarios',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_USERS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de usuarios',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_USERS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar usuarios',
                status: 1,
              },
              {
                id: 4,
                key: 'CHANGE_STATUS_USERS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar usuarios',
                status: 1,
              },
              {
                id: 5,
                key: 'DELETE_USERS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar usuarios',
                status: 0,
              },
            ],
          },
          {
            id: 4,
            name: 'Puestos de trabajo',
            permissions: [
              {
                id: 1,
                key: 'VIEW_JOBS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de puestos de trabajo',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_JOBS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de puestos de trabajo',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_JOBS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar puestos de trabajo',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_JOBS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar puestos de trabajo',
                status: 0,
              },
              {
                id: 5,
                key: 'CHANGE_STATUS_JOBS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar puestos de trabajo',
                status: 1,
              },
            ],
          },
          {
            id: 5,
            name: 'Equipos',
            permissions: [
              {
                id: 1,
                key: 'VIEW_TEAMS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de equipos',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_TEAMS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de equipos',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_TEAMS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar equipos',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_TEAMS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar equipos',
                status: 1,
              },
              {
                id: 5,
                key: 'CHANGE_STATUS_TEAMS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar equipos',
                status: 0,
              },
            ],
          },
          {
            id: 6,
            name: 'Noticias',
            permissions: [
              {
                id: 1,
                key: 'VIEW_NEWS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de noticias',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_NEWS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de noticias',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_NEWS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar noticias',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_NEWS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar noticias',
                status: 0,
              },
            ],
          },
        ],
      },
      {
        id: 8,
        name: 'ACERVO DOCUMENTAL',
        key: 'DOCUMENTARY_HERITAGE',
        status: 1,
        submodules: [
          {
            id: 1,
            name: 'Club',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CLUB',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta del corporativo',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_CORPORATE',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro del corporativo',
                status: 1,
              },
            ],
          },
          {
            id: 2,
            name: 'Corporativo',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CORPORATE',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta del corporativo',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_CORPORATE',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el corporativo',
                status: 1,
              },
              {
                id: 6,
                key: 'CHANGE_STATUS_CORPORATE',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar el corporativo',
                status: 1,
              },
              {
                id: 7,
                key: 'DONWLOAD_CORPORATE_PDF',
                name: 'Descargar PDF',
                description: 'Descripcion de prueba, otorgar el permiso para descargar la informacion del corporativo en pdf',
                status: 1,
              },
            ],
          },
          {
            id: 3,
            name: 'Sociedad',
            permissions: [
              {
                id: 1,
                key: 'VIEW_SOCIEDAD',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la sociedad',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_SOCIEDAD',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la sociedad',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_SOCIEDAD',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la sociedad',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_SOCIEDAD',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la sociedad',
                status: 1,
              },
            ],
          },
          {
            id: 4,
            name: 'Asambleas',
            permissions: [
              {
                id: 1,
                key: 'VIEW_ASSEMBLY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la sociedad',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_ASSEMBLY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la sociedad',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_ASSEMBLY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la sociedad',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_ASSEMBLY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la sociedad',
                status: 1,
              },
            ],
          },
          {
            id: 5,
            name: 'Poderes',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CAPACITY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los poderes',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_CAPACITY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los poderes',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_CAPACITY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de los poderes',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_CAPACITY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de los poderes',
                status: 1,
              },
            ],
          },
          {
            id: 6,
            name: 'Apoderados',
            permissions: [
              {
                id: 1,
                key: 'VIEW_PROXIES',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los apoderados',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_PROXIES',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los apoderados',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_PROXIES',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de los apoderados',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_PROXIES',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de los apoderados',
                status: 1,
              },
            ],
          },
          {
            id: 7,
            name: 'Propiedad intelectual',
            permissions: [
              {
                id: 1,
                key: 'VIEW_INTELLECTUAL_PROPERTY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_INTELLECTUAL_PROPERTY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_INTELLECTUAL_PROPERTY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_INTELLECTUAL_PROPERTY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
          {
            id: 8,
            name: 'Integrantes',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 5,
                key: 'FILTER_MEMBERS_CLUB',
                name: 'Filtrar por club',
                description: 'Descripcion de prueba, otorga el permiso de filtrar a los integrantes po club',
                status: 1,
              },
            ],
          },
          {
            id: 9,
            name: 'Integrantes Jugadores',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS_PLAYERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_MEMBERS_PLAYERS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_MEMBERS_PLAYERS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_MEMBERS_PLAYERS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
          {
            id: 10,
            name: 'Integrantes Cuerpo técnico',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS_COACHING_STAFF',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_MEMBERS_COACHING_STAFF',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_MEMBERS_COACHING_STAFF',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_MEMBERS_COACHING_STAFF',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 5,
    role_id: 5,
    modules: [
      {
        id: 9,
        name: 'ADMINISTRACIÓN',
        key: 'ADMINISTRATION',
        status: 0,
        submodules: [
          {
            id: 1,
            name: 'Roles',
            permissions: [
              {
                id: 1,
                key: 'VIEW_ROLES',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los roles',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_ROLES',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los roles',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_ROLES',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar los roles',
                status: 1,
              },
              {
                id: 6,
                key: 'CHANGE_STATUS_ROLES',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar los roles',
                status: 1,
              },
              {
                id: 7,
                key: 'DELETE_ROLES',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar los roles',
                status: 1,
              },
            ],
          },
          {
            id: 2,
            name: 'Permisos',
            permissions: [
              {
                id: 1,
                key: 'VIEW_PERMISSIONS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los permisos',
                status: 1,
              },
              {
                id: 2,
                key: 'EDIT_PERMISSIONS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar los permisos',
                status: 0,
              },
            ],
          },
          {
            id: 3,
            name: 'Usuarios',
            permissions: [
              {
                id: 1,
                key: 'VIEW_USERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de usuarios',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_USERS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de usuarios',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_USERS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar usuarios',
                status: 1,
              },
              {
                id: 4,
                key: 'CHANGE_STATUS_USERS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar usuarios',
                status: 1,
              },
              {
                id: 5,
                key: 'DELETE_USERS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar usuarios',
                status: 0,
              },
            ],
          },
          {
            id: 4,
            name: 'Puestos de trabajo',
            permissions: [
              {
                id: 1,
                key: 'VIEW_JOBS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de puestos de trabajo',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_JOBS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de puestos de trabajo',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_JOBS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar puestos de trabajo',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_JOBS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar puestos de trabajo',
                status: 0,
              },
              {
                id: 5,
                key: 'CHANGE_STATUS_JOBS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar puestos de trabajo',
                status: 1,
              },
            ],
          },
          {
            id: 5,
            name: 'Equipos',
            permissions: [
              {
                id: 1,
                key: 'VIEW_TEAMS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de equipos',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_TEAMS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de equipos',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_TEAMS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar equipos',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_TEAMS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar equipos',
                status: 1,
              },
              {
                id: 5,
                key: 'CHANGE_STATUS_TEAMS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar equipos',
                status: 0,
              },
            ],
          },
          {
            id: 6,
            name: 'Noticias',
            permissions: [
              {
                id: 1,
                key: 'VIEW_NEWS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de noticias',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_NEWS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de noticias',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_NEWS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar noticias',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_NEWS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar noticias',
                status: 0,
              },
            ],
          },
        ],
      },
      {
        id: 10,
        name: 'ACERVO DOCUMENTAL',
        key: 'DOCUMENTARY_HERITAGE',
        status: 1,
        submodules: [
          {
            id: 1,
            name: 'Club',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CLUB',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta del corporativo',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_CORPORATE',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro del corporativo',
                status: 1,
              },
            ],
          },
          {
            id: 2,
            name: 'Corporativo',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CORPORATE',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta del corporativo',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_CORPORATE',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el corporativo',
                status: 1,
              },
              {
                id: 6,
                key: 'CHANGE_STATUS_CORPORATE',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar el corporativo',
                status: 1,
              },
              {
                id: 7,
                key: 'DONWLOAD_CORPORATE_PDF',
                name: 'Descargar PDF',
                description: 'Descripcion de prueba, otorgar el permiso para descargar la informacion del corporativo en pdf',
                status: 1,
              },
            ],
          },
          {
            id: 3,
            name: 'Sociedad',
            permissions: [
              {
                id: 1,
                key: 'VIEW_SOCIEDAD',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la sociedad',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_SOCIEDAD',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la sociedad',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_SOCIEDAD',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la sociedad',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_SOCIEDAD',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la sociedad',
                status: 1,
              },
            ],
          },
          {
            id: 4,
            name: 'Asambleas',
            permissions: [
              {
                id: 1,
                key: 'VIEW_ASSEMBLY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la sociedad',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_ASSEMBLY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la sociedad',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_ASSEMBLY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la sociedad',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_ASSEMBLY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la sociedad',
                status: 1,
              },
            ],
          },
          {
            id: 5,
            name: 'Poderes',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CAPACITY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los poderes',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_CAPACITY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los poderes',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_CAPACITY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de los poderes',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_CAPACITY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de los poderes',
                status: 1,
              },
            ],
          },
          {
            id: 6,
            name: 'Apoderados',
            permissions: [
              {
                id: 1,
                key: 'VIEW_PROXIES',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los apoderados',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_PROXIES',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los apoderados',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_PROXIES',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de los apoderados',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_PROXIES',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de los apoderados',
                status: 1,
              },
            ],
          },
          {
            id: 7,
            name: 'Propiedad intelectual',
            permissions: [
              {
                id: 1,
                key: 'VIEW_INTELLECTUAL_PROPERTY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_INTELLECTUAL_PROPERTY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_INTELLECTUAL_PROPERTY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_INTELLECTUAL_PROPERTY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
          {
            id: 8,
            name: 'Integrantes',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 5,
                key: 'FILTER_MEMBERS_CLUB',
                name: 'Filtrar por club',
                description: 'Descripcion de prueba, otorga el permiso de filtrar a los integrantes po club',
                status: 1,
              },
            ],
          },
          {
            id: 9,
            name: 'Integrantes Jugadores',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS_PLAYERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_MEMBERS_PLAYERS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_MEMBERS_PLAYERS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_MEMBERS_PLAYERS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
          {
            id: 10,
            name: 'Integrantes Cuerpo técnico',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS_COACHING_STAFF',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_MEMBERS_COACHING_STAFF',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_MEMBERS_COACHING_STAFF',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_MEMBERS_COACHING_STAFF',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
        ],
      },
    ],
  },
  // John Lennox
  {
    id: 6,
    role_id: 6,
    modules: [
      {
        id: 11,
        name: 'ADMINISTRACIÓN',
        key: 'ADMINISTRATION',
        status: 1,
        submodules: [
          {
            id: 1,
            name: 'Roles',
            permissions: [
              {
                id: 1,
                key: 'VIEW_ROLES',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los roles',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_ROLES',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los roles',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_ROLES',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar los roles',
                status: 1,
              },
              {
                id: 6,
                key: 'CHANGE_STATUS_ROLES',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar los roles',
                status: 1,
              },
              {
                id: 7,
                key: 'DELETE_ROLES',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar los roles',
                status: 1,
              },
            ],
          },
          {
            id: 2,
            name: 'Permisos',
            permissions: [
              {
                id: 1,
                key: 'VIEW_PERMISSIONS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los permisos',
                status: 1,
              },
              {
                id: 2,
                key: 'EDIT_PERMISSIONS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar los permisos',
                status: 0,
              },
            ],
          },
          {
            id: 3,
            name: 'Usuarios',
            permissions: [
              {
                id: 1,
                key: 'VIEW_USERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de usuarios',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_USERS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de usuarios',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_USERS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar usuarios',
                status: 1,
              },
              {
                id: 4,
                key: 'CHANGE_STATUS_USERS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar usuarios',
                status: 1,
              },
              {
                id: 5,
                key: 'DELETE_USERS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar usuarios',
                status: 0,
              },
            ],
          },
          {
            id: 4,
            name: 'Puestos de trabajo',
            permissions: [
              {
                id: 1,
                key: 'VIEW_JOBS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de puestos de trabajo',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_JOBS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de puestos de trabajo',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_JOBS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar puestos de trabajo',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_JOBS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar puestos de trabajo',
                status: 0,
              },
              {
                id: 5,
                key: 'CHANGE_STATUS_JOBS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar puestos de trabajo',
                status: 1,
              },
            ],
          },
          {
            id: 5,
            name: 'Equipos',
            permissions: [
              {
                id: 1,
                key: 'VIEW_TEAMS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de equipos',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_TEAMS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de equipos',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_TEAMS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar equipos',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_TEAMS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar equipos',
                status: 1,
              },
              {
                id: 5,
                key: 'CHANGE_STATUS_TEAMS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar equipos',
                status: 0,
              },
            ],
          },
          {
            id: 6,
            name: 'Noticias',
            permissions: [
              {
                id: 1,
                key: 'VIEW_NEWS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de noticias',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_NEWS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de noticias',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_NEWS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar noticias',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_NEWS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar noticias',
                status: 0,
              },
            ],
          },
        ],
      },
      {
        id: 12,
        name: 'ACERVO DOCUMENTAL',
        key: 'DOCUMENTARY_HERITAGE',
        status: 1,
        submodules: [
          {
            id: 1,
            name: 'Club',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CLUB',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta del corporativo',
                status: 0,
              },
              {
                id: 2,
                key: 'CREATE_CORPORATE',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro del corporativo',
                status: 0,
              },
            ],
          },
          {
            id: 2,
            name: 'Corporativo',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CORPORATE',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta del corporativo',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_CORPORATE',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el corporativo',
                status: 0,
              },
              {
                id: 6,
                key: 'CHANGE_STATUS_CORPORATE',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar el corporativo',
                status: 0,
              },
              {
                id: 7,
                key: 'DONWLOAD_CORPORATE_PDF',
                name: 'Descargar PDF',
                description: 'Descripcion de prueba, otorgar el permiso para descargar la informacion del corporativo en pdf',
                status: 0,
              },
            ],
          },
          {
            id: 3,
            name: 'Sociedad',
            permissions: [
              {
                id: 1,
                key: 'VIEW_SOCIEDAD',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la sociedad',
                status: 0,
              },
              {
                id: 2,
                key: 'REGISTER_SOCIEDAD',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la sociedad',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_SOCIEDAD',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la sociedad',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_SOCIEDAD',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la sociedad',
                status: 0,
              },
            ],
          },
          {
            id: 4,
            name: 'Asambleas',
            permissions: [
              {
                id: 1,
                key: 'VIEW_ASSEMBLY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la sociedad',
                status: 0,
              },
              {
                id: 2,
                key: 'REGISTER_ASSEMBLY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la sociedad',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_ASSEMBLY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la sociedad',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_ASSEMBLY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la sociedad',
                status: 0,
              },
            ],
          },
          {
            id: 5,
            name: 'Poderes',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CAPACITY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los poderes',
                status: 0,
              },
              {
                id: 2,
                key: 'REGISTER_CAPACITY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los poderes',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_CAPACITY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de los poderes',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_CAPACITY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de los poderes',
                status: 0,
              },
            ],
          },
          {
            id: 6,
            name: 'Propiedad intelectual',
            permissions: [
              {
                id: 1,
                key: 'VIEW_INTELLECTUAL_PROPERTY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 0,
              },
              {
                id: 2,
                key: 'REGISTER_INTELLECTUAL_PROPERTY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_INTELLECTUAL_PROPERTY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_INTELLECTUAL_PROPERTY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 0,
              },
            ],
          },
          {
            id: 7,
            name: 'Integrantes',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 5,
                key: 'FILTER_MEMBERS_CLUB',
                name: 'Filtrar por club',
                description: 'Descripcion de prueba, otorga el permiso de filtrar a los integrantes po club',
                status: 0,
              },
            ],
          },
          {
            id: 8,
            name: 'Integrantes Jugadores',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS_PLAYERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_MEMBERS_PLAYERS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_MEMBERS_PLAYERS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_MEMBERS_PLAYERS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 0,
              },
            ],
          },
          {
            id: 9,
            name: 'Integrantes Cuerpo técnico',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS_COACHING_STAFF',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_MEMBERS_COACHING_STAFF',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_MEMBERS_COACHING_STAFF',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_MEMBERS_COACHING_STAFF',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 0,
              },
            ],
          },
        ],
      },
    ],
  },
  // Pablo Olvera
  {
    id: 7,
    role_id: 7,
    modules: [
      {
        id: 13,
        name: 'ADMINISTRACIÓN',
        key: 'ADMINISTRATION',
        status: 1,
        submodules: [
          {
            id: 1,
            name: 'Roles',
            permissions: [
              {
                id: 1,
                key: 'VIEW_ROLES',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los roles',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_ROLES',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los roles',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_ROLES',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar los roles',
                status: 1,
              },
              {
                id: 6,
                key: 'CHANGE_STATUS_ROLES',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar los roles',
                status: 1,
              },
              {
                id: 7,
                key: 'DELETE_ROLES',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar los roles',
                status: 1,
              },
            ],
          },
          {
            id: 2,
            name: 'Permisos',
            permissions: [
              {
                id: 1,
                key: 'VIEW_PERMISSIONS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los permisos',
                status: 1,
              },
              {
                id: 2,
                key: 'EDIT_PERMISSIONS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar los permisos',
                status: 0,
              },
            ],
          },
          {
            id: 3,
            name: 'Usuarios',
            permissions: [
              {
                id: 1,
                key: 'VIEW_USERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de usuarios',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_USERS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de usuarios',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_USERS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar usuarios',
                status: 1,
              },
              {
                id: 4,
                key: 'CHANGE_STATUS_USERS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar usuarios',
                status: 1,
              },
              {
                id: 5,
                key: 'DELETE_USERS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar usuarios',
                status: 0,
              },
            ],
          },
          {
            id: 4,
            name: 'Puestos de trabajo',
            permissions: [
              {
                id: 1,
                key: 'VIEW_JOBS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de puestos de trabajo',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_JOBS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de puestos de trabajo',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_JOBS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar puestos de trabajo',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_JOBS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar puestos de trabajo',
                status: 0,
              },
              {
                id: 5,
                key: 'CHANGE_STATUS_JOBS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar puestos de trabajo',
                status: 1,
              },
            ],
          },
          {
            id: 5,
            name: 'Equipos',
            permissions: [
              {
                id: 1,
                key: 'VIEW_TEAMS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de equipos',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_TEAMS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de equipos',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_TEAMS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar equipos',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_TEAMS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar equipos',
                status: 1,
              },
              {
                id: 5,
                key: 'CHANGE_STATUS_TEAMS',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar equipos',
                status: 0,
              },
            ],
          },
          {
            id: 6,
            name: 'Noticias',
            permissions: [
              {
                id: 1,
                key: 'VIEW_NEWS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de noticias',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_NEWS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de noticias',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_NEWS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar noticias',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_NEWS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminiar noticias',
                status: 0,
              },
            ],
          },
        ],
      },
      {
        id: 14,
        name: 'ACERVO DOCUMENTAL',
        key: 'DOCUMENTARY_HERITAGE',
        status: 1,
        submodules: [
          {
            id: 1,
            name: 'Club',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CLUB',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta del corporativo',
                status: 1,
              },
              {
                id: 2,
                key: 'CREATE_CORPORATE',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro del corporativo',
                status: 1,
              },
            ],
          },
          {
            id: 2,
            name: 'Corporativo',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CORPORATE',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta del corporativo',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_CORPORATE',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el corporativo',
                status: 1,
              },
              {
                id: 6,
                key: 'CHANGE_STATUS_CORPORATE',
                name: 'Desactivar',
                description: 'Descripcion de prueba, otorgar el permiso para desactivar el corporativo',
                status: 1,
              },
              {
                id: 7,
                key: 'DONWLOAD_CORPORATE_PDF',
                name: 'Descargar PDF',
                description: 'Descripcion de prueba, otorgar el permiso para descargar la informacion del corporativo en pdf',
                status: 1,
              },
            ],
          },
          {
            id: 3,
            name: 'Sociedad',
            permissions: [
              {
                id: 1,
                key: 'VIEW_SOCIEDAD',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la sociedad',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_SOCIEDAD',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la sociedad',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_SOCIEDAD',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la sociedad',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_SOCIEDAD',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la sociedad',
                status: 1,
              },
            ],
          },
          {
            id: 4,
            name: 'Asambleas',
            permissions: [
              {
                id: 1,
                key: 'VIEW_ASSEMBLY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la sociedad',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_ASSEMBLY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la sociedad',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_ASSEMBLY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la sociedad',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_ASSEMBLY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la sociedad',
                status: 1,
              },
            ],
          },
          {
            id: 5,
            name: 'Poderes',
            permissions: [
              {
                id: 1,
                key: 'VIEW_CAPACITY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los poderes',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_CAPACITY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los poderes',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_CAPACITY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de los poderes',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_CAPACITY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de los poderes',
                status: 1,
              },
            ],
          },
          {
            id: 6,
            name: 'Apoderados',
            permissions: [
              {
                id: 1,
                key: 'VIEW_PROXIES',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de los apoderados',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_PROXIES',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de los apoderados',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_PROXIES',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de los apoderados',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_PROXIES',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de los apoderados',
                status: 1,
              },
            ],
          },
          {
            id: 7,
            name: 'Propiedad intelectual',
            permissions: [
              {
                id: 1,
                key: 'VIEW_INTELLECTUAL_PROPERTY',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_INTELLECTUAL_PROPERTY',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_INTELLECTUAL_PROPERTY',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_INTELLECTUAL_PROPERTY',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
          {
            id: 8,
            name: 'Integrantes',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 5,
                key: 'FILTER_MEMBERS_CLUB',
                name: 'Filtrar por club',
                description: 'Descripcion de prueba, otorga el permiso de filtrar a los integrantes po club',
                status: 1,
              },
            ],
          },
          {
            id: 9,
            name: 'Integrantes Jugadores',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS_PLAYERS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_MEMBERS_PLAYERS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 3,
                key: 'EDIT_MEMBERS_PLAYERS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 1,
              },
              {
                id: 4,
                key: 'DELETE_MEMBERS_PLAYERS',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
          {
            id: 10,
            name: 'Integrantes Cuerpo técnico',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS_COACHING_STAFF',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de la propiedad intelectual',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_MEMBERS_COACHING_STAFF',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de la propiedad intelectual',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_MEMBERS_COACHING_STAFF',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de la propiedad intelectual',
                status: 0,
              },
              {
                id: 4,
                key: 'DELETE_MEMBERS_COACHING_STAFF',
                name: 'Eliminar',
                description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
                status: 1,
              },
            ],
          },
          {
            id: 11,
            name: 'Condiciones contractuales (Integrantes)',
            permissions: [
              {
                id: 1,
                key: 'VIEW_MEMBERS_CONTRACTUAL_CONDITIONS',
                name: 'Consultar',
                description: 'Descripcion de prueba, otorgar el permiso para la consulta de condiciones contractuales',
                status: 1,
              },
              {
                id: 2,
                key: 'REGISTER_MEMBERS_CONTRACTUAL_CONDITIONS',
                name: 'Registar',
                description: 'Descripcion de prueba, otorgar el permiso para el registro de condiciones contractuales',
                status: 0,
              },
              {
                id: 3,
                key: 'EDIT_MEMBERS_CONTRACTUAL_CONDITIONS',
                name: 'Modificar',
                description: 'Descripcion de prueba, otorgar el permiso para modificar el registro de condiciones contractuales',
                status: 0,
              },
              // {
              //   id: 4,
              //   key: 'DELETE_MEMBERS_CONTRACTUAL_CONDITIONS',
              //   name: 'Eliminar',
              //   description: 'Descripcion de prueba, otorgar el permiso para eliminar el registro de la propiedad intelectual',
              //   status: 1,
              // },
            ],
          },
        ],
      },
    ],
  },
]

mock.onGet('/permissions/modules').reply(() => [200, modules])

mock.onGet('/permissions').reply(({ params }) => {
  const { idRole } = params
  const dataFilter = roleModuleData.filter(roleModule => roleModule.role_id === idRole)[0]
  dataFilter.role = dataRoles[dataRoles.findIndex(rol => rol.id === idRole)]

  return [200, dataFilter]
})

mock.onPost('/permissions').reply(({ data }) => {
  const role = JSON.parse(data)
  const lastIndex = roleModuleData[roleModuleData.length - 1].id + 1

  roleModuleData.push({
    id: lastIndex,
    role_id: role.id,
    modules,
  })

  return [200]
})
mock.onPut('/role/permissions').reply(config => {
  // Get event from post data
  const permission = JSON.parse(config.data)

  const filterData = roleModuleData.filter(element => element.role_id === Number(permission.role_id))[0]
  const indexModule = roleModuleData.findIndex(element => element.role_id === Number(permission.role_id))
  const index = filterData.modules.findIndex(element => element.id === Number(permission.id))

  delete permission.role_id
  filterData.modules[index] = permission

  roleModuleData[indexModule] = filterData
  return [200, permission]
})

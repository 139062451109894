import mock from '@/@fake-db/mock'

export const data = [
  { id: 1, name: 'Educación básica' },
  { id: 2, name: 'Educación media superior' },
  { id: 3, name: 'Educación superior' },
  { id: 4, name: 'Posgrado' },
]

mock.onGet('/study-levels').reply(() => [200, data])

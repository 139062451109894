import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import { $themeColors } from '@themeConfig'
// ! Doc https://sweetalert2.github.io/

Vue.mixin({
  computed: {
    ...mapGetters({
      colorRGB: 'colorRGBX',
    }),
  },
  methods: {
    /**
     * Response
     *
     * @param {string} text - response message
     *
     * @param {string} name - Nombre del registro
     *
     */
    responseSuccess(pText) {
      this.$swal({
        title: this.$t('successful-message'),
        text: pText,
        icon: 'success',
        confirmButtonText: this.$t('generic.accept'),
        confirmButtonColor: this.colorRGB,
      })
    },
    responseSuccessCreate(text) {
      this.$swal({
        title: this.$t('successful-registration'),
        text,
        icon: 'success',
        confirmButtonText: this.$t('generic.accept'),
        confirmButtonColor: this.colorRGB,
      })
    },
    responseSuccessUpdate(text) {
      this.$swal({
        title: this.$t('successful-update'),
        text,
        icon: 'success',
        confirmButtonText: this.$t('generic.accept'),
        confirmButtonColor: this.colorRGB,
      })
    },
    responseSuccessElimination(text) {
      this.$swal({
        title: this.$t('successful-elimination'),
        text,
        icon: 'success',
        confirmButtonText: this.$t('generic.accept'),
        confirmButtonColor: this.colorRGB,
      })
    },
    statusUpdate(definiteArticle = '', status) {
      this.$swal({
        title: this.$t('successful-update'),
        text:
          `${definiteArticle} ${this.$t('has-been')} ${status ? this.$t('activated') : this.$t('disabled')}`,
        icon: 'success',
        confirmButtonText: this.$t('generic.accept'),
        confirmButtonColor: this.colorRGB,
      })
    },
    showError(text) {
      this.$swal({
        title: this.$t('error-catch'),
        text,
        icon: 'error',
        confirmButtonText: this.$t('generic.accept'),
        confirmButtonColor: this.colorRGB,
      })
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: this.$t('Error'),
      //     text,
      //     icon: 'XCircleIcon',
      //     variant: 'danger',
      //   },
      // })
    },
    // showError(text) {
    //   this.$toast({
    //     component: ToastificationContent,
    //     props: {
    //       title: this.$t('Error'),
    //       text,
    //       icon: 'XCircleIcon',
    //       variant: 'danger',
    //     },
    //   })
    // },
    showNotification(title, text) {
      text = text.length > 55 ? `${text.substring(0, 55)}...` : text
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon: 'BellIcon',
          variant: 'primary',
        },
      })
    },
    async sampleDeleteQuestion() {
      const res = await this.$swal({
        title: this.$t('are-you-sure'),
        text: this.$t('you-will-not-be-able-to-reverse-this'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('buttons.cancel'),
        confirmButtonText: this.$t('continue'),
        confirmButtonColor: this.colorRGB,
        cancelButtonColor: $themeColors.secondary,
        customClass: {
          cancelButton: 'ml-1',
        },
      })
      return res
    },
    responseCatch(e) {
      const { data } = e.response
      this.$swal({
        title: this.$t('error-catch'),
        html: data.message,
        icon: 'error',
        confirmButtonText: this.$t('generic.accept'),
        confirmButtonColor: this.colorRGB,
      })
    },
    showDynamicMessage(pTitle, pText, pIcon) {
      this.$swal({
        title: this.$t(pTitle),
        text: `${this.$t(pText)}`,
        icon: pIcon,
        confirmButtonText: this.$t('generic.accept'),
        confirmButtonColor: this.colorRGB,
      })
    },
    // cancelButtonText: this.$t('buttons.cancel'),
    // cancelButtonColor: $themeColors.secondary,
    async confirmationQuestion(title, text) {
      const res = await this.$swal({
        title,
        text,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonColor: this.colorRGB,
        denyButtonColor: $themeColors.secondary,
        denyButtonText: this.$t('generic.cleanForm'),
        confirmButtonText: this.$t('continue'),
        reverseButtons: true,
        customClass: {
          confirmButton: 'ml-1',
        },
      })
      return res
    },

    // Message informative option cnacel
    async showDeleteWarning(title = this.$t('delete')) {
      const res = await this.$swal({
        toast: true,
        title,
        icon: 'info',
        confirmButtonText: this.$t('buttons.cancel'),
        showConfirmButton: true,
        showCancelButton: false,
        buttonsStyling: false,
        timer: 3000,
        timerProgressBar: true,
        customClass: {
          confirmButton: 'btn btn-sm btn-outline-secondary',
        },
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        position: 'top-right',
      })
      return res
    },
  },
})

import Vue from 'vue'
import VueRouter from 'vue-router'

// validate routes
import checkPermission from '@/auth/permissions'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'

// store
import store from '@/store/index'

// Routes
import { initialAbility } from '@/libs/acl/config'
import pages from './routes/pages'
import accounts from './routes/accounts'
import dashboard from './routes/dashboard'
import documentCollection from './routes/document_collection'
import sportsIntelligence from './routes/sports-intelligence'
import legal from './routes/legal'
import competition from './routes/competition'
import financial from './routes/financial'
import medicine from './routes/medicine'
import inventory from './routes/inventory'
import commercial from './routes/commercial'
import warehouse from './routes/warehouse'
import infrastructure from './routes/infrastructure'
import administration from './routes/administration'
import member from './routes/member'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-main' } },
    ...pages,
    ...accounts,
    ...dashboard,
    ...documentCollection,
    ...sportsIntelligence,
    ...legal,
    ...competition,
    ...financial,
    ...medicine,
    ...inventory,
    ...commercial,
    ...warehouse,
    ...infrastructure,
    ...administration,
    ...member,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const vuexLocal = JSON.parse(localStorage.getItem('vuex'))

  if (to.meta.forgot) {
    return next()
  }

  if (vuexLocal && (vuexLocal.userAuthStore.fullName != null && !localStorage.hasOwnProperty('access_token'))) {
    setTimeout(() => next({ name: 'auth-login' }), 400)

    store.dispatch('deleteSessionInfo')
    this.$ability.update(initialAbility)
  }

  if (!localStorage.hasOwnProperty('vuex') && !localStorage.hasOwnProperty('access_token') && to.name != 'auth-login') {
    setTimeout(() => next({ name: 'auth-login' }), 400)

    store.dispatch('deleteSessionInfo')
    this.$ability.update(initialAbility)
  }

  if (localStorage.hasOwnProperty('vuex') && vuexLocal.userAuthStore.fullName == null && to.name != 'auth-login') {
    return next({ name: 'auth-login' })
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }

  /* if (to.meta.redirectIfProfileIsIncomplete && _.meta.profile && (store.state.userAuthStore.incompleteByImage || store.state.userAuthStore.incomplete || !store.state.userAuthStore.updatedPassword)) {
    return 0
  }

  if (to.meta.redirectIfProfileIsIncomplete && (store.state.userAuthStore.incompleteByImage || store.state.userAuthStore.incomplete || !store.state.userAuthStore.updatedPassword)) {
    return next({ name: 'account-settings', params: { id: store.state.userAuthStore.id } })
  }

  if (to.meta.redirectIfClubIsIncomplete && !store.state.club.assign_representatives.length && !checkPermission(['ALLOW_ALL'])) {
    return next({ name: 'clubs' })
  } */

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

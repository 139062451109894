// eslint-disable-next-line import/no-cycle
import apiInstance from './index'
import queryParams from './queryParams'

export function getMembers(tempQuery) {
  const query = queryParams.serializeParams(tempQuery.paramsObj, tempQuery.filtersObj)
  return apiInstance({
    url: `/members${query}`,
    method: 'GET',
    // params: query,
  })
}
export function getMembersIncompleted(tempQuery) {
  const query = queryParams.serializeParams(tempQuery.paramsObj, tempQuery.filtersObj)
  return apiInstance({
    url: `/members/incompleted${query}`,
    method: 'GET',
    // params: query,
  })
}

export function storeMember(data) {
  return apiInstance({
    url: '/members',
    method: 'POST',
    data,
  })
}
export function updateMember(data) {
  return apiInstance({
    url: `/members/${data.hash}`,
    method: 'PUT',
    data,
  })
}
export function destroyMember(hash) {
  return apiInstance({
    url: `/members/${hash}`,
    method: 'DELETE',
  })
}
export function updateStatus(hash) {
  return apiInstance({
    url: `/members/status/${hash}`,
    method: 'PUT',
  })
}
export function getMemberbyId(hash) {
  return apiInstance({
    url: `/members/${hash}`,
    method: 'GET',
  })
}
export function validateViewPemission(data) {
  return apiInstance({
    url: 'protected-files/view',
    method: 'POST',
    data,
  })
}

export function validateDownloadPemission(data) {
  return apiInstance({
    url: 'protected-files/download',
    method: 'POST',
    data,
  })
}

export function setIncompleteRecord(data) {
  return apiInstance({
    url: 'members/incomplete-record',
    method: 'POST',
    data,
  })
}

export function updateIncompleteRecord(data, id) {
  return apiInstance({
    url: `members/incomplete-record/${id}`,
    method: 'POST',
    data,
  })
}

export function fetchIncompleteRecord(data) {
  return apiInstance({
    url: 'members/incomplete-record/by-type',
    method: 'POST',
    data,
  })
}

export function deleteIncompleteRecord(id) {
  return apiInstance({
    url: `members/incomplete-record/${id}`,
    method: 'DELETE',
  })
}

import mock from '@/@fake-db/mock'
import checkPermission from '@/auth/permissions'
import { paginateArray } from '@/@fake-db/utils'
import { data as dataClubes } from '@/@fake-db/data/clubs/club-setting'
import { data as dataCountries } from '@/@fake-db/data/catalogs/countries'
import { data as dataCities } from '@/@fake-db/data/catalogs/cities'
import dataUserClub from '@/@fake-db/data/clubs/club-user'
// import dataUserRole from '@/@fake-db/data/roles/role-user'
import ostrail from '../clubs/ostrail'

export const data = [
  {
    id: 5,
    name: 'Coordinador',
    allow_all: false,
    is_admin_club: 0,
    sport_club_id: 3,
    create_at: '2022-02-21 11:35:15',
    status: 0,
  },
  {
    id: 4,
    name: 'Administrador de club Athletic',
    allow_all: false,
    is_admin_club: 1,
    sport_club_id: 2,
    create_at: '2022-02-21 11:25:10',
    status: 1,
  },
  {
    id: 3,
    name: 'Administrador',
    allow_all: false,
    is_admin_club: 0,
    sport_club_id: 2,
    create_at: '2022-02-21 11:22:15',
    status: 1,
  },
  {
    id: 2,
    name: 'Administradora global',
    allow_all: true,
    is_admin_club: 0,
    sport_club_id: null,
    create_at: '2022-02-20 11:22:15',
    status: 1,
  },
  {
    id: 1,
    name: 'Supervisor (Eliminado)',
    allow_all: false,
    is_admin_club: 0,
    sport_club_id: 1,
    create_at: '2022-02-20 11:22:15',
    status: 1, // Deleted
  },
  {
    id: 6,
    name: 'Administrador de equipos',
    allow_all: false,
    is_admin_club: 0,
    sport_club_id: 1,
    create_at: '2022-02-21 11:22:15',
    status: 1,
  },
  {
    id: 7,
    name: 'Administrador de club FCB',
    allow_all: false,
    is_admin_club: 1,
    sport_club_id: 1,
    create_at: '2021-02-21 11:22:15',
    status: 1,
  },
]

mock.onGet('/roles').reply(({ params }) => {
  // let dataList = paginateArray(data, params.filters.perPage, params.filters.page)
  let dataList = data
  dataList.forEach(rol => {
    if (rol.sport_club_id != null) {
      const clubData = dataClubes.filter(sportClub => sportClub.id == rol.sport_club_id)
      rol.sport_club = clubData.length > 0 ? clubData[0] : null
    }
    // Ostrail
    if (rol.sport_club_id == null) {
      rol.sport_club = ostrail
    }
    if (rol.sport_club != null) {
      const indexCountry = dataCountries.findIndex(element => element.id === rol.sport_club.country_id)
      const indexCity = dataCities.findIndex(element => element.id === rol.sport_club.city_id)
      rol.sport_club.country = dataCountries[indexCountry]
      rol.sport_club.city = dataCities[indexCity]
    }
  })

  const userData = JSON.parse(localStorage.getItem('userData'))
  if (!checkPermission(['ALLOW_ALL'])) {
    const filterClub = dataUserClub.filter(item => item.user_id === Number(userData.id))
    dataList = dataList.filter(item => filterClub.find(e => e.sport_club_id == item.sport_club_id))
  }

  // Filter search
  const filter = dataList
  if (params.filters.search != null) {
    const dataFilter = filter.filter(item => item.name !== null)
    dataList = dataFilter.filter(item => item.name.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }
  if (params.filters.search != null && dataList.length === 0) {
    const filterRole = filter.filter(item => item.sport_club != null)
    dataList = filterRole.filter(item => item.sport_club.club_name.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }

  // Filter club
  if (params.filters.idClub != null) {
    const dataFilter = dataList.filter(item => item.sport_club_id !== null)
    dataList = dataFilter.filter(item => item.sport_club_id == params.filters.idClub)
  }

  // Filter country
  if (params.filters.idCountry != null) {
    const dataFilter = dataList.filter(item => item.sport_club_id !== null)
    dataList = dataFilter.filter(item => item.sport_club.country_id === params.filters.idCountry)
  }

  // Filter state
  if (params.filters.idState != null) {
    const dataFilter = dataList.filter(item => item.sport_club_id !== null)
    dataList = dataFilter.filter(item => item.sport_club.city_id === params.filters.idState)
  }

  // Filter status
  if (params.filters.status == null) {
    dataList = dataList.filter(item => item.status !== 2)
  }
  if (params.filters.status == 0) {
    dataList = dataList.filter(item => item.status === 0)
  }
  if (params.filters.status == 1) {
    dataList = dataList.filter(item => item.status === 1)
  }

  if (params.filters.status == 2) {
    dataList = dataList.filter(item => item.status === 2)
  }

  const totalItems = dataList.length

  dataList = paginateArray(dataList, params.filters.perPage, params.filters.page)

  return [200, {
    data: dataList,
    totalRecors: totalItems,
    to: params.filters.perPage * (params.filters.page - 1) + dataList.length,
    from: params.filters.perPage * (params.filters.page - 1) + (dataList.length ? 1 : 0),
  }]
})

// ------------------------------------------------
// POST: Add new rol
// ------------------------------------------------
mock.onPost('/roles').reply(config => {
  // Get event from post data
  const rol = JSON.parse(config.data)

  rol.id = Date.now()
  rol.create_at = new Date()

  if (rol.sport_club_id != null) {
    const clubData = dataClubes.filter(sportClub => sportClub.id == rol.sport_club_id)
    rol.sport_club = clubData.length > 0 ? clubData[0] : null
  }
  // Ostrail
  if (rol.sport_club_id == null) {
    rol.sport_club = ostrail
  }
  if (rol.sport_club != null) {
    const indexCountry = dataCountries.findIndex(element => element.id === rol.sport_club.country_id)
    const indexCity = dataCities.findIndex(element => element.id === rol.sport_club.city_id)
    rol.sport_club.country = dataCountries[indexCountry]
    rol.sport_club.city = dataCities[indexCity]
  }
  // data.push(rol)
  data.unshift(rol)

  return [200, rol]
})

// ------------------------------------------------
// POST: update rol
// ------------------------------------------------
mock.onPut('/roles').reply(config => {
  // Get event from post data
  const rol = JSON.parse(config.data)
  const index = data.findIndex(element => element.id == rol.id)
  data[index] = rol

  return [200, rol]
})

// ------------------------------------------------
// POST: delete rol
// ------------------------------------------------
mock.onPost('/roles/delete').reply(config => {
  // Get event from post data
  const rol = JSON.parse(config.data)
  const index = data.findIndex(element => element.id == rol.id)
  data.splice(index, 1)

  return [200, rol]
})

mock.onGet('/roles/list').reply(() => {
  const rol = data.filter(item => item.sport_club_id == null)
  return [200, rol]
})

const response = []
mock.onGet(/\/roles\/\d+/).reply(config => {
  const rolId = config.url.split('/')[2]

  const rol = data.filter(item => item.sport_club_id === Number(rolId))

  if (rol.length > 0) {
    response.push(rol)
  }
  const [...resul] = response

  return [200, resul]
  // return [200, rol]
})

// const response = []
mock.onGet(/\/role-club\/\d+/).reply(config => {
  const rolId = config.url.split('/')[2]

  const rol = data.filter(item => item.sport_club_id === Number(rolId))

  return [200, rol]
})

// Disable
// ------------------------------------------------
// Get: update rol
// ------------------------------------------------
// mock.onPut('/clubs/rol').reply(config => {
//   // Get event from post data
//   const rol = JSON.parse(config.data)
//   const roles = []
//   let temp = []
//   if (rol.sport_club) {
//     rol.sport_club.forEach(e => {
//       temp = data.filter(item => item.sport_club_id === e.id)
//       roles.push(temp)
//     })
//   }
//   const [a, b, c] = roles

//   let element = []
//   if (a != null && b == null && c == null) {
//     element = a
//   } else if (a != null && b != null) {
//     element = a.concat(b)
//   } else if (a != null && b != null && c != null) {
//     element = a.concat(b)
//     element = c.concat(element)
//   }

//   return [200, element]
// })

import mock from '@/@fake-db/mock'

const date = new Date()
const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
// prettier-ignore
const nextMonth = date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1)
// prettier-ignore
const prevMonth = date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1)

const avatar2 = require('@/assets/images/avatars/13-small.png')
const avatar5 = require('@/assets/images/avatars/8-small.png')

const data = {
  events: [
    {
      id: 1,
      url: 'https://meet.google.com/hpm-cdrs-mh',
      title: 'Design Review',
      start: date,
      end: nextDay,
      allDay: 0,
      location: 'Cancha',
      description: 'This is a wider card with supporting text below as a natural lead-in to additional content',
      guests: [{
        id: 2,
        name: 'Roberto',
        avatar: avatar2,
      }, {
        id: 5,
        avatar: avatar5,
        name: 'Pablo',
      }],
      include_everyone: 0,
      exclude_guests: [],
      user_id: 4,
      sport_club_id: 1,
      event_id: null,
    },
    {
      id: 2,
      url: '',
      title: 'Meeting With Client',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
      allDay: 1,
      location: 'Oficina',
      description: '',
      guests: [],
      include_everyone: 0,
      exclude_guests: [],
      user_id: 5,
      sport_club_id: 1,
      event_id: null,
    },
    {
      id: 3,
      url: 'https://meet.google.com/hpm-cdrs-mh',
      title: 'Family Trip',
      allDay: 1,
      start: new Date(date.getFullYear(), date.getMonth() + 1, -9),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -7),
      location: 'Oficina',
      description: 'This is a wider card with supporting text below as a natural lead-in to additional content',
      guests: [],
      include_everyone: 0,
      exclude_guests: [],
      user_id: null,
      sport_club_id: null,
      event_id: null,
    },
    {
      id: 4,
      url: '',
      title: "Doctor's Appointment",
      start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
      allDay: 1,
      location: 'Oficina',
      description: '',
      guests: [],
      include_everyone: 1,
      exclude_guests: [],
      user_id: 1,
      sport_club_id: null,
      event_id: null,
    },
    {
      id: 5,
      url: 'https://meet.google.com/hpm-cdrs-mh',
      title: 'Dart Game?',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
      allDay: 1,
      location: 'Oficina',
      description: '',
      guests: [],
      include_everyone: 0,
      exclude_guests: [],
      user_id: 5,
      sport_club_id: null,
      event_id: null,
    },
    {
      id: 6,
      url: 'https://meet.google.com/hpm-cdrs-mh',
      title: 'Meditation',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
      allDay: 1,
      location: 'Cancha',
      description: 'This is a wider card with supporting text below as a natural lead-in to additional content',
      guests: [],
      include_everyone: 0,
      exclude_guests: [],
      user_id: 4,
      sport_club_id: null,
      event_id: null,
    },
    {
      id: 7,
      url: 'https://meet.google.com/hpm-cdrs-mh',
      title: 'Dinner',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
      allDay: 1,
      location: '',
      description: 'This is a wider card with supporting text below as a natural lead-in to additional content',
      guests: [],
      include_everyone: 0,
      exclude_guests: [],
      user_id: 5,
      sport_club_id: null,
      event_id: null,
    },
    {
      id: 8,
      url: '',
      title: 'Product Review',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
      allDay: 1,
      location: '',
      description: '',
      guests: [],
      include_everyone: 0,
      exclude_guests: [],
      user_id: 4,
      sport_club_id: null,
      event_id: null,
    },
    {
      id: 9,
      url: '',
      title: 'Monthly Meeting',
      start: nextMonth,
      end: nextMonth,
      allDay: 1,
      location: '',
      description: '',
      guests: [],
      include_everyone: 0,
      exclude_guests: [],
      user_id: 1,
      sport_club_id: null,
      event_id: null,
    },
    {
      id: 10,
      url: '',
      title: 'Monthly Checkup',
      start: prevMonth,
      end: prevMonth,
      allDay: 1,
      location: '',
      description: '',
      guests: [],
      include_everyone: 0,
      exclude_guests: [],
      user_id: 1,
      sport_club_id: null,
      event_id: null,
    },
    {
      id: 11,
      url: '',
      title: 'Prueba',
      start: new Date('2022-05-06'),
      end: new Date('2022-05-07'),
      allDay: 1,
      location: '',
      description: '',
      guests: [],
      include_everyone: 0,
      exclude_guests: [],
      user_id: 4,
      sport_club_id: null,
      event_id: null,
    },
  ],
}

// ------------------------------------------------
// GET: Return calendar events filter date
// ------------------------------------------------
mock.onGet('/apps/calendar/events').reply(config => {
  // Get requested calendars as Array
  let dataList = data.events
  const { day } = config.params
  dataList.forEach(e => {
    const d = new Date(e.start)
    const r = d.getDate()
    e.day = r
  })
  dataList = dataList.filter(item => item.day === day)
  const userData = JSON.parse(localStorage.getItem('userData'))
  dataList = dataList.filter(item => item.user_id === Number(userData.id))
  // if (dataList.length > 0) {
  //   dataList = data
  // }
  return [200, dataList]
})

// ------------------------------------------------
// GET: Return events filter user notifications
// ------------------------------------------------
mock.onGet('/apps/calendar/events/notifications').reply(() => {
  // Get requested calendars as Array
  let dataList = data.events
  const userData = JSON.parse(localStorage.getItem('userData'))
  dataList = dataList.filter(item => item.guests.find(e => e.id === userData.id) || item.include_everyone === 1)

  return [200, dataList]
})

// ------------------------------------------------
// POST: Add new event
// ------------------------------------------------
mock.onPost('/apps/calendar/events').reply(config => {
  // Get event from post data
  const { event } = JSON.parse(config.data)

  const userData = JSON.parse(localStorage.getItem('userData'))

  const { length } = data.events
  let lastIndex = 0
  if (length) {
    lastIndex = data.events[length - 1].id
  }
  event.id = lastIndex + 1
  event.user_id = userData.id

  data.events.push(event)

  return [201, event]
})

// ------------------------------------------------
// POST: Update Event
// ------------------------------------------------
mock.onPost(/\/apps\/calendar\/events\/\d+/).reply(config => {
  const { event: eventData } = JSON.parse(config.data)

  // Convert Id to number
  eventData.id = Number(eventData.id)

  const event = data.events.find(e => e.id === Number(eventData.id))
  Object.assign(event, eventData)

  return [200, event]
})

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete(/\/apps\/calendar\/events\/\d+/).reply(config => {
  // Get event id from URL
  let eventId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  eventId = Number(eventId)

  const eventIndex = data.events.findIndex(e => e.id === Number(eventId))
  data.events.splice(eventIndex, 1)
  return [200, eventId]
})

// ------------------------------------------------
// GET: Return calendar events list
// ------------------------------------------------

mock.onGet('/apps/calendar/list').reply(() => {
  let dataList = data.events
  const userData = JSON.parse(localStorage.getItem('userData'))
  dataList = dataList.filter(item => item.user_id === userData.id)
  return [200, dataList]
})

// ------------------------------------------------
// Show: view Event
// ------------------------------------------------
mock.onGet(/\/apps\/calendar\/event\/\d+/).reply(config => {
  // Get event id from URL
  let eventId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  eventId = Number(eventId)

  const event = data.events.find(e => e.id === eventId)
  return [200, event]
})

export default [
  {
    path: '/medicine',
    name: 'medicine',
    component: () => import('@/views/partials/pages/UnderConstruction.vue'),
    meta: {
      redirectIfProfileIsIncomplete: true,
      redirectIfClubIsIncomplete: true,
    },
  },
]

import axios from '@axios'

export default {
  state: {
    typesOLOAW: [],
  },

  mutations: {
    setTypesOfLiteraryOrArtisticWork(state, value) {
      state.typesOLOAW = value
    },
  },

  actions: {
    fetchTypesOfLiteraryOrArtisticWork({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/clubs/types-of-literary-or-artistic-work').then(response => {
          commit('setTypesOfLiteraryOrArtisticWork', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },

  getters: {
    typesOLOAWList(state) {
      return state.typesOLOAW
    },
  },
}

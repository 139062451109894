import axios from '@axios'

export default {
  state: {
    partners: [],
  },

  mutations: {

  },

  actions: {

  },

  getters: {

  },
}

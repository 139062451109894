import axios from '@axios'

export const resetState = ({ commit }) => {
  commit('MUTATE_SET_RESET_STATE')
}

export const getUsersData = ({ commit }, filters) => new Promise((resolve, reject) => {
  axios.get('/users', {
    params: {
      filters,
    },
  }).then(Response => {
    commit('setUserListData', Response.data)
    resolve(true)
  }).catch(error => {
    reject(error)
  })
})
/* export const getUserslist = ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/users/list').then(Response => {
    commit('setUserListArray', Response.data)
    resolve(true)
  }).catch(error => {
    reject(error)
  })
}) */
export const createNewUser = ({ commit }, newUser) => new Promise((resolve, reject) => {
  axios.post('/users', newUser).then(response => {
    commit('addNewUser', response.data)

    resolve(true)
  }).catch(error => {
    reject(error)
  })
})
export const updateUser = ({ commit }, user) => new Promise((resolve, reject) => {
  axios.put('/users', user).then(response => {
    commit('updateUser', response.data)
    resolve(true)
  }).catch(error => {
    reject(error)
  })
})
export const deleteUser = ({ commit }, user) => new Promise((resolve, reject) => {
  axios.post('/users/delete', user).then(response => {
    commit('deleteUser', response.data)
    resolve(true)
  }).catch(error => {
    reject(error)
  })
})

export const getModulePermissions = ({ commit }, idRole) => new Promise((resolve, reject) => {
  axios.get(`/role/${idRole}`).then(response => {
    commit('setPermissions', response.data)
    resolve(true)
  }).catch(error => {
    reject(error)
  })
})

export const updateModulePermissions = ({ commit }, module) => new Promise((resolve, reject) => {
  axios.put('/user/role/permissions', module).then(response => {
    commit('updateModulePermissions', response.data)
    resolve(true)
  }).catch(error => {
    reject(error)
  })
})

export const fetchUserInfoById = ({ commit }, userId) => new Promise((resolve, reject) => {
  axios.get(`/users/${userId}`).then(response => {
    commit('setUser', response.data)
    resolve(response)
  }).catch(error => reject(error))
})

export const updatePassword = ({ commit }, params) => new Promise((resolve, reject) => {
  axios.put('/users/info', params).then(response => {
    commit('setUserPassword', response.data)
    resolve(true)
  }).catch(error => {
    reject(error)
  })
})

import getDefaultState from './state'

export const resetState = state => {
  Object.assign(state, getDefaultState())
}
export const setData = (state, list) => {
  state.memberList = list
}
export const setIncompleteData = (state, list) => {
  state.membersIncompleteDataList = list
}
export const setMembers = (state, member) => {
  // state.memberList.data.push(member)
  state.memberList.data.unshift(member)
}
// export const update = (state, member) => {
// const index = state.memberList.data.findIndex(element => element.id === member.id)
// state.memberList.data[index] = member
// }
export const destroy = (state, member) => {
  const index = state.memberList.data.findIndex(element => element.id === member.id)
  state.memberList.data.splice(index, 1)
}
export const setMember = (state, member) => {
  state.member = member
}
export const SET_TYPE_CONTRACTUAL_CONDITION = (state, type) => {
  state.member.contractual_condition.type_contractual_conditions = type
}
export const SET_HIDE_PLAYER_AGENT = (state, type) => {
  state.member.contractual_condition.player_agent_key = type
}
export const setSelectType = (state, type) => {
  state.selectType = type
}
export const setSelectGenderHash = (state, genderHash) => {
  state.selectGenderHash = genderHash
}

import mock from '@/@fake-db/mock'

import { paginateArray } from '@/@fake-db/utils'

import { data as dataInstruments } from '@/@fake-db/data/clubs/instrument-numbers'
import { data as dataARI } from '@/@fake-db/data/clubs/assign-representative-instrument'
import { data as dataARPR } from '@/@fake-db/data/clubs/assign-representative-partner-representative'
import { data as dataARPG } from '@/@fake-db/data/clubs/assign-representative-power-granted'
// import { data as dataRepresentatives } from '@/@fake-db/data/clubs/representatives'
import dataRepresentatives from '@/@fake-db/data/clubs/representatives'
import { data as dataPowersGranted } from '@/@fake-db/data/clubs/powers-granted'
import { data as dataPartners } from '@/@fake-db/data/clubs/partners'

export const data = [
  {
    id: 1,
    club_id: 1,
    has_many_representatives: false,
    undefined_date: true,
    date_range: '',
    status: 1,
    is_current: 1,
  },
]

//
mock.onPost(/\/clubs-aux-save\/\d+\/assign-representatives/).reply(config => {
  let auxId = 1
  const dataRepresentativeRequest = JSON.parse(config.data)

  if (data.length > 0) {
    while (data.findIndex(el => el.id == auxId) != -1) {
      auxId = Math.floor(Math.random() * 1000) + 5
    }

    // buscar registro actual de asignacion de apoderados
    const auxIndex = data.findIndex(el => el.club_id == dataRepresentativeRequest.club_id && el.status == 1 && el.is_current == 1)

    // modificar valor de registro asignacion de apoderados a no actual
    if (auxIndex != -1) {
      data[auxIndex].is_current = 0
    }
  }

  // crear registro asignar apoderados
  const backRepresentativeRequest = {
    id: auxId,
    club_id: dataRepresentativeRequest.club_id,
    has_many_representatives: dataRepresentativeRequest.has_many_representatives,
    undefined_date: dataRepresentativeRequest.undefined_date,
    date_range: dataRepresentativeRequest.date_range,
    status: dataRepresentativeRequest.status,
    is_current: dataRepresentativeRequest.is_current,
  }
  data.push(backRepresentativeRequest)

  // asignar numeros de instrumento
  dataRepresentativeRequest.instrument_numbers.forEach(itemA => {
    let ariId = 1

    if (dataARI.length > 0) {
      while (dataARI.findIndex(el => el.id == ariId) != -1) {
        ariId = Math.floor(Math.random() * 1000) + 5
      }
    }

    const auxARI = {
      id: ariId,
      assign_representative_id: auxId,
      instrument_number_id: itemA,
    }

    dataARI.push(auxARI)
  })

  // asignar poderes otorgados
  dataRepresentativeRequest.powers_granted.forEach(itemB => {
    let arpgId = 1

    if (dataARPG.length > 0) {
      while (dataARPG.findIndex(el => el.id == arpgId) != -1) {
        arpgId = Math.floor(Math.random() * 1000) + 5
      }
    }

    const auxARPG = {
      id: arpgId,
      assign_representative_id: auxId,
      power_granted_id: itemB,
      special_powers_granted: itemB == 0 ? dataRepresentativeRequest.special_powers_granted : null,
    }

    dataARPG.push(auxARPG)
  })

  // asignar socio (1 solo registro)
  if (dataRepresentativeRequest.representative_id != null) {
    // agregar socio
    let arprOneId = 1

    if (dataARPR.length > 0) {
      while (dataARPR.findIndex(el => el.id == arprOneId) != -1) {
        arprOneId = Math.floor(Math.random() * 1000) + 5
      }
    }

    const auxARPROne = {
      id: arprOneId,
      assign_representative_id: auxId,
      partner_id: dataRepresentativeRequest.representative_id,
      representative_id: null,
    }

    dataARPR.push(auxARPROne)
  }

  // agregar otro apoderado (1 solo registro)
  if (dataRepresentativeRequest.other_representative != null) {
    let otherOneId = 1

    if (dataRepresentatives.length > 0) {
      while (dataRepresentatives.findIndex(el => el.id == otherOneId) != -1) {
        otherOneId = Math.floor(Math.random() * 1000) + 5
      }

      // buscar nombre de apoderado
      const otherOneR = dataRepresentatives.filter(el => el.name == dataRepresentativeRequest.other_representative && el.club_id == dataRepresentativeRequest.club_id)

      if (otherOneR.length > 0) {
        // si el apoderado existe solo se obtiene el id del registro existente, para no duplicar datos
        otherOneId = otherOneR[0].id
      } else {
        // se crea nuevo representante
        const otherObj = {
          id: otherOneId,
          name: dataRepresentativeRequest.other_representative,
          club_id: dataRepresentativeRequest.club_id,
        }
        dataRepresentatives.push(otherObj)
      }
    } else {
      // se crea nuevo representante
      const otherObj = {
        id: otherOneId,
        name: dataRepresentativeRequest.other_representative,
        club_id: dataRepresentativeRequest.club_id,
      }
      dataRepresentatives.push(otherObj)
    }

    let arprOneXId = 1

    if (dataARPR.length > 0) {
      while (dataARPR.findIndex(el => el.id == arprOneXId) != -1) {
        arprOneXId = Math.floor(Math.random() * 1000) + 5
      }
    }

    const auxARPROneX = {
      id: arprOneXId,
      assign_representative_id: auxId,
      partner_id: null,
      representative_id: otherOneId,
    }

    dataARPR.push(auxARPROneX)
  }

  // asignar n numero de socios
  if (dataRepresentativeRequest.representatives.length) {
    // asignar mas de un socio
    dataRepresentativeRequest.representatives.forEach(itemC => {
      let arprId = 1

      if (dataARPR.length > 0) {
        while (dataARPR.findIndex(el => el.id == arprId) != -1) {
          arprId = Math.floor(Math.random() * 1000) + 5
        }
      }

      const auxARPR = {
        id: arprId,
        assign_representative_id: auxId,
        partner_id: itemC,
        representative_id: null,
      }

      dataARPR.push(auxARPR)
    })
  }

  // asignar n numero de registros (otros apoderados)
  if (dataRepresentativeRequest.other_representatives.length) {
    // asignar mas de un apoderado
    dataRepresentativeRequest.other_representatives.forEach(itemCA => {
      // crear nuevo apoderado
      let otherId = 1

      if (dataRepresentatives.length > 0) {
        while (dataRepresentatives.findIndex(el => el.id == otherId) != -1) {
          otherId = Math.floor(Math.random() * 1000) + 5
        }
        // buscar nombre de apoderado
        const otherR = dataRepresentatives.filter(el => el.name == itemCA && el.club_id == dataRepresentativeRequest.club_id)

        if (otherR.length > 0) {
          // si el apoderado existe solo se obtiene el id del registro existente, para no duplicar datos
          otherId = otherR[0].id
        } else {
          // se crea nuevo representante
          const otherObj = {
            id: otherId,
            name: itemCA,
            club_id: dataRepresentativeRequest.club_id,
          }
          dataRepresentatives.push(otherObj)
        }
      } else {
        // se crea nuevo representante
        const otherObj = {
          id: otherId,
          name: itemCA,
          club_id: dataRepresentativeRequest.club_id,
        }
        dataRepresentatives.push(otherObj)
      }

      // asignar datos en relacion asignar_representante_socio_representante
      let arproId = 1

      if (dataARPR.length > 0) {
        while (dataARPR.findIndex(el => el.id == arproId) != -1) {
          arproId = Math.floor(Math.random() * 1000) + 5
        }
      }

      const auxoARPR = {
        id: arproId,
        assign_representative_id: auxId,
        partner_id: null,
        representative_id: otherId,
      }

      dataARPR.push(auxoARPR)
    })
  }

  const inserts = {
    data,
    dataARI,
    dataARPG,
    dataRepresentatives,
    dataARPR,
  }

  return [200, { message: 'El registro se ha creado correctamente.', inserts }]
})

//
mock.onGet(/\/clubs-aux\/\d+\/assign-representatives/).reply(config => {
  const clubIdX = config.url.split('/')[2]

  const { filters } = config.params

  const currentAssign = data.length > 0 ? data.filter(item => item.club_id == clubIdX && item.is_current == 1) : []
  const auxAssign = data.length > 0 ? data.filter(item => item.club_id == clubIdX && item.is_current == 0) : []

  const result = auxAssign.sort((a, b) => {
    if (a.created_at < b.created_at) {
      return 1
    }
    if (a.created_at > b.created_at) {
      return -1
    }
    // a must be equal to b
    return 0
  })

  const auxData = JSON.parse(JSON.stringify(paginateArray(result, filters.perPage, filters.page)))

  return [200, {
    current: currentAssign.length ? currentAssign[0] : null,
    assignRepresentatives: auxData,
    totalRecords: auxAssign.length,
    to: filters.perPage * (filters.page - 1) + auxAssign.length,
    from: filters.perPage * (filters.page - 1) + (auxAssign.length ? 1 : 0),
  }]
})

mock.onGet(/\/clubs-aux-show\/\d+\/assign-representatives\/\d+/).reply(config => {
  const clubIdB = config.url.split('/')[2]
  const representativeId = config.url.split('/')[4]

  // buscar datos de la asignación de apoderados
  const dataByClubBAndRepId = data.filter(item => (item.id == representativeId && item.club_id == clubIdB))

  // buscar números de instrumento con relación a la asignación de apoderados
  const instrumentNumbers = dataARI.filter(element => element.assign_representative_id == representativeId)

  const allIntruments = []
  instrumentNumbers.forEach(item => {
    const instrumentX = dataInstruments.filter(element => element.id == item.instrument_number_id)
    allIntruments.push(instrumentX[0])
  })

  // buscar poderes otorgados con relación a la asignación de apoderados
  const powersGranted = dataARPG.filter(element => element.assign_representative_id == representativeId)

  const allPowersGranted = []
  let specialPowersGrantedX = ''
  powersGranted.forEach(item => {
    const powerGX = dataPowersGranted.filter(element => element.id == item.power_granted_id)

    if (item.power_granted_id == 0) {
      specialPowersGrantedX = item.special_powers_granted
    }

    allPowersGranted.push(powerGX[0])
  })

  // buscar apoderados "socios y otros apoderados" con relación a la asignación de apoderados
  const allUsers = dataARPR.filter(element => element.assign_representative_id == representativeId)

  const partnerRepresentatives = []
  const otherRepresentatives = []
  allUsers.forEach(item => {
    // socios
    if (item.partner_id != null) {
      const partnerX = dataPartners.filter(element => element.id == item.partner_id)
      partnerRepresentatives.push(partnerX[0])
    }

    // otros apoderados
    if (item.representative_id != null) {
      const otherX = dataRepresentatives.filter(element => element.id == item.representative_id)
      otherRepresentatives.push(otherX[0])
    }
  })

  dataByClubBAndRepId[0].instruments = allIntruments
  dataByClubBAndRepId[0].powers_granted = allPowersGranted
  dataByClubBAndRepId[0].special_powers_granted = specialPowersGrantedX
  dataByClubBAndRepId[0].partners = partnerRepresentatives
  dataByClubBAndRepId[0].otherRepresentatives = otherRepresentatives

  return [200, dataByClubBAndRepId[0]]
})

import axios from '@axios'
// export const myAction = async ({ commit }) => {

// }

export const getJobsData = ({ commit }, filters) => new Promise((resolve, reject) => {
  axios.get('/jobs', {
    params: {
      filters,
    },
  }).then(Response => {
    commit('setJobListData', Response.data)
    resolve(true)
  }).catch(error => {
    reject(error)
  })
})
export const createNewJob = ({ commit }, newJob) => new Promise((resolve, reject) => {
  axios.post('/jobs', newJob).then(response => {
    commit('addNewJob', response.data)
    resolve(true)
  }).catch(error => {
    reject(error)
  })
})
export const updateJob = ({ commit }, job) => new Promise((resolve, reject) => {
  axios.put('/jobs', job).then(response => {
    commit('updateJob', response.data)
    resolve(true)
  }).catch(error => {
    reject(error)
  })
})
export const deleteJob = ({ commit }, job) => new Promise((resolve, reject) => {
  axios.post('/jobs/delete', job).then(response => {
    commit('deleteJob', response.data)
    resolve(true)
  }).catch(error => {
    reject(error)
  })
})

// Filters
export const fetchJobByIdClub = ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/jobs/${id}`).then(response => {
    commit('setJobArrayData', response.data)
    resolve(response)
  }).catch(error => reject(error))
})
export const fetchJobsListData = ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/jobs/list').then(response => {
    commit('setJobListData', response.data)
    resolve(response)
  }).catch(error => reject(error))
})
// Filtra solo por un solo club
export const fetchJobClubByIdClub = ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/jobs-club/${id}`).then(response => {
    commit('setJobArrayData', response.data)
    resolve(response)
  }).catch(error => reject(error))
})

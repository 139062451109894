const date = new Date()
export default () => ({
  selectType: null,
  selectGenderHash: null,
  memberList: [],
  membersIncompleteDataList: [],
  member: {
    id: null,
    hash: null,
    member_type_hash: null,
    member_type_id: null,
    team_hash: null,
    team_id: null,
    ceding_club: null,
    title_or_licence: null,
    file_title_or_licence: {}, // ! No add database
    path_file_title_or_licence_media_file_hash: null,

    // Integrante
    avatar_file: {},
    avatar_file_media_file_hash: null,
    avatar_file_media_file_id: null,
    category_hash: null,
    category_id: null,
    validity_init_date: null,
    validity_end_date: null,
    file_contract: {},
    path_file_contract_media_file_hash: null,
    path_file_contract_media_file_id: null,
    registration_in_federation: null,
    date_of_admission: null,
    jersey_number: null,
    player_status_hash: null,
    player_status: null,
    id_fifa: null,

    // General
    name: null,
    first_name: null,
    last_name: null,
    gender_hash: null,
    gender_id: null,
    birthday: new Date(date.getFullYear() - 19, 0, 1),
    country_birth_hash: null,
    state_birth_hash: null,
    education_level_hash: null,
    address_hash: null,
    address: {
      hash: null,
      address: null,
      country_hash: null,
      state_hash: null,
      zip_code: null,
    },
    email: null,
    cell_phone: null,

    // identificación
    curp: null,
    file_curp: {},
    path_file_curp_media_file_hash: null,
    path_file_curp_media_file_id: null,
    id_type: null,
    id_number: null,
    file_identification: {},
    path_file_identification_media_file_hash: null,
    path_file_identification_media_file_id: null,
    file_birth_certificate: {},
    path_file_birth_certificate_media_file_hash: null,
    path_file_birth_certificate_media_file_id: null,
    file_another_document: {},
    path_file_another_document_media_file_hash: null,
    path_file_another_document_media_file_id: null,

    // Nationality
    nationalities: [{
      id: null,
      hash: null,
      nationality_hash: null,
      with_passport: true,
      file_passport: {},
      path_file_passport_media_file_hash: null,
      path_file_passport_media_file_id: null,
      passport_number: null,
      passport_expiration: null,
      is_main: false,
      member_hash: null,
    }],
    // Visa
    visas: [{
      id: null,
      hash: null,
      expedition_country_hash: null,
      file_visa: {},
      path_file_visa_media_file_hash: null,
      path_file_visa_media_file_id: null,
      visa_number: null,
      type_of_visa: null,
      visa_expiration: null,
      member_hash: null,
    }],

    club_hash: null,
    club_id: null,
    club: {},
    staff_job_position_id: null,
    staff_job_position_hash: null,
    another_job: null,
    holder_name: null,

    contractual_condition_hash: null,
    contractual_condition_id: null,
    // Conditions
    contractual_condition: {
      hash: null,
      member_hash: null,
      member_id: null,
      type_contractual_conditions: 'TRANSFER',
      descrition_contractual_conditions: null,
      // Transfer
      transfer_hash: null,
      transfer_id: null,
      transfer: {
        hash: null,
        operations: 'DOMESTIC',
        tms_number: null, // only international -> operations
        type_operation_hash: null,
        type_operation_id: null,
        type_operation: null, // ! No add database
        init_date_operation: null,
        end_date_operation: null,
        ceding_club: null,
        percentage_transfer_economic_rights: null,
        transfer_conditions: null, // Solo si no es el 100 de transferencia
        contract_agreement: 'YES', // yes no

        // yes
        assign_representative_key: null,
        assign_representative_id: null,
        signature_date: null,
        init_date: null,
        end_date: null,
        against_provision: {
          has_fixed_quantity: false,
          fixed_quantity: null, // Amount
          has_variable_quantity: false,
          variable_quantity: null,
        },
        method_payment: null,
        payment_period: null, // ! No add database
        payment_period_hash: null,
        payment_period_id: null,
        payment_period_other: null,
        penalty_amount: null,
        address_hash: null,

        // Notification Address
        address: {
          hash: null,
          address: null,
          zip_code: null,
          country_hash: null,
          state_hash: null,
        },
        email: null,
        type_jurisdiction: null, // ! No add database
        type_jurisdiction_hash: null,
        jurisdiction_other: null,
        law_applicable_contract: null,

        // Option Agente
        // player_agent: 'YES',

        player_agent_hash: null,
        player_agent_id: null,
        player_agent_key: 'YES',

        // Yes
        player_agent: {
          hash: null,
          name: null,
          license_number: null,
          role: null,
          payment_amount: null,
          method_payment: null,
          payment_period: null, // ! No add database
          payment_period_hash: null,
          // payment_period_id: null,
          payment_period_other: null,
          init_effective_date: null,
          end_effective_date: null,
          file_document: {},
          path_file_document_media_file_hash: null,
          path_file_document_media_file_id: null,
          assign_representative_key: null,
          assign_representative_id: null,
        },
      },

      // General
      type_contract: null, // ! No add database
      type_contract_hash: null,
      type_contract_other: null,
      title_or_licence: null,
      file_title_or_licence: {}, // ! No add database
      path_file_title_or_licence_media_file_hash: null,
      assign_representative_key: null,
      assign_representative_id: null,
      signature_date: null,
      init_date: null,
      end_date: null,
      against_provision: {
        has_fixed_quantity: false,
        fixed_quantity: null, // Amount
        has_variable_quantity: false,
        variable_quantity: null,
      },
      increments: [{
        id: Date.now(),
        value: null,
      }],
      increase_conditions: null,

      method_payment: null,
      payment_period: null, // ! No add database
      payment_period_hash: null,
      payment_period_id: null,
      payment_period_other: null,
      bonds: null,
      benefits: [{
        id: Date.now(),
        value: null,
      }],
      compensation_amount: null,
      compensation_details: null,

      penalty_amount: null,
      penalty_details: null,

      rescission_amount: null,
      rescission_details: null,

      // Notificartion Address
      address: {
        hash: null,
        address: null,
        zip_code: null,
        country_hash: null,
        state_hash: null,
      },
      email: null,
      type_jurisdiction: null, // ! No add database
      type_jurisdiction_hash: null,
      jurisdiction_other: null,
      law_applicable_contract: null,

      // Agents general
      // player_agent: 'YES',

      player_agent_hash: null,
      player_agent_id: null,
      player_agent_key: 'YES',

      // Yes
      player_agent: {
        hash: null,
        name: null,
        license_number: null,
        role: null,
        payment_amount: null,
        method_payment: null,
        payment_period: null, // ! No add database
        payment_period_hash: null,
        payment_period_other: null,
        init_effective_date: null,
        end_effective_date: null,
        file_document: {},
        path_file_document_media_file_hash: null,
        assign_representative_key: null,
      },
    },
    is_complete_data: false,
  },
})

// import axios from '@axios'
import {
  getNotices,
  storeNotice,
  updateNotice,
  destroyNotice,
  showNotice,
  showPublicNotice,
  updateStatusNotice,
} from '@/services/noticeService'

// const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
// const url = `${proxyUrl}http://site.api.espn.com/apis/site/v2/sports/football/college-football/news`
// const URLNews = new Request(url)

// const URLNews = 'http://site.api.espn.com/apis/site/v2/sports/football/college-football/news'
// const URLNews = 'https://yesno.wtf/api'
// https://nubecolectiva.com/api/v1/postres
export default {
  namespaced: true,
  state: {
    notice: {
      hash: null,
      title: null,
      tags: [],
      description: null,
      content: null,
      status: false,
      location: 'es',
      slug: null,
      // published: null,
      // publication_end_date: null,
      show_all_clubs: false,
      init_publication_date: null,
      end_publication_date: null,
      share_post: false,
      image: null,
      image_media_file_hash: null,
      head_content_type_key: null, // url, File
      head_content_url: null,
      head_content_media_file_hash: null,
      head_content: null, // ! no data base
      user_hash: null,
      user: null,
      club_hash: null,
      club: null,
      country_hash: null,
      country: {},
      owned_by: null,
    },
    notices: [],
    // newListSidebar: [],
    // newsNotifications: [],
  },
  getters: {
    notices(state) {
      return state.notices
    },
    notice(state) {
      return state.notice
    },
    // newListSidebar(state) {
    //   return state.newListSidebar
    // },
    // newsFilter(state) {
    //   return state.newsFilter
    // },
    // newsNotifications(state) {
    //   return state.newsNotifications
    // },
  },
  mutations: {
    setNotices(state, list) {
      state.notices = list
    },
    setAddNotice(state, val) {
      state.notices.data.unshift(val)
    },
    setNotice(state, val) {
      state.notice = val
    },
    deleteNotice(state, idNew) {
      const indexList = state.notices.data.findIndex(element => element.hash === Number(idNew))
      state.notices.data.splice(indexList, 1)
    },
    // updateNotice(state, notice) {
    //   const indexList = state.notices.data.findIndex(element => element.hash === notice.hash)
    //   state.notices.data[indexList] = notice
    // },
  },
  actions: {
    fetchData({ commit }, tempQuery) {
      return new Promise((resolve, reject) => {
        getNotices(tempQuery).then(response => {
          commit('setNotices', response.data)
          resolve(response)
        })
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line no-unused-vars
    fetchDataRecommended({ commit }, tempQuery) {
      return new Promise((resolve, reject) => {
        getNotices(tempQuery).then(response => {
          resolve(response)
        })
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line no-unused-vars
    fetchDataSidebar({ commit }, tempQuery) {
      return new Promise((resolve, reject) => {
        getNotices(tempQuery).then(response => {
          resolve(response)
        })
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, notice) {
      return new Promise((resolve, reject) => {
        storeNotice(notice).then(response => {
          resolve(response)
        })
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, notice) {
      return new Promise((resolve, reject) => {
        updateNotice(notice).then(response => {
          resolve(response)
        })
          .catch(error => reject(error))
      })
    },
    remove({ commit }, hash) {
      return new Promise((resolve, reject) => {
        destroyNotice(hash).then(response => {
          commit('deleteNotice', response.data.data)
          resolve(response)
        })
          .catch(error => reject(error))
      })
    },
    fetchDataById({ commit }, hash) {
      return new Promise((resolve, reject) => {
        showNotice(hash).then(response => {
          commit('setNotice', response.data.data)
          resolve(response)
        })
          .catch(error => reject(error))
      })
    },
    fetchDataPublicById({ commit }, hash) {
      return new Promise((resolve, reject) => {
        showPublicNotice(hash).then(response => {
          commit('setNotice', response.data.data)
          resolve(response)
        })
          .catch(error => reject(error))
      })
    },
    updateStatus({ commit }, hash) {
      return new Promise((resolve, reject) => {
        updateStatusNotice(hash).then(response => {
          commit('updateNotice', response.data.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // TODO posible quit
    // fetchNewsSidebar({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/news/sidebar')
    //       .then(response => {
    //         commit('setNewsListSidebar', response.data)
    //         resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },
    // fetchNewsList({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/apps/calendar/list')
    //       .then(response => {
    //         commit('setCalendarList', response.data)
    //         resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },
    // fetchNewById({ commit }, id) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`/new/${id}`)
    //       .then(response => {
    //         commit('setNew', response.data)
    //         resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },
    // fetchNewNotifications({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/apps/calendar/news/notifications')
    //       .then(response => {
    //         commit('setNewsNotifications', response.data)
    //         resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },
  },
}

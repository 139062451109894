import axios from '@axios'

export default {
  state: {
    representative: {},
    representativeX: {},
    representatives: [],
  },

  mutations: {
    setRepresentativesInfo(state, value) {
      state.representatives = value
    },

    setRepresentativeInfo(state, value) {
      state.representative = value
    },

    setRepresentativeInfoX(state, value) {
      state.representativeX = value
    },
  },

  actions: {
    fetchRepresentativesByClubId({ commit }, representativeFilters) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux/${representativeFilters.clubId}/representatives`, { params: { filters: representativeFilters } }).then(response => {
          commit('setRepresentativesInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    // TODO: se creo para traer la lista de repesentantes o apoderados en formulario de condiciones de registro de integrante
    fetchRepresentativesListByClubId({ commit }, representativeFilters) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs/${representativeFilters.clubId}/representatives`, { params: { filters: representativeFilters } }).then(response => {
          commit('setRepresentativesInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    showRepresentativeByClubId({ commit }, repObj) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux-show/${repObj.clubId}/representatives/${repObj.powerId}`).then(response => {
          commit('setRepresentativeInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    showRepresentativeByClubIdX({ commit }, repObj) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux-show/${repObj.clubId}/representatives/${repObj.powerId}`).then(response => {
          commit('setRepresentativeInfoX', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    fetchRepresentativeInfo({ commit }, repObj) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux-edit/${repObj.clubId}/representatives/${repObj.powerId}/edit`).then(response => {
          commit('setRepresentativeInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    updateRepresentative({ commit }, rep) {
      return new Promise((resolve, reject) => {
        axios.post(`/clubs-aux-update/${rep.club_id}/representatives`, rep).then(response => {
          commit('setRepresentativesInfo', response.data.representativeData)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },

  getters: {
    representativesList(state) {
      return state.representatives
    },

    representativeInfo(state) {
      return state.representative
    },

    representativeInfoX(state) {
      return state.representativeX
    },
  },
}

import mock from '@/@fake-db/mock'
import { paginateArray } from '@/@fake-db/utils'
import { data as dataTypes } from '@/@fake-db/data/clubs/types-of-literary-or-artistic-work'

export const data = []

//
mock.onGet(/\/clubs\/\d+\/literary-or-artistic-works/).reply(config => {
  const clubIdX = config.url.split('/')[2]

  const { filters } = config.params

  const auxWorkData = []
  let currentWork = []
  let auxWorks = []

  const artisticWorks = data.length > 0 ? data.filter(item => item.club_id == clubIdX) : []

  if (artisticWorks.length == 1) {
    currentWork = artisticWorks
  } else if (artisticWorks.length > 1) {
    currentWork = artisticWorks.pop()
    auxWorks = artisticWorks
  }

  const result = auxWorks.sort((a, b) => {
    if (a.created_at < b.created_at) {
      return 1
    }
    if (a.created_at > b.created_at) {
      return -1
    }
    // a must be equal to b
    return 0
  })

  const auxData = JSON.parse(JSON.stringify(paginateArray(result, filters.perPage, filters.page)))

  auxData.forEach(el => {
    const indexType = dataTypes.findIndex(element => element.id === el.type_of_literary_work_id)

    const objWork = {
      id: el.id,
      clubId: el.club_id,
      typeOfLiteraryWorkId: el.type_of_literary_work_id,
      dateOfRequest: el.date_of_request,
      dateOfConcession: el.date_of_concession,
      titleOfLiteraryOrArtisticWork: el.title_of_literary_or_artistic_work,
      holderName: el.holder_name,
      creatorAndOrDesigner: el.creator_and_or_designer,
      applicationFile: el.application_file,
      contractFile: el.contract_file,
      status: el.status,
      typeOfLiteraryWork: dataTypes[indexType].name,
    }

    auxWorkData.push(objWork)
  })

  return [200, {
    current: currentWork.length ? currentWork[0] : null,
    powers: auxWorkData,
    totalRecords: auxWorks.length,
    to: filters.perPage * (filters.page - 1) + auxWorks.length,
    from: filters.perPage * (filters.page - 1) + (auxWorks.length ? 1 : 0),
  }]
})

//
mock.onPost('/clubs/literary-or-artistic-work').reply(config => {
  let auxId = 1
  const dataWorkRequest = JSON.parse(config.data)

  if (data.length > 0) {
    while (data.findIndex(el => el.id === auxId) !== -1) {
      auxId = Math.floor(Math.random() * 1000) + 5
    }
  }

  dataWorkRequest.id = auxId
  dataWorkRequest.created_at = new Date()

  data.push(dataWorkRequest)

  return [200, { message: 'El registro se ha creado correctamente.', artisticWork: dataWorkRequest }]
})

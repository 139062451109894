// import axios from '@axios'
import {
  getEvents,
  showEvent,
  storeEvent,
  updateEvent,
  destroyEvent,
} from '@/services/eventService'

export default {
  namespaced: true,
  state: {
    isCalendar: false,
    event: {
      hash: null,
      title: null,
      description: null,
      location: null,
      status: true,
      all_day: false,
      init_date: new Date(),
      end_date: null,
      include_everyone: false,
      url: null,
      user_hash: null,
      include_users: [],
      exclude_users: [],
      club_hash: null,
      color: '',
      color_assigned: null,
      color_hash: null,
    },
    events: [],
  },
  getters: {
    events(state) {
      return state.events
    },
    event(state) {
      return state.event
    },
    isCalendar(state) {
      return state.isCalendar
    },
  },
  mutations: {
    SET_EVENTS(state, list) {
      state.events = list
    },
    SET_EVENT(state, val) {
      state.event = val
    },
    SET_IS_CALENDAR(state, val) {
      state.isCalendar = val
    },
    DELETE_EVENT(state, idEvent) {
      const indexList = state.events.findIndex(element => element.id === Number(idEvent))
      state.events.splice(indexList, 1)
    },
  },
  actions: {
    fetchData({ commit }) {
      return new Promise((resolve, reject) => {
        getEvents()
          .then(response => {
            commit('SET_EVENTS', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, event) {
      return new Promise((resolve, reject) => {
        storeEvent(event).then(response => {
          resolve(response)
        })
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, event) {
      return new Promise((resolve, reject) => {
        updateEvent(event).then(response => {
          resolve(response)
        })
          .catch(error => reject(error))
      })
    },
    remove({ commit }, id) {
      return new Promise((resolve, reject) => {
        destroyEvent(id)
          .then(response => {
            commit('DELETE_EVENT', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchEventById({ commit }, id) {
      return new Promise((resolve, reject) => {
        showEvent(id)
          .then(response => {
            commit('SET_EVENT', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}

import axios from '@axios'

export default {
  state: {
    allInstrumentNumbers: [],
  },

  mutations: {
    setAllInstrumentNumbersInfo(state, value) {
      state.allInstrumentNumbers = value
    },
  },

  actions: {
    fetchAllInstrumentNumbersByClubId({ commit }, clubId) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubsx/${clubId}/all-instrument-numbers`).then(response => {
          commit('setAllInstrumentNumbersInfo', response.data.allInstrumentNumbers)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },

  getters: {
    allInstrumentNumbersList(state) {
      return state.allInstrumentNumbers
    },
  },
}

import axios from '@axios'

export default {
  state: {
    assembly: {},
    assemblyX: {},
    assemblies: [],
  },

  mutations: {
    setAssembliesInfo(state, value) {
      state.assemblies = value
    },

    setAssemblyInfo(state, value) {
      state.assembly = value
    },

    setAssemblyInfoX(state, value) {
      state.assemblyX = value
    },
  },

  actions: {
    fetchAssembliesByClubId({ commit }, assemblyFilters) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux/${assemblyFilters.clubId}/assemblies`, { params: { filters: assemblyFilters } }).then(response => {
          commit('setAssembliesInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    saveAssembly({ commit }, assembly) {
      return new Promise((resolve, reject) => {
        axios.post(`/clubs-aux-save/${assembly.club_id}/assemblies`, assembly).then(response => {
          commit('setAssembliesInfo', response.data.assemblyData)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    showAssemblyByClubId({ commit }, assemblyObj) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux-show/${assemblyObj.clubId}/assemblies/${assemblyObj.assemblyId}`).then(response => {
          commit('setAssemblyInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    showAssemblyByClubIdX({ commit }, assemblyObj) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux-show/${assemblyObj.clubId}/assemblies/${assemblyObj.assemblyId}`).then(response => {
          commit('setAssemblyInfoX', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    fetchAssemblyInfo({ commit }, assemblyObj) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux-edit/${assemblyObj.clubId}/assemblies/${assemblyObj.assemblyId}/edit`).then(response => {
          commit('setAssemblyInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    updateAssembly({ commit }, assemblyP) {
      return new Promise((resolve, reject) => {
        axios.post(`/clubs-aux-update/${assemblyP.club_id}/assemblies`, assemblyP).then(response => {
          commit('setAssembliesInfo', response.data.assemblyData)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },

  getters: {
    assemblyList(state) {
      return state.assemblies
    },

    assemblyInfo(state) {
      return state.assembly
    },

    assemblyInfoX(state) {
      return state.assemblyX
    },
  },
}

import store from '@/store/index'
import checkPermission from '@/auth/permissions'
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  const vuexValue = JSON.parse(localStorage.getItem('vuex'))
  return localStorage.hasOwnProperty('vuex') ? (vuexValue.userAuthStore.fullName != null && localStorage.hasOwnProperty('access_token')) : false
}

export const getUserData = () => JSON.parse(localStorage.getItem('vuex'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = () => {
  if (checkPermission(['VIEW_DASHBOARD'])) return { name: 'dashboard-main' }
  return { name: 'account-settings', params: { id: store.state.userAuthStore.id } }
}

import mock from '@/@fake-db/mock'

const data = [
  { id: 0, name: 'Carlos', club_id: 2 },
  { id: 1, name: 'Juan', club_id: 2 },
  { id: 2, name: 'Adrian', club_id: 2 },
  { id: 3, name: 'Fabian', club_id: 2 },
]

mock.onGet(/\/clubs\/\d+\/representatives/).reply(() => [200, data])

export default data

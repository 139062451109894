import mock from '@/@fake-db/mock'

export const data = [

    {
        id: 1,
        name: "AFGANA",
        country_code: 301,
        key_nacionality: "AFG"
    },
    {
        id: 2,
        name: "ALBANESA",
        country_code: 401,
        key_nacionality: "ALB"
    },
    {
        id: 3,
        name: "ALEMANA",
        country_code: 403,
        key_nacionality: "DEU"
    },
    {
        id: 4,
        name: "AMERICANA",
        country_code: 299,
        key_nacionality: "USA"
    },
    {
        id: 5,
        name: "ANDORRANA",
        country_code: 404,
        key_nacionality: "AND"
    },
    {
        id: 6,
        name: "ANGOLESA",
        country_code: 103,
        key_nacionality: "AGO"
    },
    {
        id: 7,
        name: "ARABE",
        country_code: 311,
        key_nacionality: "SAU"
    },
    {
        id: 8,
        name: "ARGELIANA",
        country_code: 104,
        key_nacionality: "DZA"
    },
    {
        id: 9,
        name: "ARGENTINA",
        country_code: 201,
        key_nacionality: "ARG"
    },
    {
        id: 10,
        name: "AUSTRALIANA",
        country_code: 501,
        key_nacionality: "AUS"
    },
    {
        id: 11,
        name: "AUSTRIACA",
        country_code: 405,
        key_nacionality: "AUT"
    },
    {
        id: 12,
        name: "BAHAMEÑA",
        country_code: 202,
        key_nacionality: "BHS"
    },
    {
        id: 13,
        name: "BELGA",
        country_code: 406,
        key_nacionality: "BEL"
    },
    {
        id: 14,
        name: "BELICEÑA",
        country_code: 204,
        key_nacionality: "BLZ"
    },
    {
        id: 15,
        name: "BHUTANESA",
        country_code: 305,
        key_nacionality: "BTN"
    },
    {
        id: 16,
        name: "BIRMANA",
        country_code: 306,
        key_nacionality: "BUR"
    },
    {
        id: 17,
        name: "BOLIVIANA",
        country_code: 205,
        key_nacionality: "BOL"
    },
    {
        id: 18,
        name: "BOTSWANESA",
        country_code: 106,
        key_nacionality: "BWA"
    },
    {
        id: 19,
        name: "BRASILEÑA",
        country_code: 206,
        key_nacionality: "BRA"
    },
    {
        id: 20,
        name: "BRITANICA",
        country_code: 218,
        key_nacionality: "VGB"
    },
    {
        id: 21,
        name: "BRITANICA",
        country_code: 427,
        key_nacionality: "IOT"
    },
    {
        id: 22,
        name: "BULGARA",
        country_code: 407,
        key_nacionality: "BGR"
    },
    {
        id: 23,
        name: "BURKINA FASO",
        country_code: 609,
        key_nacionality: "BFA"
    },
    {
        id: 24,
        name: "BURUNDESA",
        country_code: 107,
        key_nacionality: "BDI"
    },
    {
        id: 25,
        name: "CAMBOYANA",
        country_code: 320,
        key_nacionality: "KHM"
    },
    {
        id: 26,
        name: "CAMERUNESA",
        country_code: 136,
        key_nacionality: "CMR"
    },
    {
        id: 27,
        name: "CANADIENSE",
        country_code: 207,
        key_nacionality: "CAN"
    },
    {
        id: 28,
        name: "CENTRO AFRICANA",
        country_code: 135,
        key_nacionality: "CAF"
    },
    {
        id: 29,
        name: "CHADIANA",
        country_code: 112,
        key_nacionality: "TCD"
    },
    {
        id: 30,
        name: "CHECOSLOVACA",
        country_code: 408,
        key_nacionality: "CSK"
    },
    {
        id: 31,
        name: "CHILENA",
        country_code: 211,
        key_nacionality: "CHL"
    },
    {
        id: 32,
        name: "CHINA",
        country_code: 309,
        key_nacionality: "CHN"
    },
    {
        id: 33,
        name: "CHIPRIOTA",
        country_code: 310,
        key_nacionality: "CYP"
    },
    {
        id: 34,
        name: "COLOMBIANA",
        country_code: 208,
        key_nacionality: "COL"
    },
    {
        id: 35,
        name: "COMORENSE",
        country_code: 109,
        key_nacionality: "COM"
    },
    {
        id: 36,
        name: "CONGOLESA",
        country_code: 110,
        key_nacionality: "COD"
    },
    {
        id: 37,
        name: "COSTARRICENSE",
        country_code: 209,
        key_nacionality: "CRI"
    },
    {
        id: 38,
        name: "CUBANA",
        country_code: 210,
        key_nacionality: "CUB"
    },
    {
        id: 39,
        name: "DANESA",
        country_code: 409,
        key_nacionality: "DNK"
    },
    {
        id: 40,
        name: "DE BAHREIN",
        country_code: 303,
        key_nacionality: "BHR"
    },
    {
        id: 41,
        name: "DE BARBADOS",
        country_code: 203,
        key_nacionality: "BRB"
    },
    {
        id: 42,
        name: "DE BENNIN",
        country_code: 105,
        key_nacionality: "BEN"
    },
    {
        id: 43,
        name: "DE CABO VERDE",
        country_code: 108,
        key_nacionality: "CPV"
    },
    {
        id: 44,
        name: "DE DJIBOUTI",
        country_code: 113,
        key_nacionality: "DJI"
    },
    {
        id: 45,
        name: "DE SANTO TOME",
        country_code: 141,
        key_nacionality: "STP"
    },
    {
        id: 46,
        name: "DE SEYCHELLES",
        country_code: 143,
        key_nacionality: "SYC"
    },
    {
        id: 47,
        name: "DE ZIMBAWI",
        country_code: 154,
        key_nacionality: "ZWE"
    },
    {
        id: 48,
        name: "DEL QATAR",
        country_code: 329,
        key_nacionality: "QAT"
    },
    {
        id: 49,
        name: "DEL SAHARA",
        country_code: 140,
        key_nacionality: "ESH"
    },
    {
        id: 50,
        name: "DOMINICA",
        country_code: 212,
        key_nacionality: "DMA"
    },
    {
        id: 51,
        name: "DOMINICANA",
        country_code: 229,
        key_nacionality: "DOM"
    },
    {
        id: 52,
        name: "EGIPCIA",
        country_code: 114,
        key_nacionality: "EGY"
    },
    {
        id: 53,
        name: "ESLOVACA",
        country_code: 667,
        key_nacionality: "SVK"
    },
    {
        id: 54,
        name: "ESLOVAQUIA",
        country_code: 684,
        key_nacionality: "XES"
    },
    {
        id: 55,
        name: "ESLOVENIA",
        country_code: 668,
        key_nacionality: "SVN"
    },
    {
        id: 56,
        name: "ESPAÑOLA",
        country_code: 411,
        key_nacionality: "ESP"
    },
    {
        id: 57,
        name: "ESTADOUNIDENSE",
        country_code: 215,
        key_nacionality: "USA"
    },
    {
        id: 58,
        name: "ESTONIA",
        country_code: 621,
        key_nacionality: "EST"
    },
    {
        id: 59,
        name: "ETIOPE",
        country_code: 115,
        key_nacionality: "ETH"
    },
    {
        id: 60,
        name: "FIJIANA",
        country_code: 502,
        key_nacionality: "FJI"
    },
    {
        id: 61,
        name: "FILIPINA",
        country_code: 312,
        key_nacionality: "PHL"
    },
    {
        id: 62,
        name: "FINLANDESA",
        country_code: 412,
        key_nacionality: "FIN"
    },
    {
        id: 63,
        name: "FRANCESA",
        country_code: 413,
        key_nacionality: "FRA"
    },
    {
        id: 64,
        name: "GABONESA",
        country_code: 116,
        key_nacionality: "GAB"
    },
    {
        id: 65,
        name: "GAMBIANA",
        country_code: 117,
        key_nacionality: "GMB"
    },
    {
        id: 66,
        name: "GHANATA",
        country_code: 118,
        key_nacionality: "GHA"
    },
    {
        id: 67,
        name: "GRANADINA",
        country_code: 216,
        key_nacionality: "VCT"
    },
    {
        id: 68,
        name: "GRIEGA",
        country_code: 414,
        key_nacionality: "GRC"
    },
    {
        id: 69,
        name: "GUATEMALTECA",
        country_code: 217,
        key_nacionality: "GTM"
    },
    {
        id: 70,
        name: "GUINEA",
        country_code: 119,
        key_nacionality: "GNB"
    },
    {
        id: 71,
        name: "GUINEA",
        country_code: 120,
        key_nacionality: "GIN"
    },
    {
        id: 72,
        name: "GUINEA ECUATORIANA",
        country_code: 121,
        key_nacionality: "GNQ"
    },
    {
        id: 73,
        name: "GUYANESA",
        country_code: 219,
        key_nacionality: "GUY"
    },
    {
        id: 74,
        name: "HAITIANA",
        country_code: 220,
        key_nacionality: "HTI"
    },
    {
        id: 75,
        name: "HINDU",
        country_code: 313,
        key_nacionality: "IND"
    },
    {
        id: 76,
        name: "HOLANDESA",
        country_code: 424,
        key_nacionality: "NLD"
    },
    {
        id: 77,
        name: "HONDUREÑA",
        country_code: 221,
        key_nacionality: "HND"
    },
    {
        id: 78,
        name: "HUNGARA",
        country_code: 415,
        key_nacionality: "HUN"
    },
    {
        id: 79,
        name: "INDONESA",
        country_code: 314,
        key_nacionality: "IDN"
    },
    {
        id: 80,
        name: "IRANI",
        country_code: 316,
        key_nacionality: "IRN"
    },
    {
        id: 81,
        name: "IRAQUI",
        country_code: 315,
        key_nacionality: "IRQ"
    },
    {
        id: 82,
        name: "IRLANDESA",
        country_code: 416,
        key_nacionality: "IRL"
    },
    {
        id: 83,
        name: "ISLANDESA",
        country_code: 417,
        key_nacionality: "ISL"
    },
    {
        id: 84,
        name: "ISRAELI",
        country_code: 317,
        key_nacionality: "ISR"
    },
    {
        id: 85,
        name: "ITALIANA",
        country_code: 418,
        key_nacionality: "ITA"
    },
    {
        id: 86,
        name: "JAMAIQUINA",
        country_code: 222,
        key_nacionality: "JAM"
    },
    {
        id: 87,
        name: "JAPONESA",
        country_code: 318,
        key_nacionality: "JPN"
    },
    {
        id: 88,
        name: "JORDANA",
        country_code: 319,
        key_nacionality: "JOR"
    },
    {
        id: 89,
        name: "KENIANA",
        country_code: 123,
        key_nacionality: "KEN"
    },
    {
        id: 90,
        name: "KIRGUISTAN",
        country_code: 642,
        key_nacionality: "KGZ"
    },
    {
        id: 91,
        name: "KUWAITI",
        country_code: 321,
        key_nacionality: "KWT"
    },
    {
        id: 92,
        name: "LAOSIANA",
        country_code: 331,
        key_nacionality: "LAO"
    },
    {
        id: 93,
        name: "LESOTHENSE",
        country_code: 124,
        key_nacionality: "LSO"
    },
    {
        id: 94,
        name: "LIBANESA",
        country_code: 322,
        key_nacionality: "LBN"
    },
    {
        id: 95,
        name: "LIBERIANA",
        country_code: 125,
        key_nacionality: "LBR"
    },
    {
        id: 96,
        name: "LIBIA",
        country_code: 122,
        key_nacionality: "LBY"
    },
    {
        id: 97,
        name: "LIECHTENSTENSE",
        country_code: 419,
        key_nacionality: "LIE"
    },
    {
        id: 98,
        name: "LITUANIA ",
        country_code: 645,
        key_nacionality: "LTU"
    },
    {
        id: 99,
        name: "LUXEMBURGUESA",
        country_code: 420,
        key_nacionality: "LUX"
    },
    {
        id: 100,
        name: "MACEDONIA",
        country_code: 650,
        key_nacionality: "MKD"
    },
    {
        id: 101,
        name: "MALASIA",
        country_code: 323,
        key_nacionality: "MYS"
    },
    {
        id: 102,
        name: "MALAWIANA",
        country_code: 127,
        key_nacionality: "MWI"
    },
    {
        id: 103,
        name: "MALDIVA",
        country_code: 324,
        key_nacionality: "MDV"
    },
    {
        id: 104,
        name: "MALIENSE",
        country_code: 128,
        key_nacionality: "MLI"
    },
    {
        id: 105,
        name: "MALTESA",
        country_code: 421,
        key_nacionality: "MLT"
    },
    {
        id: 106,
        name: "MARFILEÑA",
        country_code: 111,
        key_nacionality: "COG"
    },
    {
        id: 107,
        name: "MARROQUI",
        country_code: 129,
        key_nacionality: "MAR"
    },
    {
        id: 108,
        name: "MAURICIANA",
        country_code: 130,
        key_nacionality: "MUS"
    },
    {
        id: 109,
        name: "MAURITANA",
        country_code: 131,
        key_nacionality: "MRT"
    },
    {
        id: 110,
        name: "MEXICANA",
        country_code: 223,
        key_nacionality: "MEX"
    },
    {
        id: 111,
        name: "MICRONECIA",
        country_code: 624,
        key_nacionality: "FSM"
    },
    {
        id: 112,
        name: "MOLDOVIA, REPUBLICA DE",
        country_code: 648,
        key_nacionality: "MDA"
    },
    {
        id: 113,
        name: "MONEGASCA",
        country_code: 422,
        key_nacionality: "MCO"
    },
    {
        id: 114,
        name: "MONGOLESA",
        country_code: 325,
        key_nacionality: "MNG"
    },
    {
        id: 115,
        name: "MOZAMBIQUEÑA",
        country_code: 132,
        key_nacionality: "MOZ"
    },
    {
        id: 116,
        name: "NAMIBIANA",
        country_code: 101,
        key_nacionality: "NAM"
    },
    {
        id: 117,
        name: "NAURUANA",
        country_code: 504,
        key_nacionality: "NRU"
    },
    {
        id: 118,
        name: "NEPALESA",
        country_code: 326,
        key_nacionality: "NPL"
    },
    {
        id: 119,
        name: "NICARAGUENSE",
        country_code: 224,
        key_nacionality: "NIC"
    },
    {
        id: 120,
        name: "NIGERIANA",
        country_code: 134,
        key_nacionality: "NGA"
    },
    {
        id: 121,
        name: "NIGERINA",
        country_code: 133,
        key_nacionality: "NER"
    },
    {
        id: 122,
        name: "NORCOREANA",
        country_code: 307,
        key_nacionality: "PRK"
    },
    {
        id: 123,
        name: "NORUEGA",
        country_code: 423,
        key_nacionality: "NOR"
    },
    {
        id: 124,
        name: "NORVIETNAMITA",
        country_code: 337,
        key_nacionality: "VNM"
    },
    {
        id: 125,
        name: "OMANESA",
        country_code: 327,
        key_nacionality: "OMN"
    },
    {
        id: 126,
        name: "PAKISTANI",
        country_code: 328,
        key_nacionality: "PAK"
    },
    {
        id: 127,
        name: "PANAMEÑA",
        country_code: 225,
        key_nacionality: "PAN"
    },
    {
        id: 128,
        name: "PAPUENSE",
        country_code: 506,
        key_nacionality: "PNG"
    },
    {
        id: 129,
        name: "PARAGUAYA",
        country_code: 226,
        key_nacionality: "PRY"
    },
    {
        id: 130,
        name: "PERUANA",
        country_code: 227,
        key_nacionality: "PER"
    },
    {
        id: 131,
        name: "PORTUGUESA",
        country_code: 426,
        key_nacionality: "PRT"
    },
    {
        id: 132,
        name: "PUERTORRIQUEÑA",
        country_code: 228,
        key_nacionality: "PRI"
    },
    {
        id: 133,
        name: "REINO UNIDO",
        country_code: 629,
        key_nacionality: "GBR"
    },
    {
        id: 134,
        name: "REINO UNIDO(BRIT. DEL EXT.)",
        country_code: 626,
        key_nacionality: "GBN"
    },
    {
        id: 135,
        name: "REINO UNIDO(C. BRIT. DEL EXT.)",
        country_code: 627,
        key_nacionality: "GBO"
    },
    {
        id: 136,
        name: "REINO UNIDO(DEPEN. TET. BRIT.)",
        country_code: 625,
        key_nacionality: "GBD"
    },
    {
        id: 137,
        name: "RUMANA",
        country_code: 430,
        key_nacionality: "ROM"
    },
    {
        id: 138,
        name: "RWANDESA",
        country_code: 139,
        key_nacionality: "RWA"
    },
    {
        id: 139,
        name: "SALOMONESA",
        country_code: 503,
        key_nacionality: "SLB"
    },
    {
        id: 140,
        name: "SALVADOREÑA",
        country_code: 214,
        key_nacionality: "SLV"
    },
    {
        id: 141,
        name: "SAMOANA",
        country_code: 507,
        key_nacionality: "WSM"
    },
    {
        id: 142,
        name: "SAN MARINENSE",
        country_code: 431,
        key_nacionality: "SMR"
    },
    {
        id: 143,
        name: "SANTA LUCIENSE",
        country_code: 230,
        key_nacionality: "LCA"
    },
    {
        id: 144,
        name: "SENEGALESA",
        country_code: 142,
        key_nacionality: "SEN"
    },
    {
        id: 145,
        name: "SIERRA LEONESA",
        country_code: 144,
        key_nacionality: "SLE"
    },
    {
        id: 146,
        name: "SINGAPORENSE",
        country_code: 332,
        key_nacionality: "SGP"
    },
    {
        id: 147,
        name: "SIRIA",
        country_code: 330,
        key_nacionality: "SYR"
    },
    {
        id: 148,
        name: "SOMALI",
        country_code: 145,
        key_nacionality: "SOM"
    },
    {
        id: 149,
        name: "SOVIETICA BIELORRUSA",
        country_code: 428,
        key_nacionality: "BLR"
    },
    {
        id: 150,
        name: "SOVIETICA UCRANIANA",
        country_code: 429,
        key_nacionality: "UKR"
    },
    {
        id: 151,
        name: "SUDAFRICANA",
        country_code: 146,
        key_nacionality: "ZAF"
    },
    {
        id: 152,
        name: "SUDANESA",
        country_code: 147,
        key_nacionality: "SDN"
    },
    {
        id: 153,
        name: "SUDCOREANA",
        country_code: 308,
        key_nacionality: "KOR"
    },
    {
        id: 154,
        name: "SUECA",
        country_code: 432,
        key_nacionality: "SWE"
    },
    {
        id: 155,
        name: "SUIZA",
        country_code: 433,
        key_nacionality: "CHE"
    },
    {
        id: 156,
        name: "SURINAMENSE",
        country_code: 231,
        key_nacionality: "SUR"
    },
    {
        id: 157,
        name: "SWAZI",
        country_code: 148,
        key_nacionality: "SWZ"
    },
    {
        id: 158,
        name: "TAILANDESA",
        country_code: 334,
        key_nacionality: "THA"
    },
    {
        id: 159,
        name: "TAIWANESA",
        country_code: 335,
        key_nacionality: "TWN"
    },
    {
        id: 160,
        name: "TANZANIANA",
        country_code: 137,
        key_nacionality: "TZA"
    },
    {
        id: 161,
        name: "TOGOLESA",
        country_code: 149,
        key_nacionality: "TGO"
    },
    {
        id: 162,
        name: "TRINITARIA",
        country_code: 232,
        key_nacionality: "TTO"
    },
    {
        id: 163,
        name: "TUNECINA",
        country_code: 150,
        key_nacionality: "TUN"
    },
    {
        id: 164,
        name: "TURCA",
        country_code: 336,
        key_nacionality: "TUR"
    },
    {
        id: 165,
        name: "UGANDESA",
        country_code: 151,
        key_nacionality: "UGA"
    },
    {
        id: 166,
        name: "URUGUAYA",
        country_code: 233,
        key_nacionality: "URY"
    },
    {
        id: 167,
        name: "VATICANA",
        country_code: 410,
        key_nacionality: "VAT"
    },
    {
        id: 168,
        name: "VENEZOLANA",
        country_code: 234,
        key_nacionality: "VEN"
    },
    {
        id: 169,
        name: "YEMENI",
        country_code: 339,
        key_nacionality: "YEM"
    },
    {
        id: 170,
        name: "YUGOSLAVA",
        country_code: 435,
        key_nacionality: "YUG"
    },
    {
        id: 171,
        name: "ZAIRANA",
        country_code: 152,
        key_nacionality: "ZAR"
    },
    {
        id: 172,
        name: "ZAMBIANA",
        country_code: 153,
        key_nacionality: "ZMB"
    }
]

mock.onGet('/nacionalities').reply(() => [200, data])
export const setRolListData = (state, list) => {
  state.rolList = list
}
export const setRolesListDataAll = (state, list) => {
  state.rolList = list
}
export const addNewRol = (state, rol) => {
  state.rolList.data.unshift(rol)
}
export const updateRol = (state, rol) => {
  const index = state.rolList.data.findIndex(element => element.id === rol.id)
  state.rolList.data[index] = rol
}
export const deleteRol = (state, rol) => {
  const index = state.rolList.data.findIndex(element => element.id === rol.id)
  state.rolList.data.splice(index, 1)
}
export const setRoleByIdClub = (state, value) => {
  state.rolListArray = value
}

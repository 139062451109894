export default () => ({
  role: null,
  moduleList: [],
  userList: [],
  userListArray: [],
  user: {
    id: 0,
    name: 'Carlos',
    first_name: 'Nestor',
    last_name: 'Test',
    email: 'carlos@gmail.com',
    nationality_id: 110,
    phone: null,
    cell_phone: '+525547113677',
    address: {
      address: 'hanck 16',
      country_id: 110,
      state_id: 1,
      zip_code: 190,
    },
    sport_club_id: null,
    sport_clubs: [{
      id: Date.now(),
      sport_club_id: null,
      role_id: null,
      job_position_id: null,
    }],
    is_admin_club: 0,
    roles: [],
    job_position_id: null,
    role_id: null,
    status: 1,
  },
})

// user: {
//   id: 0,
//   name: null,
//   first_name: null,
//   last_name: null,
//   email: null,
//   nationality_id: null,
//   phone: null,
//   cell_phone: null,
//   address: {
//     address: null,
//     country_id: null,
//     state_id: null,
//     zip_code: null,
//   },
//   sport_club_id: null,
//   sport_clubs: [{
//     id: '',
//     sport_club_id: null,
//     role_id: null,
//     job_position_id: null,
//   }],
//   is_admin_club: 0,
//   roles: [],
//   job_position_id: null,
//   role_id: null,
// },

import apiInstance from './index'
import queryParams from './queryParams'

const baseURLRelative = '/contractual-conditions'

// export function getContractualConditions(tempQuery) {
//   const query = queryParams.serializeParams(tempQuery.paramsObj, tempQuery.filtersObj)
//   return apiInstance({
//     url: `${baseURLRelative}${query}`,
//     method: 'GET',
//   })
// }

export function getContractualConditionsLog(tempQuery) {
  const query = queryParams.serializeParams(tempQuery.paramsObj, tempQuery.filtersObj)
  return apiInstance({
    url: `${baseURLRelative}/record${query}`,
    method: 'GET',
  })
}

export function storeContractualCondition(data) {
  return apiInstance({
    url: baseURLRelative,
    method: 'POST',
    data,
  })
}
// export function updateContractualCondition(data) {
//   return apiInstance({
//     url: `${baseURLRelative}${data.hash}`,
//     method: 'PUT',
//     data,
//   })
// }
export function destroyContractualCondition(hash) {
  return apiInstance({
    url: `${baseURLRelative}/${hash}`,
    method: 'DELETE',
  })
}

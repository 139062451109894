import mock from '@/@fake-db/mock'

export const data = [
  {
    id: 1,
    name: 'Publicación de Nombre Comercial',
    status: 1,
  },
  {
    id: 2,
    name: 'Registro de Aviso Comercial',
    status: 1,
  },
  {
    id: 3,
    name: 'Registro de Imagen Comercial',
    status: 1,
  },
  {
    id: 4,
    name: 'Registro de Marca',
    status: 1,
  },
  {
    id: 5,
    name: 'Registro de Marca Colectiva',
    status: 1,
  },
]

mock.onGet('/clubs/request-types').reply(() => [200, data])

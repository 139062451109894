// import axios from '@axios'
import {
  getTasks,
  getTasksByDate,
  storeTask,
  updateTask,
  destroyTask,
  orderTask,
} from '@/services/taskService'

export default {
  namespaced: true,
  state: {
    list: [],
    listFilter: [],
  },
  getters: {
    list(state) {
      return state.list
    },
    listFilter(state) {
      return state.listFilter
    },
  },
  mutations: {
    setList(state, value) {
      state.list = value
    },
    setListFilter(state, value) {
      state.listFilter = value
    },
  },
  actions: {
    fetchTasks({ commit }, payload) {
      return new Promise((resolve, reject) => {
        getTasks(payload).then(response => {
          commit('setList', response.data.data)
          resolve(response)
        })
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line no-unused-vars
    getTasksByDate({ commit }) {
      return new Promise((resolve, reject) => {
        getTasksByDate().then(response => {
          commit('setListFilter', response.data.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    addTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        storeTask(taskData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTask(ctx, { task }) {
      return new Promise((resolve, reject) => {
        updateTask(task).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        destroyTask(id).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    orderTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        orderTask(taskData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

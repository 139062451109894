import store from '@/store'

/**
 * @param {Array} value
 * @returns {Boolean}
 */
export default function checkPermission(value) {
  if (value && value instanceof Array && value.length > 0) {
    const permissions = store.getters && store.getters.allPermissions
    const permissionRoles = value

    const hasPermission = permissions.some(item => permissionRoles.includes(item))
    return hasPermission
  }
  console.error('Permission not found')
  return false
}

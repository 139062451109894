import mock from '@/@fake-db/mock'

export const data = [
  {
    id: 0,
    key: 'WORK_SPORTS_CONTRACT',
    name: 'Contrato deportivo de trabajo',
    status: 1,
  },
  {
    id: 1,
    key: 'WORK_CONTRACT',
    name: 'Contrato laboral',
    status: 1,
  },
  {
    id: 2,
    key: 'SERVICE_PROVISION_CONTRACT',
    name: 'Contrato de prestación de serv.',
    status: 1,
  },
  {
    id: 3,
    key: 'ADDENDUM',
    name: 'Addendum',
    status: 1,
  },
  {
    id: 4,
    key: 'MODIFYING_CONVENTION',
    name: 'Convenio modificatorio',
    status: 1,
  },
  {
    id: 5,
    key: 'AGREEMENT',
    name: 'Acuerdo',
    status: 1,
  },
  {
    id: 6,
    key: 'HEALTH_INSURANCE',
    name: 'Convenio',
    status: 1,
  },
  {
    id: 7,
    key: 'CONTRACT_TERMINATION',
    name: 'Terminación de contrato',
    status: 1,
  },
  {
    id: 8,
    key: 'OTHER',
    name: 'Otro',
    status: 1,
  },
]

mock.onGet('/type-contract').reply(() => [200, data])

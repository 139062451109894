import apiInstance from './index'
import queryParams from './queryParams'

export function categories(tempQuery) {
  let query = ''
  if (tempQuery) {
    query = queryParams.serializeParams(tempQuery.paramsObj, tempQuery.filtersObj)
  }
  return apiInstance({
    url: `/catalog/category${query}`,
    method: 'GET',
  })
}
export function genders(tempQuery) {
  let query = ''
  if (tempQuery) {
    query = queryParams.serializeParams(tempQuery.paramsObj, tempQuery.filtersObj)
  }
  return apiInstance({
    url: `/catalog/gender${query}`,
    method: 'GET',
  })
}
export function typeJurisdictions() {
  return apiInstance({
    url: '/catalog/type-jurisdiction',
    method: 'GET',
  })
}
export function typeContracts() {
  return apiInstance({
    url: '/catalog/type-contract',
    method: 'GET',
  })
}
export function typeOperations() {
  return apiInstance({
    url: '/catalog/type-operation',
    method: 'GET',
  })
}
export function typePaymentperiods() {
  return apiInstance({
    url: '/catalog/payment-period',
    method: 'GET',
  })
}
export function playerStatuses() {
  return apiInstance({
    url: '/catalog/player-status',
    method: 'GET',
  })
}
export function staffJobPositions(tempQuery) {
  let query = ''
  if (tempQuery) {
    query = queryParams.serializeParams(tempQuery.paramsObj, tempQuery.filtersObj)
  }
  return apiInstance({
    url: `/catalog/staff-job-position${query}`,
    method: 'GET',
  })
}
export function membersTypes() {
  return apiInstance({
    url: '/members/type',
    method: 'GET',
  })
}
export function educationLevels() {
  return apiInstance({
    url: '/catalog/education-level',
    method: 'GET',
  })
}
export function nationalities() {
  return apiInstance({
    url: '/catalog/nationality',
    method: 'GET',
  })
}
export function waysToManage() {
  return apiInstance({
    url: '/catalog/way-to-manage',
    method: 'GET',
  })
}
export function powersGranted() {
  return apiInstance({
    url: '/catalog/powers-granted',
    method: 'GET',
  })
}
export function trademarkTypes() {
  return apiInstance({
    url: '/catalog/trademark-types',
    method: 'GET',
  })
}
export function requestTypes() {
  return apiInstance({
    url: '/catalog/request-types',
    method: 'GET',
  })
}
export function literaryWorkTypes() {
  return apiInstance({
    url: '/catalog/literary-work-types',
    method: 'GET',
  })
}
export function filters() {
  return apiInstance({
    url: '/catalog/filter',
    method: 'GET',
  })
}
export function tags() {
  return apiInstance({
    url: '/catalog/tag',
    method: 'GET',
  })
}
export function messageTypes() {
  return apiInstance({
    url: '/catalog/message-types',
    method: 'GET',
  })
}
export function actions() {
  return apiInstance({
    url: '/catalog/actions',
    method: 'GET',
  })
}
export function models() {
  return apiInstance({
    url: '/catalog/models',
    method: 'GET',
  })
}
export function colors() {
  return apiInstance({
    url: '/catalog/colors',
    method: 'GET',
  })
}
export function notificationTypes() {
  return apiInstance({
    url: '/catalog/notification-types',
    method: 'GET',
  })
}

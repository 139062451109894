import axios from '@axios'

export default {
  state: {
    trademarkTypes: [],
  },

  mutations: {
    setTrademarkTypes(state, value) {
      state.trademarkTypes = value
    },
  },

  actions: {
    fetchTrademarkTypes({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/clubs/trademark-types').then(response => {
          commit('setTrademarkTypes', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },

  getters: {
    trademarkTypesList(state) {
      return state.trademarkTypes
    },
  },
}

import Vue from 'vue'
import i18n from '@/libs/i18n'
// var moment = require('moment')

// Vue.use(moment)

import moment from 'moment'
import momentTimeZone from 'moment-timezone'

momentTimeZone.locale(i18n.locale)
moment.locale(i18n.locale)
// moment.locale('en')
Vue.filter('formatDate', value => {
  if (value) {
    return momentTimeZone(String(value)).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('L')
  }
  return ''
})

Vue.filter('formatDateTime', value => {
  if (value) {
    return momentTimeZone(String(value)).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('D/MM/YYYY, HH:mm:ss ')
  }
  return ''
})
Vue.filter('formatDateLetter', value => {
  if (value) {
    return momentTimeZone(String(value)).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('LLLL')
  }
  return ''
})

Vue.filter('formatDateTimeRemaining', value => {
  if (value) {
    return momentTimeZone(String(value)).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).startOf('hour').fromNow()
  }
  return ''
})

import { RouterView } from '@/router/router-view'

export default [
  {
    path: '/members',
    component: RouterView,
    props: route => ({ id: route.name }),
    children: [
      /* ----------- member ----------- */
      {
        path: '',
        name: 'member',
        component: () => import('@/views/member/Index.vue'),
        meta: {
          navActiveLink: 'clubs',
          redirectIfProfileIsIncomplete: true,
          redirectIfClubIsIncomplete: true,
        },
      },
      /* ----------- crear member ----------- */
      {
        path: 'create',
        name: 'create-member',
        component: () => import('@/views/member/CreateMemberWizard.vue'),
        meta: {
          navActiveLink: 'clubs',
          redirectIfProfileIsIncomplete: true,
          redirectIfClubIsIncomplete: true,
        },
        props: true,
      },
      {
        path: 'details/:id',
        // path: 'details',
        name: 'details-member',
        component: () => import('@/views/member/DetailsMember.vue'),
        meta: {
          navActiveLink: 'clubs',
          redirectIfProfileIsIncomplete: true,
          redirectIfClubIsIncomplete: true,
        },
        props: true,
      },
    ],
  },
  {
    path: '/document',
    name: 'document-member',
    component: () => import('@/views/member/AppViewerPDF.vue'),
    meta: {
      layout: 'full',
      action: 'read',
      redirectIfProfileIsIncomplete: true,
      redirectIfClubIsIncomplete: true,
    },
    props: true,
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('@/views/team/Index.vue'),
    meta: {
      navActiveLink: 'clubs',
      redirectIfProfileIsIncomplete: true,
      redirectIfClubIsIncomplete: true,
    },
    props: true,
  },
]

<template>
  <div>
    <b-modal
      id="view-timer"
      ref="viewTimer"
      :title="message"
      centered
      no-close-on-backdrop
      @close="closeCurrentModal"
    >
      <b-row>
        <b-col md="12">
          <p>
            <strong>
              <i class="icon-0-icons-dark-clock" />
              {{ $t('generic.yourSessionWillExpireSoonIn') }}
              <span class="text-danger">{{ timer }}</span>
              {{ $t('generic.seconds') }}
            </strong>
          </p>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="float-right">
          <b-button
            variant="secondary"
            pill
            @click="closeCurrentModal"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BModal, BButton } from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BButton,
  },

  props: {
    timer: { Number },
  },

  computed: {
    ...mapGetters({ userName: 'name' }),

    message() {
      return `${this.$t('generic.hello')}, ${this.userName}, ${this.$t('generic.areYouStillThere')}`
    },
  },

  mounted() {
    this.$bvModal.show('view-timer')
  },

  methods: {
    closeCurrentModal() {
      this.$bvModal.hide('view-timer')
      this.$emit('close-modal')
    },
  },
}
</script>

import mock from '@/@fake-db/mock'
import { data as dataSocieties } from '@/@fake-db/data/clubs/societies'
import { data as dataPartners } from '@/@fake-db/data/clubs/partners'
import { data as dataAssemblies } from '@/@fake-db/data/clubs/assemblies'
import { data as dataPowers } from '@/@fake-db/data/clubs/powers'

export const data = [
  {
    id: 1,
    instrument_number: '0897654321',
    city_of_notary_id: 1,
    notary_name: 'Roberto Peralta',
    notary_number: '347654322',
    club_id: 1,
  },
  {
    id: 2,
    instrument_number: '6789504312',
    city_of_notary_id: 1,
    notary_name: 'Pedro Robles',
    notary_number: '1089504312',
    club_id: 2,
  },
  {
    id: 3,
    instrument_number: '4539860172',
    city_of_notary_id: 1,
    notary_name: 'Julio Castro',
    notary_number: '9986017252',
    club_id: 3,
  },
  {
    id: 4,
    instrument_number: '8324506179',
    city_of_notary_id: 1,
    notary_name: 'Carlos Rivera',
    notary_number: '5804506334',
    club_id: 4,
  },
]

// mock.onGet(/\/clubs\/\d+\/instrument-numbers/).reply(() => [200, data])

mock.onGet(/\/clubsx\/\d+\/all-instrument-numbers/).reply(config => {
  const clubIdB = config.url.split('/')[2]

  const allInstruments = data.filter(el => el.club_id == clubIdB)

  const filterDataSoc = []
  const filterDataAss = []
  const filterDataPow = []

  allInstruments.forEach(item => {
    const instrumentOfSoc = dataSocieties.filter(el => el.instrument_number_id == item.id)

    if (instrumentOfSoc.length) {
      const auxPartners = dataPartners.filter(el => el.society_id == instrumentOfSoc[0].id && el.status == 1)

      const objectS = {
        id: item.id,
        instrument_number: item.instrument_number,
        type: 'clubs.society.society',
        key: 'society',
        users: auxPartners,
      }

      filterDataSoc.push(objectS)
    } else {
      const instrumentsAss = dataAssemblies.filter(el => el.instrument_number_id == item.id)

      if (instrumentsAss.length) {
        const objectA = {
          id: item.id,
          instrument_number: item.instrument_number,
          type: 'clubs.assemblies.assembly',
          key: 'assembly',
          users: [],
        }

        filterDataAss.push(objectA)
      } else {
        const instrumentsPow = dataPowers.filter(el => el.instrument_number_id == item.id)

        if (instrumentsAss.length) {
          const objectP = {
            id: item.id,
            instrument_number: item.instrument_number,
            type: 'clubs.powers.powerOfAttorney',
            key: 'power',
            users: [],
          }

          filterDataPow.push(objectP)
        }
      }
    }
  })

  const allInstrumentNumbers = filterDataSoc.concat(filterDataAss, filterDataPow)

  return [200, { allInstrumentNumbers }]
})

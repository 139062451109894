import apiInstance from './index'

const baseURLRelative = '/notifications'

export function getNotifications() {
  return apiInstance({
    url: `${baseURLRelative}`,
    method: 'GET',
  })
}

export function counterNotifications() {
  return apiInstance({
    url: `${baseURLRelative}/counter`,
    method: 'GET',
  })
}

export function showNotification(hash) {
  return apiInstance({
    url: `${baseURLRelative}/${hash}`,
    method: 'GET',
  })
}

export function destroyNotification(hash) {
  return apiInstance({
    url: `${baseURLRelative}/${hash}`,
    method: 'DELETE',
  })
}
export function updateStatusNotification(hash) {
  return apiInstance({
    url: `${baseURLRelative}/status/${hash}`,
    method: 'PUT',
  })
}

export function markAllAsRead() {
  return apiInstance({
    url: `${baseURLRelative}/mark/read`,
    method: 'GET',
  })
}

import apiInstance from './index'
// import queryParams from './queryParams'

const baseURLRelative = '/events'

export function getEvents() {
  return apiInstance({
    url: `${baseURLRelative}`,
    method: 'GET',
  })
}
export function showEvent(hash) {
  return apiInstance({
    url: `${baseURLRelative}/${hash}`,
    method: 'GET',
  })
}
export function storeEvent(data) {
  return apiInstance({
    url: baseURLRelative,
    method: 'POST',
    data,
  })
}
export function updateEvent(data) {
  return apiInstance({
    url: `${baseURLRelative}/${data.hash}`,
    method: 'PUT',
    data,
  })
}
export function destroyEvent(hash) {
  return apiInstance({
    url: `${baseURLRelative}/${hash}`,
    method: 'DELETE',
  })
}

import mock from '@/@fake-db/mock'

import { paginateArray } from '@/@fake-db/utils'
import { data as dataCities } from '@/@fake-db/data/catalogs/cities'
import { data as dataClubSettings } from '@/@fake-db/data/clubs/club-setting'

export const data = []

//
mock.onGet(/\/clubs-aux\/\d+\/assemblies/).reply(config => {
  const clubIdX = config.url.split('/')[2]

  const { filters } = config.params

  const auxAssemblyData = []

  const currentAssembly = data.length > 0 ? data.filter(item => item.club_id == clubIdX && item.status == 1) : []
  const auxAssemblies = data.length > 0 ? data.filter(item => item.club_id == clubIdX && item.status == 0) : []

  const result = auxAssemblies.sort((a, b) => {
    if (a.created_at < b.created_at) {
      return 1
    }
    if (a.created_at > b.created_at) {
      return -1
    }
    // a must be equal to b
    return 0
  })

  const auxData = JSON.parse(JSON.stringify(paginateArray(result, filters.perPage, filters.page)))

  auxData.forEach(el => {
    const indexCity = dataCities.findIndex(element => element.id === el.city_of_notary_id)
    const clubX = dataClubSettings.filter(element => element.id === el.club_id)

    const objAssembly = {
      id: el.id,
      incorporation_date: el.incorporation_date,
      instrument_number: el.instrument_number,
      city_of_notary: dataCities[indexCity].name,
      notary_name: el.notary_name,
      notary_number: el.notary_number,
      agreements: el.agreements,
      denomination: clubX[0].denomination,
    }

    auxAssemblyData.push(objAssembly)
  })

  return [200, {
    current: currentAssembly.length ? currentAssembly[0] : null,
    assemblies: auxAssemblyData,
    totalRecors: auxAssemblies.length,
    to: filters.perPage * (filters.page - 1) + auxAssemblies.length,
    from: filters.perPage * (filters.page - 1) + (auxAssemblies.length ? 1 : 0),
  }]
})

//
mock.onPost(/\/clubs-aux-save\/\d+\/assemblies/).reply(config => {
  let auxId = 1
  const dataAssemblyRequest = JSON.parse(config.data)

  if (data.length > 0) {
    while (data.findIndex(el => el.id === auxId) !== -1) {
      auxId = Math.floor(Math.random() * 1000) + 5
    }

    const auxIndex = data.findIndex(el => el.club_id === dataAssemblyRequest.club_id && el.status == 1)

    if (auxIndex != -1) {
      data[auxIndex].status = 0
    }
  }

  dataAssemblyRequest.id = auxId
  dataAssemblyRequest.created_at = new Date()

  data.push(dataAssemblyRequest)

  return [200, { message: 'La asamblea se ha creado correctamente.', assemblyData: data }]
})

//
mock.onGet(/\/clubs-aux-show\/\d+\/assemblies\/\d+/).reply(config => {
  const clubIdB = config.url.split('/')[2]
  const assemblyIdB = config.url.split('/')[4]

  let dataByClubBAndAssemblyId = data.filter(item => (item.id == assemblyIdB && item.club_id == clubIdB))
  const city = dataCities.filter(element => element.id === dataByClubBAndAssemblyId[0].city_of_notary_id)

  dataByClubBAndAssemblyId[0].city = city[0]

  return [200, dataByClubBAndAssemblyId[0]]
})

//
mock.onGet(/\/clubs-aux-edit\/\d+\/assemblies\/\d+\/edit/).reply(config => {
  const clubIdB = config.url.split('/')[2]
  const assemblyIdB = config.url.split('/')[4]

  const dataByClubBAndAssemblyId = data.filter(item => (item.id == assemblyIdB && item.club_id == clubIdB))

  return [200, dataByClubBAndAssemblyId[0]]
})

//
mock.onPost(/\/clubs-aux-update\/\d+\/assemblies/).reply(config => {
  const dataAssemblyRequest = JSON.parse(config.data)

  const index = data.findIndex(item => (item.id == dataAssemblyRequest.id && item.club_id == dataAssemblyRequest.club_id))

  data[index] = dataAssemblyRequest

  return [200, { message: 'La asamblea se ha editado correctamente.', assemblyData: dataAssemblyRequest }]
})

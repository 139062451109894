import axios from '@axios'
import { getContractualConditionsLog, storeContractualCondition } from '@/services/contractualConditionsService'
// import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    list: [],
    listLog: {
      data: [],
    },
    contractual_condition: {
      hash: null,
      member_hash: null,
      member_id: null,
      type_contractual_conditions: 'TRANSFER',
      descrition_contractual_conditions: null,
      // Transfer
      transfer_hash: null,
      transfer_id: null,
      transfer: {
        hash: null,
        operations: 'DOMESTIC',
        tms_number: null, // only international -> operations
        type_operation_hash: null,
        type_operation_id: null,
        type_operation: null, // ! No add database
        init_date_operation: null,
        end_date_operation: null,
        ceding_club: null,
        percentage_transfer_economic_rights: null,
        transfer_conditions: null, // Solo si no es el 100 de transferencia
        contract_agreement: 'YES', // yes no

        // yes
        assign_representative_key: null,
        assign_representative_id: null,
        signature_date: null,
        init_date: null,
        end_date: null,
        against_provision: {
          has_fixed_quantity: false,
          fixed_quantity: null, // Amount
          has_variable_quantity: false,
          variable_quantity: null,
        },
        method_payment: null,
        payment_period: null, // ! No add database
        payment_period_hash: null,
        payment_period_id: null,
        payment_period_other: null,
        penalty_amount: null,
        address_hash: null,

        // Notification Address
        address: {
          hash: null,
          address: null,
          zip_code: null,
          country_hash: null,
          state_hash: null,
        },
        email: null,
        type_jurisdiction: null, // ! No add database
        type_jurisdiction_hash: null,
        jurisdiction_other: null,
        law_applicable_contract: null,

        // Option Agente
        // player_agent: 'YES',

        player_agent_hash: null,
        player_agent_id: null,
        player_agent_key: 'YES',

        // Yes
        player_agent: {
          hash: null,
          name: null,
          license_number: null,
          role: null,
          payment_amount: null,
          method_payment: null,
          payment_period: null, // ! No add database
          payment_period_hash: null,
          // payment_period_id: null,
          payment_period_other: null,
          init_effective_date: null,
          end_effective_date: null,
          file_document: {},
          path_file_document_media_file_hash: null,
          path_file_document_media_file_id: null,
          assign_representative_key: null,
          assign_representative_id: null,
        },
      },

      // General
      type_contract: null, // ! No add database
      type_contract_hash: null,
      type_contract_other: null,
      title_or_licence: null,
      file_title_or_licence: {}, // ! No add database
      path_file_title_or_licence_media_file_hash: null,
      assign_representative_key: null,
      assign_representative_id: null,
      signature_date: null,
      init_date: null,
      end_date: null,
      against_provision: {
        has_fixed_quantity: false,
        fixed_quantity: null, // Amount
        has_variable_quantity: false,
        variable_quantity: null,
      },
      increments: [{
        id: Date.now(),
        value: null,
      }],
      increase_conditions: null,

      method_payment: null,
      payment_period: null, // ! No add database
      payment_period_hash: null,
      payment_period_id: null,
      payment_period_other: null,
      bonds: null,
      benefits: [{
        id: Date.now(),
        value: null,
      }],
      compensation_amount: null,
      compensation_details: null,

      penalty_amount: null,
      penalty_details: null,

      rescission_amount: null,
      rescission_details: null,

      // Notificartion Address
      address: {
        hash: null,
        address: null,
        zip_code: null,
        country_hash: null,
        state_hash: null,
      },
      email: null,
      type_jurisdiction: null, // ! No add database
      type_jurisdiction_hash: null,
      jurisdiction_other: null,
      law_applicable_contract: null,

      // Agents general
      // player_agent: 'YES',

      player_agent_hash: null,
      player_agent_id: null,
      player_agent_key: 'YES',

      // Yes
      player_agent: {
        hash: null,
        name: null,
        license_number: null,
        role: null,
        payment_amount: null,
        method_payment: null,
        payment_period: null, // ! No add database
        payment_period_hash: null,
        payment_period_other: null,
        init_effective_date: null,
        end_effective_date: null,
        file_document: {},
        path_file_document_media_file_hash: null,
        assign_representative_key: null,
      },
    },

    // contractual_condition: {},

  },
  getters: {
    listLog(state) {
      state.listLog.data.forEach(element => {
        const { created_at: dateRegister = new Date(), ...condition } = element.data
        Object.assign(element, condition)
        element.date_register_condition_contractual = dateRegister
        delete element.data
      })
      return state.listLog
    },
    contractual_conditions(state) {
      return state.contractual_condition
    },
    // contractualConditions
  },
  mutations: {
    // setList(state, value) {
    //   state.list = value
    // },
    setContractualCondition(state, value) {
      state.contractual_condition = value
    },
    setListLog(state, value) {
      state.listLog = value
    },
  },
  actions: {
    fetchData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/contractual/conditions', { params: payload })
          .then(response => {
            commit('setList', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchDataLogByMemberId({ commit }, tempQuery) {
      return new Promise((resolve, reject) => {
        getContractualConditionsLog(tempQuery).then(response => {
          commit('setListLog', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    store({ commit }, conditionsData) {
      return new Promise((resolve, reject) => {
        storeContractualCondition(conditionsData)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    update(ctx, conditionsData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/contractual/conditions/${conditionsData.id}`, conditionsData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    remove(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/contractual/conditions/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

import mock from '@/@fake-db/mock'
import { data as dataGender } from '@/@fake-db/data/clubs/club-setting'

export const data = [
  { 
    id: 1,
    name: 'Director Técnico',
    gender_id: 1,
  },
  {
    id: 2,
    name: 'Auxiliar Técnico',
    gender_id: 1,
  },
  { 
    id: 3,
    name: 'Secretario Técnico',
    gender_id: 1,
  },
  { 
    id: 4,
    name: 'Entrenador de Porteros',
    gender_id: 1,
  },
  { 
    id: 5,
    name: 'Doctor',
    gender_id: 1,
  },
  { 
    id: 6,
    name: 'Kinesiólogo',
    gender_id: 1,
  },
  { 
    id: 7,
    name: 'Masajista',
    gender_id: 1,
  },
  { 
    id: 8,
    name: 'Utilero',
    gender_id: 1,
  },
  { 
    id: 9,
    name: 'Psicólogo',
    gender_id: 1,
  },
  { 
    id: 10,
    name: 'Directora Técnica',
    gender_id: 0,
  },
  {
    id: 11,
    name: 'Auxiliar Técnica',
    gender_id: 0,
  },
  { 
    id: 12,
    name: 'Secretaria Técnica',
    gender_id: 0,
  },
  { 
    id: 13,
    name: 'Entrenadora de Porteras',
    gender_id: 0,
  },
  { 
    id: 14,
    name: 'Doctora',
    gender_id: 0,
  },
  { 
    id: 15,
    name: 'Kinesióloga',
    gender_id: 0,
  },
  { 
    id: 16,
    name: 'Masajista',
    gender_id: 0,
  },
  { 
    id: 17,
    name: 'Utilera',
    gender_id: 0,
  },
  { 
    id: 18,
    name: 'Psicóloga',
    gender_id: 0,
  },
]

mock.onGet('/jobs-staff').reply(() => [200, data])

mock.onGet(/\/jobs-staff\/\d+/).reply(config => {
  const idGender = config.url.split('/')[2]

  const jobs = data.filter(item => item.gender_id === Number(idGender))

  return [200, jobs]
})

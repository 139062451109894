import mock from '@/@fake-db/mock'

import { paginateArray } from '@/@fake-db/utils'

import { data as dataCities } from '@/@fake-db/data/catalogs/cities'
import { data as dataInstruments } from '@/@fake-db/data/clubs/instrument-numbers'
import { data as dataPartners } from '@/@fake-db/data/clubs/partners'
import { data as dataClubSettings } from '@/@fake-db/data/clubs/club-setting'
import { data as dataWaysToManage } from '@/@fake-db/data/catalogs/ways-to-manage'

export const data = [
  {
    id: 1,
    club_id: 1,
    incorporation_date: new Date(),
    instrument_number_id: 1,
    society_object: 'Texto relacionado al objeto de la sociedad 1',
    duration: 4,
    apply_foreign_users: true,
    way_to_manage_id: 3,
    other_option: '',
    management_structure: 'Texto relacionado a la estructura de la administración 1',
    observations: 'Texto relacionado a las observaciones del club 1',
    commissioner: 'Enrique Marquez',
    created_at: new Date(),
    is_current: 1,
    status: 1,
  },
  {
    id: 2,
    club_id: 2,
    incorporation_date: new Date(),
    instrument_number_id: 2,
    society_object: 'Texto relacionado al objeto de la sociedad 2',
    duration: 3,
    apply_foreign_users: false,
    way_to_manage_id: 2,
    other_option: '',
    management_structure: 'Texto relacionado a la estructura de la administración 2',
    observations: 'Texto relacionado a las observaciones del club 2',
    commissioner: 'Pablo Lovera',
    created_at: new Date(),
    is_current: 1,
    status: 1,
  },
  {
    id: 3,
    club_id: 3,
    incorporation_date: new Date(),
    instrument_number_id: 3,
    society_object: 'Texto relacionado al objeto de la sociedad 3',
    duration: 6,
    apply_foreign_users: true,
    way_to_manage_id: null,
    other_option: 'Auditoría',
    management_structure: 'Texto relacionado a la estructura de la administración 3',
    observations: 'Texto relacionado a las observaciones del club 3',
    commissioner: 'Alberto Contreras',
    created_at: new Date(),
    is_current: 1,
    status: 1,
  },
  {
    id: 4,
    club_id: 4,
    incorporation_date: new Date(),
    instrument_number_id: 4,
    society_object: 'Texto relacionado al objeto de la sociedad 4',
    duration: 15,
    apply_foreign_users: false,
    way_to_manage_id: 1,
    other_option: '',
    management_structure: 'Texto relacionado a la estructura de la administración 4',
    observations: 'Texto relacionado a las observaciones del club 4',
    commissioner: 'Samuel González',
    created_at: new Date(),
    is_current: 1,
    status: 1,
  },
]

//
mock.onGet(/\/clubs-aux\/\d+\/societies/).reply(config => {
  const { filters } = config.params
  const clubIdX = config.url.split('/')[2]

  const auxSocietyData = []

  // asignar actual
  const currentSociety = data.filter(item => item.club_id == clubIdX && item.is_current == 1 && item.status == 1)
  //
  // const instrumentA = dataInstruments.filter(element => element.id === currentSociety[0].instrument_number_id)
  // const indexCityA = dataCities.findIndex(element => element.id === instrumentA[0].city_of_notary_id)
  // instrumentA[0].city_of_notary = dataCities[indexCityA].name
  // currentSociety[0].instrument = instrumentA[0]

  // asignar historico
  const auxSocieties = data.filter(item => item.club_id == clubIdX && item.is_current == 0)
  // ordenar historico
  const result = auxSocieties.sort((a, b) => {
    if (a.created_at < b.created_at) {
      return 1
    }
    if (a.created_at > b.created_at) {
      return -1
    }
    // a must be equal to b
    return 0
  })
  // paginar historico
  const auxData = JSON.parse(JSON.stringify(paginateArray(result, filters.perPage, filters.page)))

  // asignar datos relacionados al historico
  auxData.forEach(el => {
    const clubX = dataClubSettings.filter(element => element.id === el.club_id)
    const partnersX = dataPartners.filter(element => element.society_id === el.id)
    const wayToManageX = el.way_to_manage_id != null ? dataWaysToManage.filter(element => element.id === el.way_to_manage_id) : null

    const instrumentX = dataInstruments.filter(element => element.id === el.instrument_number_id)
    const indexCity = dataCities.findIndex(element => element.id === instrumentX[0].city_of_notary_id)
    instrumentX[0].city_of_notary = dataCities[indexCity].name

    const objSociety = {
      id: el.id,
      incorporation_date: el.incorporation_date,
      instrument: instrumentX[0],
      denomination: clubX[0].denomination,
      way_to_manage: wayToManageX != null ? wayToManageX[0].name : null,
      partners: partnersX,
    }

    auxSocietyData.push(objSociety)
  })

  return [200, {
    current: currentSociety.length ? currentSociety[0] : null,
    societies: auxSocietyData,
    totalRecors: auxSocieties.length,
    to: filters.perPage * (filters.page - 1) + auxSocieties.length,
    from: filters.perPage * (filters.page - 1) + (auxSocieties.length ? 1 : 0),
  }]
})

//
mock.onGet(/\/clubs-aux-edit\/\d+\/societies\/\d+\/edit/).reply(config => {
  const clubIdB = config.url.split('/')[2]
  const societyIdB = config.url.split('/')[4]

  // buscar sociedad
  const dataByClubBAndSocietyId = data.filter(item => (item.id == societyIdB && item.club_id == clubIdB))

  // buscar numero de instrumento de sociedad
  const instrumentA = dataInstruments.filter(element => element.id === dataByClubBAndSocietyId[0].instrument_number_id)

  // buscar socios por sociedad_id
  const dataParnersBySocietyId = dataPartners.filter(itemx => (itemx.society_id == societyIdB))
  // const dataWayToManage = dataWaysToManage.filter(itemx => (itemx.id == dataByClubBAndSocietyId[0].way_to_manage_id))

  dataByClubBAndSocietyId[0].instrument = instrumentA[0]
  dataByClubBAndSocietyId[0].partners = dataParnersBySocietyId
  return [200, dataByClubBAndSocietyId[0]]
})

//
mock.onPost(/\/clubs-aux-save\/\d+\/societies/).reply(config => {
  const dataSocietyRequest = JSON.parse(config.data)
  // respaldar y eliiminar propiead socios
  const backPartners = dataSocietyRequest.partners
  delete dataSocietyRequest.partners

  // inicia agregar instrumento
  let instrumentId = 1

  if (dataInstruments.length > 0) {
    while (dataInstruments.findIndex(el => el.id === instrumentId) !== -1) {
      instrumentId = Math.floor(Math.random() * 1000) + 5
    }
  }

  dataSocietyRequest.instrument.id = instrumentId
  dataSocietyRequest.instrument.club_id = dataSocietyRequest.club_id

  dataInstruments.push(dataSocietyRequest.instrument)
  delete dataSocietyRequest.instrument
  // finalizar agregar instrumento

  // inicia agregar sociedad
  let auxId = 1

  if (data.length > 0) {
    while (data.findIndex(el => el.id === auxId) !== -1) {
      auxId = Math.floor(Math.random() * 1000) + 5
    }

    const auxIndex = data.findIndex(el => el.club_id === dataSocietyRequest.club_id && el.is_current == 1)

    if (auxIndex != -1) {
      data[auxIndex].is_current = 0
    }
  }

  dataSocietyRequest.id = auxId
  dataSocietyRequest.instrument_number_id = instrumentId
  dataSocietyRequest.created_at = new Date()

  data.push(dataSocietyRequest)
  // finaliza agregar sociedad

  // inicia agregar socios
  backPartners.forEach(el => {
    let partnerId = el.id

    if (dataPartners.length > 0) {
      while (dataPartners.findIndex(el => el.id === partnerId) !== -1) {
        partnerId = Math.floor(Math.random() * 1000) + 5
      }
    }

    const auxObjP = {
      id: partnerId,
      name: el.name,
      percentage: el.percentage,
      society_id: auxId,
      created_at: new Date(),
      status: 1,
    }

    dataPartners.push(auxObjP)
  })
  // finaliza agregar socios

  return [200, { message: 'La sociedad se ha creado correctamente.', societyData: data }]
})

//
mock.onPost(/\/clubs-aux-update\/\d+\/societies/).reply(config => {
  const dataSocietyRequest = JSON.parse(config.data)

  const index = data.findIndex(item => (item.id == dataSocietyRequest.id && item.club_id == dataSocietyRequest.club_id))
  const indexI = dataInstruments.findIndex(item => (item.id == dataSocietyRequest.instrument_number_id))

  dataInstruments[indexI] = dataSocietyRequest.instrument
  delete dataSocietyRequest.instrument

  data[index] = dataSocietyRequest

  return [200, { message: 'La sociedad se ha editado correctamente.', societyData: dataSocietyRequest }]
})

//
mock.onGet(/\/clubs-aux-show\/\d+\/societies\/\d+/).reply(config => {
  const clubIdB = config.url.split('/')[2]
  const societyIdB = config.url.split('/')[4]

  const dataByClubBAndSocietyId = data.filter(item => (item.id == societyIdB && item.club_id == clubIdB && item.is_current == 1))

  const instrumentB = dataInstruments.filter(element => element.id === dataByClubBAndSocietyId[0].instrument_number_id)
  const indexCityB = dataCities.findIndex(element => element.id === instrumentB[0].city_of_notary_id)

  instrumentB[0].city_of_notary = dataCities[indexCityB].name

  const partnersX = dataPartners.filter(element => element.society_id === dataByClubBAndSocietyId[0].id)
  const wayToManageX = dataByClubBAndSocietyId[0].way_to_manage_id != null ? dataWaysToManage.filter(element => element.id == dataByClubBAndSocietyId[0].way_to_manage_id) : null

  dataByClubBAndSocietyId[0].instrument = instrumentB[0]
  dataByClubBAndSocietyId[0].partners = partnersX
  dataByClubBAndSocietyId[0].way_to_manage = wayToManageX != null ? wayToManageX[0] : null

  return [200, dataByClubBAndSocietyId[0]]
})

//
mock.onDelete(/\/clubs-aux-delete\/\d+\/societies\/\d+/).reply(config => {
  const clubIdB = config.url.split('/')[2]
  const societyIdB = config.url.split('/')[4]

  const indexD = data.findIndex(item => (item.id == societyIdB && item.club_id == clubIdB))

  data.splice(indexD, 1)

  return [200, indexD]
})

//
mock.onGet(/\/clubs-aux\/\d+\/society/).reply(config => {
  const clubIdX = config.url.split('/')[2]

  // buscar actual
  let currentSociety = data.filter(item => item.club_id == clubIdX && item.is_current == 1 && item.status == 1)

  if (currentSociety.length > 0) {
    const instrumentS = dataInstruments.filter(element => element.id === currentSociety[0].instrument_number_id)

    const partnersX = dataPartners.filter(element => element.society_id === currentSociety[0].id)
    currentSociety[0].instrument = instrumentS[0]
    currentSociety[0].partners = partnersX
  }

  return [200, currentSociety[0]]
})

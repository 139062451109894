import mock from '@/@fake-db/mock'

export const data = [
  {
    id: 1,
    name: 'Literaria',
    status: 1,
  },
  {
    id: 2,
    name: 'Musical, con o sin letra',
    status: 1,
  },
  {
    id: 3,
    name: 'Dramática',
    status: 1,
  },
  {
    id: 4,
    name: 'Danza',
    status: 1,
  },
  {
    id: 5,
    name: 'Pictórica o de dibujo',
    status: 1,
  },
  {
    id: 6,
    name: 'Escultórica y de carácter plástico',
    status: 1,
  },
  {
    id: 7,
    name: 'Caricatura e historieta',
    status: 1,
  },
  {
    id: 8,
    name: 'Arquitectónica',
    status: 1,
  },
  {
    id: 9,
    name: 'Cinematográfica y demás obras audiovisuales',
    status: 1,
  },
  {
    id: 10,
    name: 'Programas de radio y televisión',
    status: 1,
  },
  {
    id: 11,
    name: 'Programas de cómputo',
    status: 1,
  },
  {
    id: 12,
    name: 'Fotográfica',
    status: 1,
  },
  {
    id: 13,
    name: 'Obras de arte aplicado que incluyen el diseño gráfico o textil',
    status: 1,
  },
  {
    id: 14,
    name: 'De compilación, integrada por las colecciones de obras',
    status: 1,
  },
]

mock.onGet('/clubs/types-of-literary-or-artistic-work').reply(() => [200, data])

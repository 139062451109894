import { RouterView } from '@/router/router-view'

export default [
  {
    path: '/clubs',
    component: RouterView,
    meta: {
      redirectIfProfileIsIncomplete: true,
    },
    children: [
      /* ----------- clubes ----------- */
      {
        path: '',
        name: 'clubs',
        component: () => import('@/views/sportClub/Club.vue'),
        meta: {
          redirectIfProfileIsIncomplete: true,
        },
      },
      /* ----------- crear club ----------- */
      {
        path: 'create',
        name: 'create-club',
        component: () => import('@/views/sportClub/club/Create.vue'),
        meta: {
          navActiveLink: 'clubs',
          redirectIfProfileIsIncomplete: true,
          redirectIfClubIsIncomplete: true,
        },
        props: true,
      },
      {
        path: 'admin/create',
        name: 'admin-create-club',
        component: () => import('@/views/sportClub/club/CreateWizard.vue'),
        meta: {
          navActiveLink: 'clubs',
          redirectIfProfileIsIncomplete: true,
        },
      },
      /* ----------- ver club ----------- */
      {
        path: ':id',
        name: 'show-club',
        component: () => import('@/views/sportClub/club/Modules.vue'),
        meta: {
          navActiveLink: 'clubs',
          redirectIfProfileIsIncomplete: true,
          redirectIfClubIsIncomplete: true,
        },
        children: [
          {
            path: '',
            redirect: { name: 'clubs' },
          },
          /* ----------- edit club ----------- */
          {
            path: 'edit',
            name: 'edit-club',
            component: () => import('@/views/sportClub/club/Create.vue'),
            meta: {
              navActiveLink: 'clubs',
              redirectIfProfileIsIncomplete: true,
              redirectIfClubIsIncomplete: true,
            },
          },
          /* ----------- corporativo ----------- */
          {
            path: 'corporate',
            name: 'corporate',
            component: () => import('@/views/sportClub/club/corporate/Corporate.vue'),
            meta: {
              navActiveLink: 'clubs',
              redirectIfProfileIsIncomplete: true,
              redirectIfClubIsIncomplete: true,
            },
          },
          /* ----------- sociedad ----------- */
          {
            path: 'society',
            name: 'society',
            component: () => import('@/views/sportClub/club/societies/Society.vue'),
            meta: {
              navActiveLink: 'clubs',
              redirectIfProfileIsIncomplete: true,
              redirectIfClubIsIncomplete: true,
            },
          },
          /* ----------- poderes ----------- */
          {
            path: 'power-of-attorney',
            name: 'power-of-attorney',
            component: () => import('@/views/sportClub/club/powerOfAttorney/PowerOfAttorney.vue'),
            meta: {
              navActiveLink: 'clubs',
              redirectIfProfileIsIncomplete: true,
              redirectIfClubIsIncomplete: true,
            },
          },
          /* ----------- apoderados ----------- */
          {
            path: 'representative',
            name: 'representative',
            component: () => import('@/views/sportClub/club/representatives/Representatives.vue'),
            meta: {
              navActiveLink: 'clubs',
              redirectIfProfileIsIncomplete: true,
              redirectIfClubIsIncomplete: true,
            },
          },
          /* ----------- asambleas ----------- */
          {
            path: 'assemblies',
            name: 'assemblies',
            component: () => import('@/views/sportClub/club/assemblies/Assembly.vue'),
            meta: {
              navActiveLink: 'clubs',
              redirectIfProfileIsIncomplete: true,
              redirectIfClubIsIncomplete: true,
            },
          },
          /* ----------- propiedad intelectual ----------- */
          {
            path: 'intellectual-property/agreements',
            name: 'pi-agreements',
            component: () => import('@/views/sportClub/club/intellectualProperty/agreements/Agreements.vue'),
            meta: {
              navActiveLink: 'clubs',
              redirectIfProfileIsIncomplete: true,
              redirectIfClubIsIncomplete: true,
            },
          },
          {
            path: 'intellectual-property/trademarks',
            name: 'pi-trademarks',
            component: () => import('@/views/sportClub/club/intellectualProperty/trademarks/Trademarks.vue'),
            meta: {
              navActiveLink: 'clubs',
              redirectIfProfileIsIncomplete: true,
              redirectIfClubIsIncomplete: true,
            },
          },
          {
            path: 'intellectual-property/literary-or-artistic-works',
            name: 'pi-literary-or-artistic-works',
            component: () => import('@/views/sportClub/club/intellectualProperty/literaryOrArtisticWork/LiteraryOrArtisticWorks.vue'),
            meta: {
              navActiveLink: 'clubs',
              redirectIfProfileIsIncomplete: true,
              redirectIfClubIsIncomplete: true,
            },
          },
        ],
        props: route => ({ id: route.name }),
      },
    ],
  },
  {
    path: '/dynamic-document',
    name: 'dynamic-document-cl',
    component: () => import('@/views/partials/viewer/PdfViewer.vue'),
    meta: {
      layout: 'full',
      // agregar meta para denegar vista
    },
  },
]

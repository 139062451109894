export const setTeamListData = (state, list) => {
  state.teamList = list
}
export const addNewTeam = (state, team) => {
  state.teamList.data.unshift(team)
}
export const updateTeam = (state, team) => {
  const index = state.teamList.data.findIndex(element => element.hash === team.hash)
  state.teamList.data[index] = team
}
export const deleteTeam = (state, team) => {
  const index = state.teamList.data.findIndex(element => element.hash === team.hash)
  state.teamList.data.splice(index, 1)
}
export const setJerseyNumbers = (state, list) => {
  state.jerseyNumbers = list
}

import mock from '@/@fake-db/mock'

import { paginateArray } from '@/@fake-db/utils'

import dataUsers from '@/@fake-db/jwt/index'
import dataUserClub from '@/@fake-db/data/clubs/club-user'
import { data as dataCountries } from '@/@fake-db/data/catalogs/countries'
import { data as dataCities } from '@/@fake-db/data/catalogs/cities'
import { data as dataSocieties } from '@/@fake-db/data/clubs/societies'
import { data as dataAssignRepresentative } from '@/@fake-db/data/clubs/assign-representative'
import { data as dataInstruments } from '@/@fake-db/data/clubs/instrument-numbers'
import { data as dataPartners } from '@/@fake-db/data/clubs/partners'
import { data as dataARI } from '@/@fake-db/data/clubs/assign-representative-instrument'
import { data as dataARPG } from '@/@fake-db/data/clubs/assign-representative-power-granted'
import { data as dataARPR } from '@/@fake-db/data/clubs/assign-representative-partner-representative'
import dataRepresentatives from '@/@fake-db/data/clubs/representatives'
import dataJPosition from '@/@fake-db/data/job-position/job-position'
import dataJPositionUser from '@/@fake-db/data/job-position/job-position-user'

/* eslint-disable global-require */
export const data = [
  {
    id: 1,
    country_id: 110,
    city_id: 1,
    club_image: require('@/assets/images/clubs/fcb.png'),
    club_name: 'FCB',
    denomination: 'Fútbol Club Barcelona',
    layout: {
      background_color: '#004d98',
      btn_color: '#a50044',
    },
    status: 1,
    created_at: new Date(),
  },
  {
    id: 2,
    country_id: 110,
    city_id: 2,
    club_image: require('@/assets/images/clubs/athletic.png'),
    club_name: 'Athletic Club',
    denomination: 'Athletic Club',
    layout: {
      background_color: '#000000',
      btn_color: '#ee2523',
    },
    status: 1,
    created_at: new Date(),
  },
  {
    id: 3,
    country_id: 110,
    city_id: 3,
    club_image: require('@/assets/images/clubs/real.png'),
    club_name: 'Real Madrid CF',
    denomination: 'Real Madrid Club CF',
    layout: {
      background_color: '#023d85',
      btn_color: '#023d85',
    },
    status: 0,
    created_at: new Date(),
  },
  {
    id: 4,
    country_id: 110,
    city_id: 4,
    club_image: require('@/assets/images/clubs/valencia.png'),
    club_name: 'Valencia CF',
    denomination: 'Valencia Club CF',
    layout: {
      background_color: '#ed3423',
      btn_color: '#ebb81b',
    },
    status: 1,
    created_at: new Date(),
  },
]

mock.onGet('/club-setting/data/all').reply(() => {
  const dataUserAux = JSON.parse(localStorage.getItem('vuex'))
  const dataUser = dataUserAux.userAuthStore
  // const dataClub = []
  let dataClub = data

  // Get clubs users admin club
  const filterClub = dataUserClub.filter(item => item.user_id === Number(dataUser.id))

  if (filterClub.length > 0) {
    dataClub = dataClub.filter(item => filterClub.find(e => e.sport_club_id === Number(item.id)))
    // dataClub = data.filter(item => item.id === filterClub)
  }

  return [200, dataClub]
})

mock.onGet('/clubs').reply(config => {
  const { filters } = config.params

  const clubs = []

  const auxData = JSON.parse(JSON.stringify(paginateArray(data, filters.perPage, filters.page)))

  auxData.forEach(elem => {
    const usersX = dataUserClub.filter(item => item.sport_club_id === elem.id)

    const administratorUsers = []
    usersX.forEach(e => {
      const userAux = dataUsers.users.filter(item => item.id === e.user_id)

      const userObj = {
        id: userAux[0].id,
        name: userAux[0].fullName,
      }

      administratorUsers.push(userObj)
    })

    const objClub = {
      id: elem.id,
      club_image: elem.club_image,
      club_name: elem.club_name,
      status: elem.status,
      created_at: elem.created_at,
      administrators: administratorUsers,
    }

    clubs.push(objClub)
  })

  return [200, { clubs, total: data.length }]
})

mock.onPost('/clubs').reply(config => {
  let auxClId = 1
  const dataClubRequest = JSON.parse(config.data)

  while (data.findIndex(el => el.id === auxClId) !== -1) {
    auxClId = Math.floor(Math.random() * 1000) + 5
  }

  dataClubRequest.id = auxClId
  dataClubRequest.created_at = new Date()

  data.push(dataClubRequest)

  return [200, { message: 'El club se ha creado correctamente.', clubData: dataClubRequest }]
})

mock.onGet(/\/clubs\/\d+/).reply(config => {
  const clubId = config.url.split('/')[2]

  const club = data.filter(item => item.id == clubId)

  const usersX = dataUserClub.filter(item => item.sport_club_id === club[0].id)

  const administratorUsers = []
  usersX.forEach(e => {
    const userAux = dataUsers.users.filter(item => item.id === e.user_id)

    const userObj = {
      id: userAux[0].id,
      name: userAux[0].fullName,
    }

    administratorUsers.push(userObj)
  })

  const indexCountry = dataCountries.findIndex(element => element.id === club[0].country_id)
  const indexCity = dataCities.findIndex(element => element.id === club[0].city_id)

  // buscar sociedades con relación al club
  const auxSocieties = dataSocieties.filter(item => item.club_id == club[0].id && item.is_current == 1 && item.status == 1)
  // buscar apoderados con relación al club
  const auxAssignRep = dataAssignRepresentative.filter(item => item.club_id == club[0].id)

  club[0].administrators = administratorUsers
  club[0].country = dataCountries[indexCountry]
  club[0].city = dataCities[indexCity]
  club[0].hasSocieties = auxSocieties.length > 0
  club[0].hasRepresentatives = auxAssignRep.length > 0

  return [200, club[0]]
})

mock.onPost('/clubs-info').reply(config => {
  const dataClubRequest = JSON.parse(config.data)

  const indexCl = data.findIndex(element => element.id == dataClubRequest.club_id)
  data[indexCl].administrator_id = dataClubRequest.administrator_id

  const clubData = {
    index: indexCl,
    data: data[indexCl],
  }

  return [200, clubData]
})

mock.onGet(/\/clubs-info\/\d+\/users\/\d+/).reply(config => {
  const clubId = config.url.split('/')[2]
  const userId = config.url.split('/')[4]

  // get data user
  let dataUser = dataUsers.users.filter(item => (item.id == userId))

  // get job position
  const filterJPosition = dataJPosition.filter(item => item.sport_club_id == clubId)

  // get name job position
  let auxJobPosition = null
  filterJPosition.forEach(item => {
    if (dataJPositionUser.filter(itemB => itemB.job_position_id == item.id && itemB.user_id == dataUser[0].id)) {
      auxJobPosition = item
    }
  })

  dataUser[0].job_position = auxJobPosition

  return [200, dataUser[0]]
})

mock.onPost('/clubs/new-register').reply(config => {
  let auxClubId = 1
  const dataClubRequest = JSON.parse(config.data)

  // buscar el club actual --------------------------------------------------
  const indexCl = data.findIndex(element => element.id == dataClubRequest.club.id)
  // actualizar datos del club actual
  data[indexCl] = dataClubRequest.club

  // agregar sociedaddes --------------------------------------------------
  if (dataClubRequest.hasSocieties) { // validar si ya existen sociedades
    // buscar la sociedad
    const currentSociety = dataSocieties.filter(item => item.club_id == dataClubRequest.club.id && item.is_current == 1 && item.status == 1)
    // actualizar los datos de la sociedad

    // datos del instrumento

    // datos de los socios
  } else { // crear nueva sociedad relacionando el id del club
    // respaldar y eliminar propiead socios
    const backPartners = dataClubRequest.society.partners
    delete dataClubRequest.society.partners

    // inicia agregar instrumento
    let instrumentId = 1

    if (dataInstruments.length > 0) {
      while (dataInstruments.findIndex(a => a.id === instrumentId) !== -1) {
        instrumentId = Math.floor(Math.random() * 1000) + 5
      }
    }

    dataClubRequest.society.instrument.id = instrumentId
    dataClubRequest.society.instrument.club_id = dataClubRequest.society.club_id

    dataInstruments.push(dataClubRequest.society.instrument)
    delete dataClubRequest.society.instrument
    // finalizar agregar instrumento

    // inicia agregar sociedad
    let auxSoId = 1

    if (dataSocieties.length > 0) {
      while (dataSocieties.findIndex(b => b.id === auxSoId) !== -1) {
        auxSoId = Math.floor(Math.random() * 1000) + 5
      }

      // const auxIndex = dataSocieties.findIndex(el => el.club_id === dataClubRequest.society.club_id && el.is_current == 1)
      //
      // if (auxIndex != -1) {
      //   dataSocieties[auxIndex].is_current = 0
      // }
    }

    dataClubRequest.society.id = auxSoId
    dataClubRequest.society.instrument_number_id = instrumentId
    dataClubRequest.society.created_at = new Date()

    dataSocieties.push(dataClubRequest.society)
    // finaliza agregar sociedad

    // inicia agregar socios
    backPartners.forEach(cc => {
      let partnerId = cc.id

      if (dataPartners.length > 0) {
        while (dataPartners.findIndex(c => c.id === partnerId) !== -1) {
          partnerId = Math.floor(Math.random() * 1000) + 5
        }
      }

      const auxObjP = {
        id: partnerId,
        name: cc.name,
        percentage: cc.percentage,
        society_id: auxSoId,
        created_at: new Date(),
        status: 1,
      }

      dataPartners.push(auxObjP)
    })
    // finaliza agregar socios
  }

  // crear nueva asignación de apoderados --------------------------------------------------
  let auxId = 1

  if (dataAssignRepresentative.length > 0) {
    while (dataAssignRepresentative.findIndex(d => d.id == auxId) != -1) {
      auxId = Math.floor(Math.random() * 1000) + 5
    }

    /* // buscar registro actual de asignacion de apoderados
    const auxIndex = dataAssignRepresentative.findIndex(el => el.club_id == dataClubRequest.representative.club_id && el.status == 1 && el.is_current == 1)

    // modificar valor de registro asignacion de apoderados a no actual
    if (auxIndex != -1) {
      dataAssignRepresentative[auxIndex].is_current = 0
    } */
  }

  // crear registro asignar apoderados
  const backRepresentativeRequest = {
    id: auxId,
    club_id: dataClubRequest.representative.club_id,
    has_many_representatives: dataClubRequest.representative.has_many_representatives,
    undefined_date: dataClubRequest.representative.undefined_date,
    date_range: dataClubRequest.representative.date_range,
    status: dataClubRequest.representative.status,
    is_current: dataClubRequest.representative.is_current,
  }
  dataAssignRepresentative.push(backRepresentativeRequest)

  // asignar numero de instrumento
  let ariId = 1

  if (dataARI.length > 0) {
    while (dataARI.findIndex(f => f.id == ariId) != -1) {
      ariId = Math.floor(Math.random() * 1000) + 5
    }
  }

  const auxARI = {
    id: ariId,
    assign_representative_id: auxId,
    instrument_number_id: dataClubRequest.society.instrument_number_id,
  }

  dataARI.push(auxARI)

  // asignar poderes otorgados
  dataClubRequest.representative.powers_granted.forEach(itemB => {
    let arpgId = 1

    if (dataARPG.length > 0) {
      while (dataARPG.findIndex(g => g.id == arpgId) != -1) {
        arpgId = Math.floor(Math.random() * 1000) + 5
      }
    }

    const auxARPG = {
      id: arpgId,
      assign_representative_id: auxId,
      power_granted_id: itemB,
      special_powers_granted: itemB == 0 ? dataClubRequest.representative.special_powers_granted : null,
    }

    dataARPG.push(auxARPG)
  })

  // asignar socio (1 solo registro)
  if (dataClubRequest.representative.representative_id != null) {
    let arprOneId = 1
    let partnerIdN = null

    if (dataARPR.length > 0) {
      while (dataARPR.findIndex(h => h.id == arprOneId) != -1) {
        arprOneId = Math.floor(Math.random() * 1000) + 5
      }
    }
    // agregar socio
    if (!dataClubRequest.hasSocieties) { // validar si ya existen sociedades
      const indexPart = dataPartners.filter(i => i.name == dataClubRequest.representative.representative_id)
      partnerIdN = indexPart[0].id
    }

    const auxARPROne = {
      id: arprOneId,
      assign_representative_id: auxId,
      partner_id: dataClubRequest.hasSocieties ? dataClubRequest.representative.representative_id : partnerIdN,
      representative_id: null,
    }

    dataARPR.push(auxARPROne)
  }

  // agregar otro apoderado (1 solo registro)
  if (dataClubRequest.representative.other_representative != null) {
    let otherOneId = 1

    if (dataRepresentatives.length > 0) {
      while (dataRepresentatives.findIndex(j => j.id == otherOneId) != -1) {
        otherOneId = Math.floor(Math.random() * 1000) + 5
      }

      // buscar nombre de apoderado
      const otherOneR = dataRepresentatives.filter(k => k.name == dataClubRequest.representative.other_representative && el.club_id == dataClubRequest.representative.club_id)

      if (otherOneR.length > 0) {
        // si el apoderado existe solo se obtiene el id del registro existente, para no duplicar datos
        otherOneId = otherOneR[0].id
      } else {
        // se crea nuevo representante
        const otherObj = {
          id: otherOneId,
          name: dataClubRequest.representative.other_representative,
          club_id: dataClubRequest.representative.club_id,
        }
        dataRepresentatives.push(otherObj)
      }
    } else {
      // se crea nuevo representante
      const otherObj = {
        id: otherOneId,
        name: dataClubRequest.representative.other_representative,
        club_id: dataClubRequest.representative.club_id,
      }
      dataRepresentatives.push(otherObj)
    }

    let arprOneXId = 1

    if (dataARPR.length > 0) {
      while (dataARPR.findIndex(l => l.id == arprOneXId) != -1) {
        arprOneXId = Math.floor(Math.random() * 1000) + 5
      }
    }

    const auxARPROneX = {
      id: arprOneXId,
      assign_representative_id: auxId,
      partner_id: null,
      representative_id: otherOneId,
    }

    dataARPR.push(auxARPROneX)
  }

  // asignar n numero de socios
  if (dataClubRequest.representative.representatives.length) {
    // asignar mas de un socio
    dataClubRequest.representative.representatives.forEach(itemC => {
      let arprId = 1

      if (dataARPR.length > 0) {
        while (dataARPR.findIndex(m => m.id == arprId) != -1) {
          arprId = Math.floor(Math.random() * 1000) + 5
        }
      }

      const auxARPR = {
        id: arprId,
        assign_representative_id: auxId,
        partner_id: itemC,
        representative_id: null,
      }

      dataARPR.push(auxARPR)
    })
  }

  // asignar n numero de registros (otros apoderados)
  if (dataClubRequest.representative.other_representatives.length) {
    // asignar mas de un apoderado
    dataClubRequest.representative.other_representatives.forEach(itemCA => {
      // crear nuevo apoderado
      let otherId = 1

      if (dataRepresentatives.length > 0) {
        while (dataRepresentatives.findIndex(n => n.id == otherId) != -1) {
          otherId = Math.floor(Math.random() * 1000) + 5
        }
        // buscar nombre de apoderado
        const otherR = dataRepresentatives.filter(o => o.name == itemCA && o.club_id == dataClubRequest.representative.club_id)

        if (otherR.length > 0) {
          // si el apoderado existe solo se obtiene el id del registro existente, para no duplicar datos
          otherId = otherR[0].id
        } else {
          // se crea nuevo representante
          const otherObj = {
            id: otherId,
            name: itemCA,
            club_id: dataClubRequest.representative.club_id,
          }
          dataRepresentatives.push(otherObj)
        }
      } else {
        // se crea nuevo representante
        const otherObj = {
          id: otherId,
          name: itemCA,
          club_id: dataClubRequest.representative.club_id,
        }
        dataRepresentatives.push(otherObj)
      }

      // asignar datos en relacion asignar_representante_socio_representante
      let arproId = 1

      if (dataARPR.length > 0) {
        while (dataARPR.findIndex(p => p.id == arproId) != -1) {
          arproId = Math.floor(Math.random() * 1000) + 5
        }
      }

      const auxoARPR = {
        id: arproId,
        assign_representative_id: auxId,
        partner_id: null,
        representative_id: otherId,
      }

      dataARPR.push(auxoARPR)
    })
  }

  /* const inserts = {
    dataAssignRepresentative,
    dataARI,
    dataARPG,
    dataRepresentatives,
    dataARPR,
  } */

  return [200, { message: 'El club se ha creado correctamente.', clubData: data }]
})

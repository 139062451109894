import { RouterView } from '@/router/router-view'

export default [
  {
    path: '/roles',
    component: RouterView,
    props: route => ({ id: route.name }),
    children: [
      {
        path: '',
        name: 'roles',
        component: () => import('@/views/roles/rolesList/RolesList.vue'),
        meta: {
          navActiveLink: 'roles',
          redirectIfProfileIsIncomplete: true,
          redirectIfClubIsIncomplete: true,
        },
      },
      {
        path: ':id/permissions',
        name: 'permissions',
        component: () => import('@/views/roles/permissions/PermissionsList.vue'),
        props: true,
        meta: {
          navActiveLink: 'roles',
          redirectIfProfileIsIncomplete: true,
          redirectIfClubIsIncomplete: true,
        },
      },
    ],
  },
  {
    path: '/users',
    component: RouterView,
    props: route => ({ id: route.name }),
    children: [
      {
        path: '',
        name: 'users',
        component: () => import('@/views/users/UsersList.vue'),
        meta: {
          navActiveLink: 'roles',
          redirectIfProfileIsIncomplete: true,
          redirectIfClubIsIncomplete: true,
        },
      },
      {
        path: ':id/permissions',
        name: 'users-permissions',
        component: () => import('@/views/users/Permissions.vue'),
        props: true,
        meta: {
          navActiveLink: 'roles',
          redirectIfProfileIsIncomplete: true,
          redirectIfClubIsIncomplete: true,
        },
      },
    ],
  },
  {
    path: '/job-position',
    name: 'job-position',
    component: () => import('@/views/job-position/Index.vue'),
    meta: {
      navActiveLink: 'roles',
      redirectIfProfileIsIncomplete: true,
      redirectIfClubIsIncomplete: true,
    },
  },
  {
    path: '/news',
    component: RouterView,
    props: route => ({ id: route.name }),
    children: [
      {
        path: '',
        name: 'news',
        // component: () => import('@/views/news/management/Index.vue'),
        component: () => import('@/views/news/management/PostsList.vue'),
        meta: {
          navActiveLink: 'roles',
          redirectIfProfileIsIncomplete: true,
          redirectIfClubIsIncomplete: true,
        },
      },
      {
        path: 'edit/:id',
        name: 'new-edit',
        component: () => import('@/views/news/management/NewEdit.vue'),
        meta: {
          navActiveLink: 'roles',
          redirectIfProfileIsIncomplete: true,
          redirectIfClubIsIncomplete: true,
        },
      },
      {
        path: 'add',
        name: 'new-add',
        component: () => import('@/views/news/management/NewEdit.vue'),
        meta: {
          navActiveLink: 'roles',
          redirectIfProfileIsIncomplete: true,
          redirectIfClubIsIncomplete: true,
        },
      },
      {
        path: '/posts',
        component: RouterView,
        props: route => ({ id: route.name }),
        children: [
          {
            path: '',
            name: 'new-posts',
            component: () => import('@/views/news/management/PostsList.vue'),
            meta: {
              navActiveLink: 'roles',
              redirectIfProfileIsIncomplete: true,
              redirectIfClubIsIncomplete: true,
            },
          },
          {
            path: 'details/:id',
            name: 'new-details',
            component: () => import('@/views/news/management/NewDetails.vue'),
            meta: {
              navActiveLink: 'roles',
              redirectIfProfileIsIncomplete: true,
              redirectIfClubIsIncomplete: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/public-news/:id',
    name: 'public-news',
    component: () => import('@/views/news/PostShare.vue'),
    meta: {
      layout: 'full',
      action: 'read',
      forgot: true,
    },
    props: true,
  },
  {
    path: '/todo',
    name: 'todo',
    component: () => import('@/views/todo/Todo.vue'),
    meta: {
      navActiveLink: 'roles',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/todo/:filter',
    name: 'todo-filter',
    component: () => import('@/views/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'roles',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/todo/tag/:tag',
    name: 'todo-tag',
    component: () => import('@/views/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'roles',
    },
    // beforeEnter(to, _, next) {
    //   if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
    //   else next({ name: 'error-404' })
    // },
  },
  {
    path: '/logs',
    component: RouterView,
    meta: {
      redirectIfProfileIsIncomplete: true,
    },
    children: [
      /* ----------- logs ----------- */
      {
        path: '',
        name: 'logs-info',
        component: () => import('@/views/info/LogInfo.vue'),
        meta: {
          redirectIfProfileIsIncomplete: true,
        },
      },
    ],
  },
]

import mock from '@/@fake-db/mock'

import { paginateArray } from '@/@fake-db/utils'
import { data as dataTrademarkTypes } from '@/@fake-db/data/clubs/trademark-types'
import { data as dataRequestTypes } from '@/@fake-db/data/clubs/request-types'

export const data = []

mock.onGet('/clubs/trademarks').reply(() => [200, data])

//
mock.onGet(/\/clubs-aux\/\d+\/trademarks/).reply(config => {
  const clubIdX = config.url.split('/')[2]

  const { filters } = config.params

  const auxTrademarkData = []

  const currentTrademark = data.length > 0 ? data.filter(item => item.club_id == clubIdX && item.status == 1) : []
  const auxTrademarks = data.length > 0 ? data.filter(item => item.club_id == clubIdX && item.status == 0) : []

  const result = auxTrademarks.sort((a, b) => {
    if (a.created_at < b.created_at) {
      return 1
    }
    if (a.created_at > b.created_at) {
      return -1
    }
    // a must be equal to b
    return 0
  })

  const auxData = JSON.parse(JSON.stringify(paginateArray(result, filters.perPage, filters.page)))

  auxData.forEach(el => {
    const trademarkX = dataTrademarkTypes.filter(element => element.id === el.trademark_type_id)
    const requestX = dataRequestTypes.filter(element => element.id === el.request_type_id)

    const objTrademark = {
      id: el.id,
      clubId: el.club_id,
      requestTypeId: el.request_type_id,
      trademarkTypeId: el.trademark_type_id,
      classNumber: el.class_number,
      classDescription: el.class_description,
      denomination: el.denomination,
      registerNumber: el.register_number,
      dateOfConcession: el.date_of_concession,
      effectiveDate: el.effective_date,
      applicationForTheRegistration: el.application_for_the_registration,
      image: el.image,
      status: el.status,
      trademarkType: trademarkX[0],
      requestType: requestX[0],
    }
    auxTrademarkData.push(objTrademark)
  })

  return [200, {
    current: currentTrademark.length ? currentTrademark[0] : null,
    trademarks: auxTrademarkData,
    totalRecors: auxTrademarks.length,
    to: filters.perPage * (filters.page - 1) + auxTrademarks.length,
    from: filters.perPage * (filters.page - 1) + (auxTrademarks.length ? 1 : 0),
  }]
})

//
mock.onPost(/\/clubs-aux-save\/\d+\/trademarks/).reply(config => {
  let auxId = 1
  const dataTrademarkRequest = JSON.parse(config.data)

  if (data.length > 0) {
    while (data.findIndex(el => el.id === auxId) !== -1) {
      auxId = Math.floor(Math.random() * 1000) + 5
    }

    const auxIndex = data.findIndex(el => el.club_id === dataTrademarkRequest.club_id && el.status == 1)

    if (auxIndex != -1) {
      data[auxIndex].status = 0
    }
  }

  dataTrademarkRequest.id = auxId
  dataTrademarkRequest.created_at = new Date()
  data.push(dataTrademarkRequest)

  return [200, { message: 'La marca se ha creado correctamente.', trademarkData: data }]
})

//
mock.onGet(/\/clubs-aux-show\/\d+\/trademarks\/\d+/).reply(config => {
  const clubIdB = config.url.split('/')[2]
  const trademarkId = config.url.split('/')[4]

  let dataByClubBAndTrademarkId = data.filter(item => (item.id == trademarkId && item.club_id == clubIdB))
  const trademarkTypes = dataTrademarkTypes.filter(element => element.id === dataByClubBAndTrademarkId[0].trademark_type_id)
  const requestTypes = dataRequestTypes.filter(element => element.id === dataByClubBAndTrademarkId[0].request_type_id)

  const trademarkArray = [
    {
      id: dataByClubBAndTrademarkId[0].id,
      clubId: dataByClubBAndTrademarkId[0].club_id,
      requestTypeId: dataByClubBAndTrademarkId[0].request_type_id,
      trademarkTypeId: dataByClubBAndTrademarkId[0].trademark_type_id,
      classNumber: dataByClubBAndTrademarkId[0].class_number,
      classDescription: dataByClubBAndTrademarkId[0].class_description,
      denomination: dataByClubBAndTrademarkId[0].denomination,
      registerNumber: dataByClubBAndTrademarkId[0].register_number,
      dateOfConcession: dataByClubBAndTrademarkId[0].date_of_concession,
      effectiveDate: dataByClubBAndTrademarkId[0].effective_date,
      applicationForTheRegistration: dataByClubBAndTrademarkId[0].application_for_the_registration,
      image: dataByClubBAndTrademarkId[0].image,
      status: dataByClubBAndTrademarkId[0].status,
      trademarkType: trademarkTypes[0],
      requestType: requestTypes[0],
    },
  ]

  return [200, trademarkArray[0]]
})

// import axios from '@axios'
import servicesCountry from '@/services/countryService'

export default {
  state: {
    countries: [],
    states: [],
  },

  mutations: {
    setCountries(state, value) {
      state.countries = value
    },
    setStates(state, value) {
      state.states = value
    },
  },

  actions: {
    fetchCountries({ commit }) {
      return new Promise((resolve, reject) => {
        servicesCountry.getCountries().then(response => {
          commit('setCountries', response.data.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    fetchStateByCountryId({ commit }, countryIdHash) {
      return new Promise((resolve, reject) => {
        servicesCountry.getStates({ country_hash: countryIdHash }).then(response => {
          commit('setStates', response.data.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },

  getters: {
    countries(state) {
      const list = state.countries.map(c => ({ id: c.hash, name: c.labelables[0].label }))
      return list
    },
    states(state) {
      const list = state.states.map(c => ({ id: c.hash, name: c.name }))
      return list
    },
  },
}

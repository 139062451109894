/* eslint-disable no-param-reassign */
import mock from '@/@fake-db/mock'
import checkPermission from '@/auth/permissions'
import { paginateArray } from '@/@fake-db/utils'
import { data as dataClubes } from '@/@fake-db/data/clubs/club-setting'
import { data as dataCountries } from '@/@fake-db/data/catalogs/countries'
import { data as dataCities } from '@/@fake-db/data/catalogs/cities'
import dataJersey from '@/@fake-db/data/catalogs/jersey'
import dataUserClub from '@/@fake-db/data/clubs/club-user'
import ostrail from '../clubs/ostrail'

const data = [
  {
    id: 1,
    name: 'sub-7 y sub-8: Pre-Benjamín FCB',
    number_participants: 100,
    number_participants_min: 301,
    number_participants_max: 401,
    excluded_numbers: [306, 366],
    sport_club_id: 1,
    create_at: '2020-02-21 11:35:15',
    status: 1,
  },
  {
    id: 2,
    name: 'sub-9 y sub-10: Benjamín FCB',
    number_participants: 49,
    number_participants_min: 251,
    number_participants_max: 300,
    excluded_numbers: [258, 288],
    sport_club_id: 1,
    create_at: '2022-02-21 11:25:10',
    status: 0,
  },
  {
    id: 3,
    name: 'sub-11 sub-12: Alevín',
    number_participants: 49,
    number_participants_min: 201,
    number_participants_max: 250,
    excluded_numbers: [210, 240],
    sport_club_id: 1,
    create_at: '2022-02-21 11:22:15',
    status: 1,
  },
  {
    id: 4,
    name: 'sub-13 y sub-14: Infantil',
    number_participants: 49,
    number_participants_min: 151,
    number_participants_max: 200,
    excluded_numbers: [188, 195],
    sport_club_id: 1,
    create_at: '2022-02-20 11:22:15',
    status: 1,
  },
  {
    id: 5,
    name: 'sub-15 y sub-16: Cadete',
    number_participants: 50,
    number_participants_min: 100,
    number_participants_max: 150,
    excluded_numbers: [120, 145],
    sport_club_id: 1,
    create_at: '2022-02-20 11:22:15',
    status: 1,
  },
  {
    id: 6,
    name: 'sub-17, sub-18 y sub-19: Juvenil',
    number_participants: 49,
    number_participants_min: 51,
    number_participants_max: 100,
    excluded_numbers: [55, 77],
    sport_club_id: 1,
    create_at: '2022-02-20 11:22:15',
    status: 1,
  },
  {
    id: 7,
    name: 'Absoluta: Sénior',
    number_participants: 50,
    number_participants_min: 1,
    number_participants_max: 50,
    excluded_numbers: [2, 48],
    sport_club_id: 1,
    create_at: '2022-02-20 11:22:15',
    status: 1,
  },
  {
    id: 8,
    name: 'sub-7 y sub-8: Pre-Benjamín Athletic',
    number_participants: 100,
    number_participants_min: 301,
    number_participants_max: 401,
    excluded_numbers: [308, 351],
    sport_club_id: 2,
    create_at: '2020-02-21 11:35:15',
    status: 1,
  },
  {
    id: 9,
    name: 'sub-9 y sub-10: Benjamín Athletic',
    number_participants: 49,
    number_participants_min: 251,
    number_participants_max: 300,
    excluded_numbers: [258, 277],
    sport_club_id: 2,
    create_at: '2022-02-21 11:25:10',
    status: 0,
  },
  {
    id: 10,
    name: 'sub-11 sub-12: Alevín',
    number_participants: 49,
    number_participants_min: 201,
    number_participants_max: 250,
    excluded_numbers: [230, 248],
    sport_club_id: 2,
    create_at: '2022-02-21 11:22:15',
    status: 1,
  },
  {
    id: 11,
    name: 'sub-13 y sub-14: Infantil',
    number_participants: 49,
    number_participants_min: 151,
    number_participants_max: 200,
    excluded_numbers: [160, 169],
    sport_club_id: 2,
    create_at: '2022-02-20 11:22:15',
    status: 1,
  },
  {
    id: 12,
    name: 'sub-15 y sub-16: Cadete',
    number_participants: 50,
    number_participants_min: 100,
    number_participants_max: 150,
    excluded_numbers: [110, 115],
    sport_club_id: 2,
    create_at: '2022-02-20 11:22:15',
    status: 1,
  },
  {
    id: 13,
    name: 'sub-17, sub-18 y sub-19: Juvenil',
    number_participants: 49,
    number_participants_min: 51,
    number_participants_max: 100,
    excluded_numbers: [53, 55],
    sport_club_id: 2,
    create_at: '2022-02-20 11:22:15',
    status: 1,
  },
  {
    id: 14,
    name: 'Absoluta: Sénior',
    number_participants: 50,
    number_participants_min: 1,
    number_participants_max: 50,
    excluded_numbers: [3, 46],
    sport_club_id: 2,
    create_at: '2022-02-20 11:22:15',
    status: 1,
  },

  // Tercer registro
]

mock.onGet('/teams').reply(({ params }) => {
  // let dataList = paginateArray(data, params.filters.perPage, params.filters.page)
  let dataList = data

  dataList.forEach(team => {
    if (team.sport_club_id != null) {
      const clubData = dataClubes.filter(sportClub => sportClub.id === team.sport_club_id)
      team.sport_club = clubData.length > 0 ? clubData[0] : null
    }
    // if (team.number_participants != null) {
    //   const jerseyData = dataJersey.filter(item => item.team_id === team.id)
    //   tea
    // }
    if (team.sport_club_id == null) {
      team.sport_club = ostrail
    }
    if (team.sport_club != null) {
      const indexCountry = dataCountries.findIndex(element => element.id === team.sport_club.country_id)
      const indexCity = dataCities.findIndex(element => element.id === team.sport_club.city_id)
      team.sport_club.country = dataCountries[indexCountry]
      team.sport_club.city = dataCities[indexCity]
    }
  })
  // Filter search
  const filter = dataList
  if (params.filters.search != null) {
    dataList = filter.filter(item => item.name.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }
  if (params.filters.search != null && dataList.length === 0) {
    const filterClub = filter.filter(item => item.sport_club_id != null)
    dataList = filterClub.filter(item => item.sport_club.club_name.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }
  if (params.filters.search != null && dataList.length === 0) {
    const filterCreate = filter.filter(item => item.create_at != null)
    dataList = filterCreate.filter(item => item.create_at.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }

  // Filter by member auth
  const userData = JSON.parse(localStorage.getItem('userData'))
  if (!checkPermission(['ALLOW_ALL'])) {
    const filterClub = dataUserClub.filter(item => item.user_id === Number(userData.id))
    dataList = dataList.filter(item => filterClub.find(e => e.sport_club_id === item.sport_club_id))
  }

  // Filter country
  if (params.filters.idCountry != null) {
    const dataFilter = dataList.filter(item => item.sport_club_id !== null)
    dataList = dataFilter.filter(item => item.sport_club.country_id === params.filters.idCountry)
  }

  // Filter state
  if (params.filters.idState != null) {
    const dataFilter = dataList.filter(item => item.sport_club_id !== null)
    dataList = dataFilter.filter(item => item.sport_club.city_id === params.filters.idState)
  }

  // Filter club
  if (params.filters.idClub != null) {
    const dataFilter = dataList.filter(item => item.sport_club_id !== null)
    dataList = dataFilter.filter(item => item.sport_club_id === params.filters.idClub)
  }

  // Filter status
  if (params.filters.status == null) {
    dataList = dataList.filter(item => item.status !== 2)
  }
  if (params.filters.status === 0) {
    dataList = dataList.filter(item => item.status === 0)
  }
  if (params.filters.status === 1) {
    dataList = dataList.filter(item => item.status === 1)
  }

  if (params.filters.status === 2) {
    dataList = dataList.filter(item => item.status === 2)
  }

  const totalItems = dataList.length

  dataList = paginateArray(dataList, params.filters.perPage, params.filters.page)

  return [200, {
    data: dataList,
    totalRecors: totalItems,
    to: params.filters.perPage * (params.filters.page - 1) + dataList.length,
    from: params.filters.perPage * (params.filters.page - 1) + (dataList.length ? 1 : 0),
  }]
})
// ------------------------------------------------
// POST: Add new team
// ------------------------------------------------
mock.onPost('/teams').reply(config => {
  // Get event from post data
  const team = JSON.parse(config.data)

  team.id = Date.now()
  team.create_at = new Date()
  data.unshift(team)

  let index = team.number_participants_min
  for (index; index <= team.number_participants_max; index += 1) {
    // const exclud = team.excluded_numbers.filter(item => item.id ===)
    dataJersey.push({
      id: Date.now() + index,
      label: String(index),
      value: index,
      status: team.excluded_numbers.includes(index) ? 2 : 1,
      team_id: team.id,
    })
  }

  return [200, team]
})

// ------------------------------------------------
// POST: update team
// ------------------------------------------------
mock.onPut('/teams').reply(config => {
  // Get event from post data
  const team = JSON.parse(config.data)
  const index = data.findIndex(element => element.id === team.id)
  data[index] = team

  return [200, team]
})

// ------------------------------------------------
// POST: delete team
// ------------------------------------------------
mock.onPost('/teams/delete').reply(config => {
  // Get event from post data
  const team = JSON.parse(config.data)
  const index = data.findIndex(element => element.id === Number(team.id))
  data.splice(index, 1)

  return [200, team]
})

mock.onGet('/teams/list').reply(() => {
  const teams = data.filter(item => item.sport_club_id == null)
  return [200, teams]
})

mock.onGet(/\/teams\/\d+/).reply(config => {
  const clubId = config.url.split('/')[2]

  const teams = data.filter(item => item.sport_club_id === Number(clubId))

  return [200, teams]
})

export default data

export default [
  {
    path: '/warehouse',
    name: 'warehouse',
    component: () => import('@/views/partials/pages/UnderConstruction.vue'),
    meta: {
      redirectIfProfileIsIncomplete: true,
      redirectIfClubIsIncomplete: true,
    },
  },
]

/* eslint-disable no-param-reassign */
import mock from '@/@fake-db/mock'
import jwt from 'jsonwebtoken'

import checkPermission from '@/auth/permissions'
import { paginateArray } from '@/@fake-db/utils'
import { data as dataClubes } from '@/@fake-db/data/clubs/club-setting'
import { data as dataRoles } from '@/@fake-db/data/roles/roles'
import { data as dataCountries } from '@/@fake-db/data/catalogs/countries'
import { data as dataCities } from '@/@fake-db/data/catalogs/cities'
import { roleModuleData as roleModulePermissionData } from '@/@fake-db/data/permissions/permissions'

import widgetList from '@/@fake-db/data/catalogs/widgetList'
import dataJobPosition from '@/@fake-db/data/job-position/job-position'
import dataUserClub from '@/@fake-db/data/clubs/club-user'
import dataRoleUser from '@/@fake-db/data/roles/role-user'
import dataJobPositionClub from '@/@fake-db/data/job-position/job-position-user'

const avatar1 = require('@/assets/images/avatars/peniche.jpeg')
const avatar2 = require('@/assets/images/avatars/13-small.png')
const avatar3 = require('@/assets/images/avatars/1-small.png')
const avatar4 = require('@/assets/images/avatars/6-small.png')
const avatar5 = require('@/assets/images/avatars/8-small.png')

// imgaeDasboard
const gamesPlayed = require('@/assets/images/dashboard/gamesPlayed.png')
const teamPerformance = require('@/assets/images/dashboard/teamPerformance.png')
const playerPerformance = require('@/assets/images/dashboard/playerPerformance.png')
const postNew = require('@/assets/images/dashboard/postNew.png')
const stadium = require('@/assets/images/dashboard/stadium.png')
const goalsFor = require('@/assets/images/dashboard/goalsFor.png')
const goalsAgainst = require('@/assets/images/dashboard/goalsAgainst.png')
const note = require('@/assets/images/dashboard/note.png')
const weather = require('@/assets/images/dashboard/weather.png')


const data = {
  users: [
    {
      // Current user data
      id: 1,
      avatar: avatar1,
      fullName: 'Ana Peniche',
      name: 'Ana',
      first_name: 'Peniche',
      last_name: null,
      gender_id: 0,
      email: 'ana.peniche@ostrail.com',
      password: '@089Ostrail',
      nationality_id: 110,
      phone: '5512345678',
      cell_phone: '5565985621',
      updated_password: true,
      agree_to_terms: true,
      address: {
        address: 'Dirección de prueba',
        country_id: 110,
        state_id: 1,
        zip_code: '00365',
      },
      sport_clubs: [],
      job_position_id: null,
      role_id: 2,
      status: 1,
      allPermissions: null,

      // last data
      role: 'super-admin',
      ability: [
        {
          action: 'manage',
          subject: 'all',
        },
      ],
      extras: {
        eCommerceCartItemsCount: 5,
      },
      dashboard: {
        widget: {
          xxs: [
            {
              x: 0, y: 0, w: 12, h: 2, i: '0', minW: 12, minH: 2, maxW: 12, maxH: 2,
            },
            // Clima
            {
              x: 0, y: 2, w: 12, h: 7, i: '7', minW: 12, minH: 7, maxW: 12, maxH: 7, title: 'Clima', status: 1, img: weather,
            },
            // Note
            {
              x: 0, y: 4, w: 12, h: 11, i: '8', minW: 12, minH: 11, maxW: 12, maxH: 11, title: 'Pendientes', status: 1, img: note,
            },
            // Stadim
            {
              x: 0, y: 6, w: 12, h: 4, i: '9', minW: 12, minH: 4, maxW: 12, maxH: 4, title: 'Estadio', status: 1, img: stadium,
            },
            // GamesPlayed
            {
              x: 0, y: 8, w: 12, h: 8, i: '10', minW: 12, minH: 8, maxW: 12, maxH: 8, title: 'Partidos jugados', status: 1, img: gamesPlayed,
            },
            // teamPerformance
            {
              x: 0, y: 10, w: 12, h: 5, i: '11', minW: 12, minH: 5, maxW: 12, maxH: 5, title: 'Desempeño de equipo', status: 1, img: teamPerformance,
            },
            // PlayerPerformance
            {
              x: 0, y: 12, w: 12, h: 5, i: '12', minW: 12, minH: 5, maxW: 12, maxH: 5, title: 'Desempeño de jugadores', status: 1, img: playerPerformance,
            },
            // post card
            {
              x: 0, y: 14, w: 12, h: 8, i: '13', minW: 12, minH: 8, maxW: 12, maxH: 8, title: 'Noticias', status: 1, img: postNew,
            },
            // goals for
            {
              x: 0, y: 16, w: 12, h: 4, i: '14', minW: 12, minH: 4, maxW: 12, maxH: 4, title: 'Goles a favor', status: 1, img: goalsFor,
            },
            // goals against
            {
              x: 0, y: 18, w: 12, h: 4, i: '15', minW: 12, minH: 4, maxW: 12, maxH: 4, title: 'Goles en contra', status: 1, img: goalsAgainst,
            },
          ],
          md: [
            {
              x: 0, y: 0, w: 3, h: 2, i: '0',
            },
            // Clima
            {
              x: 9, y: 0, w: 3, h: 8, i: '7', title: 'Clima', status: 1, img: weather,
            },
            // Note
            {
              x: 0, y: 2, w: 5, h: 11, i: '8', title: 'Pendientes', status: 1, img: note,
            },
            // Stadim
            {
              x: 0, y: 13, w: 10, h: 8, i: '9', title: 'Estadio', status: 1, img: stadium,
            },
            // GamesPlayed
            {
              x: 5, y: 0, w: 4, h: 8, i: '10', title: 'Partidos jugados', status: 1, img: gamesPlayed,
            },
            // teamPerformance
            {
              x: 0, y: 29, w: 10, h: 8, i: '11', title: 'Desempeño de equipo', status: 1, img: teamPerformance,
            },
            // PlayerPerformance
            {
              x: 0, y: 21, w: 10, h: 8, i: '12', title: 'Desempeño de jugadores', status: 1, img: playerPerformance,
            },
            // post card
            {
              x: 6, y: 37, w: 4, h: 8, i: '13', title: 'Noticias', status: 1, img: postNew,
            },
            // goals for
            {
              x: 0, y: 37, w: 6, h: 4, i: '14', title: 'Goles a favor', status: 1, img: goalsFor,
            },
            // goals against
            {
              x: 6, y: 8, w: 6, h: 4, i: '15', title: 'Goles en contra', status: 1, img: goalsAgainst,
            },
          ],
          lg: [
            {
              x: 0, y: 0, w: 2, h: 2, i: '0', minW: 2, minH: 2, maxW: 2, maxH: 2,
            },
            // Clima
            {
              x: 10, y: 0, w: 2, h: 8, i: '7', minW: 2, minH: 7, maxW: 3, maxH: 8, title: 'Clima', status: 1, img: weather,
            },
            // Note
            {
              x: 0, y: 2, w: 4, h: 11, i: '8', minW: 3, minH: 11, maxW: 5, maxH: 11, title: 'Pendientes', status: 1, img: note,
            },
            // Stadim
            {
              x: 4, y: 8, w: 6, h: 9, i: '9', minW: 6, minH: 9, maxW: 6, maxH: 9, title: 'Estadio', status: 1, img: stadium,
            },
            // GamesPlayed
            {
              x: 10, y: 8, w: 2, h: 8, i: '10', minW: 2, minH: 8, maxW: 2, maxH: 8, title: 'Partidos jugados', status: 1, img: gamesPlayed,
            },
            // teamPerformance
            {
              x: 0, y: 17, w: 5, h: 8, i: '11', minW: 5, minH: 8, maxW: 5, maxH: 8, title: 'Desempeño de equipo', status: 1, img: teamPerformance,
            },
            // PlayerPerformance
            {
              x: 4, y: 0, w: 5, h: 8, i: '12', minW: 5, minH: 8, maxW: 5, maxH: 8, title: 'Desempeño de jugadores', status: 1, img: playerPerformance,
            },
            // post card
            {
              x: 5, y: 17, w: 2, h: 8, i: '13', minW: 2, minH: 8, maxW: 2, maxH: 8, title: 'Noticias', status: 1, img: postNew,
            },
            // goals for
            {
              x: 0, y: 13, w: 3, h: 4, i: '14', minW: 3, minH: 4, maxW: 3, maxH: 4, title: 'Goles a favor', status: 1, img: goalsFor,
            },
            // goals against
            {
              x: 9, y: 17, w: 3, h: 4, i: '15', minW: 3, minH: 4, maxW: 3, maxH: 4, title: 'Goles en contra', status: 1, img: goalsAgainst,
            },
          ],
        },
      },
    },
    {
      // Current user data
      id: 2,
      avatar: avatar2,
      fullName: 'Roberto Ortega',
      name: 'Roberto',
      first_name: 'Ortega',
      last_name: null,
      gender_id: 1,
      email: 'admin@ostrail.com',
      password: '@00Ostrail',
      nationality_id: 110,
      phone: '5512345678',
      cell_phone: '5581375228',
      updated_password: true,
      agree_to_terms: true,
      address: {
        address: 'Dirección de prueba',
        country_id: 110,
        state_id: 2,
        zip_code: '00365',
      },
      sport_clubs: [],
      job_position_id: 1,
      role_id: 1,
      status: 1,
      allPermissions: null,

      // last data
      role: 'admin',
      ability: [
        {
          action: 'manage',
          subject: 'all',
        },
      ],
      extras: {
        eCommerceCartItemsCount: 5,
      },
      is_admin_club: 0,
    },
    {
      // Current user data
      id: 3,
      avatar: avatar3,
      fullName: 'Jaime Nuñez',
      name: 'Jaime',
      first_name: 'Nuñez',
      last_name: null,
      gender_id: 1,
      email: 'user@ostrail.com',
      password: '@00Ostrail',
      nationality_id: 110,
      phone: '5512345678',
      cell_phone: '5578324784',
      updated_password: true,
      agree_to_terms: true,
      address: {
        address: 'Dirección de prueba',
        country_id: 110,
        state_id: 3,
        zip_code: '00365',
      },
      sport_clubs: [],
      job_position_id: 2,
      role_id: 3,
      status: 1,
      allPermissions: null,

      // last data
      role: 'user',
      ability: [
        /* {
          action: 'read',
          subject: 'ACL',
        },
        {
          action: 'read',
          subject: 'Auth',
        }, */
        {
          action: 'manage',
          subject: 'all',
        },
      ],
      extras: {
        eCommerceCartItemsCount: 5,
      },
    },
    {
      // Current user data
      id: 4,
      avatar: avatar4,
      fullName: 'John Lennox',
      name: 'John',
      first_name: 'Lennox',
      last_name: 'Walker',
      gender_id: 1,
      email: 'john@ostrail.com',
      password: '@00Ostrail',
      nationality_id: 110,
      phone: '5512345678',
      cell_phone: '5556784307',
      updated_password: true,
      agree_to_terms: true,
      address: {
        address: 'Dirección de prueba',
        country_id: 110,
        state_id: 1,
        zip_code: '00365',
      },
      sport_clubs: [],
      job_position_id: 2,
      role_id: 6,
      status: 1,
      allPermissions: null,

      // last data
      role: 'user',
      ability: [
        /* {
          action: 'read',
          subject: 'ACL',
        },
        {
          action: 'read',
          subject: 'Auth',
        }, */
        {
          action: 'manage',
          subject: 'all',
        },
      ],
      extras: {
        eCommerceCartItemsCount: 5,
      },
      is_admin_club: 1,
    },
    {
      // Current user data
      id: 5,
      avatar: avatar5,
      fullName: 'Pablo Olvera',
      name: 'Pablo',
      first_name: 'Olvera',
      last_name: 'Walker',
      gender_id: 1,
      email: 'pablo@club.com',
      password: '@00Ostrail',
      nationality_id: 110,
      phone: '5512345678',
      cell_phone: '5520010130',
      updated_password: true,
      agree_to_terms: true,
      address: {
        address: 'Dirección de prueba',
        country_id: 110,
        state_id: 1,
        zip_code: '00365',
      },
      sport_clubs: [],
      job_position_id: 2,
      role_id: 7,
      status: 1,
      allPermissions: null,

      // last data
      role: 'user',
      ability: [
        /* {
          action: 'read',
          subject: 'ACL',
        },
        {
          action: 'read',
          subject: 'Auth',
        }, */
        {
          action: 'manage',
          subject: 'all',
        },
      ],
      extras: {
        eCommerceCartItemsCount: 5,
      },
      is_admin_club: 0,
    },
  ],
}

export default data

// ! These two secrets shall be in .env file and not in any other file
const jwtConfig = {
  secret: 'dd5f3089-40c3-403d-af14-d0c228b05cb4',
  refreshTokenSecret: '7c4c1c50-3230-45bf-9eae-c9b2e401c767',
  expireTime: '10m',
  refreshTokenExpireTime: '10m',
}

mock.onPost('/jwt/login').reply(request => {
  const { email, password } = JSON.parse(request.data)

  let error = {
    email: ['Something went wrong'],
  }

  let user = data.users.find(u => u.email === email && u.password === password)

  // obtener clubs relacionados al usuario
  const filterClub = dataUserClub.filter(item => item.user_id === Number(user.id))

  let auxSportClubs = []
  filterClub.forEach(e => {
    auxSportClubs.push(dataClubes.filter(item => item.id === Number(e.sport_club_id)))
  })

  const allSportClubs = auxSportClubs.flat()
  // obtener clubs relacionados al usuario

  if (user) {
    try {
      const accessToken = jwt.sign({ id: user.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })
      const refreshToken = jwt.sign({ id: user.id }, jwtConfig.refreshTokenSecret, {
        expiresIn: jwtConfig.refreshTokenExpireTime,
      })

      const userData = { ...user }

      // Roles
      const filterRoleUser = dataRoleUser.filter(item => item.user_id === Number(userData.id))
      user.roles = dataRoles.filter(item => filterRoleUser.find(e => e.role_id == item.id))

      user.roles.forEach(role => {
        const clubs = allSportClubs.filter(item => item.id === Number(role.sport_club_id))[0]
        role.sport_club = clubs || null
        if (role.sport_club) {
          const indexCountry = dataCountries.findIndex(element => element.id == clubs.country_id)
          const indexCity = dataCities.findIndex(element => element.id == clubs.city_id)
          role.sport_club.country = dataCountries[indexCountry]
          role.sport_club.city = dataCities[indexCity]
        }
      })

      // Role
      const roleFilter = user.roles.find(item => item.status === 1)
      userData.role = roleFilter

      // Jobs
      const jobs = dataJobPositionClub.filter(item => item.user_id === Number(userData.id))
      const dataJobP = dataJobPosition.filter(itemx => (itemx.id === Number(jobs.job_position_id)))
      userData.jobsPositions = dataJobP != null ? dataJobP : null

      userData.jobPosition = dataJobP.find(item => item.sport_club_id === user.role.sport_club_id)

      // TODO: permisos de ususario
      const roleModulePermision = roleModulePermissionData.find(item => item.role_id === userData.role.id)
      userData.allPermissions = roleModulePermision

      delete userData.password

      const response = {
        userData,
        accessToken,
        refreshToken,
      }

      return [200, response]
    } catch (e) {
      error = e
    }
  } else {
    error = {
      email: ['Email or Password is Invalid'],
    }
  }

  return [400, { error }]
})

mock.onPost('/jwt/register').reply(request => {
  const { username, email, password } = JSON.parse(request.data)

  // If not any of data is missing return 400
  if (!(username && email && password)) return [400]

  const isEmailAlreadyInUse = data.users.find(user => user.email === email)
  const isUsernameAlreadyInUse = data.users.find(user => user.username === username)

  const error = {
    password: !password ? ['Please enter password'] : null,
    email: (() => {
      if (!email) return ['Please enter your email.']
      if (isEmailAlreadyInUse) return ['This email is already in use.']
      return null
    })(),
    username: (() => {
      if (!username) return ['Please enter your username.']
      if (isUsernameAlreadyInUse) return ['This username is already in use.']
      return null
    })(),
  }

  if (!error.username && !error.email) {
    const userData = {
      email,
      password,
      username,
      fullName: '',
      avatar: null,
      role: 'admin',
      ability: [
        {
          action: 'manage',
          subject: 'all',
        },
      ],
    }

    // Add user id
    const { length } = data.users
    let lastIndex = 0
    if (length) {
      lastIndex = data.users[length - 1].id
    }
    userData.id = lastIndex + 1

    data.users.push(userData)

    const accessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })

    const user = { ...userData }
    delete user.password
    const response = {
      userData: user,
      accessToken,
    }

    return [200, response]
  }
  return [400, { error }]
})

mock.onPost('/jwt/refresh-token').reply(request => {
  const { refreshToken } = JSON.parse(request.data)

  try {
    const { id } = jwt.verify(refreshToken, jwtConfig.refreshTokenSecret)

    const userData = { ...data.users.find(user => user.id === id) }

    const newAccessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expiresIn })
    const newRefreshToken = jwt.sign({ id: userData.id }, jwtConfig.refreshTokenSecret, {
      expiresIn: jwtConfig.refreshTokenExpireTime,
    })

    delete userData.password
    const response = {
      userData,
      accessToken: newAccessToken,
      refreshToken: newRefreshToken,
    }

    return [200, response]
  } catch (e) {
    const error = 'Invalid refresh token'
    return [401, { error }]
  }
})

mock.onGet('/users').reply(({ params }) => {
  let dataList = paginateArray(data.users, params.filters.perPage, params.filters.page)
  dataList.forEach(user => {
    // Sport Club
    const filterUserClub = dataUserClub.filter(item => item.user_id === Number(user.id))
    user.sport_clubs = dataClubes.filter(item => filterUserClub.find(e => e.sport_club_id == item.id))

    // Role
    const filterRoleUser = dataRoleUser.filter(item => item.user_id === Number(user.id))
    user.roles = dataRoles.filter(item => filterRoleUser.find(e => e.role_id == item.id))
    // user.role = user.roles.find(item => item.)

    // user.roles.forEach(role => {
    //   const clubs = user.sport_clubs.filter(item => item.id === Number(role.sport_club_id))[0]
    //   role.club = clubs || null
    //   if (role.club) {
    //     const indexCountry = dataCountries.findIndex(element => element.id == clubs.country_id)
    //     const indexCity = dataCities.findIndex(element => element.id == clubs.city_id)
    //     role.club.country = dataCountries[indexCountry]
    //     role.club.city = dataCities[indexCity]
    //   }
    // })

    // Addres
    if (user.address != null) {
      const indexCountry = dataCountries.findIndex(element => element.id === user.address.country_id)
      const indexCity = dataCities.findIndex(element => element.id === user.address.state_id)
      user.address.country = dataCountries[indexCountry]
      user.address.city = dataCities[indexCity]
    }

    // fullName
    if (user.name != null) {
      user.fullName = `${user.name} ${user.first_name}`
    }
  })

  const userData = JSON.parse(localStorage.getItem('userData'))
  if (!checkPermission(['ALLOW_ALL'])) {
    const filterClub = dataUserClub.filter(item => item.user_id === Number(userData.id))
    dataList = dataList.filter(item => filterClub.find(e => item.roles.find(el => el.sport_club_id == e.sport_club_id)))
  }

  // Filter search
  const filter = dataList
  if (params.filters.search != null) {
    dataList = filter.filter(item => item.name.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }
  if (params.filters.search != null && dataList.length === 0) {
    dataList = filter.filter(item => item.email.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }
  if (params.filters.search != null && dataList.length === 0) {
    const filterRole = filter.filter(item => item.roles != null)
    dataList = filterRole.filter(item => item.roles.name.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }
  // TODO: Revisar
  // if (params.filters.search != null && dataList.length === 0) {
  //   const filterRole = filter.filter(item => item.sport_clubs != null)
  //   dataList = filterRole.filter(item => item.sport_clubs.club_name.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  // }

  // Filter club
  if (params.filters.idClub != null) {
    const dataFilter = dataList.filter(item => item.roles.length !== 0)
    dataList = dataFilter.filter(item => item.roles.find(e => e.sport_club_id == Number(params.filters.idClub)))
    // dataList = dataFilter.filter(item => filterClub.find(e => item.roles.find(el => el.sport_club_id == e.sport_club_id)))
  }

  // Filter country
  if (params.filters.idCountry != null) {
    const dataFilter = dataList.filter(item => item.address != null)
    dataList = dataFilter.filter(item => item.address.country_id == params.filters.idCountry)
  }

  // Filter state
  if (params.filters.idState != null) {
    const dataFilter = dataList.filter(item => item.address != null)
    dataList = dataFilter.filter(item => item.address.state_id == params.filters.idState)
  }

  // Filter status
  if (params.filters.status == null) {
    dataList = dataList.filter(item => item.status !== 2)
  }
  if (params.filters.status == 0) {
    dataList = dataList.filter(item => item.status === 0)
  }
  if (params.filters.status == 1) {
    dataList = dataList.filter(item => item.status === 1)
  }

  if (params.filters.status == 2) {
    dataList = dataList.filter(item => item.status === 2)
  }

  return [200, {
    data: dataList,
    totalRecors: data.length,
    to: params.filters.perPage * (params.filters.page - 1) + dataList.length,
    from: params.filters.perPage * (params.filters.page - 1) + (dataList.length ? 1 : 0),
  }]
})

// Create new User
mock.onPost('/users').reply(config => {
  // Get event from post data
  const user = JSON.parse(config.data)

  user.id = Date.now()
  user.create_at = new Date()
  user.status = 1

  user.sport_clubs.forEach((item, index) => {
    dataUserClub.push({
      id: Date.now() + index,
      sport_club_id: item.sport_club_id,
      user_id: user.id,
    })
    dataRoleUser.push({
      id: Date.now() + index,
      role_id: item.role_id,
      user_id: user.id,
    })
    dataJobPositionClub.push({
      id: Date.now() + index,
      job_position_id: item.job_position_id,
      user_id: user.id,
    })
  })

  // Sport Club
  const filterUserClub = dataUserClub.filter(item => item.user_id === Number(user.id))
  user.sport_clubs = dataClubes.filter(item => filterUserClub.find(e => e.sport_club_id == item.id))

  // Role
  const filterRoleUser = dataRoleUser.filter(item => item.user_id === Number(user.id))
  user.roles = dataRoles.filter(item => filterRoleUser.find(e => e.role_id == item.id))

  // fullName
  if (user.name != null) {
    user.fullName = `${user.name} ${user.first_name}`
  }

  return [200, user]
})

// ------------------------------------------------
// PUT: update user
// ------------------------------------------------
mock.onPut('/users').reply(config => {
  // Get event from post data
  const user = JSON.parse(config.data)
  // fullName
  if (user.name != null) {
    user.fullName = `${user.name} ${user.first_name}`
  }
  const index = data.users.findIndex(element => element.id === user.id)
  data.users[index] = user

  return [200, user]
})

// ------------------------------------------------
// POST: delete rol
// ------------------------------------------------
mock.onPost('/users/delete').reply(config => {
  // Get event from post data
  const user = JSON.parse(config.data)
  const index = data.users.findIndex(element => element.id === user.id)
  data.users.splice(index, 1)

  return [200, user]
})

// TODO PErmissions
mock.onGet(/\/users\/\d+/).reply(config => {
  const userId = config.url.split('/')[2]

  const dataUser = data.users.filter(item => (item.id === Number(userId)))[0]

  // Get clubs users admin
  const filterClub = dataUserClub.filter(item => item.user_id === Number(dataUser.id))

  filterClub.forEach(e => {
    dataUser.sport_clubs.push(dataClubes.filter(item => item.id === Number(e.sport_club_id)))
  })
  // End get clubs users admin

  const jobs = dataJobPositionClub.filter(item => item.user_id === Number(dataUser.id))
  const dataJobP = dataJobPosition.filter(itemx => (itemx.id === Number(jobs.job_position_id)))
  dataUser.jobsPositions = dataJobP != null ? dataJobP : null

  dataUser.jobPosition = dataJobP.find(item => item.status === 1)

  // Roles
  const filterRoleUser = dataRoleUser.filter(item => item.user_id === Number(dataUser.id))
  const roles = dataRoles.filter(item => filterRoleUser.find(e => e.role_id == item.id))

  // Role
  const role = roles.find(item => item.status === 1)

  // Club de rol
  role.sport_club = dataClubes.filter(item => item.id === Number(role.sport_club_id))
  const indexCountry = dataCountries.findIndex(element => element.id == role.sport_club.country_id)
  const indexCity = dataCities.findIndex(element => element.id == role.sport_club.city_id)
  role.sport_club.country = dataCountries[indexCountry]
  role.sport_club.city = dataCities[indexCity]

  // Permissions
  const roleModulePermision = roleModulePermissionData.find(item => item.role_id === role.id)
  dataUser.allPermissions = roleModulePermision

  dataUser.role = role
  dataUser.roles = roles

  // validar que los campos sean distintos de nulo
  let counter = 0
  let counterImg = 0

  for (let [key, value] of Object.entries(dataUser)) {
    if (key === 'address') {
      for (let [keyA, valueA] of Object.entries(value)) {
        if (valueA === null) {
          counter += 1
        }
      }
    } else if (key === 'avatar' && value === null) {
      counterImg += 1
    } else if (key !== 'id'
        && key !== 'fullName'
        && key !== 'last_name'
        && key !== 'password'
        && key !== 'sport_clubs'
        && key !== 'job_position_id'
        && key !== 'allPermissions'
        && key !== 'role'
        && key !== 'ability'
        && key !== 'extras'
        && key !== 'roles'
        && key !== 'jobsPositions'
        && key !== 'jobPosition'
    ) {
      if (value === null) {
        counter += 1
      }
    }
  }

  dataUser.incomplete = (counter > 0)
  dataUser.incomplete_by_image = (counterImg > 0)

  if (dataUser.dashboard == null) {
    dataUser.dashboard = {
      widget: widgetList,
    }
  }

  return [200, dataUser]
})

// ------------------------------------------------
// GET: lista de permisos de un usuario
// ------------------------------------------------
mock.onGet(/\/role\/\d+/).reply(config => {
  const idRole = config.url.split('/')[2]

  const dataFilter = roleModulePermissionData.filter(roleModule => roleModule.role_id === Number(idRole))[0]
  dataFilter.role = dataRoles[dataRoles.findIndex(rol => rol.id === Number(idRole))]

  return [200, dataFilter]
})

// ------------------------------------------------
// PUT: update module permissions
// ------------------------------------------------
mock.onPut('/user/role/permissions').reply(config => {
  // Get event from post data
  const permission = JSON.parse(config.data)

  const filterData = roleModulePermissionData.filter(element => element.role_id === Number(permission.role_id))[0]
  const indexModule = roleModulePermissionData.findIndex(element => element.role_id === Number(permission.role_id))
  const index = filterData.modules.findIndex(element => element.id === Number(permission.id))

  delete permission.role_id
  filterData.modules[index] = permission

  roleModulePermissionData[indexModule] = filterData
  return [200, permission]
})

mock.onGet(/\/clubs-info\/\d+\/users/).reply(config => {
  const clubId = config.url.split('/')[2]
  const usersList = []

  const dataUsers = data.users.filter(item => (item.sport_club_id == clubId && item.status == 1))

  dataUsers.forEach(elem => {
    const objUsers = {
      id: elem.id,
      name: elem.fullName,
    }

    usersList.push(objUsers)
  })

  return [200, usersList]
})

// ------------------------------------------------
// GET: Cambio de de equipo y rol
// ------------------------------------------------
mock.onGet(/\/change-role\/\d+/).reply(config => {
  const idClub = config.url.split('/')[2]

  // Roles
  const userData = JSON.parse(localStorage.getItem('userData'))
  const filterRoleUser = dataRoleUser.filter(item => item.user_id === Number(userData.id))
  const roles = dataRoles.filter(item => filterRoleUser.find(e => e.role_id == item.id))

  const role = roles.find(item => item.sport_club_id === Number(idClub))

  role.sport_club = dataClubes.find(item => item.id === Number(idClub))

  // role.sport_club = club

  const indexCountry = dataCountries.findIndex(element => element.id == role.sport_club.country_id)
  const indexCity = dataCities.findIndex(element => element.id == role.sport_club.city_id)
  role.sport_club.country = dataCountries[indexCountry]
  role.sport_club.city = dataCities[indexCity]

  // Permissions
  const roleModulePermision = roleModulePermissionData.find(item => item.role_id === role.id)
  userData.allPermissions = roleModulePermision

  userData.role = role

  // validar que los campos sean distintos de nulo
  let counter = 0
  let counterImg = 0

  for (let [key, value] of Object.entries(userData)) {
    if (key === 'address') {
      for (let [keyA, valueA] of Object.entries(value)) {
        if (valueA === null) {
          counter += 1
        }
      }
    } else if (key === 'avatar' && value === null) {
      counterImg += 1
    } else if (key !== 'id'
        && key !== 'fullName'
        && key !== 'last_name'
        && key !== 'password'
        && key !== 'sport_clubs'
        && key !== 'job_position_id'
        && key !== 'allPermissions'
        && key !== 'role'
        && key !== 'ability'
        && key !== 'extras'
        && key !== 'roles'
        && key !== 'jobsPositions'
        && key !== 'jobPosition'
    ) {
      if (value === null) {
        counter += 1
      }
    }
  }

  userData.incomplete = (counter > 0)
  userData.incomplete_by_image = (counterImg > 0)

  localStorage.setItem('userData', JSON.stringify(userData))

  return [200, userData]
})

mock.onGet('/users/list').reply(() => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  let dataList = data.users

  // const filterRoleUser = dataRoleUser.filter(item => item.user_id === Number(userData.id))
  // const roles = dataRoles.filter(item => filterRoleUser.find(e => e.role_id == item.id))

  // const role = roles.find(item => item.sport_club_id === Number(idClub))

  // role.sport_club = dataClubes.find(item => item.id === Number(idClub))
  // userData.role = role
  dataList = dataList.filter(item => item.role.sport_club_id != null)

  dataList = dataList.filter(item => item.role.sport_club_id === Number(userData.clubs.hash))
  return [200, dataList]
})

// ------------------------------------------------
// UPDATE: DASHBOARD
// ------------------------------------------------
mock.onPost('/user/update/dashboard').reply(config => {
  // Get event from post data
  const { id, dashboard } = JSON.parse(config.data)

  const index = data.users.findIndex(element => element.id === id)
  data.users[index].dashboard = dashboard

  // dashboard.widget = addItem()

  return [200, dashboard]
})

// List
mock.onGet('/widget/list').reply(() => {
  // const data = widgetList.filter(item => item.id === id)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const user = data.users.find(item => item.id === userData.id)
  const dataList = widgetList
  // TODO Revisar
  dataList.lg.forEach(item => {
    const filterStatus = user.dashboard.widget.lg.find(itemW => itemW.i == item.i)
    item.status = filterStatus != null ? 1 : 0
  })

  dataList.lg = dataList.lg.filter(item => item.title != null)
  dataList.md = dataList.md.filter(item => item.title != null)
  dataList.xxs = dataList.xxs.filter(item => item.title != null)

  return [200, widgetList]
})

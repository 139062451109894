import mock from '@/@fake-db/mock'
import dataUsers from '@/@fake-db/jwt'

const avatar1 = require('@/assets/images/avatars/peniche.jpeg')
const avatar2 = require('@/assets/images/avatars/13-small.png')
const avatar5 = require('@/assets/images/avatars/8-small.png')

/* eslint-disable global-require */
const data = {
  messages: [
    {
      id: 1,
      description:
        'Hola Ana, Ut massa diam, suscipit et egestas ac, dapibus et tellus. Maecenas dignissim enim nisi, nec hendrerit dui commodo nec. Curabitur rutrum magna consequat tortor faucibus interdum egestas id tellus. Maecenas eget vulputate lectus. Donec sed arcu turpis.',
      guests: [{
        id: 1,
        name: 'Ana',
        avatar: avatar1,
      }],
      include_everyone: 0,
      exclude_guests: [],
      user_id: 4,
      sport_club_id: 1,
      message_id: null,
      isComment: false,
      comments: [],
      user: null,
    },
    {
      id: 2,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vel varius quam. Ut ipsum nibh, ultricies ac metus non, ullamcorper dictum urna. Nunc tincidunt magna a nisl malesuada pellentesque. Sed et dolor ex. In at enim euismod, mollis lorem nec, efficitur velit fusce. ',
      guests: [{
        id: 2,
        name: 'Roberto',
        avatar: avatar2,
      }, {
        id: 5,
        avatar: avatar5,
        name: 'Pablo',
      }],
      include_everyone: 0,
      exclude_guests: [],
      user_id: 1,
      sport_club_id: 1,
      message_id: null,
      isComment: false,
      comments: [],
      user: null,
    },
    {
      id: 3,
      description:
        'Phasellus mattis magna et diam aliquam, sed nulla.',
      guests: [{
        id: 2,
        name: 'Roberto',
        avatar: avatar2,
      }, {
        id: 5,
        avatar: avatar5,
        name: 'Pablo',
      }],
      include_everyone: 0,
      exclude_guests: [],
      user_id: 1,
      sport_club_id: null,
      message_id: null,
      isComment: false,
      comments: [],
      user: null,
    },
    {
      id: 4,
      description:
        'Hola, este es un comentario de prueba de Roberto',
      guests: [],
      include_everyone: 0,
      exclude_guests: [],
      user_id: 2,
      sport_club_id: 1,
      message_id: 2,
      isComment: true,
      comments: [],
      user: null,
    },
    {
      id: 5,
      description:
        'Hola, este es un comentario de prueba de pablo',
      guests: [],
      include_everyone: 0,
      exclude_guests: [],
      user_id: 5,
      sport_club_id: 1,
      message_id: 2,
      isComment: true,
      comments: [],
      user: null,
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Emails
// ------------------------------------------------
mock.onGet('/apps/messages').reply(() => {
  // eslint-disable-next-line object-curly-newline
  let dataList = data.messages
  const userDataAux = JSON.parse(localStorage.getItem('vuex'))
  const userData = userDataAux
  // const userData = JSON.parse(localStorage.getItem('userData'))
  dataList = dataList.filter(item => item.isComment === false)
  dataList = dataList.filter(item => item.guests.find(e => e.id === userData.id) || item.include_everyone === 1 || item.user_id === userData.id)
  dataList.forEach(item => {
    const us = dataUsers.users.find(e => e.id === item.user_id)
    item.user = {
      id: us.id,
      fullName: us.fullName,
      email: us.email,
      avatar: us.avatar,
    }
    item.comments = data.messages.filter(e => e.message_id === item.id)
  })
  return [200, dataList]
})

// ------------------------------------------------
// POST: Update Email
// ------------------------------------------------
mock.onPost('/apps/messages').reply(config => {
  const { message } = JSON.parse(config.data)

  const userDataAux = JSON.parse(localStorage.getItem('vuex'))
  const userData = userDataAux

  message.id = Date.now()
  message.user_id = userData.id

  // Users guests
  message.user = {
    id: userData.id,
    fullName: userData.fullName,
    email: userData.email,
    avatar: userData.avatar,
  }

  data.messages.unshift(message)

  return [200, message]
})

// ------------------------------------------------
// Show: view Event
// ------------------------------------------------
mock.onGet(/\/apps\/message\/\d+/).reply(config => {
  // Get event id from URL
  let messageId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  messageId = Number(messageId)

  const message = data.messages.find(e => e.id === messageId)
  const messagesComents = data.messages.filter(e => e.message_id === messageId)

  messagesComents.forEach(item => {
    const us = dataUsers.users.find(e => e.id === item.user_id)
    item.user = {
      id: us.id,
      fullName: us.fullName,
      email: us.email,
      avatar: us.avatar,
    }
  })

  message.comments = messagesComents
  return [200, message]
})

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete(/\/apps\/messages\/\d+/).reply(config => {
  // Get event id from URL
  let messageId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  messageId = Number(messageId)

  const messageIndex = data.messages.findIndex(e => e.id === messageId)
  data.messages.splice(messageIndex, 1)
  return [200, messageId]
})

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete(/\/apps\/message\/\d+\/comment\/\d+/).reply(config => {
  // Get event id from URL
  // let messageId = config.url.substring(config.url.lastIndexOf('/') + 1)
  // let messageId = config.url.split('/')[3]
  let commentId = config.url.split('/')[5]

  // Convert Id to number
  // messageId = Number(messageId)
  commentId = Number(commentId)

  const messageIndex = data.messages.findIndex(e => e.id === commentId)
  data.messages.splice(messageIndex, 1)

  // search
  // const message = data.messages.find(e => e.id === messageId)
  // const messagesComents = data.messages.filter(e => e.message_id === messageId)

  // messagesComents.forEach(item => {
  //   const us = dataUsers.users.find(e => e.id === item.user_id)
  //   item.user = {
  //     id: us.id,
  //     fullName: us.fullName,
  //     email: us.email,
  //     avatar: us.avatar,
  //   }
  // })

  // message.comments = messagesComents
  return [200]
})

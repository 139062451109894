export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    meta: {
      redirectIfProfileIsIncomplete: true,
      redirectIfClubIsIncomplete: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard-main',
    component: () => import('@/views/dashboard/index.vue'),
    meta: {
      redirectIfProfileIsIncomplete: true,
      redirectIfClubIsIncomplete: true,
    },
  },
]

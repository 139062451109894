import Vue from 'vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import {
  ToastPlugin, ModalPlugin, BootstrapVue, IconsPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/moments'
import '@/libs/v-calendar'

// Global Mixin
import './mixin'

// Axios Mock Adapter
import '@/@fake-db/db'

// get Permissions
// !Cambiar id por token
// const userDataAux = JSON.parse(localStorage.getItem('vuex'))
// const userData = userDataAux ? userDataAux.userAuthStore : null
//
// async function getInfo(id) {
//   await store.dispatch('getInfo', id)
//   await store.dispatch('fetchClubDataAll')
// }
// if (userData != null) {
//   getInfo(userData.id)
// }
// End permissions

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Element UI
Vue.use(ElementUI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
// require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// Fontello (Icons Ostrail)
require('./assets/fonts/fontello/css/fontello.css')

// Font google (Asap | Asap Condensed  )
require('./assets/fonts/asap/css/asap.css')
require('./assets/fonts/asap-condensed/css/asap-condensed.css')

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

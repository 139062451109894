import mock from '@/@fake-db/mock'

export const data = [
  { 
    id: 0,
    name: 'Jugador',
    type: 'male',
    query: 'player',
  },
  {
    id: 2,
    name: 'Jugadora',
    type: 'female',
    query: 'player',
  },
  {
    id: 1,
    name: 'Cuerpo técnico',
    type: 'coaching',
    query: 'cs',
  },
]

mock.onGet('/member-type').reply(() => [200, data])

import mock from '@/@fake-db/mock'

export const data = [
  {
    id: 0,
    key: 'DEFINITIVE',
    name: 'Definitiva con costo',
    status: 1,
  },
  {
    id: 1,
    key: 'DEFINITIVE',
    name: 'Definitiva sin costo',
    status: 1,
  },
  {
    id: 2,
    key: 'TEMPORARY',
    name: 'Temporal con costo',
    status: 1,
  },
  {
    id: 3,
    key: 'TEMPORARY',
    name: 'Temporal sin costo',
    status: 1,
  },
]

mock.onGet('/type-operation').reply(() => [200, data])

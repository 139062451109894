import mock from '@/@fake-db/mock'

export const data = [
  {
    id: 1,
    name: 'Nominativa',
    description: '(denominación: formado por letras, palabras y/o números)',
    full: 'Nominativa (denominación: formado por letras, palabras y/o números)',
    applyDenomination: false,
    status: 1,
  },
  {
    id: 2,
    name: 'Innominada',
    description: '(diseño, logotipo o figura)',
    full: 'Innominada (diseño, logotipo o figura)',
    applyDenomination: false,
    status: 1,
  },
  {
    id: 3,
    name: 'Mixta',
    description: '(denominación y diseño)',
    full: 'Mixta (denominación y diseño)',
    applyDenomination: true,
    status: 1,
  },
  {
    id: 4,
    name: 'Mixta',
    description: '(diseño y forma tridimensional)',
    full: 'Mixta (diseño y forma tridimensional)',
    applyDenomination: false,
    status: 1,
  },
  {
    id: 5,
    name: 'Mixta',
    description: '(denominación y forma tridimensional)',
    full: 'Mixta (denominación y forma tridimensional)',
    applyDenomination: true,
    status: 1,
  },
  {
    id: 6,
    name: 'Mixta',
    description: '(denominación, diseño y forma tridimensional)',
    full: 'Mixta (denominación, diseño y forma tridimensional)',
    applyDenomination: true,
    status: 1,
  },
  {
    id: 7,
    name: 'Tridimencional',
    description: '(forma en tres planos)',
    full: 'Tridimencional (forma en tres planos)',
    applyDenomination: false,
    status: 1,
  },
]

mock.onGet('/clubs/trademark-types').reply(() => [200, data])

import mock from '@/@fake-db/mock'
// import { paginateArray } from '@/@fake-db/utils'
// import { data as dataClubes } from '@/@fake-db/data/clubs/club-setting'
// import { data as dataCountries } from '@/@fake-db/data/catalogs/countries'
// import { data as dataCities } from '@/@fake-db/data/catalogs/cities'
// import i18n from '@/libs/i18n'
// import ostrail from '../clubs/ostrail'
/* eslint-disable global-require */
const data = [{
  id: 2,
  member_id: 1,
  type_contractual_conditions: 'TRANSFER',
  descrition_contractual_conditions: null,
  // Transfer
  transfer: {
    operations: 'DOMESTIC',
    tms_number: null, // only international -> operations
    type_operation_id: 1,
    type_operation: null,
    init_date_operation: null,
    end_date_operation: null,
    ceding_club: 'Club de pruba',
    percentage_transfer_economic_rights: null,
    transfer_conditions: null, // Solo si no es el 100 de transferencia
    contract_agreement: 'YES', // yes no

    // yes
    assign_representative_id: null,
    signature_date: null,
    init_date: null,
    end_date: null,
    against_provision: {
      fixed_quantity: null, // Amount
      variable_quantity: null,
    },
    method_payment: null,
    payment_period_id: null,
    payment_period_other: null,
    penalty_amount: null,

    // Notification Address
    address: {
      address: null,
      zip_code: null,
      country_id: null,
      state_id: null,
      email: null,
    },
    jurisdiction_id: null,
    jurisdiction_other: null,
    law_applicable_contract: null,

    // Option Agente
    player_agent: 'YES',

    // Yes
    agent: {
      id: 3,
      name: 'Tres',
      license_number: null,
      role: null,
      payment_amount: null,
      method_payment: null,
      payment_period_id: null,
      payment_period_other: null,
      init_effective_date: null,
      end_effective_date: null,
      document_path: null,
      assign_representative_id: null,
    },
  },

  // General
  type_contract_id: null,
  type_contract_other: null,
  assign_representative_id: null,
  signature_date: null,
  init_date: null,
  end_date: null,
  against_provision: {
    fixed_quantity: null, // Amount
    variable_quantity: null,
  },
  increments: [{
    id: Date.now(),
    value: null,
  }],
  increase_conditions: null,

  method_payment: null,
  payment_period_id: null,
  bonds: null,
  benefits: [{
    id: Date.now(),
    value: null,
  }],
  compensation_amount: null,
  compensation_details: null,

  penalty_amount: null,
  penalty_details: null,

  rescission_amount: null,
  rescission_details: null,

  // Notificartion Address
  address: {
    address: null,
    zip_code: null,
    country_id: null,
    state_id: null,
    email: null,
  },
  jurisdiction_id: null,
  jurisdiction_other: null,
  law_applicable_contract: null,

  // Agents general
  player_agent: 'YES',

  // Yes
  agent: {
    id: 4,
    name: 'cuarto',
    license_number: null,
    role: null,
    payment_amount: null,
    method_payment: null,
    payment_period_id: null,
    payment_period_other: null,
    init_effective_date: null,
    end_effective_date: null,
    document_path: null,
    assign_representative_id: null,
  },
  updated_at: '2022-03-15 21:37:14',
  created_at: '2020-03-15 21:37:14',
}]
/* eslint-disable global-require */
mock.onGet('/contractual/conditions').reply(() => {
  // let dataList = data
  const dataList = data
  // const userDataAux = JSON.parse(localStorage.getItem('vuex'))
  // const userData = userDataAux.userAuthStore

  // dataList = dataList.filter(item => item.user_id === Number(userData.id))

  return [200, dataList]
})

// ------------------------------------------------
// POST: Add conditions
// ------------------------------------------------
mock.onPost('/contractual/conditions').reply(config => {
  // Get objNew from post data
  const contractualConditionsData = JSON.parse(config.data)

  const userDataAux = JSON.parse(localStorage.getItem('vuex'))
  const userData = userDataAux.userAuthStore

  const { length } = data
  let lastIndex = 0
  if (length) {
    lastIndex = data[length - 1].id
  }
  contractualConditionsData.id = lastIndex + 1
  contractualConditionsData.user_id = userData.id

  data.push(contractualConditionsData)

  return [201, contractualConditionsData]
})

// ------------------------------------------------
// POST: update conditions
// ------------------------------------------------
mock.onPut(/\/contractual\/conditions\/\d+/).reply(config => {
  // Get event from post data
  const conditionData = JSON.parse(config.data)
  const index = data.findIndex(element => element.id === conditionData.id)
  data[index] = conditionData

  return [200, conditionData]
})

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete(/\/direct\/access\/\d+/).reply(config => {
  // Get new id from URL
  const id = config.url.substring(config.url.lastIndexOf('/') + 1)

  const newIndex = data.findIndex(e => e.id === Number(id))
  data.splice(newIndex, 1)
  return [200, id]
})

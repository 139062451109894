import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import roleStore from './roles'
import userStore from './users'
import userAuthStore from './user/user'
import permissions from './permissions'
import profile from './profile'
import club from './club'
import societies from './club/societies'
import assemblies from './club/assemblies'
import powers from './club/powers'
import instrumentNumbers from './club/instrumentNumbers'
import literaryOrArtisticWorks from './club/intellectual-property/literary-or-artistic-works'
import typesOfLiteraryOrArtisticWork from './club/intellectual-property/types-of-literary-or-artistic-work'
import assignRepresentatives from './club/assignRepresentatives'
import representatives from './club/assignRepresentatives/representatives'
import powersGranted from './club/assignRepresentatives/powers-granted'
import trademarks from './club/intellectual-property/trademarks'
import trademarkTypes from './club/intellectual-property/trademark-types'
import requestTypes from './club/intellectual-property/request-types'
import ways from './club/societies/ways-to-manage'
import partners from './club/societies/partners'
import countries from './catalogs/countries'
import nacionalities from './catalogs/nacionalities'
import leagues from './catalogs/leagues'
import jobPosition from './job-position'
import memberStore from './members'
import teamStore from './teams'
import templateColorsStore from './template-colors'
import calendar from './calendar'
import noticeStore from './notices'
import todo from './todo'
import dashboard from './dashboard'
import widget from './widget'
import message from './message'
import contractualConditions from './contractual-conditions'
import mediaFile from './media-file'

// Catalogs
import catalogs from './catalogs'
import tags from './tag'
import notificationStore from './notification'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: [
    'app',
    'appConfig',
    'club',
    'userAuthStore',
    'verticalMenu',
  ],
})

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    roleStore,
    permissions,
    userStore,
    userAuthStore,
    profile,
    club,
    societies,
    assemblies,
    powers,
    instrumentNumbers,
    literaryOrArtisticWorks,
    typesOfLiteraryOrArtisticWork,
    assignRepresentatives,
    representatives,
    powersGranted,
    trademarks,
    trademarkTypes,
    requestTypes,
    ways,
    partners,
    countries,
    leagues,
    nacionalities,
    jobPosition,
    memberStore,
    teamStore,
    templateColorsStore,
    calendar,
    noticeStore,
    todo,
    dashboard,
    widget,
    message,
    contractualConditions,
    mediaFile,
    catalogs,
    tags,
    notificationStore,
  },
  strict: process.env.DEV,
  plugins: [vuexLocal.plugin],
})

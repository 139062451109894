/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-cycle
import {
  getMembers,
  getMembersIncompleted,
  storeMember,
  updateMember,
  getMemberbyId,
  validateViewPemission,
  validateDownloadPemission,
  updateStatus,
  destroyMember,
  setIncompleteRecord,
  updateIncompleteRecord,
  fetchIncompleteRecord,
  deleteIncompleteRecord,
} from '@/services/memberService'

export const resetMemberState = ({ commit }) => {
  commit('resetState')
}

export const getDataMembersCompleted = ({ commit }, tempQuery) => new Promise((resolve, reject) => {
  getMembers(tempQuery).then(response => {
    commit('setData', response.data)
    resolve(response)
  }).catch(error => {
    reject(error)
  })
})
export const membersWhitIncompleteData = ({ commit }, tempQuery) => new Promise((resolve, reject) => {
  getMembersIncompleted(tempQuery).then(response => {
    commit('setIncompleteData', response.data)
    resolve(response)
  }).catch(error => {
    reject(error)
  })
})
export const store = ({ commit }, member) => new Promise((resolve, reject) => {
  storeMember(member).then(response => {
    resolve(response)
  }).catch(error => {
    reject(error)
  })
})
export const update = ({ commit }, member) => new Promise((resolve, reject) => {
  updateMember(member).then(response => {
    resolve(response)
  }).catch(error => {
    reject(error)
  })
})
export const destroy = ({ commit }, hash) => new Promise((resolve, reject) => {
  destroyMember(hash).then(response => {
    // commit('destroy', response.data)
    resolve(response)
  }).catch(error => {
    reject(error)
  })
})
// Filters
export const fetchMemberById = ({ commit }, id) => new Promise((resolve, reject) => {
  getMemberbyId(id).then(response => {
    commit('setMember', response.data.data)
    resolve(response)
  }).catch(error => reject(error))
})
export const validateViewPemissionByPassword = ({ commit }, dataPermision) => new Promise((resolve, reject) => {
  validateViewPemission(dataPermision).then(response => {
    resolve(response)
  }).catch(error => reject(error))
})
export const validateDownloadPemissionByPassword = ({ commit }, dataPermision) => new Promise((resolve, reject) => {
  validateDownloadPemission(dataPermision).then(response => {
    resolve(response)
  }).catch(error => reject(error))
})
export const updatePlayerStatus = ({ commit }, dataStatus) => new Promise((resolve, reject) => {
  updateStatus(dataStatus).then(response => {
    resolve(response)
  }).catch(error => {
    reject(error)
  })
})
export const storeIncompleteRecord = ({ commit }, member) => new Promise((resolve, reject) => {
  setIncompleteRecord(member).then(response => {
    resolve(response)
  }).catch(error => {
    reject(error)
  })
})
export const upIncompleteRecord = ({ commit }, memberObj) => new Promise((resolve, reject) => {
  updateIncompleteRecord(memberObj.member, memberObj.id).then(response => {
    resolve(response)
  }).catch(error => {
    reject(error)
  })
})
export const getIncompleteRecord = ({ commit }, recordObj) => new Promise((resolve, reject) => {
  fetchIncompleteRecord(recordObj).then(response => {
    resolve(response)
  }).catch(error => {
    reject(error)
  })
})
export const destroyIncompleteRecord = ({ commit }, id) => new Promise((resolve, reject) => {
  deleteIncompleteRecord(id).then(response => {
    resolve(response)
  }).catch(error => {
    reject(error)
  })
})

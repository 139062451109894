import mock from './mock'

/* eslint-disable import/extensions */

// JWT
import './jwt'

// Table
import './data/extensions/good-table'
import './data/extensions/auto-suggest'
import './data/card/card-statistics'
import './data/card/card-analytics'

// Apps
// import './data/apps/calendar'
import './data/apps/user'
import './data/apps/email'
import './data/apps/invoice'
// import './data/apps/todo'
import './data/apps/chat'
import './data/apps/eCommerce'

// dashboard
import './data/dashboard/analytics'
import './data/dashboard/ecommerce'

// pages
import './data/pages/faq-data'
import './data/pages/knowledge-base'
import './data/pages/pricing-data'
import './data/pages/profile-data'
import './data/pages/blog'
/* eslint-enable import/extensions */

// Catalogs
import './data/catalogs/cities'
import './data/catalogs/countries'
import './data/catalogs/leagues'
import './data/catalogs/nacionalities'
import './data/catalogs/ways-to-manage'
import './data/catalogs/categories'
import './data/catalogs/study-levels'
import './data/catalogs/genders'
import './data/catalogs/jobs-staff'
import './data/catalogs/member-type'
import './data/catalogs/status'

// Acervo Documental - Club
import './data/clubs/club-setting'
// Acervo Documental - Asambleas
import './data/clubs/assemblies'
// Acervo Documental - Sociedades
import './data/clubs/societies'
import './data/clubs/partners'
// Acervo Documental - Poderes
import './data/clubs/powers'
// Acervo Documental - Apoderados
import './data/clubs/representatives'
import './data/clubs/assign-representative'
import './data/clubs/assign-representative-instrument'
import './data/clubs/assign-representative-power-granted'
import './data/clubs/assign-representative-partner-representative'
import './data/clubs/powers-granted'
// Acervo Documental - Marcas
import './data/clubs/trademarks'
import './data/clubs/trademark-types'
import './data/clubs/request-types'
// Acervo Documental - Obras
import './data/clubs/literary-or-artistic-works'
import './data/clubs/types-of-literary-or-artistic-work'
// Acervo Documental - Números de instrumento
import './data/clubs/instrument-numbers'

// Roles
import './data/roles/roles'
import './data/roles/role-user'
// Roles
import './data/permissions/permissions'
// Job position
import './data/job-position/job-position'
import './data/job-position/job-position-user'
// Members
import './data/member'
// team
import './data/team/team'
import './data/catalogs/jersey'
import './data/calendar'
import './data/news'
import './data/todo'
import './data/direct-access'
import './data/catalogs/widgetList'
import './data/message'
import './data/catalogs/type-operation'
import './data/catalogs/payment-period'
import './data/catalogs/jurisdiction'
import './data/catalogs/type-contract'
import './data/catalogs/new-status'
import './data/contractual-conditions'

mock.onAny().passThrough() // forwards the matched request over network

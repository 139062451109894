import axios from '@axios'

export default {
  state: {
    trademark: {},
    trademarks: [],
  },

  mutations: {
    setTrademarksInfo(state, value) {
      state.trademarks = value
    },

    setTrademarkInfo(state, value) {
      state.trademark = value
    },
  },

  actions: {
    fetchTrademarksByClubId({ commit }, trademarkFilters) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux/${trademarkFilters.clubId}/trademarks`, { params: { filters: trademarkFilters } }).then(response => {
          commit('setTrademarksInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    saveTrademark({ commit }, trademark) {
      return new Promise((resolve, reject) => {
        axios.post(`/clubs-aux-save/${trademark.club_id}/trademarks`, trademark).then(response => {
          commit('setTrademarkInfo', response.data.trademarkData)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    showTrademarkByClubId({ commit }, trademarkObj) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-aux-show/${trademarkObj.clubId}/trademarks/${trademarkObj.trademarkId}`).then(response => {
          commit('setTrademarkInfo', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },

  getters: {
    trademarksList(state) {
      return state.trademarks
    },

    trademarkInformation(state) {
      return state.trademark
    },
  },
}

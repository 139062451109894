import Vue from 'vue'
import VCalendar from 'v-calendar'
// import VDatePickter from 'v-date-picker'

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
})
// Vue.use(VDatePickter, {
//   componentPrefix: 'vd',
// })

export default () => ({
  jobList: [],
  jobListArray: [],
  job: {
    id: 0,
    name: null,
    sport_club_id: null,
    sport_club: null,
    create_at: null,
    status: 0,
  },
})

const gamesPlayed = require('@/assets/images/dashboard/gamesPlayed.png')
const teamPerformance = require('@/assets/images/dashboard/teamPerformance.png')
const playerPerformance = require('@/assets/images/dashboard/playerPerformance.png')
const postNew = require('@/assets/images/dashboard/postNew.png')
const stadium = require('@/assets/images/dashboard/stadium.png')
const goalsFor = require('@/assets/images/dashboard/goalsFor.png')
const goalsAgainst = require('@/assets/images/dashboard/goalsAgainst.png')
const note = require('@/assets/images/dashboard/note.png')
const weather = require('@/assets/images/dashboard/weather.png')

export default {
  xxs: [
    // {
    //   x: 0, y: 0, w: 12, h: 2, i: '0', minW: 12, minH: 2, maxW: 12, maxH: 2,
    // },
    // Clima
    {
      x: 0, y: 2, w: 12, h: 7, i: '7', minW: 12, minH: 7, maxW: 12, maxH: 7, title: 'Clima', status: 1, img: weather,
    },
    // Note
    {
      x: 0, y: 4, w: 12, h: 11, i: '8', minW: 12, minH: 11, maxW: 12, maxH: 11, title: 'Pendientes', status: 1, img: note,
    },
    // Stadim
    {
      x: 0, y: 6, w: 12, h: 4, i: '9', minW: 12, minH: 4, maxW: 12, maxH: 4, title: 'Estadio', status: 0, img: stadium,
    },
    // GamesPlayed
    {
      x: 0, y: 8, w: 12, h: 8, i: '10', minW: 12, minH: 8, maxW: 12, maxH: 8, title: 'Partidos jugados', status: 0, img: gamesPlayed,
    },
    // teamPerformance
    {
      x: 0, y: 10, w: 12, h: 5, i: '11', minW: 12, minH: 5, maxW: 12, maxH: 5, title: 'Desempeño de equipo', status: 0, img: teamPerformance,
    },
    // PlayerPerformance
    {
      x: 0, y: 12, w: 12, h: 5, i: '12', minW: 12, minH: 5, maxW: 12, maxH: 5, title: 'Desempeño de jugadores', status: 0, img: playerPerformance,
    },
    // post card
    {
      x: 0, y: 14, w: 12, h: 8, i: '13', minW: 12, minH: 8, maxW: 12, maxH: 8, title: 'Noticias', status: 0, img: postNew,
    },
    // goals for
    {
      x: 0, y: 16, w: 12, h: 4, i: '14', minW: 12, minH: 4, maxW: 12, maxH: 4, title: 'Goles a favor', status: 0, img: goalsFor,
    },
    // goals against
    {
      x: 0, y: 18, w: 12, h: 4, i: '15', minW: 12, minH: 4, maxW: 12, maxH: 4, title: 'Goles en contra', status: 0, img: goalsAgainst,
    },
  ],
  md: [
    // {
    //   x: 0, y: 0, w: 3, h: 2, i: '0',
    // },
    // Clima
    {
      x: 9, y: 0, w: 3, h: 8, i: '7', title: 'Clima', status: 1, img: weather,
    },
    // Note
    {
      x: 0, y: 2, w: 5, h: 11, i: '8', title: 'Pendientes', status: 1, img: note,
    },
    // Stadim
    {
      x: 0, y: 13, w: 10, h: 8, i: '9', title: 'Estadio', status: 0, img: stadium,
    },
    // GamesPlayed
    {
      x: 5, y: 0, w: 4, h: 8, i: '10', title: 'Partidos jugados', status: 0, img: gamesPlayed,
    },
    // teamPerformance
    {
      x: 0, y: 29, w: 10, h: 8, i: '11', title: 'Desempeño de equipo', status: 0, img: teamPerformance,
    },
    // PlayerPerformance
    {
      x: 0, y: 21, w: 10, h: 8, i: '12', title: 'Desempeño de jugadores', status: 0, img: playerPerformance,
    },
    // post card
    {
      x: 6, y: 37, w: 4, h: 8, i: '13', title: 'Noticias', status: 0, img: postNew,
    },
    // goals for
    {
      x: 0, y: 37, w: 6, h: 4, i: '14', title: 'Goles a favor', status: 0, img: goalsFor,
    },
    // goals against
    {
      x: 6, y: 8, w: 6, h: 4, i: '15', title: 'Goles en contra', status: 0, img: goalsAgainst,
    },
  ],
  lg: [
    // {
    //   x: 0, y: 0, w: 2, h: 2, i: '0', minW: 2, minH: 2, maxW: 2, maxH: 2,
    // },
    // Clima
    {
      x: 10, y: 0, w: 2, h: 8, i: '7', minW: 2, minH: 7, maxW: 3, maxH: 8, title: 'Clima', status: 1, img: weather,
    },
    // Note
    {
      x: 0, y: 2, w: 4, h: 11, i: '8', minW: 3, minH: 11, maxW: 5, maxH: 11, title: 'Pendientes', status: 1, img: note,
    },
    // Stadim
    {
      x: 4, y: 8, w: 6, h: 9, i: '9', minW: 6, minH: 9, maxW: 6, maxH: 9, title: 'Estadio', status: 0, img: stadium,
    },
    // GamesPlayed
    {
      x: 10, y: 8, w: 2, h: 8, i: '10', minW: 2, minH: 8, maxW: 2, maxH: 8, title: 'Partidos jugados', status: 0, img: gamesPlayed,
    },
    // teamPerformance
    {
      x: 0, y: 17, w: 5, h: 8, i: '11', minW: 5, minH: 8, maxW: 5, maxH: 8, title: 'Desempeño de equipo', status: 0, img: teamPerformance,
    },
    // PlayerPerformance
    {
      x: 4, y: 0, w: 5, h: 8, i: '12', minW: 5, minH: 8, maxW: 5, maxH: 8, title: 'Desempeño de jugadores', status: 0, img: playerPerformance,
    },
    // post card
    {
      x: 5, y: 17, w: 2, h: 8, i: '13', minW: 2, minH: 8, maxW: 2, maxH: 8, title: 'Noticias', status: 0, img: postNew,
    },
    // goals for
    {
      x: 0, y: 13, w: 3, h: 4, i: '14', minW: 3, minH: 4, maxW: 3, maxH: 4, title: 'Goles a favor', status: 0, img: goalsFor,
    },
    // goals against
    {
      x: 9, y: 17, w: 3, h: 4, i: '15', minW: 3, minH: 4, maxW: 3, maxH: 4, title: 'Goles en contra', status: 0, img: goalsAgainst,
    },
  ],
}

// export default data

import apiInstance from './index'

export function storeMediaFile(data, config) {
  return apiInstance({
    url: '/media-file-upload',
    method: 'POST',
    data,
    ...config,
  })
}
export function destroyMediaFile(hash, config) {
  return apiInstance({
    url: `/media-file-upload/${hash}`,
    method: 'DELETE',
    ...config,
  })
}
export function showMediaFile(hash, config) {
  return apiInstance({
    url: `/media-file-upload/${hash}`,
    method: 'GET',
    responseType: 'blob',
    ...config,
  })
}

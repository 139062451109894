export default () => ({
  rolList: [],
  rolListArray: [],
  rol: {
    id: 0,
    name: null,
    allow_all: false,
    is_admin_club: 0,
    sport_club_id: null,
    sport_club: {
      country_id: null,
      city_id: null,
    },
    create_at: null,
    status: 1,
  },
})

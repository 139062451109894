import axios from '@axios'

export const getRolesData = ({ commit }, filters) => new Promise((resolve, reject) => {
  axios.get('/roles', {
    params: {
      filters,
    },
  }).then(Response => {
    commit('setRolListData', Response.data)
    resolve(true)
  }).catch(error => {
    reject(error)
  })
})
export const createNewRol = ({ commit }, newRol) => new Promise((resolve, reject) => {
  axios.post('/roles', newRol).then(response => {
    commit('addNewRol', response.data)
    axios.post('/permissions', response.data)
      .then(() => resolve(true))
      .catch(error => reject(error))

    resolve(true)
  }).catch(error => {
    reject(error)
  })
})
export const updateRol = ({ commit }, rol) => new Promise((resolve, reject) => {
  axios.put('/roles', rol).then(response => {
    commit('updateRol', response.data)
    resolve(true)
  }).catch(error => {
    reject(error)
  })
})
export const deleteRol = ({ commit }, rol) => new Promise((resolve, reject) => {
  axios.post('/roles/delete', rol).then(response => {
    commit('deleteRol', response.data)
    resolve(true)
  }).catch(error => {
    reject(error)
  })
})
export const fetchRoleByIdClub = ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/roles/${id}`).then(response => {
    commit('setRoleByIdClub', response.data)
    resolve(response)
  }).catch(error => reject(error))
})
export const fetchRolesListData = ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/roles/list').then(response => {
    commit('setRolesListDataAll', response.data)
    resolve(response)
  }).catch(error => reject(error))
})
export const fetchRoleClubByIdClub = ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/role-club/${id}`).then(response => {
    commit('setRoleByIdClub', response.data)
    resolve(response)
  }).catch(error => reject(error))
})
// TODO: Multiple select clubs
// export const fetchRoleByIdClub = ({ commit }, rol) => new Promise((resolve, reject) => {
//   axios.put('/clubs/rol', rol).then(response => {
//     commit('setRoleByIdClub', response.data)
//     resolve(true)
//   }).catch(error => {
//     reject(error)
//   })
// })

import axios from '@axios'

export default {
  namespaced: true,
  state: {
    message: {
      id: null,
      description: '',
      guests: [],
      include_everyone: 0,
      exclude_guests: [],
      user_id: null,
      user: {},
      sport_club_id: null,
      message_id: null,
      isComment: true,
      comments: [],
    },
    messagesList: [],
    messagesNotifications: [],
  },
  getters: {
    messagesList(state) {
      return state.messagesList
    },
    message(state) {
      return state.message
    },
    messagesNotifications(state) {
      return state.messagesNotifications
    },
  },
  mutations: {
    setMessageList(state, list) {
      state.messagesList = list
    },
    setMessagesNotifications(state, list) {
      state.messagesNotifications = list
    },
    setMessageDetails(state, val) {
      state.message = val
    },
    setMessage(state, val) {
      // state.message = val
      state.messagesList.unshift(val)
    },
    deleteMessage(state, idMessage) {
      const indexList = state.messagesList.findIndex(element => element.id === Number(idMessage))
      state.messagesList.splice(indexList, 1)
    },
    updateMessage(state, message) {
      const indexList = state.messagesList.findIndex(element => element.id === message.id)
      state.messagesList[indexList] = message
    },
  },
  actions: {
    fetchMessages({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/messages')
          .then(response => {
            commit('setMessageList', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    add({ commit }, message) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/messages', { message })
          .then(response => {
            commit('setMessage', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateMessage({ commit }, message) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/messages/${message.id}`, { message })
          .then(response => {
            commit('updateMessage', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    remove({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/messages/${id}`)
          .then(response => {
            commit('deleteMessage', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    removeComment(ctx, commentData) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/message/${commentData.messageId}/comment/${commentData.commentId}`)
          .then(response => {
            // commit('setMessageDetails', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchMessageById({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/message/${id}`)
          .then(response => {
            commit('setMessageDetails', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    // fetchMessagesList({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/apps/message/list')
    //       .then(response => {
    //         commit('setMessageList', response.data)
    //         resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },
    // fetchMessageNotifications({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/apps/messages/notifications')
    //       .then(response => {
    //         commit('setMessagesNotifications', response.data)
    //         resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },
  },
}

import mock from '@/@fake-db/mock'
import { paginateArray } from '@/@fake-db/utils'
import { data as dataClubes } from '@/@fake-db/data/clubs/club-setting'
import { data as dataCountries } from '@/@fake-db/data/catalogs/countries'
import { data as dataCities } from '@/@fake-db/data/catalogs/cities'
import i18n from '@/libs/i18n'
import ostrail from '../clubs/ostrail'
/* eslint-disable global-require */
const data = {
  // new list
  newList: [
    {
      id: 1,
      title: 'Madrid, días de furia... y fútbol',
      thumb: require('@/assets/images/slider/new1.jpg'),
      img: require('@/assets/images/slider/new1.jpg'),
      author: {
        id: 1,
        fullName: 'Ana Peniche',
        avatar: require('@/assets/images/avatars/peniche.jpeg'),
      },
      published: 'May 5, 2022',
      tags: ['Deporte', 'Futboll'],
      description: 'En la búsqueda de esas explicaciones no encontradas (les confieso que yo necesité confirmar el resultado en la mañana de ayer) muchos, yo mismo, hablamos de los milagros del Bernabéu.',
      content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sic enim censent, oportunitatis esse beate vivere. Duo Reges: constructio interrete. Quamquam tu hanc copiosiorem etiam soles dicere. Quid igitur dubitamus in tota eius natura quaerere quid sit effectum? </p>

      <p>Iubet igitur nos Pythius Apollo noscere nosmet ipsos. Habes, inquam, Cato, formam eorum, de quibus loquor, philosophorum. Quid enim possumus hoc agere divinius? Is es profecto tu. Dicet pro me ipsa virtus nec dubitabit isti vestro beato M. Animi enim quoque dolores percipiet omnibus partibus maiores quam corporis. Quamquam id quidem, infinitum est in hac urbe; Cuius quidem, quoniam Stoicus fuit, sententia condemnata mihi videtur esse inanitas ista verborum. Nec vero alia sunt quaerenda contra Carneadeam illam sententiam. </p>
      
      <p>Aliter enim explicari, quod quaeritur, non potest. Idem fecisset Epicurus, si sententiam hanc, quae nunc Hieronymi est, coniunxisset cum Aristippi vetere sententia. Sed eum qui audiebant, quoad poterant, defendebant sententiam suam. Plane idem, inquit, et maxima quidem, qua fieri nulla maior potest. Atque hoc loco similitudines eas, quibus illi uti solent, dissimillimas proferebas. Aliter enim nosmet ipsos nosse non possumus. Levatio igitur vitiorum magna fit in iis, qui habent ad virtutem progressionis aliquantum. Pauca mutat vel plura sane; Nam quibus rebus efficiuntur voluptates, eae non sunt in potestate sapientis. Tu enim ista lenius, hic Stoicorum more nos vexat. </p>
      
      <p>Unum nescio, quo modo possit, si luxuriosus sit, finitas cupiditates habere. Sed ut iis bonis erigimur, quae expectamus, sic laetamur iis, quae recordamur. Cyrenaici quidem non recusant; Quae quidem sapientes sequuntur duce natura tamquam videntes; Sed nimis multa. Quare conare, quaeso. Teneo, inquit, finem illi videri nihil dolere. Ita graviter et severe voluptatem secrevit a bono. Atqui eorum nihil est eius generis, ut sit in fine atque extrerno bonorum. </p>
      
      <p>Scio enim esse quosdam, qui quavis lingua philosophari possint; Mihi enim erit isdem istis fortasse iam utendum. Sin te auctoritas commovebat, nobisne omnibus et Platoni ipsi nescio quem illum anteponebas? Quod cum dixissent, ille contra. Non enim, si omnia non sequebatur, idcirco non erat ortus illinc. Non dolere, inquam, istud quam vim habeat postea videro; Mihi quidem Homerus huius modi quiddam vidisse videatur in iis, quae de Sirenum cantibus finxerit. Quid, si reviviscant Platonis illi et deinceps qui eorum auditores fuerunt, et tecum ita loquantur? Quantam rem agas, ut Circeis qui habitet totum hunc mundum suum municipium esse existimet? </p>
      
      `,
      status: 1,
      location: 'es',
      user_id: 1,
      category: 'Sports',
      sport_club_id: 1,
      publication_end_date: null,
      sharePost: 0,
    },
    {
      id: 2,
      img: require('@/assets/images/slider/new2.jpg'),
      title: 'Thiago Alcántara, el futbolista que persigue su tercera Champions',
      thumb: require('@/assets/images/slider/new2.jpg'),
      author: {
        id: 6,
        fullName: 'Diana',
        avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
      },
      published: 'May 8, 2022',
      tags: ['Gaming', 'Video'],
      description: 'Al igual que hizo Seedorf, el volante del Liverpool se sitúa a un partido de ganar su tercera copa de Europa tras levantarla con el Barça en 2011 y el Bayern en 2020 Thiago habilita a Mané en La Cerámica.',
      content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullum inveniri verbum potest quod magis idem declaret Latine, quod Graece, quam declarat voluptas. Certe nihil nisi quod possit ipsum propter se iure laudari. Tum mihi Piso: Quid ergo? Cum ageremus, inquit, vitae beatum et eundem supremum diem, scribebamus haec. Nec lapathi suavitatem acupenseri Galloni Laelius anteponebat, sed suavitatem ipsam neglegebat; Quid ad utilitatem tantae pecuniae? Si enim non fuit eorum iudicii, nihilo magis hoc non addito illud est iudicatum-. </p>

      <p>Nam quid possumus facere melius? Conferam tecum, quam cuique verso rem subicias; Idemne potest esse dies saepius, qui semel fuit? An vero displicuit ea, quae tributa est animi virtutibus tanta praestantia? </p>
      
      <p>Videsne ut, quibus summa est in voluptate, perspicuum sit quid iis faciendum sit aut non faciendum? Sed tamen enitar et, si minus multa mihi occurrent, non fugiam ista popularia. Huic mori optimum esse propter desperationem sapientiae, illi propter spem vivere. Aperiendum est igitur, quid sit voluptas; Graecis hoc modicum est: Leonidas, Epaminondas, tres aliqui aut quattuor; </p>
      
      <p>Immo alio genere; Quid enim possumus hoc agere divinius? Miserum hominem! Si dolor summum malum est, dici aliter non potest. Quodsi vultum tibi, si incessum fingeres, quo gravior viderere, non esses tui similis; Ergo id est convenienter naturae vivere, a natura discedere. Si verbum sequimur, primum longius verbum praepositum quam bonum. Sed quia studebat laudi et dignitati, multum in virtute processerat. </p>
      
      <p>Quae cum dixisset paulumque institisset, Quid est? Cur id non ita fit? Cum sciret confestim esse moriendum eamque mortem ardentiore studio peteret, quam Epicurus voluptatem petendam putat. Suo enim quisque studio maxime ducitur. Dici enim nihil potest verius. Inde igitur, inquit, ordiendum est. Non est ista, inquam, Piso, magna dissensio. Non quam nostram quidem, inquit Pomponius iocans; Nam si propter voluptatem, quae est ista laus, quae possit e macello peti? </p>
      
      <p>Duo Reges: constructio interrete. Cum autem negant ea quicquam ad beatam vitam pertinere, rursus naturam relinquunt. Antiquorum autem sententiam Antiochus noster mihi videtur persequi diligentissime, quam eandem Aristoteli fuisse et Polemonis docet. Quod si ita sit, cur opera philosophiae sit danda nescio. Mene ergo et Triarium dignos existimas, apud quos turpiter loquare? Cur id non ita fit? Non quaeritur autem quid naturae tuae consentaneum sit, sed quid disciplinae. Quo modo autem optimum, si bonum praeterea nullum est? Videamus animi partes, quarum est conspectus illustrior; </p>
      
      `,
      status: 1,
      location: 'es',
      user_id: 6,
      category: 'Sports',
      sport_club_id: 1,
      publication_end_date: null,
      sharePost: 1,
    },
    {
      id: 3,
      img: require('@/assets/images/slider/new3.jpg'),
      title: 'El Real Madrid derrota al Manchester City y se clasifica a la final de la Champions League',
      thumb: require('@/assets/images/slider/new3.jpg'),
      author: {
        id: 1,
        fullName: 'Ana Peniche',
        avatar: require('@/assets/images/avatars/peniche.jpeg'),
      },
      published: 'May 10, 2022',
      tags: ['Gaming', 'Food'],
      description: 'El equipo de Carlo Ancelotti remonta el partido (3-1) y la eliminatoria (6-5) y luchará por el título ante el Liverpool en París',
      content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non igitur de improbo, sed de callido improbo quaerimus, qualis Q. Nam quibus rebus efficiuntur voluptates, eae non sunt in potestate sapientis. Tum ille timide vel potius verecunde: Facio, inquit. Duo Reges: constructio interrete. Eam si varietatem diceres, intellegerem, ut etiam non dicente te intellego; Frater et T. Aliena dixit in physicis nec ea ipsa, quae tibi probarentur; </p>

      <p>An nisi populari fama? Unum nescio, quo modo possit, si luxuriosus sit, finitas cupiditates habere. Nam si amitti vita beata potest, beata esse non potest. Primum divisit ineleganter; </p>
      
      <p>Sed nimis multa. Laelius clamores sofòw ille so lebat Edere compellans gumias ex ordine nostros. Respondent extrema primis, media utrisque, omnia omnibus. Nam si quae sunt aliae, falsum est omnis animi voluptates esse e corporis societate. </p>
      
      <p>Idque testamento cavebit is, qui nobis quasi oraculum ediderit nihil post mortem ad nos pertinere? At ille pellit, qui permulcet sensum voluptate. Igitur neque stultorum quisquam beatus neque sapientium non beatus. Frater et T. </p>
      
      <p>Quod si ita se habeat, non possit beatam praestare vitam sapientia. Non quam nostram quidem, inquit Pomponius iocans; Bonum liberi: misera orbitas. Ergo adhuc, quantum equidem intellego, causa non videtur fuisse mutandi nominis. Scaevola tribunus plebis ferret ad plebem vellentne de ea re quaeri. Ne amores quidem sanctos a sapiente alienos esse arbitrantur. Animum autem reliquis rebus ita perfecit, ut corpus; </p>
      
      <p>Qui non moveatur et offensione turpitudinis et comprobatione honestatis? Aliena dixit in physicis nec ea ipsa, quae tibi probarentur; Hoc est dicere: Non reprehenderem asotos, si non essent asoti. An vero, inquit, quisquam potest probare, quod perceptfum, quod. </p>
      
      `,
      status: 1,
      location: 'es',
      user_id: 1,
      category: 'Sports',
      sport_club_id: 2,
      publication_end_date: null,
      sharePost: 1,
    },
    {
      id: 4,
      img: require('@/assets/images/slider/new4.jpg'),
      title: 'Forearm injury puts Suryakumar Yadav out of remainder of IPL 2022',
      thumb: require('@/assets/images/slider/new4.jpg'),
      author: {
        id: 6,
        fullName: 'Diana',
        avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
      },
      published: 'April 10, 2022',
      tags: ['Video'],
      description: 'Suryakumar Yadav, the Mumbai Indians middle-order batter, has been ruled out of the remainder of IPL 2022 after sustaining an injury to a muscle in his left forearm.',
      content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nos cum te, M. Nam et complectitur verbis, quod vult, et dicit plane, quod intellegam; Tollitur beneficium, tollitur gratia, quae sunt vincla concordiae. Sin laboramus, quis est, qui alienae modum statuat industriae? Duo Reges: constructio interrete. Quantum Aristoxeni ingenium consumptum videmus in musicis? Solum praeterea formosum, solum liberum, solum civem, stultost; </p>

      <p>Quae iam oratio non a philosopho aliquo, sed a censore opprimenda est. Nullus est igitur cuiusquam dies natalis. Honesta oratio, Socratica, Platonis etiam. Illud mihi a te nimium festinanter dictum videtur, sapientis omnis esse semper beatos; Sapientem locupletat ipsa natura, cuius divitias Epicurus parabiles esse docuit. Cur post Tarentum ad Archytam? Partim cursu et peragratione laetantur, congregatione aliae coetum quodam modo civitatis imitantur; Huius, Lyco, oratione locuples, rebus ipsis ielunior. </p>
      
      <p>Quia dolori non voluptas contraria est, sed doloris privatio. Atque his de rebus et splendida est eorum et illustris oratio. Igitur neque stultorum quisquam beatus neque sapientium non beatus. Sed quanta sit alias, nunc tantum possitne esse tanta. Et harum quidem rerum facilis est et expedita distinctio. Nam illud vehementer repugnat, eundem beatum esse et multis malis oppressum. Primum divisit ineleganter; </p>
      
      <p>Aliis esse maiora, illud dubium, ad id, quod summum bonum dicitis, ecquaenam possit fieri accessio. Consequentia exquirere, quoad sit id, quod volumus, effectum. Facile est hoc cernere in primis puerorum aetatulis. Prioris generis est docilitas, memoria; Sed non sunt in eo genere tantae commoditates corporis tamque productae temporibus tamque multae. Quam si explicavisset, non tam haesitaret. An me, inquam, nisi te audire vellem, censes haec dicturum fuisse? Superiores tres erant, quae esse possent, quarum est una sola defensa, eaque vehementer. Expectoque quid ad id, quod quaerebam, respondeas. Haec quo modo conveniant, non sane intellego. Invidiosum nomen est, infame, suspectum. </p>
      
      <p>Sextilio Rufo, cum is rem ad amicos ita deferret, se esse heredem Q. Sic exclusis sententiis reliquorum cum praeterea nulla esse possit, haec antiquorum valeat necesse est. At ille pellit, qui permulcet sensum voluptate. Ut alios omittam, hunc appello, quem ille unum secutus est. Si verbum sequimur, primum longius verbum praepositum quam bonum. Dat enim intervalla et relaxat. Unum est sine dolore esse, alterum cum voluptate. Hoc loco tenere se Triarius non potuit. Cur id non ita fit? Quae qui non vident, nihil umquam magnum ac cognitione dignum amaverunt. Tu autem, si tibi illa probabantur, cur non propriis verbis ea tenebas? Non quam nostram quidem, inquit Pomponius iocans; </p>
      
      <p>Traditur, inquit, ab Epicuro ratio neglegendi doloris. Cum ageremus, inquit, vitae beatum et eundem supremum diem, scribebamus haec. Si longus, levis; Quae hic rei publicae vulnera inponebat, eadem ille sanabat. Neque enim civitas in seditione beata esse potest nec in discordia dominorum domus; Neque enim disputari sine reprehensione nec cum iracundia aut pertinacia recte disputari potest. Sed quanta sit alias, nunc tantum possitne esse tanta. Negabat igitur ullam esse artem, quae ipsa a se proficisceretur; </p>
      
      `,
      status: 1,
      location: 'en',
      user_id: 2,
      category: 'Sports',
      sport_club_id: 2,
      publication_end_date: null,
      sharePost: 0,
    },
    {
      id: 5,
      img: require('@/assets/images/slider/new5.jpg'),
      title: 'Ryan Campbell discharged from hospital after making full recovery',
      thumb: require('@/assets/images/slider/new5.jpg'),
      author: {
        id: 1,
        fullName: 'Ana Peniche',
        avatar: require('@/assets/images/avatars/peniche.jpeg'),
      },
      published: 'April 10, 2022',
      tags: ['Sport'],
      description: "Ryan Campbell, the former Australia wicketkeeper-batter and current Netherlands men's head coach, has been discharged from hospital after follow-up tests showed no damage to his heart.",
      content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. An haec ab eo non dicuntur? Tria genera bonorum; Expectoque quid ad id, quod quaerebam, respondeas. Etiam inchoatum, ut, si iuste depositum reddere in recte factis sit, in officiis ponatur depositum reddere; Etenim si delectamur, cum scribimus, quis est tam invidus, qui ab eo nos abducat? Satisne ergo pudori consulat, si quis sine teste libidini pareat? Primum quid tu dicis breve? Nonne videmus quanta perturbatio rerum omnium consequatur, quanta confusio? </p>

      <p>Quis enim potest ea, quae probabilia videantur ei, non probare? Idem iste, inquam, de voluptate quid sentit? Ut scias me intellegere, primum idem esse dico voluptatem, quod ille don. Ratio enim nostra consentit, pugnat oratio. Minime vero, inquit ille, consentit. </p>
      
      <p>Et quidem iure fortasse, sed tamen non gravissimum est testimonium multitudinis. Quorum sine causa fieri nihil putandum est. Videamus igitur sententias eorum, tum ad verba redeamus. Tum mihi Piso: Quid ergo? Huius, Lyco, oratione locuples, rebus ipsis ielunior. Quam si explicavisset, non tam haesitaret. Cur id non ita fit? Non quaero, quid dicat, sed quid convenienter possit rationi et sententiae suae dicere. </p>
      
      <p>Nam, ut sint illa vendibiliora, haec uberiora certe sunt. Theophrastus mediocriterne delectat, cum tractat locos ab Aristotele ante tractatos? Videamus igitur sententias eorum, tum ad verba redeamus. Idem adhuc; Is es profecto tu. Idem etiam dolorem saepe perpetiuntur, ne, si id non faciant, incidant in maiorem. </p>
      
      <p>Duo Reges: constructio interrete. Si quicquam extra virtutem habeatur in bonis. Nunc haec primum fortasse audientis servire debemus. Igitur neque stultorum quisquam beatus neque sapientium non beatus. Nam quid possumus facere melius? </p>
      
      <p>Apud ceteros autem philosophos, qui quaesivit aliquid, tacet; Scisse enim te quis coarguere possit? Id quaeris, inquam, in quo, utrum respondero, verses te huc atque illuc necesse est. Hoc loco discipulos quaerere videtur, ut, qui asoti esse velint, philosophi ante fiant. Ne amores quidem sanctos a sapiente alienos esse arbitrantur. Praeclarae mortes sunt imperatoriae; Itaque rursus eadem ratione, qua sum paulo ante usus, haerebitis. Scientiam pollicentur, quam non erat mirum sapientiae cupido patria esse cariorem. </p>
      
      `,
      status: 1,
      location: 'en',
      user_id: 1,
      category: 'Sports',
      sport_club_id: null,
      publication_end_date: null,
      sharePost: 0,
    },
    {
      id: 6,
      img: require('@/assets/images/slider/10.jpg'),
      title: 'Ships at a distance have Every Man’s Wish on Board',
      thumb: require('@/assets/images/slider/02.jpg'),
      author: {
        id: 6,
        fullName: 'Diana',
        avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
      },
      published: 'April 20, 2022',
      tags: ['Fashion', 'Video'],
      description: 'A little personality goes a long way, especially on a business new. So don’t be afraid to let loose.',
      content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Si enim ad populum me vocas, eum. Quamvis enim depravatae non sint, pravae tamen esse possunt. Non igitur de improbo, sed de callido improbo quaerimus, qualis Q. Duo Reges: constructio interrete. Cum autem venissemus in Academiae non sine causa nobilitata spatia, solitudo erat ea, quam volueramus. Sed emolumenta communia esse dicuntur, recte autem facta et peccata non habentur communia. Hoc tu nunc in illo probas. </p>

      <p>Semper enim ita adsumit aliquid, ut ea, quae prima dederit, non deserat. Sapientem locupletat ipsa natura, cuius divitias Epicurus parabiles esse docuit. Quis Aristidem non mortuum diligit? Qua igitur re ab deo vincitur, si aeternitate non vincitur? Beatus autem esse in maximarum rerum timore nemo potest. Neque solum ea communia, verum etiam paria esse dixerunt. Eam tum adesse, cum dolor omnis absit; </p>
      
      <p>Hunc ipsum Zenonis aiunt esse finem declarantem illud, quod a te dictum est, convenienter naturae vivere. Facit igitur Lucius noster prudenter, qui audire de summo bono potissimum velit; Quoniam, si dis placet, ab Epicuro loqui discimus. Quid enim me prohiberet Epicureum esse, si probarem, quae ille diceret? Universa enim illorum ratione cum tota vestra confligendum puto. Quae qui non vident, nihil umquam magnum ac cognitione dignum amaverunt. Indicant pueri, in quibus ut in speculis natura cernitur. Nonne igitur tibi videntur, inquit, mala? </p>
      
      <p>Cupiditates non Epicuri divisione finiebat, sed sua satietate. Sed quoniam et advesperascit et mihi ad villam revertendum est, nunc quidem hactenus; Illa argumenta propria videamus, cur omnia sint paria peccata. Quam ob rem tandem, inquit, non satisfacit? Qua ex cognitione facilior facta est investigatio rerum occultissimarum. Servari enim iustitia nisi a forti viro, nisi a sapiente non potest. Negat enim summo bono afferre incrementum diem. Tubulo putas dicere? Laelius clamores sofòw ille so lebat Edere compellans gumias ex ordine nostros. </p>
      
      <p>Ostendit pedes et pectus. Graecum enim hunc versum nostis omnes-: Suavis laborum est praeteritorum memoria. Cur post Tarentum ad Archytam? Theophrasti igitur, inquit, tibi liber ille placet de beata vita? Iubet igitur nos Pythius Apollo noscere nosmet ipsos. O magnam vim ingenii causamque iustam, cur nova existeret disciplina! Perge porro. Tanta vis admonitionis inest in locis; Scientiam pollicentur, quam non erat mirum sapientiae cupido patria esse cariorem. Ne in odium veniam, si amicum destitero tueri. Quid, cum fictas fabulas, e quibus utilitas nulla elici potest, cum voluptate legimus? </p>
      
      <p>Possumusne ergo in vita summum bonum dicere, cum id ne in cena quidem posse videamur? Atqui, inquam, Cato, si istud optinueris, traducas me ad te totum licebit. Gloriosa ostentatio in constituendo summo bono. Si enim ad populum me vocas, eum. </p>
      
      `,
      status: 0,
      location: 'en',
      user_id: 6,
      category: 'Sports',
      sport_club_id: null,
      publication_end_date: null,
      sharePost: 0,
    },
  ],

  // sidebar
  newSidebar: {
    recentPosts: [
      {
        id: 7,
        img: require('@/assets/images/banner/banner-22.jpg'),
        title: 'Why Should Forget Facebook?',
        createdTime: 'Jan 14 2020',
      },
      {
        id: 8,
        img: require('@/assets/images/banner/banner-27.jpg'),
        title: 'Publish your passions, your way',
        createdTime: 'Mar 04 2020',
      },
      {
        id: 9,
        img: require('@/assets/images/banner/banner-39.jpg'),
        title: 'The Best Ways to Retain More',
        createdTime: 'Feb 18 2020',
      },
      {
        id: 10,
        img: require('@/assets/images/banner/banner-35.jpg'),
        title: 'Share a Shocking Fact or Statistic',
        createdTime: 'Oct 08 2020',
      },
    ],
    categories: [
      { category: 'Fashion', icon: 'WatchIcon' },
      { category: 'Food', icon: 'ShoppingCartIcon' },
      { category: 'Gaming', icon: 'CommandIcon' },
      { category: 'Quote', icon: 'HashIcon' },
      { category: 'Video', icon: 'VideoIcon' },
    ],
  },

  // detail
  newDetail: {
    new: {
      img: require('@/assets/images/banner/banner-12.jpg'),
      title: 'The Best Features Coming to iOS and Web design',
      avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
      userFullName: 'Ghani Pradita',
      createdTime: 'Jan 10, 2020',
      tags: ['Gaming', 'Video'],
      content:
        '<p>Before you get into the nitty-gritty of coming up with a perfect title, start with a rough draft: your working title. What is that, exactly? A lot of people confuse working titles with topics. Let\'s clear that Topics are very general and could yield several different new posts. Think "raising healthy kids," or "kitchen storage." A writer might look at either of those topics and choose to take them in very, very different directions.A working title, on the other hand, is very specific and guides the creation of a single new post. For example, from the topic "raising healthy kids," you could derive the following working title See how different and specific each of those is? That\'s what makes them working titles, instead of overarching topics.</p><h4>Unprecedented Challenge</h4><ul class="mb-2"><li>Preliminary thinking systems</li><li>Bandwidth efficient</li><li>Green space</li><li>Social impact</li><li>Thought partnership</li><li>Fully ethical life</li></ul>  ',
      comments: 19100,
      bookmarked: 139,
      UserComment: [
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
          fullName: 'Willie Clark',
          comment:
            'Based in London, Uncode is a new by Willie Clark. His posts explore modern design trends through photos and quotes by influential creatives and web designer around the world.',
        },
      ],
    },
    comments: [
      {
        avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
        userFullName: 'Chad Alexander',
        commentedAt: 'May 24, 2020',
        commentText:
          'A variation on the question technique above, the multiple-choice question great way to engage your reader.',
      },
    ],
  },

  // edit
  newEdit: {
    avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
    userFullName: 'Chad Alexander',
    createdTime: 'May 24, 2020',
    newTitle: 'The Best Features Coming to iOS and Web design',
    newCategories: ['Fashion', 'Gaming'],
    slug: 'the-best-features-coming-to-ios-and-web-design',
    status: 'Published',
    description:
      '<p>Cupcake ipsum dolor sit. Amet dessert donut candy chocolate bar cotton dessert candy chocolate. Candy muffin danish. Macaroon brownie jelly beans marzipan cheesecake oat cake. Carrot cake macaroon chocolate cake. Jelly brownie jelly. Marzipan pie sweet roll.</p><p><br></p><p>Liquorice dragée cake chupa chups pie cotton candy jujubes bear claw sesame snaps. Fruitcake chupa chups chocolate bonbon lemon drops croissant caramels lemon drops. Candy jelly cake marshmallow jelly beans dragée macaroon. Gummies sugar plum fruitcake. Candy canes candy cupcake caramels cotton candy jujubes fruitcake.</p>',
    featuredImage: require('@/assets/images/slider/03.jpg'),
  },
}
/* eslint-disable global-require */
mock.onGet('/news/list/data').reply(({ params }) => {
  let dataList = data.newList

  dataList.forEach(item => {
    if (item.sport_club_id != null) {
      const clubData = dataClubes.filter(sportClub => sportClub.id === Number(item.sport_club_id))
      item.sport_club = clubData.length > 0 ? clubData[0] : null
    }
    // Ostrail
    if (item.sport_club_id == null) {
      item.sport_club = ostrail
    }
    if (item.sport_club != null) {
      const indexCountry = dataCountries.findIndex(element => element.id === item.sport_club.country_id)
      const indexCity = dataCities.findIndex(element => element.id === item.sport_club.city_id)
      item.sport_club.country = dataCountries[indexCountry]
      item.sport_club.city = dataCities[indexCity]
    }
  })

  // Search
  const filter = dataList
  if (params.filters.search != null) {
    const dataFilter = filter.filter(item => item.title !== null)
    dataList = dataFilter.filter(item => item.title.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }
  // Description
  if (params.filters.search != null && dataList.length === 0) {
    const dataFilter = filter.filter(item => item.description !== null)
    dataList = dataFilter.filter(item => item.description.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }
  // Author
  if (params.filters.search != null && dataList.length === 0) {
    const dataFilter = filter.filter(item => item.author.fullName !== null)
    dataList = dataFilter.filter(item => item.author.fullName.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }
  // End Search

  // Filters
  // Author
  if (params.filters.idClub != null) {
    const dataFilter = dataList.filter(item => item.sport_club_id !== null)
    dataList = dataFilter.filter(item => item.sport_club_id === Number(params.filters.idClub))
  }

  // Filter Locale
  if (params.filters.locale != null) {
    const dataFilter = dataList.filter(item => item.location !== null)
    dataList = dataFilter.filter(item => item.location === params.filters.locale)
  }

  // Filter Date
  if (params.filters.initDate != null) {
    const dataFilter = dataList.filter(item => item.published !== null)
    dataList = dataFilter.filter(item => new Date(item.published) >= new Date(params.filters.initDate))
  }
  if (params.filters.endDate != null) {
    const dataFilter = dataList.filter(item => item.published !== null)
    dataList = dataFilter.filter(item => new Date(item.published) <= new Date(params.filters.endDate))
  }

  // Filter status
  if (params.filters.status == null) {
    dataList = dataList.filter(item => item.status !== 2)
  }
  if (params.filters.status == 0) {
    dataList = dataList.filter(item => item.status === 0)
  }
  if (params.filters.status == 1) {
    dataList = dataList.filter(item => item.status === 1)
  }

  dataList = paginateArray(dataList, params.filters.perPage, params.filters.page)

  return [200, {
    data: dataList,
    totalRecors: dataList.length,
    to: params.filters.perPage * (params.filters.page - 1) + dataList.length,
    from: params.filters.perPage * (params.filters.page - 1) + (dataList.length ? 1 : 0),
  }]
})

mock.onGet('/news/list/data/sidebar/').reply(() => [200, data.newSidebar])
mock.onGet('/news/list/data/detail').reply(() => [200, data.newDetail])
mock.onGet('/news/list/data/edit').reply(() => [200, data.newEdit])

mock.onGet('/news/sidebar').reply(() => {
  const dataList = data.newList.filter(item => item.status === 1 && item.location === i18n.locale)
  dataList.forEach(item => {
    item.headline = item.title
  })
  return [200, dataList]
})

mock.onGet(/\/new\/\d+/).reply(config => {
  const newId = config.url.split('/')[2]

  const objNew = data.newList.find(item => item.id === Number(newId))

  if (objNew.sport_club_id != null) {
    const clubData = dataClubes.filter(sportClub => sportClub.id === Number(objNew.sport_club_id))
    objNew.sport_club = clubData.length > 0 ? clubData[0] : null
  }
  // Ostrail
  if (objNew.sport_club_id == null) {
    objNew.sport_club = ostrail
  }
  if (objNew.sport_club != null) {
    const indexCountry = dataCountries.findIndex(element => element.id === objNew.sport_club.country_id)
    const indexCity = dataCities.findIndex(element => element.id === objNew.sport_club.city_id)
    objNew.sport_club.country = dataCountries[indexCountry]
    objNew.sport_club.city = dataCities[indexCity]
  }

  return [200, objNew]
})

// ------------------------------------------------
// POST: Add new objNew
// ------------------------------------------------
mock.onPost('/news').reply(config => {
  // Get objNew from post data
  const { objNew } = JSON.parse(config.data)

  const userData = JSON.parse(localStorage.getItem('userData'))

  const { length } = data.newList
  let lastIndex = 0
  if (length) {
    lastIndex = data.newList[length - 1].id
  }
  objNew.id = lastIndex + 1
  objNew.user_id = userData.id
  objNew.thumb = require('@/assets/images/slider/new1.jpg')
  objNew.img = require('@/assets/images/slider/new1.jpg')
  objNew.author.id = objNew.user_id
  objNew.author.fullName = userData.fullName
  objNew.author.avatar = userData.avatar_file
  objNew.published = new Date()

  // data.newList.push(objNew)
  data.newList.unshift(objNew)

  return [201, objNew]
})

// ------------------------------------------------
// POST: Update Event
// ------------------------------------------------
mock.onPost(/\/news\/\d+/).reply(config => {
  const { objNew: newData } = JSON.parse(config.data)

  const objNew = data.newList.find(e => e.id === Number(newData.id))
  Object.assign(objNew, newData)

  return [200, objNew]
})

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete(/\/news\/\d+/).reply(config => {
  // Get new id from URL
  const newId = config.url.substring(config.url.lastIndexOf('/') + 1)

  const newIndex = data.newList.findIndex(e => e.id === Number(newId))
  data.newList.splice(newIndex, 1)
  return [200, newId]
})

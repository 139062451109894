// import axios from '@axios'
import {
  getNotifications,
  destroyNotification,
  updateStatusNotification,
  showNotification,
  counterNotifications,
  markAllAsRead,
} from '@/services/notificationService'

export default {
  namespaced: true,
  state: {
    notifications: [],
    notification: {},
    total: 0,
  },
  getters: {
    notifications(state) {
      return state.notifications
    },
    unreadNotfications(state) {
      return state.total
    },
  },
  mutations: {
    SET_NOTIFICATIONS(state, value) {
      state.notifications = value
    },
    SET_NOTIFICATION(state, value) {
      state.notifications.unshift(value)
    },
    SET_TOTAL(state, value) {
      state.total = value
    },
  },
  actions: {
    fetchData({ commit }) {
      return new Promise((resolve, reject) => {
        getNotifications().then(response => {
          commit('SET_NOTIFICATIONS', response.data.data)
          resolve(response)
        })
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line no-unused-vars
    fetchNotificationById({ commit }, id) {
      return new Promise((resolve, reject) => {
        showNotification(id)
          .then(response => {
            // commit('SET_NOTIFICATION', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchCounterNotifications({ commit }) {
      return new Promise((resolve, reject) => {
        counterNotifications()
          .then(response => {
            commit('SET_TOTAL', response.data.data[0])
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateStatus(ctx, id) {
      return new Promise((resolve, reject) => {
        updateStatusNotification(id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    remove(ctx, id) {
      return new Promise((resolve, reject) => {
        destroyNotification(id).then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    markAllAsRead() {
      return new Promise((resolve, reject) => {
        markAllAsRead().then(response => {
          resolve(response)
        })
          .catch(error => reject(error))
      })
    },
  },
}

/* eslint-disable no-param-reassign */
import mock from '@/@fake-db/mock'
import { paginateArray } from '@/@fake-db/utils'
import { data as dataClubes } from '@/@fake-db/data/clubs/club-setting'
import { data as dataCountries } from '@/@fake-db/data/catalogs/countries'
import { data as dataCities } from '@/@fake-db/data/catalogs/cities'
import dataJobPositionClub from '@/@fake-db/data/job-position/job-position-user'
import ostrail from '../clubs/ostrail'

const data = [
  {
    id: 1,
    name: 'Director',
    sport_club_id: 3,
    create_at: '2022-02-21 11:35:15',
    status: 0,
  },
  {
    id: 4,
    name: 'CEO',
    sport_club_id: 2,
    create_at: '2022-02-21 11:25:10',
    status: 1,
  },
  {
    id: 3,
    name: 'Administrador',
    sport_club_id: 1,
    create_at: '2022-02-21 11:22:15',
    status: 1,
  },
  {
    id: 2,
    name: 'Gerente general',
    sport_club_id: null,
    create_at: '2022-02-20 11:22:15',
    status: 1,
  },
]

mock.onGet('/jobs').reply(({ params }) => {
  let dataList = paginateArray(data, params.filters.perPage, params.filters.page)

  dataList.forEach(job => {
    if (job.sport_club_id != null) {
      const clubData = dataClubes.filter(sportClub => sportClub.id === job.sport_club_id)
      job.sport_club = clubData.length > 0 ? clubData[0] : null
    }
    if (job.sport_club_id == null) {
      job.sport_club = ostrail
    }
    if (job.sport_club != null) {
      const indexCountry = dataCountries.findIndex(element => element.id === job.sport_club.country_id)
      const indexCity = dataCities.findIndex(element => element.id === job.sport_club.city_id)
      job.sport_club.country = dataCountries[indexCountry]
      job.sport_club.city = dataCities[indexCity]
    }
  })

  // Filter search
  const filter = dataList
  if (params.filters.search != null) {
    const dataFilter = filter.filter(item => item.name !== null)
    dataList = dataFilter.filter(item => item.name.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }
  if (params.filters.search != null && dataList.length === 0) {
    const filterRole = filter.filter(item => item.sport_club != null)
    dataList = filterRole.filter(item => item.sport_club.club_name.toLowerCase().includes(params.filters.search.toLocaleLowerCase()))
  }

  // Filter country
  if (params.filters.idCountry != null) {
    const dataFilter = dataList.filter(item => item.sport_club_id !== null)
    dataList = dataFilter.filter(item => item.sport_club.country_id === params.filters.idCountry)
  }

  // Filter state
  if (params.filters.idState != null) {
    const dataFilter = dataList.filter(item => item.sport_club_id !== null)
    dataList = dataFilter.filter(item => item.sport_club.city_id === params.filters.idState)
  }

  // Filter club
  if (params.filters.idClub != null) {
    const dataFilter = dataList.filter(item => item.sport_club_id !== null)
    dataList = dataFilter.filter(item => item.sport_club_id === params.filters.idClub)
  }

  // Filter status
  if (params.filters.status == null) {
    dataList = dataList.filter(item => item.status !== 2)
  }
  if (params.filters.status == 0) {
    dataList = dataList.filter(item => item.status === 0)
  }
  if (params.filters.status == 1) {
    dataList = dataList.filter(item => item.status === 1)
  }

  if (params.filters.status == 2) {
    dataList = dataList.filter(item => item.status === 2)
  }

  return [200, {
    data: dataList,
    totalRecors: data.length,
    to: params.filters.perPage * (params.filters.page - 1) + dataList.length,
    from: params.filters.perPage * (params.filters.page - 1) + (dataList.length ? 1 : 0),
  }]
})
// ------------------------------------------------
// POST: Add new job
// ------------------------------------------------
mock.onPost('/jobs').reply(config => {
  // Get event from post data
  const job = JSON.parse(config.data)

  job.id = Date.now()
  job.create_at = new Date()
  job.status = 1

  // Club
  if (job.sport_club_id != null) {
    const clubData = dataClubes.filter(sportClub => sportClub.id === job.sport_club_id)
    job.sport_club = clubData.length > 0 ? clubData[0] : null
  }
  if (job.sport_club_id == null) {
    job.sport_club = ostrail
  }
  if (job.sport_club != null) {
    const indexCountry = dataCountries.findIndex(element => element.id === job.sport_club.country_id)
    const indexCity = dataCities.findIndex(element => element.id === job.sport_club.city_id)
    job.sport_club.country = dataCountries[indexCountry]
    job.sport_club.city = dataCities[indexCity]
  }
  data.unshift(job)

  return [200, job]
})

// ------------------------------------------------
// POST: update job
// ------------------------------------------------
mock.onPut('/jobs').reply(config => {
  // Get event from post data
  const job = JSON.parse(config.data)
  const index = data.findIndex(element => element.id === job.id)
  data[index] = job

  return [200, job]
})

// ------------------------------------------------
// POST: delete job
// ------------------------------------------------
mock.onPost('/jobs/delete').reply(config => {
  // Get event from post data
  const job = JSON.parse(config.data)
  const index = data.findIndex(element => element.id === job.id)
  data.splice(index, 1)

  return [200, job]
})

mock.onGet('/jobs/list').reply(() => {
  const jobs = data.filter(item => item.sport_club_id == null)
  return [200, jobs]
})

const response = []
mock.onGet(/\/jobs\/\d+/).reply(config => {
  const clubId = config.url.split('/')[2]

  const jobs = data.filter(item => item.sport_club_id === Number(clubId))

  if (jobs.length > 0) {
    response.push(jobs)
  }
  const [...resul] = response

  return [200, resul]
})

// const response = []
mock.onGet(/\/jobs-club\/\d+/).reply(config => {
  const clubId = config.url.split('/')[2]

  const jobs = data.filter(item => item.sport_club_id === Number(clubId))

  return [200, jobs]
})

export default data

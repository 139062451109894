import axios from '@axios'

export default {
  namespaced: true,
  state: {
    list: {},
  },
  getters: {
    list(state) {
      return state.list
    },
  },
  mutations: {
    setlist(state, listData) {
      state.list = listData
    },
    // setAccesDirect(state, accesData) {
    //   state.list.push(accesData)
    // },
  },
  actions: {
    fetchData({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/widget/list')
          .then(response => {
            resolve(response)
            commit('setlist', response.data)
          })
          .catch(error => reject(error))
      })
    },
    // addData({ commit }, directAccessData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/access/direct', { directAccess: directAccessData })
    //       .then(response => {
    //         resolve(response)
    //         commit('setAccesDirect', response.data)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
